
import { ImageExtension, VideoExtension } from '@/types/simulation';
import Vue, { PropType } from 'vue';
import Player from 'xgplayer';

const boronUrl = process.env.VUE_APP_BORON;
export default Vue.extend({
  props: {
    visible: { type: Boolean },
    fileItem: { type: Object as PropType<{ name: string; path: string; id: string }> },
  },
  data() {
    return {
      player: {},
      videoExtension: [...VideoExtension],
      boronUrl,
    };
  },
  computed: {
    isVideo(): boolean {
      const extension = this.fileItem.name
        ?.substring(this.fileItem.name.lastIndexOf('.') + 1)
        ?.toUpperCase();
      return this.videoExtension.includes(extension);
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false);
    },
    handleConifrm() {},
  },
  mounted() {
    this.player = new Player({
      el: this.$refs.mse as HTMLElement,
      url: `${boronUrl}/${this.fileItem.path}`,
      fluid: true,
    });
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          if (!this.isVideo) {
            return;
          }
          if (!document.querySelector('#mse')) {
            setTimeout(() => {
              this.player = new Player({
                el: this.$refs.mse as HTMLElement,
                url: `${boronUrl}/${this.fileItem.path}`,
                fluid: true,
              });
            }, 100);
          } else {
            this.player = new Player({
              el: this.$refs.mse as HTMLElement,
              url: `${boronUrl}/${this.fileItem.path}`,
              fluid: true,
            });
          }
        }
      },
      immediate: true,
    },
  },
});
