export const myConfirm = (vue, { text, resolve, cancel = () => {} }) => {
  vue
    .$confirm(text, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    })
    .then(resolve)
    .catch(cancel);
};
