// 产品信息相关
import * as api from '@/api';
import { Module } from 'vuex';
import {
  ProductTypeTreeNode,
} from '@/api/types';
import { RootState, ProductInfoState } from '../types';

const productInfoModule: Module<ProductInfoState, RootState> = {
  namespaced: true,
  state: {
    selectedClassify: [],
    categoryOptions: [],
    brandOptions: {} as any,
    currentProductInfo: {},
    productCertifications: [],
    fireRatings: [],
    isos: [],
    historyOptions: [],
    typeForestData: [] as ProductTypeTreeNode[],
  },
  mutations: {
    typeForestData(state, typeForestData) {
      state.typeForestData = typeForestData;
    },
    historyOptions(state, historyOptions) {
      state.historyOptions = historyOptions;
    },
    selectedClassify(state, classify) {
      state.selectedClassify = classify;
    },
    categoryOptions(state, categoryOptions) {
      state.categoryOptions = categoryOptions;
    },
    brandOptions(state, brandOptions) {
      state.brandOptions = brandOptions;
    },
    currentProductInfo(state, currentProductInfo) {
      state.currentProductInfo = currentProductInfo;
    },
    productCertifications(state, productCertifications) {
      state.productCertifications = productCertifications;
    },
    fireRatings(state, fireRatings) {
      state.fireRatings = fireRatings;
    },
    isos(state, isos) {
      state.isos = isos;
    },
  },
  getters: {},
  actions: {
    async getProductTypeHistory({ commit }) {
      const res = await api.getProductTypeHistory();
      if (!api.error(res)) {
        commit('historyOptions', res.data);
      }
    },
    async getProductTypeTreeSimple({ commit }) {
      const res = await api.getProductTypeTreeSimple();
      if (!api.error(res)) {
        commit('typeForestData', res.data.roots);
      }
      return res;
    },
    async getCategoryOptions({ commit }) {
      const res = await api.getCategoryOptions();
      if (!api.error(res)) {
        commit('categoryOptions', res.data);
      }
    },
    async getBrandOptions({ commit }, { MaxResultCount, SkipCount }) {
      const res = await api.getBrandOptions({ MaxResultCount, SkipCount });
      if (!api.error(res)) {
        commit('brandOptions', res.data);
      }
    },
    async getProductCertifications({ commit }) {
      const res = await api.getEnums({ group: 'Cert_BuildingMaterials' });
      if (!api.error(res)) {
        commit('productCertifications', res.data);
      }
    },
    async getFireRatings({ commit }) {
      const res = await api.getEnums({ group: 'FireRating' });
      if (!api.error(res)) {
        commit('fireRatings', res.data);
      }
    },
    async getIsos({ commit }) {
      const res = await api.getEnums({ group: 'ISO' });
      if (!api.error(res)) {
        commit('isos', res.data);
      }
    },
  },
};

export default productInfoModule;
