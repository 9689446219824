
  // 只支持数字+文字，大屏左右排列，小屏上下排列
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      borderColor: {
        type: String,
        default: 'rgba(0, 0, 0, 0.15)',
      },
      bgColor: {
        type: String,
        default: '#fff',
      },
      color: {
        type: String,
        default: 'rgba(0, 0, 0, 0.15)',
      },
      txtColor: {
        type: String,
        default: '#999',
      },
      step: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
    },
    methods: {
      handleStep() {
        this.$emit('currentStep', this.step);
      },
    },
  });
