/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Building3DApplicationDtosInAccountRegisterDto {
  /** 密码 */
  password?: string | null;

  /** 验证码 */
  captcha?: string | null;

  /** 手机号 */
  phoneNumber?: string | null;
}

/**
 * 找回密码下一步
 */
export interface Building3DApplicationDtosInFindPasswordNextStepDto {
  /** 手机号 */
  phoneNumber: string;

  /** 验证码 */
  captcha: string;
}

export interface Building3DApplicationDtosInResetPasswordDto {
  /** 手机号 */
  phoneNumber: string;

  /** 验证码 */
  captcha: string;

  /** 新密码 */
  newPassword: string;
}

/**
 * 品牌列表
 */
export interface Building3DWebMainDtosOutBrandListDto {
  /** Logo */
  logo?: string | null;

  /** 名称 */
  name?: string | null;

  /**
   * 产品数
   * @format int64
   */
  productCount?: number;

  /**
   * 粉丝数
   * @format int64
   */
  fansCount?: number;

  /**
   * 品牌Id
   * @format int64
   */
  brandId?: number;

  /** 已关注? */
  isConcerned?: boolean;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebMainDtosOutBrandListDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebMainDtosOutBrandListDto[] | null;
}

/**
 * 品牌详情
 */
export interface Building3DWebMainDtosOutBrandDetailDto {
  /** Logo */
  logo?: string | null;

  /** 名称 */
  name?: string | null;

  /**
   * 产品数
   * @format int64
   */
  productCount?: number;

  /** 电话 */
  phone?: string | null;

  /** 邮箱 */
  email?: string | null;

  /** 网址 */
  webSite?: string | null;

  /** 地址 */
  address?: string | null;

  /** 简介 */
  summary?: string | null;

  /** 是否关注 */
  isConcerned?: boolean;

  /** 认证图片 */
  certificationImages?: string[] | null;
}

export interface Building3DWebAdminDtosOutBrandCertificationDto {
  /** @format int64 */
  id?: number;
  txt?: string | null;
}

/**
 * 品牌列表
 */
export interface Building3DWebAdminDtosOutBrandPageItemDto {
  /**
   * 品牌Id
   * @format int64
   */
  id?: number;

  /** 编码 */
  code?: string | null;

  /** 拼音第一个字母 */
  pinyinFirstLetter?: string | null;

  /** 品牌图片 */
  imageUrl?: string | null;

  /** 名称 */
  name?: string | null;

  /**
   * 品牌下商品数量
   * @format int64
   */
  productCount?: number;

  /**
   * 粉丝数
   * @format int64
   */
  fansCount?: number;

  /** 电话 */
  phone?: string | null;

  /** 邮箱 */
  email?: string | null;

  /** 网址 */
  webSite?: string | null;

  /** 地址 */
  address?: string | null;

  /** 简介 */
  summary?: string | null;

  /** 是否制造商 */
  isManufacturer?: boolean;

  /** 前台是否显示 */
  isShow?: boolean;

  /** 是否可删除 */
  deletable?: boolean;

  /** 是否是系统内置品牌(系统内置品牌不允许删除,修改操作) */
  isBuiltin?: boolean;

  /** 认证Ids */
  certifications?: Building3DWebAdminDtosOutBrandCertificationDto[] | null;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutBrandPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebAdminDtosOutBrandPageItemDto[] | null;
}

export interface Building3DApplicationDtosInBrandDto {
  /**
   * 品牌Id
   * @format int64
   */
  brandId?: number | null;

  /** 品牌图片 */
  imageUrl: string;

  /** 名称 */
  name: string;

  /** 电话 */
  phone: string;

  /** 邮箱 */
  email: string;

  /** 网址 */
  webSite: string;

  /** 地址 */
  address: string;

  /** 简介 */
  summary: string;

  /** 是否制造商 */
  isManufacturer?: boolean;

  /** 前台是否显示 */
  isShow?: boolean;

  /** 选中的认证Ids */
  certificationIds?: number[] | null;
}

/**
 * 修改是否是制造商
 */
export interface Building3DApplicationDtosInBrandIsOrNotManufacturerDto {
  /** @format int64 */
  brandId?: number;

  /** 是否是制造商 */
  isManufacturer?: boolean;
}

/**
 * 修改是否是前台显示
 */
export interface Building3DApplicationDtosInBrandIsOrNotShowDto {
  /** @format int64 */
  brandId?: number;

  /** 是否是前台显示 */
  isShow?: boolean;
}

export interface Building3DWebVenderDtosOutBrandOptionDto {
  /**
   * 品牌Id
   * @format int64
   */
  brandId?: number;

  /** 名称 */
  name?: string | null;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosOutBrandOptionDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebVenderDtosOutBrandOptionDto[] | null;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesReceiverType = 0 | 1;

/**
 * @format int32
 */
export type Building3DDomainEntitiesBroadcastStatus = 0 | 10;

export interface Building3DWebAdminDtosOutBroadcastMessagePageItemDto {
  /**
   * 消息Id
   * @format int64
   */
  messageId?: number;

  /** 编号 */
  code?: string | null;

  /** 标题 */
  title?: string | null;
  receiverType?: Building3DDomainEntitiesReceiverType;

  /**
   * 创建时间
   * @format date-time
   */
  createdAt?: string;

  /**
   * 发布时间
   * @format date-time
   */
  publishedAt?: string | null;

  /** 创建人员 */
  creator?: string | null;
  status?: Building3DDomainEntitiesBroadcastStatus;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutBroadcastMessagePageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebAdminDtosOutBroadcastMessagePageItemDto[] | null;
}

export interface Building3DApplicationDtosInBroadcastMessageDto {
  /** @format int64 */
  messageId?: number | null;

  /** 标题 */
  title?: string | null;
  receiverType?: Building3DDomainEntitiesReceiverType;

  /** 内容 */
  content?: string | null;
}

export interface Building3DApplicationDtosOutBroadcastMessageSaveDto {
  /** @format int64 */
  messageId?: number;
}

export interface Building3DWebAdminDtosOutBroadcastMessageDto {
  /**
   * 消息Id
   * @format int64
   */
  messageId?: number;

  /** 标题 */
  title?: string | null;
  receiverType?: Building3DDomainEntitiesReceiverType;

  /** 内容 */
  content?: string | null;
}

export interface Building3DApplicationDtosInCertificationDto {
  /** Logo */
  logo?: string | null;
  txt?: string | null;
}

export interface Building3DWebMainDtosOutComparisonPropertyDto {
  /** 属性名 */
  name?: string | null;

  /** 单位 */
  unit?: string | null;

  /** 值 */
  value?: string | null;
}

export interface Building3DWebMainDtosOutComparisonModelDto {
  modelGroupId?: string | null;
  modelPath?: string | null;
}

export interface Building3DWebMainDtosOutComparisonProductDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 名称 */
  name?: string | null;

  /** 型号 */
  modelCode?: string | null;

  /** 缩略图 */
  thumbnail?: string | null;

  /** 品牌 */
  brand?: string | null;

  /** 属性 */
  properties?: Building3DWebMainDtosOutComparisonPropertyDto[] | null;

  /** 模型分组Id */
  modelGroupId?: string | null;
  threeDModel?: Building3DWebMainDtosOutComparisonModelDto;
  drawings?: Building3DWebMainDtosOutComparisonModelDto;
}

export interface Building3DWebFoundationDtosOutEnumDto {
  val?: string | null;
  txt?: string | null;
}

export interface Building3DWebFoundationDtosOutLogDto {
  /** @format int32 */
  id?: number;
  application?: string | null;
  logged?: string | null;
  level?: string | null;
  message?: string | null;
  logger?: string | null;
  callsite?: string | null;
  exception?: string | null;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebFoundationDtosOutLogDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebFoundationDtosOutLogDto[] | null;
}

export interface Building3DWebAdminDtosOutMemberPageItemDto {
  /** @format int64 */
  id?: number;

  /** 会员ID */
  code?: string | null;

  /** 用户名 */
  userName?: string | null;

  /** 会员名 */
  nickName?: string | null;

  /** 会员等级 */
  level?: string | null;

  /**
   * 会员等级Id
   * @format int64
   */
  levelId?: number;

  /** 国家 */
  country?: string | null;

  /** 省份 */
  province?: string | null;

  /** 城市 */
  city?: string | null;

  /**
   * 注册时间
   * @format date-time
   */
  createdAt?: string;

  /**
   * 最后登录时间
   * @format date-time
   */
  lastLoginedAt?: string | null;

  /**
   * 成长值
   * @format int64
   */
  growthValue?: number;

  /** 可用? */
  isEnabled?: boolean;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutMemberPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebAdminDtosOutMemberPageItemDto[] | null;
}

/**
 * 会员统计信息
 */
export interface Building3DWebAdminDtosOutMemberStatisticsDto {
  /**
   * 登录天数
   * @format int64
   */
  loginedNumberOfDays?: number;

  /**
   * 收藏产品数量
   * @format int64
   */
  collectedProductCount?: number;

  /**
   * 关注品牌数量
   * @format int64
   */
  concernedBrandCount?: number;

  /**
   * 下载文件数量
   * @format int64
   */
  downloadedCount?: number;

  /**
   * 成长值
   * @format int64
   */
  growthValue?: number;
}

export interface Building3DApplicationDtosInMemberModifyDto {
  /** 用户名 */
  userName: string;

  /** 新密码 */
  newPassword?: string | null;

  /** 姓名 */
  nickName?: string | null;

  /** 电话 */
  phoneNumber?: string | null;

  /**
   * 会员等级Id
   * @format int64
   */
  levelId?: number;

  /** 国家 */
  country?: string | null;

  /** 省份 */
  province?: string | null;

  /** 城市 */
  city?: string | null;

  /** 可用? */
  isEnabled?: boolean;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesGrowthChangeSourceType = 0 | 1;

/**
 * @format int32
 */
export type Building3DDomainEntitiesGrowthChangeType = 0 | 1;

/**
 * 成长值明细
 */
export interface Building3DWebAdminDtosOutGrowthDetailsPageItemDto {
  sourceType?: Building3DDomainEntitiesGrowthChangeSourceType;

  /** 成长值来源 */
  sourceTypeTxt?: string | null;
  changeType?: Building3DDomainEntitiesGrowthChangeType;

  /**
   * 改变的值
   * @format int64
   */
  changeValue?: number;

  /** @format date-time */
  creationTime?: string;

  /** 操作备注 */
  operatRemark?: string | null;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutGrowthDetailsPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebAdminDtosOutGrowthDetailsPageItemDto[] | null;
}

/**
 * 修改会员成长值
 */
export interface Building3DApplicationDtosInModifyGrowthDto {
  changeType?: Building3DDomainEntitiesGrowthChangeType;

  /**
   * 改变的值
   * @format int64
   */
  changeValue?: number;

  /** 备注 */
  remark?: string | null;
}

/**
 * 会员等级
 */
export interface Building3DWebAdminDtosOutMemberLevelDto {
  /** @format int64 */
  id?: number;

  /** 等级名称 */
  name?: string | null;

  /**
   * 成长值满足点
   * @format int64
   */
  growthPoint?: number;

  /**
   * （下载权限）每日最大下载数量
   * @format int32
   */
  dailyMaxDownloadCount?: number;

  /**
   * （登录奖励）每日登录成长值
   * @format int32
   */
  loginDailyGrowth?: number;

  /** 描述 */
  description?: string | null;

  /** 可删除的 */
  deletable?: boolean;
}

/**
 * 保存会员等级
 */
export interface Building3DApplicationDtosInSaveMemberLevelDto {
  /** @format int64 */
  id?: number | null;

  /** 等级名称 */
  name: string;

  /**
   * 成长值满足点
   * @format int64
   */
  growthPoint?: number;

  /**
   * （下载权限）每日最大下载数量
   * @format int32
   */
  dailyMaxDownloadCount?: number;

  /**
   * （登录奖励）每日登录成长值
   * @format int32
   */
  loginDailyGrowth?: number;

  /** 描述 */
  description?: string | null;
}

export interface Building3DWebAdminDtosOutMemberStatisticsInfoDto {
  /**
   * 会员总数
   * @format int64
   */
  memberTotalCount?: number;

  /**
   * 本月新增
   * @format int64
   */
  thisMonthAdded?: number;

  /**
   * 本月同比增长率
   * @format double
   */
  thisMonthYearOnYearGrowth?: number;

  /**
   * 本月环比增长率
   * @format double
   */
  thisMonthAnnulusGrowth?: number;

  /**
   * 上月新增
   * @format int64
   */
  lastMonthAdded?: number;

  /**
   * 上月同比增长率
   * @format double
   */
  lastMonthYearOnYearGrowth?: number;

  /**
   * 上月环比增长率
   * @format double
   */
  lastMonthAnnulusGrowth?: number;

  /**
   * 第一个会员注册时间
   * @format date-time
   */
  firstRegisteredAt?: string;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesTimeRange = 0 | 1 | 2;

/**
 * 会员新增趋势
 */
export interface Building3DWebAdminDtosOutMemberStatisticsAddTrendDto {
  /** 会员数据 */
  memberDatas?: object[][] | null;
}

export interface Building3DApplicationDtosStudyManagerInClassInfoPagedDto {
  /** 班级名称 */
  name?: string | null;

  /**
   * 用户id
   * @format int64
   */
  userId?: number | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface Building3DApplicationDtosStudyManagerClassesDto {
  name?: string | null;
  description?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;
  createNickName?: string | null;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerClassesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerClassesDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerTasksPageListInput {
  /** @format int64 */
  typeId?: number | null;
  name?: string | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumTaskTargetType = 0 | 1;

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumTaskType = 0 | 1;

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumConfigure = 0 | 1;

export interface Building3DApplicationDtosStudyManagerTaskExtendsDto {
  createUserName?: string | null;
  createNickName?: string | null;
  name?: string | null;

  /** @format int64 */
  typeId?: number;

  /** @format date-time */
  beginTime?: string;

  /** @format date-time */
  endTime?: string;
  beginTimeStr?: string | null;
  endTimeStr?: string | null;
  logo?: string | null;
  remark?: string | null;
  targetType?: Building3DDomainEntitiesEnumTaskTargetType;
  type?: Building3DDomainEntitiesEnumTaskType;
  configure?: Building3DDomainEntitiesEnumConfigure;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerTaskExtendsDto[] | null;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumFileType = 0 | 1 | 2 | 3 | 4 | 5;

export interface Building3DApplicationDtosStudyManagerTaskFilesPageListInput {
  /** @format int64 */
  taskId?: number;
  isTaskRule?: boolean | null;
  type?: Building3DDomainEntitiesEnumFileType;

  /** 锁定项 */
  isLocked?: boolean | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface Building3DApplicationDtosStudyManagerTaskFilesDto {
  /**
   * 文件上传标识id
   * @format int64
   */
  uploadFileId?: number;

  /**
   * 作业标识
   * @format int64
   */
  taskId?: number;

  /** 是否标准模型 */
  isTaskRule?: boolean;

  /**
   * 审查规则Id
   * @format int64
   */
  taskRuleId?: number;
  taskType?: Building3DDomainEntitiesEnumTaskType;

  /** 对应题库的task name */
  questionName?: string | null;

  /**
   * 对应题库的task id
   * @format int64
   */
  questionId?: number;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumFileConvertState = 0 | 1 | 2 | -1;

export interface Building3DApplicationDtosStudyManagerUploadFilesDto {
  /** 文件库标识 */
  fileId?: string | null;

  /** 文件名：xxx.rvt */
  fileName?: string | null;

  /** 文件后缀: .rvt .pdf */
  fileExtention?: string | null;

  /** 转换任务标识 */
  fileTaskId?: string | null;
  fileConvertState?: Building3DDomainEntitiesEnumFileConvertState;

  /** 文件hash */
  fileHash?: string | null;

  /**
   * 文件大小
   * @format int64
   */
  fileSize?: number;

  /** 文件url */
  fileUrl?: string | null;

  /**
   * modelgroupid
   * @format uuid
   */
  modelGroupId?: string;
  type?: Building3DDomainEntitiesEnumFileType;

  /** 锁定状态 */
  isLocked?: boolean;

  /** 模型缩略图 */
  modelThumbnail?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

/**
 * 保存作业文件
 */
export interface Building3DApplicationDtosStudyManagerTaskFileExtendsDto {
  taskFile?: Building3DApplicationDtosStudyManagerTaskFilesDto;
  uploadFile?: Building3DApplicationDtosStudyManagerUploadFilesDto;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskFileExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerTaskFileExtendsDto[] | null;
}

export interface Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerTaskUserCommitsPageListInput {
  isCommit?: boolean | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumTaskCommitStatus = 0 | 1 | 2 | -1;

export interface Building3DApplicationDtosStudyManagerTaskCommitExtendsDto {
  taskName?: string | null;
  userName?: string | null;
  nickName?: string | null;

  /** @format int64 */
  taskId?: number;

  /** @format int64 */
  classId?: number;

  /** @format int64 */
  userId?: number;
  status?: Building3DDomainEntitiesEnumTaskCommitStatus;

  /** 批改意见 */
  remark?: string | null;

  /**
   * 得分
   * @format int32
   */
  mark?: number;

  /**
   * 构件得分
   * @format int32
   */
  systemPropertyMark?: number;

  /**
   * 属性得分
   * @format int32
   */
  systemAttributeMark?: number;

  /**
   * 系统评分
   * @format int32
   */
  systemMark?: number;

  /** 批改人 */
  checkerName?: string | null;

  /**
   * 批改人
   * @format int64
   */
  checkerId?: number | null;

  /**
   * 批改时间
   * @format date-time
   */
  lastCheckTime?: string | null;

  /** @format date-time */
  updateTime?: string;

  /** 报告锁定状态 */
  reportLocked?: boolean;

  /** 附件集合 */
  attachFileIdArray?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerTaskCommitFilesDto {
  /** 是否标准模型 */
  isTaskRule?: boolean;

  /**
   * 审查规则Id
   * @format int64
   */
  taskRuleId?: number;

  /**
   * 上传文件Id
   * @format int64
   */
  uploadFileId?: number;

  /**
   * 作业提交记录Id
   * @format int64
   */
  taskCommitId?: number;

  /**
   * 作业Id
   * @format int64
   */
  taskId?: number;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerCheckReportsDto {
  /** @format int64 */
  taskId?: number;

  /** @format int64 */
  taskCommitId?: number;

  /** @format double */
  mark?: number;
  isQualified?: boolean;
  reportUrl?: string | null;
  path?: string | null;
  hash?: string | null;
  checkResult?: string | null;
  errorFileUrl?: string | null;
  errorFilePath?: string | null;
  errorFileHash?: string | null;
  errorMessage?: string | null;

  /**
   * 创建人标识
   * @format int64
   */
  createUserId?: number;

  /**
   * 创建时间
   * @format date-time
   */
  creationTime?: string;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerTaskUserCommitExtendsDto {
  /** @format int64 */
  userId?: number;
  userName?: string | null;
  phoneNumber?: string | null;
  nickName?: string | null;
  commitInfo?: Building3DApplicationDtosStudyManagerTaskCommitExtendsDto;
  commitFile?: Building3DApplicationDtosStudyManagerTaskCommitFilesDto;
  uploadFile?: Building3DApplicationDtosStudyManagerUploadFilesDto;
  checkReportInfo?: Building3DApplicationDtosStudyManagerCheckReportsDto;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskUserCommitExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?:
  | Building3DApplicationDtosStudyManagerTaskUserCommitExtendsDto[]
  | null;
}

/**
 * 提交作业
 */
export interface Building3DApplicationDtosStudyManagerMyTaskCommitSubmitInput {
  /** @format int64 */
  uploadFileId?: number | null;
  attachFileIdList?: string[] | null;
}

export interface Building3DWebAdminDtosOutputAuditModelPdfInput {
  taskCommitId?: string | null;
  userId?: string | null;
  isRetry?: boolean;
}

export interface Building3DApplicationDtosStudyManagerUploadFilesPageListInput {
  type?: Building3DDomainEntitiesEnumFileType;

  /**
   * 作业提交记录Id
   * @format int64
   */
  taskCommitId?: number | null;

  /**
   * 作业Id
   * @format int64
   */
  taskId?: number | null;

  /** 查询多个文件集合 */
  ids?: number[] | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerUploadFilesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerUploadFilesDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerTaskNoticesPageListInput {
  /** @format int64 */
  taskId?: number | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

/**
 * 作业通知
 */
export interface Building3DApplicationDtosStudyManagerTaskNoticesDto {
  /** @format int64 */
  taskId?: number;
  content?: string | null;
  createUserName?: string | null;
  createNickName?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskNoticesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerTaskNoticesDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerTaskOpinionsDto {
  /**
   * 得分
   * @format int32
   */
  mark?: number;
  status?: Building3DDomainEntitiesEnumTaskCommitStatus;

  /** 备注 */
  remark?: string | null;

  /**
   * 作业提交记录
   * @format int64
   */
  taskCommitId?: number;

  /**
   * 作业
   * @format int64
   */
  taskId?: number;

  /** 批改图片 */
  imageUrl?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumDesignClassType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6;

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumDesignTaskStatus = 0 | 1;

export interface Building3DApplicationDtosStudyManagerDesignTasksPageListInput {
  classType?: Building3DDomainEntitiesEnumDesignClassType;

  /** @format int64 */
  typeId?: number | null;
  status?: Building3DDomainEntitiesEnumDesignTaskStatus;

  /** 专业 */
  major?: string | null;

  /** 年级 */
  grade?: string | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumDesignFinishStatus = 0 | 1 | 2;

export interface Building3DApplicationDtosStudyManagerDesignTaskExtendsDto {
  createUserName?: string | null;
  createNickName?: string | null;
  designFinishStatus?: Building3DDomainEntitiesEnumDesignFinishStatus;

  /** @format int32 */
  step?: number;

  /** 课程名称 */
  title?: string | null;

  /** 课程封面 */
  logo?: string | null;

  /**
   * 课程开始时间
   * @format date-time
   */
  beginTime?: string;

  /**
   * 课程结束时间
   * @format date-time
   */
  endTime?: string;

  /**
   * 目录
   * @format int64
   */
  typeId?: number;

  /** 专业 */
  major?: string | null;

  /** 年级 */
  grade?: string | null;

  /** 课程介绍 */
  description?: string | null;
  status?: Building3DDomainEntitiesEnumDesignTaskStatus;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignTaskExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDesignTaskExtendsDto[] | null;
}

/**
 * 定制仿真课程视点
 */
export interface Building3DApplicationDtosStudyManagerDesignTaskViewsDto {
  /** 视点设置参数 */
  viewSettings?: string | null;

  /** 标题 */
  title?: string | null;

  /**
   * 定制资源Id
   * @format int64
   */
  designTaskId?: number;

  /**
   * 排序
   * @format int32
   */
  sort?: number;

  /**
   * 定制资源
   * @format int64
   */
  designFileId?: number;
  classType?: Building3DDomainEntitiesEnumDesignClassType;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

/**
 * 定制仿真课程
 */
export interface Building3DApplicationDtosStudyManagerDesignTasksDto {
  /** 课程名称 */
  title?: string | null;

  /** 课程封面 */
  logo?: string | null;

  /**
   * 课程开始时间
   * @format date-time
   */
  beginTime?: string;

  /**
   * 课程结束时间
   * @format date-time
   */
  endTime?: string;

  /**
   * 目录
   * @format int64
   */
  typeId?: number;

  /** 专业 */
  major?: string | null;

  /** 年级 */
  grade?: string | null;

  /** 课程介绍 */
  description?: string | null;
  status?: Building3DDomainEntitiesEnumDesignTaskStatus;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerDesignTaskDetailsDto {
  /** 定制仿真课程 */
  designTask?: Building3DApplicationDtosStudyManagerDesignTasksDto;
  viewList?: Building3DApplicationDtosStudyManagerDesignTaskViewsDto[] | null;
  designFinishStatus?: Building3DDomainEntitiesEnumDesignFinishStatus;

  /** @format int32 */
  step?: number;
}

export interface Building3DApplicationDtosStudyManagerDesignFilesDto {
  /** 资源名称 */
  name?: string | null;
  classType?: Building3DDomainEntitiesEnumDesignClassType;

  /**
   * 模型文件
   * @format int64
   */
  uploadFileId?: number;

  /**
   * 资源目录Id
   * @format int64
   */
  typeId?: number;

  /** 是否设置完成 */
  isFinish?: boolean;

  /**
   * 二维图纸文件
   * @format int64
   */
  viewFileId?: number;

  /** 初始视角配置 */
  viewSettings?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerDesignFileViewsDto {
  /** 视点设置参数 */
  viewSettings?: string | null;

  /** 标题 */
  title?: string | null;

  /**
   * 定制资源Id
   * @format int64
   */
  designFileId?: number;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerDesignFileDetails {
  designFile?: Building3DApplicationDtosStudyManagerDesignFilesDto;
  uploadFile?: Building3DApplicationDtosStudyManagerUploadFilesDto;
  viewFile?: Building3DApplicationDtosStudyManagerUploadFilesDto;
  designFileViewList?:
  | Building3DApplicationDtosStudyManagerDesignFileViewsDto[]
  | null;
}

export interface Building3DApplicationDtosStudyManagerUpdateDesignFinishStatusInput {
  /** @format int64 */
  designTaskId?: number;

  /** @format int64 */
  targetId?: number;
  status?: Building3DDomainEntitiesEnumDesignFinishStatus;

  /** @format int32 */
  step?: number;
}

export interface Building3DApplicationDtosStudyManagerQueryDesignQuestionsListByIdsInput {
  ids: number[];
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumQuestionClassType = 0 | 1 | 2;

export interface Building3DApplicationDtosStudyManagerDesignQuestionsDto {
  /** 资源名称 */
  name?: string | null;
  classType?: Building3DDomainEntitiesEnumQuestionClassType;

  /**
   * 目录Id
   * @format int64
   */
  typeId?: number;

  /** 是否设置完成 */
  isFinish?: boolean;

  /** 答案配置 */
  viewSettings?: string | null;

  /** 标准答案 */
  standAnswerArray?: string | null;

  /** 解析 */
  remark?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesClientType = 0 | 1;

export interface Building3DApplicationDtosInPageVisitCreateDto {
  /** 页面地址 */
  page: string;

  /** 页面名称 */
  pageName?: string | null;
  clientType: Building3DDomainEntitiesClientType;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesProductStatus = 0 | 10 | 20 | 30;

export interface Building3DWebVenderDtosOutProductStatusInfo {
  status?: Building3DDomainEntitiesProductStatus;

  /** @format int32 */
  count?: number;
}

export interface Building3DWebVenderDtosInProductPageItemDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 编号 */
  code?: string | null;

  /** 缩略图 */
  thumbnail?: string | null;

  /** 名称 */
  name?: string | null;

  /** 型号 */
  modelNumber?: string | null;

  /** 品牌 */
  brandName?: string | null;

  /** 下线? */
  isOffline?: boolean;

  /** 新品? */
  isNew?: boolean;

  /** 族文件格式 */
  modelExtNames?: string[] | null;

  /**
   * 收藏量
   * @format int32
   */
  collectionCount?: number;

  /**
   * 下载量
   * @format int32
   */
  downloadCount?: number;

  /**
   * 版本
   * @format int32
   */
  version?: number;
  status?: Building3DDomainEntitiesProductStatus;

  /** 状态文本 */
  statusTxt?: string | null;

  /**
   * 下个版本Id
   * @format int64
   */
  nextVersionId?: number | null;

  /**
   * 下个版本号
   * @format int32
   */
  nextVersion?: number | null;
  nextVersionStatus?: Building3DDomainEntitiesProductStatus;

  /** 下个版本状态文本 */
  nextVersionStatusTxt?: string | null;

  /**
   * 第一次上架时间
   * @format date-time
   */
  shelvedAt?: string | null;

  /**
   * 创建时间
   * @format date-time
   */
  createdAt?: string | null;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosInProductPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebVenderDtosInProductPageItemDto[] | null;
}

export interface Building3DApplicationDtosInProductSaveDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number | null;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number | null;

  /** 名称 */
  name: string;

  /**
   * 品牌
   * @format int64
   */
  brandId?: number;

  /** 型号 */
  modelNumber: string;

  /**
   * 类型
   * @format int64
   */
  category?: number;

  /** 类型文本 */
  categoryTxt?: string | null;

  /** 描述 */
  description?: string | null;

  /** 分类 */
  types: number[];
}

export interface Building3DApplicationDtosOutProductSaveDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 新版本? */
  isNewVersion?: boolean;
}

export interface Building3DWebVenderDtosOutProductVersionDto {
  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 缩略图 */
  thumbnail?: string | null;

  /** 名称 */
  name?: string | null;

  /** 型号 */
  modelNumber?: string | null;

  /**
   * 上架时间
   * @format date-time
   */
  shelvedAt?: string | null;

  /**
   * 下架时间
   * @format date-time
   */
  unshelvedAt?: string | null;

  /** 族文件格式 */
  modelExtNames?: string[] | null;

  /**
   * 下载量
   * @format int32
   */
  downloadCount?: number;

  /**
   * 版本
   * @format int32
   */
  version?: number;

  /** 当前版本? */
  isCurrentVersion?: boolean;
  status?: Building3DDomainEntitiesProductStatus;

  /** 状态文本 */
  statusTxt?: string | null;
}

/**
 * 产品上架
 */
export interface Building3DApplicationDtosInProductOnlineDto {
  productIds: number[];
}

/**
 * 产品下架
 */
export interface Building3DApplicationDtosInProductOfflineDto {
  productIds: number[];
}

/**
 * 产品总览
 */
export interface Building3DWebVenderDtosOutProductSummaryDto {
  /**
   * 上架数量
   * @format int32
   */
  onlineCount?: number;

  /**
   * 下架数量
   * @format int32
   */
  offlineCount?: number;

  /**
   * 所有数量
   * @format int32
   */
  allCount?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesAuditStatus = 0 | 10;

/**
 * 所选版本的审核记录
 */
export interface Building3DWebVenderDtosOutProductAuditRecordDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** @format date-time */
  creationTime?: string;

  /** 描述 */
  description?: string | null;
  auditStatus?: Building3DDomainEntitiesAuditStatus;
}

/**
 * 范围输入
 */
export interface Building3DWebMainDtosInHighFilterPropertyRangeInputDto {
  /**
   * 最小值
   * @format double
   */
  minValue?: number | null;

  /**
   * 最大值
   * @format double
   */
  maxValue?: number | null;

  /** 是否升序 */
  isAsc?: boolean | null;
}

/**
 * 高级筛选
 */
export interface Building3DWebMainDtosInHighFilterPropertyDto {
  /** 关键字 */
  keyword?: string | null;

  /** 范围输入 */
  rangeInput?: Building3DWebMainDtosInHighFilterPropertyRangeInputDto;

  /** 列表选择 */
  listSelectValue?: string | null;
}

export interface Building3DWebMainDtosInProductPagedDto {
  sorting?: string | null;

  /** 搜索过滤 */
  keyword?: string | null;

  /** 品牌Ids */
  brandIds?: number[] | null;

  /** 文件类型 */
  fileTypeNames?: string[] | null;

  /** 分类Id */
  categoryIds?: number[] | null;

  /** 高级筛选 */
  highFilterProperties?: Building3DWebMainDtosInHighFilterPropertyDto[] | null;

  /**
   * 获取相关产品的基准产品版本Id
   * @format int64
   */
  getCorrelationOfPVId?: number | null;

  /** 获取用户已收藏产品 */
  getUserCollectedProduct?: boolean | null;

  /** 获取用户已下载产品 */
  getUserDownloadedProduct?: boolean | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

/**
 * 产品信息
 */
export interface Building3DWebMainDtosOutProductDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 品牌Logo */
  brandLogo?: string | null;

  /** 品牌名称 */
  brandName?: string | null;

  /** 产品名称 */
  name?: string | null;

  /** 产品型号 */
  modelNumber?: string | null;

  /** 产品图片 */
  imageUrl?: string | null;

  /**
   * 浏览数
   * @format int64
   */
  browsedCount?: number;

  /**
   * 收藏数
   * @format int32
   */
  collectedCount?: number;

  /** 新品? */
  isNew?: boolean;

  /** @format date-time */
  releasedTime?: string;
  certs?: string | null;

  /** 认证Logo */
  certLogos?: string[] | null;

  /** 名称拼音 */
  namePinyin?: string | null;

  /** 已收藏? */
  isCollected?: boolean;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebMainDtosOutProductDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebMainDtosOutProductDto[] | null;
}

export interface Building3DWebMainDtosInProductSearchCountDto {
  /**
   * 获取相关产品的基准产品版本Id
   * @format int64
   */
  getCorrelationOfPVId?: number | null;

  /** 获取用户已收藏产品 */
  getUserCollectedProduct?: boolean | null;

  /** 获取用户已下载产品 */
  getUserDownloadedProduct?: boolean | null;

  /** 搜索过滤 */
  keyword?: string | null;

  /** 品牌Ids */
  brandIds?: number[] | null;

  /** 文件类型 */
  fileTypeNames?: string[] | null;

  /** 分类Id */
  categoryIds?: number[] | null;

  /** 高级筛选 */
  highFilterProperties?: Building3DWebMainDtosInHighFilterPropertyDto[] | null;
}

/**
 * 产品搜索数量
 */
export interface Building3DWebMainDtosOutProductSearchCountDto {
  /**
   * 品牌数量
   * @format int64
   */
  brandCount?: number;

  /**
   * 产品数量
   * @format int64
   */
  productCount?: number;
}

export interface Building3DWebMainDtosOutFilterPropertyBrandDto {
  /**
   * 品牌Id
   * @format int64
   */
  id?: number;

  /** Logo */
  logo?: string | null;

  /** 名称 */
  name?: string | null;

  /**
   * 产品数
   * @format int64
   */
  productCount?: number;
}

/**
 * 分类
 */
export interface Building3DApplicationDtosOutCategoryDto {
  /** 名称 */
  name?: string | null;

  /**
   * 分类下商品数量
   * @format int64
   */
  productCount?: number;

  /** 子分类 */
  children?: Building3DApplicationDtosOutCategoryDto[] | null;

  /** @format int64 */
  id?: number;
}

/**
 * 文件类型
 */
export interface Building3DWebMainDtosOutFileTypeDto {
  /** 名称 */
  name?: string | null;

  /**
   * 文件类型下商品数量
   * @format int64
   */
  productCount?: number;
}

/**
 * 筛选产品
 */
export interface Building3DWebMainDtosOutFilterPropertyDto {
  /** 品牌 */
  brands?: Building3DWebMainDtosOutFilterPropertyBrandDto[] | null;

  /** 分类 */
  categories?: Building3DApplicationDtosOutCategoryDto[] | null;

  /** 文件类型 */
  fileTypes?: Building3DWebMainDtosOutFileTypeDto[] | null;
}

/**
 * 范围输入
 */
export interface Building3DWebMainDtosOutHighFilterPropertyRangeInputDto {
  /**
   * 最小值
   * @format double
   */
  minValue?: number;

  /**
   * 最大值
   * @format double
   */
  maxValue?: number;
}

/**
 * 高级筛选
 */
export interface Building3DWebMainDtosOutHighFilterPropertyDto {
  /** 属性名称 */
  name?: string | null;

  /** 关键字 */
  keyword?: string | null;

  /** 范围输入 */
  rangeInput?: Building3DWebMainDtosOutHighFilterPropertyRangeInputDto;

  /** 列表选择 */
  listSelect?: string[] | null;
}

export interface Building3DApplicationDtosInProductSetAsNewDto {
  /** 产品Id集 */
  productIds?: number[] | null;
}

export interface Building3DApplicationDtosInProductCancelNewDto {
  /** 产品Id集 */
  productIds?: number[] | null;
}

export interface Building3DWebAdminDtosOutProductAuditPageItemDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 编号 */
  code?: string | null;

  /** 缩略图 */
  thumbnail?: string | null;

  /** 名称 */
  name?: string | null;

  /** 型号 */
  modelNumber?: string | null;

  /** 品牌 */
  brandName?: string | null;

  /** 下线? */
  isOffline?: boolean;

  /** 新品? */
  isNew?: boolean;

  /** 族文件格式 */
  modelExtNames?: string[] | null;

  /**
   * 收藏量
   * @format int32
   */
  collectionCount?: number;

  /**
   * 下载量
   * @format int32
   */
  downloadCount?: number;

  /**
   * 版本
   * @format int32
   */
  version?: number;
  status?: Building3DDomainEntitiesProductStatus;

  /** 状态文本 */
  statusTxt?: string | null;

  /**
   * 审核提交时间
   * @format date-time
   */
  submitAuditAt?: string;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutProductAuditPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebAdminDtosOutProductAuditPageItemDto[] | null;
}

export interface Building3DApplicationDtosInProductAuditPassDto {
  productIds: number[];
}

export interface Building3DApplicationDtosInProductAuditNoPassDto {
  productIds: number[];

  /** 不通过原因 */
  reason: string;
}

export interface Building3DWebVenderDtosOutProductTypeNodeDto {
  /** @format int64 */
  id?: number;

  /**
   * 父类型
   * @format int64
   */
  parentId?: number;

  /** 文本 */
  txt?: string | null;
  fullTxt?: string | null;

  /** 子节点 */
  children?: Building3DWebVenderDtosOutProductTypeNodeDto[] | null;
}

export interface Building3DWebVenderDtosOutProductTypeTreeDto {
  roots?: Building3DWebVenderDtosOutProductTypeNodeDto[] | null;
}

export interface Building3DWebVenderDtosOutProductTypeHistoryDto {
  /**
   * 类型
   * @format int64
   */
  type?: number;

  /** 类型文本 */
  typeTxt?: string | null;
}

export interface Building3DWebVenderDtosOutProductSaveBasicInfoDto {
  /** 名称 */
  name?: string | null;

  /**
   * 品牌
   * @format int64
   */
  brandId?: number;

  /** 型号 */
  modelNumber?: string | null;

  /**
   * 类型
   * @format int64
   */
  category?: number;

  /** 描述 */
  description?: string | null;
}

export interface Building3DWebVenderDtosOutProductSaveTypeDto {
  /**
   * 分类Id
   * @format int64
   */
  typeId?: number;

  /** 完整名称 */
  fullTxt?: string | null;
}

export interface Building3DWebVenderDtosOutProductSaveQualityItemDto {
  /** 编号 */
  code?: string | null;

  /** 值 */
  value?: string | null;
}

export interface Building3DWebVenderDtosOutProductSaveTechDocDto {
  /**
   * 文件Id
   * @format int64
   */
  fileId?: number;

  /** 文件名 */
  name?: string | null;

  /** 文件扩展名 */
  extensionName?: string | null;

  /**
   * 文件大小
   * @format int64
   */
  size?: number;

  /** 封面 */
  cover?: string | null;
}

export interface Building3DWebVenderDtosOutProductSaveModelDto {
  /** 地址 */
  url?: string | null;
  modelGroupId?: string | null;

  /**
   * 文件Id
   * @format int64
   */
  fileId?: number;

  /** 文件名 */
  fileName?: string | null;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesPopertyType = 0 | 1;

export interface Building3DWebVenderDtosOutProductSavePropertyDto {
  /** 键 */
  keyword?: string | null;

  /** 名称 */
  name?: string | null;

  /** 单位 */
  unit?: string | null;

  /** 值 */
  value?: string | null;
  type?: Building3DDomainEntitiesPopertyType;
}

export interface Building3DWebVenderDtosOutProductSaveDrawingsDto {
  /**
   * 文件Id
   * @format int64
   */
  fileId?: number;

  /** 文件名 */
  fileName?: string | null;
}

export interface Building3DWebVenderDtosOutProductSaveImageDto {
  /** 地址 */
  url?: string | null;

  /** 主图? */
  isPrimary?: boolean;

  /**
   * 文件Id
   * @format int64
   */
  fileId?: number;

  /** 文件名 */
  fileName?: string | null;
}

export interface Building3DWebVenderDtosOutProductSaveCorrelationDto {
  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 产品名称 */
  name?: string | null;
}

export interface Building3DWebVenderDtosOutProductSaveInfosDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;
  info?: Building3DWebVenderDtosOutProductSaveBasicInfoDto;

  /** 分类 */
  types?: Building3DWebVenderDtosOutProductSaveTypeDto[] | null;

  /** 质量项 */
  qualityItems?: Building3DWebVenderDtosOutProductSaveQualityItemDto[] | null;

  /** 技术文档 */
  techDocs?: Building3DWebVenderDtosOutProductSaveTechDocDto[] | null;
  basicModel?: Building3DWebVenderDtosOutProductSaveModelDto;

  /** 属性 */
  properties?: Building3DWebVenderDtosOutProductSavePropertyDto[] | null;

  /** 扩展模型 */
  extendedModels?: Building3DWebVenderDtosOutProductSaveModelDto[] | null;
  drawings?: Building3DWebVenderDtosOutProductSaveDrawingsDto;

  /** 图片 */
  images?: Building3DWebVenderDtosOutProductSaveImageDto[] | null;

  /** 关联方式 */
  correlationMode?: string | null;

  /** 相关产品 */
  correlations?: Building3DWebVenderDtosOutProductSaveCorrelationDto[] | null;
}

export interface Building3DWebVenderDtosOutCategoryOptionDto {
  /**
   * 类型值
   * @format int64
   */
  val?: number;

  /** 类型名 */
  name?: string | null;
}

export interface Building3DApplicationDtosInProductPropertyDto {
  /** 属性编号 */
  keyword?: string | null;

  /** 属性值 */
  value?: string | null;
}

export interface Building3DWebVenderDtosOutProductOptionDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 名称 */
  name?: string | null;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosOutProductOptionDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebVenderDtosOutProductOptionDto[] | null;
}

export interface Building3DApplicationDtosInProductCorrelationDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;
}

export interface Building3DApplicationDtosInProductSaveCorrelationsDto {
  /** 双向? */
  isTwoway?: boolean;

  /** 相关产品 */
  products?: Building3DApplicationDtosInProductCorrelationDto[] | null;
}

export interface Building3DApplicationDtosInProductSaveSelectTypesStepDto {
  /**
   * 产品Id
   * @format int64
   */
  productId: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId: number;

  /** 分类 */
  types: number[];
}

export interface Building3DApplicationDtosInProductCertAndRatingDto {
  /** 建材认证 */
  bm?: string | null;

  /** 防火等级 */
  fr?: string | null;

  /** 产品认证 */
  iso?: string | null;

  /** 标准文件 */
  sf?: string | null;
}

export interface Building3DApplicationDtosInProductTechDocDto {
  /**
   * 文件Id
   * @format int64
   */
  fileId?: number | null;

  /** 封面 */
  cover?: string | null;

  /** 文件名 */
  name?: string | null;

  /**
   * 文件大小
   * @format int64
   */
  size?: number;

  /** Url */
  url?: string | null;
}

export interface Building3DApplicationDtosInProductSaveStepOneDto {
  product?: Building3DApplicationDtosInProductSaveDto;
  certAndRating?: Building3DApplicationDtosInProductCertAndRatingDto;

  /** 技术文档 */
  techDocs?: Building3DApplicationDtosInProductTechDocDto[] | null;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesPopertyFilterMode = 0 | 1;

export interface Building3DWebAdminDtosOutProductCategoryPropertyDto {
  /** 编号 */
  code?: string | null;

  /** 编号 */
  keyword?: string | null;

  /** 名称 */
  name?: string | null;
  type?: Building3DDomainEntitiesPopertyType;

  /** 可关键字搜索? */
  isSearchable?: boolean;

  /** 可筛选? */
  isFilterable?: boolean;
  filterMode?: Building3DDomainEntitiesPopertyFilterMode;
}

export interface Building3DWebAdminDtosOutProductCategoryDto {
  /** @format int64 */
  id?: number;

  /** 名称 */
  name?: string | null;

  /** 属性 */
  properties?: Building3DWebAdminDtosOutProductCategoryPropertyDto[] | null;
}

export interface Building3DApplicationDtosInProductCategoryDefDto {
  /** @format int64 */
  id?: number | null;

  /** 名称 */
  name?: string | null;
}

export interface Building3DApplicationDtosOutProductCategoryDefDto {
  /** @format int64 */
  categoryId?: number;
}

export interface Building3DApplicationDtosInProductCategoryDefAddPropertyDto {
  /**
   * 类型Id
   * @format int64
   */
  categoryId?: number;

  /** 属性名称 */
  propertyName?: string | null;
}

export interface Building3DApplicationDtosInProductCategoryDefRemovePropertiesDto {
  /**
   * 类型Id
   * @format int64
   */
  id?: number;

  /** 属性编号 */
  properties?: string[] | null;
}

export interface Building3DApplicationDtosInProductCategoryDefSortPropertyDto {
  /**
   * 类型Id
   * @format int64
   */
  categoryId: number;

  /** 属性编号 */
  propertyCode: string;

  /** 上一个属性编号 */
  lastPropertyCode?: string | null;
}

export interface Building3DApplicationDtosInProductCategoryDefCopyDto {
  /** @format int64 */
  sourceId?: number;

  /** 名称 */
  name?: string | null;
}

export interface Building3DApplicationDtosInProductCategoryDefCopyPropertiesDto {
  /** @format int64 */
  id?: number;

  /** 属性编号 */
  properties?: string[] | null;
}

export interface Building3DWebMainDtosOutContrastProductDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 名称 */
  name?: string | null;

  /** 缩略图 */
  thumbnail?: string | null;

  /** 品牌 */
  brand?: string | null;
}

export interface Building3DApplicationDtosInProductContrastSelectionDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;
}

export interface Building3DWebMainDtosOutProductDetailModelDto {
  groupId?: string | null;
  path?: string | null;

  /** 安装动画 */
  animation?: string | null;
}

export interface Building3DWebMainDtosOutBrandDto {
  /** @format int64 */
  brandId?: number;

  /** 品牌名称 */
  name?: string | null;
  logo?: string | null;

  /**
   * 产品数
   * @format int64
   */
  productCount?: number;

  /**
   * 粉丝数
   * @format int64
   */
  fansCount?: number;
  isConcerned?: boolean;
}

/**
 * @format int32
 */
export type Building3DWebMainDtosProductDetailInfoType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7;

export interface Building3DWebMainDtosOutProductPropertyDto {
  /** 属性名 */
  code?: string | null;

  /** 属性名 */
  name?: string | null;

  /** 属性值 */
  value?: string | null;
  type?: Building3DDomainEntitiesPopertyType;
}

export interface Building3DWebMainDtosOutProductDetailDto {
  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 产品名称 */
  name?: string | null;

  /** 型号 */
  modelNumber?: string | null;

  /**
   * 发布时间
   * @format date-time
   */
  releasedTime?: string;

  /**
   * 版本号
   * @format int32
   */
  version?: number;

  /** 类型 */
  type?: string | null;

  /** 描述 */
  description?: string | null;
  drawings?: Building3DWebMainDtosOutProductDetailModelDto;
  model?: Building3DWebMainDtosOutProductDetailModelDto;

  /** 图片 */
  images?: string[] | null;

  /** 产品认证 */
  certLogos?: string[] | null;
  brand?: Building3DWebMainDtosOutBrandDto;

  /** 模型分组Id */
  modelGroupId?: string | null;

  /** 包含的信息类型 */
  infoTypes?: Building3DWebMainDtosProductDetailInfoType[] | null;

  /** 属性 */
  properties?: Building3DWebMainDtosOutProductPropertyDto[] | null;

  /** 标准编码 */
  standardCodes?: Building3DWebMainDtosOutProductPropertyDto[] | null;

  /** 已收藏? */
  isCollected?: boolean;
}

export interface Building3DDomainEntitiesBlockchainData {
  block?: string | null;
  chain?: string | null;
}

export interface Building3DWebMainDtosOutProductQualityInfoDto {
  /** 建材认证 */
  bmCerts?: string[] | null;

  /** 防火等级 */
  fireRating?: string | null;

  /** 产品认证 */
  certLogos?: string[] | null;
  traceSourceCode?: Building3DDomainEntitiesBlockchainData;

  /** 标准 */
  standards?: string | null;
}

export interface Building3DWebMainDtosOutProductTechDocDto {
  /** 名称 */
  name?: string | null;

  /** 封面 */
  cover?: string | null;

  /** 文件地址 */
  url?: string | null;
}

export interface Building3DWebMainDtosOutProductCorrelationDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 缩略图 */
  thumbnail?: string | null;
  name?: string | null;
}

export interface Building3DWebMainDtosOutProductModelFileDto {
  name?: string | null;

  /** 扩展名 */
  extensionName?: string | null;

  /** 图标 */
  icon?: string | null;
}

export interface Building3DApplicationDtosInProductModelFileDto {
  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 基础族? */
  isBasic?: boolean;

  /** 文件名 */
  name?: string | null;

  /**
   * 文件大小
   * @format int64
   */
  size?: number;
  viewFileId?: string | null;

  /** 地址 */
  url?: string | null;
}

export interface Building3DApplicationDtosOutProductFileUploadDto {
  /**
   * 文件Id
   * @format int64
   */
  fileId?: number;

  /** 文件名 */
  fileName?: string | null;
}

export interface Building3DApplicationDtosInProductDrawingsFileDto {
  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 文件名 */
  name?: string | null;

  /**
   * 文件大小
   * @format int64
   */
  size?: number;
  viewFileId?: string | null;

  /** 地址 */
  url?: string | null;
}

export interface Building3DApplicationDtosInProductFileDto {
  /** 文件名 */
  name?: string | null;

  /**
   * 文件大小
   * @format int64
   */
  size?: number;

  /** Url */
  url?: string | null;
}

export interface Building3DApplicationDtosInProductUploadFileDto1Building3DApplicationDtosInProductFileDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 文件信息 */
  files?: Building3DApplicationDtosInProductFileDto[] | null;
}

export interface Building3DApplicationDtosInProductImageFileAsPrimaryDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /**
   * 主图文件Id
   * @format int64
   */
  fileId?: number;

  /** 截图? */
  isSnapshot?: boolean;
}

export interface Building3DApplicationDtosInProductUploadFileDto1Building3DApplicationDtosInProductTechDocDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 文件信息 */
  files?: Building3DApplicationDtosInProductTechDocDto[] | null;
}

export interface Building3DApplicationDtosInUploadCoverDto {
  /**
   * 文件Id
   * @format int64
   */
  fileId?: number;

  /** 封面地址 */
  coverUrl?: string | null;
}

export interface Building3DApplicationDtosOutFileSignedUrlDto {
  url?: string | null;
}

export interface Building3DWebVenderDtosOutProductRecycleBinPageItemDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 编号 */
  code?: string | null;

  /** 缩略图 */
  thumbnail?: string | null;

  /** 名称 */
  name?: string | null;

  /** 型号 */
  modelNumber?: string | null;

  /** 品牌 */
  brandName?: string | null;

  /** 族文件格式 */
  modelExtNames?: string[] | null;

  /**
   * 收藏量
   * @format int32
   */
  collectionCount?: number;

  /**
   * 下载量
   * @format int32
   */
  downloadCount?: number;

  /**
   * 版本
   * @format int32
   */
  version?: number;
  status?: Building3DDomainEntitiesProductStatus;

  /** 状态文本 */
  statusTxt?: string | null;

  /**
   * 上架时间
   * @format date-time
   */
  shelvedAt?: string | null;

  /**
   * 回收时间
   * @format date-time
   */
  recycledAt?: string | null;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosOutProductRecycleBinPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebVenderDtosOutProductRecycleBinPageItemDto[] | null;
}

export interface Building3DWebAdminDtosOutProportionItemDto {
  /** 文字 */
  txt?: string | null;

  /**
   * 数量
   * @format double
   */
  amount?: number;
}

export interface Building3DWebAdminDtosOutDistributionItemDto {
  /** 文字 */
  txt?: string | null;

  /**
   * 数量
   * @format double
   */
  amount?: number;
}

export interface Building3DWebAdminDtosOutDistributionDto {
  items?: Building3DWebAdminDtosOutDistributionItemDto[] | null;
}

export interface Building3DWebAdminDtosOutStatisticsTopProductDto {
  /** 数量 */
  name?: string | null;

  /** 品牌 */
  brand?: string | null;

  /**
   * 上架时间
   * @format date-time
   */
  shelvedAt?: string | null;

  /**
   * 浏览数
   * @format int64
   */
  browsedCount?: number | null;

  /**
   * 浏览人数(IP)
   * @format int64
   */
  browsedUserCount?: number | null;

  /**
   * 收藏数
   * @format int64
   */
  collectedCount?: number | null;

  /**
   * 下载数
   * @format int64
   */
  downloadCount?: number | null;
}

export interface Building3DWebVenderDtosOutProductTypeFullNodeDto {
  /** @format int64 */
  id?: number;

  /**
   * 父类型
   * @format int64
   */
  parentId?: number;

  /** 文本 */
  txt?: string | null;

  /** @format int32 */
  sort?: number;

  /** 描述 */
  description?: string | null;

  /** 是否显示 */
  isShow?: boolean;

  /**
   * 分类下产品数量
   * @format int64
   */
  productCount?: number;

  /** 完整名称(包含各个层级的名称) */
  fullTxt?: string | null;

  /** 能否添加子级 */
  canAddChildren?: boolean;

  /** 子节点 */
  children?: Building3DWebVenderDtosOutProductTypeFullNodeDto[] | null;
}

/**
 * 完整分类树
 */
export interface Building3DWebVenderDtosOutProductTypeFullTreeDto {
  roots?: Building3DWebVenderDtosOutProductTypeFullNodeDto[] | null;
}

export interface Building3DApplicationDtosInProductTypeCreateDto {
  /**
   * 上级Id
   * @format int64
   */
  parentId?: number | null;

  /** 分类名称 */
  name: string;
}

/**
 * 分类修改
 */
export interface Building3DApplicationDtosInProductTypeModifyDto {
  /** @format int64 */
  id?: number;

  /** 分类名称 */
  name: string;

  /** 描述 */
  description?: string | null;

  /** 是否显示 */
  isShow?: boolean;

  /** 是否锁定 */
  isLocked?: boolean;
}

export interface Building3DApplicationDtosInProductTypeSortItemDto {
  /**
   * 分类Id
   * @format int64
   */
  id?: number;

  /**
   * 排序
   * @format int32
   */
  sort?: number;
}

/**
 * 排序
 */
export interface Building3DApplicationDtosInProductTypeSortDto {
  items: Building3DApplicationDtosInProductTypeSortItemDto[];
}

/**
 * 产品转移分类
 */
export interface Building3DApplicationDtosInProductTransferTypeDto {
  /**
   * 源分类Id
   * @format int64
   */
  sourceId?: number;

  /** 转移到分类的Id */
  targetTypeIds?: number[] | null;
}

export interface Building3DWebVenderDtosProductTypeViewProductDto {
  /**
   * 回收站产品数量
   * @format int64
   */
  recycledCount?: number;

  /**
   * 产品列表产品数量
   * @format int64
   */
  productListCount?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumAuditStatus = 0 | 1 | 2 | -1;

export interface Building3DApplicationDtosProductTypeUsersDto {
  /** 锁定 */
  isLocked?: boolean;

  /**
   * 产品类型
   * @format int64
   */
  productTypeId?: number;

  /**
   * 用户Id
   * @format int64
   */
  userId?: number;
  auditStatus?: Building3DDomainEntitiesEnumAuditStatus;

  /** 备注 */
  remark?: string | null;

  /**
   * 创建人标识
   * @format int64
   */
  createUserId?: number;

  /**
   * 创建时间
   * @format date-time
   */
  creationTime?: string;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosBatchProductTypeUserInput {
  /** @format int64 */
  userId?: number;
  productTypeIds?: number[] | null;
}

export interface Building3DApplicationDtosInPropertyDefDto {
  /** 编号 */
  code?: string | null;

  /** 名称 */
  name?: string | null;
  type?: Building3DDomainEntitiesPopertyType;

  /** 可关键字搜索? */
  isSearchable?: boolean;

  /** 可筛选? */
  isFilterable?: boolean;
  filterMode?: Building3DDomainEntitiesPopertyFilterMode;
}

/**
 * 可选择的快捷入口项
 */
export interface Building3DWebVenderDtosOutOptionalQuickEntryNodeDto {
  /**
   * 入口Id
   * @format int64
   */
  id?: number;

  /**
   * 父级节点
   * @format int64
   */
  parentId?: number;

  /** 入口名称 */
  name?: string | null;

  /** 前端约定显示的编码 */
  code?: string | null;

  /** 是否选中 */
  selected?: boolean;

  /**
   * 排序
   * @format int32
   */
  sort?: number;

  /** 子节点 */
  children?: Building3DWebVenderDtosOutOptionalQuickEntryNodeDto[] | null;
}

/**
 * 可选择的快捷入口
 */
export interface Building3DWebVenderDtosOutOptionalQuickEntryTreeDto {
  roots?: Building3DWebVenderDtosOutOptionalQuickEntryNodeDto[] | null;
}

export interface Building3DWebFoundationDtosOutRegionDto {
  /** 编号 */
  code?: string | null;

  /** 名称 */
  name?: string | null;
}

export interface Building3DWebFoundationDtosOutRoleDto {
  /** @format int64 */
  id?: number;

  /** 角色名称 */
  name?: string | null;

  /** 描述 */
  description?: string | null;

  /** 是否启用 */
  enabled?: boolean;

  /** 是否是系统内置角色(系统内置角色不允许删除,修改操作) */
  isBuiltin?: boolean;

  /**
   * 用户数量
   * @format int32
   */
  userCount?: number;

  /** 是否可删除 */
  deletable?: boolean;

  /**
   * 创建时间
   * @format date-time
   */
  creationTime?: string;
}

export interface Building3DApplicationDtosInRoleCreateDto {
  /** 角色名称 */
  name: string;

  /** 描述 */
  description?: string | null;

  /** 是否启用 */
  enabled?: boolean;

  /** 选中的权限Ids */
  selectedPermissionIds?: number[] | null;
}

export interface Building3DApplicationDtosInRoleModifyDto {
  /**
   * 角色Id
   * @format int64
   */
  id?: number;

  /** 角色名称 */
  name: string;

  /** 描述 */
  description?: string | null;

  /** 是否启用 */
  enabled?: boolean;

  /** 选中的权限Ids */
  selectedPermissionIds?: number[] | null;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesPermissionType = 1 | 2 | 3;

export interface Building3DWebFoundationDtosOutPermissionNodeDto {
  /**
   * 权限Id
   * @format int64
   */
  id?: number;

  /**
   * 父级节点
   * @format int64
   */
  parentId?: number;

  /** 权限名称 */
  name?: string | null;
  type?: Building3DDomainEntitiesPermissionType;

  /** 前端约定显示的编码 */
  code?: string | null;

  /** 是否选中 */
  selected?: boolean;

  /** 子节点 */
  children?: Building3DWebFoundationDtosOutPermissionNodeDto[] | null;
}

export interface Building3DWebFoundationDtosOutPermissionTreeDto {
  roots?: Building3DWebFoundationDtosOutPermissionNodeDto[] | null;
}

/**
 * 搜索统计前十
 */
export interface Building3DWebAdminDtosOutSearchStatisticsTopDto {
  /** 关键词 */
  keyword?: string | null;

  /**
   * 结果数量
   * @format int64
   */
  resultCount?: number;

  /**
   * 总搜索数量
   * @format int64
   */
  totalSearchCount?: number;

  /**
   * 本年搜索数量
   * @format int64
   */
  yearSearchCount?: number;

  /**
   * 本月搜索数量
   * @format int64
   */
  monthSearchCount?: number;

  /**
   * 本周搜索数量
   * @format int64
   */
  weekSearchCount?: number;

  /**
   * 今日搜索数量
   * @format int64
   */
  todaySearchCount?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesMessageCategory = 0 | 1 | 2 | 3;

/**
 * @format int32
 */
export type Building3DDomainEntitiesMessageStatus = 0 | 1;

export interface Building3DWebFoundationDtosOutSiteMessageRowDto {
  /**
   * 消息Id
   * @format int64
   */
  id?: number;

  /**
   * 类别
   * @format int32
   */
  category?: number;

  /** 标题 */
  title?: string | null;

  /** 内容 */
  content?: string | null;

  /** 已读? */
  isReaded?: boolean;

  /**
   * 创建时间
   * @format date-time
   */
  createdAt?: string;

  /** 额外数据 */
  extData?: Record<string, object>;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebFoundationDtosOutSiteMessageRowDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebFoundationDtosOutSiteMessageRowDto[] | null;
}

export interface Building3DWebFoundationDtosOutSiteMessageCategoryInfoDto {
  category?: Building3DDomainEntitiesMessageCategory;

  /**
   * 标题
   * @format int32
   */
  count?: number;
}

export interface Building3DApplicationDtosStudyManagerSetClassManagerInput {
  /**
   * 班级ID
   * @format int64
   */
  classId?: number;

  /**
   * 设置班主任Id
   * @format int64
   */
  userId?: number;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesEnumClassUserType = 1 | 2;

export interface Building3DApplicationDtosStudyManagerBatchClassMemberDto {
  /**
   * 班级
   * @format int64
   */
  classId?: number;
  userType?: Building3DDomainEntitiesEnumClassUserType;

  /** 人员id */
  userIds: number[];
}

export interface Building3DWebAdminDtosInUserPageDto {
  /** 关键字搜索(暂支持用户名/手机号/邮箱) */
  search?: string | null;
  userName?: string | null;
  phoneNumber?: string | null;
  nickName?: string | null;

  /**
   * 角色
   * @format int64
   */
  roleId?: number | null;

  /** 人员类型 */
  userTypeArr?: Building3DDomainEntitiesEnumClassUserType[] | null;

  /** 是否本班成员 */
  inClass?: boolean;

  /**
   * 查询班级
   * @format int64
   */
  classId?: number | null;

  /**
   * 作业成员
   * @format int64
   */
  taskId?: number | null;

  /** 是否作业成员 */
  inTask?: boolean | null;

  /**
   * 仿真课程成员
   * @format int64
   */
  designTaskId?: number | null;

  /** 是否仿真课程成员 */
  inDesignTask?: boolean | null;

  /** 启用状态 */
  isEnabled?: boolean | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface Building3DWebAdminDtosOutUserPageItemDto {
  /**
   * 用户Id
   * @format int64
   */
  userId?: number;

  /** 用户名 */
  userName?: string | null;

  /** 邮箱 */
  email?: string | null;

  /** 昵称 */
  nickName?: string | null;

  /** 角色名称 */
  roles?: string | null;

  /** 角色Id */
  roleIds?: number[] | null;

  /** 品牌 */
  brands?: string | null;

  /** 品牌Id */
  brandIds?: number[] | null;

  /**
   * 创建时间
   * @format date-time
   */
  createdAt?: string;

  /**
   * 最后登录时间
   * @format date-time
   */
  lastLoginedAt?: string | null;

  /** 可用? */
  isEnabled?: boolean;

  /** 备注 */
  description?: string | null;

  /** 教育板块人员类型 */
  userTypeArr?: Building3DDomainEntitiesEnumClassUserType[] | null;

  /** 教师关联班级 */
  tearcherClassIdArr?: number[] | null;

  /** 学生关联班级 */
  studentClassIdArr?: number[] | null;

  /** 是否班主任 */
  isManager?: boolean;

  /** 手机 */
  phoneNumber?: string | null;

  /**
   * 限期
   * @format date-time
   */
  limitTime?: string | null;
  designFinishStatus?: Building3DDomainEntitiesEnumDesignFinishStatus;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutUserPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebAdminDtosOutUserPageItemDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerFileDto {
  hash?: string | null;
  fileName?: string | null;
  fileType?: string | null;
}

export interface Building3DApplicationDtosStudyManagerTaskTypesPageListInput {
  /** @format int64 */
  parentId?: number | null;
  name?: string | null;
  type?: Building3DDomainEntitiesEnumTaskType;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface Building3DApplicationDtosStudyManagerTaskTypesDto {
  name?: string | null;

  /** @format int64 */
  parentId?: number;
  type?: Building3DDomainEntitiesEnumTaskType;
  createNickName?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerTaskTypesDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerTasksDto {
  name?: string | null;

  /** @format int64 */
  typeId?: number;

  /** @format date-time */
  beginTime?: string;

  /** @format date-time */
  endTime?: string;
  beginTimeStr?: string | null;
  endTimeStr?: string | null;
  logo?: string | null;
  remark?: string | null;
  targetType?: Building3DDomainEntitiesEnumTaskTargetType;
  type?: Building3DDomainEntitiesEnumTaskType;
  configure?: Building3DDomainEntitiesEnumConfigure;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerTaskQuestionDto {
  /**
   * 题库taskid
   * @format int64
   */
  questionId?: number;

  /**
   * 作业taskid
   * @format int64
   */
  taskId?: number;
}

export interface Building3DApplicationDtosStudyManagerBatchTaskUsersDto {
  /**
   * 作业Id
   * @format int64
   */
  taskId?: number;
  targetType?: Building3DDomainEntitiesEnumTaskTargetType;

  /** 成员Id */
  targetIds?: number[] | null;
}

export interface Building3DApplicationDtosStudyManagerTaskFilesBatchRemoveInput {
  taskFileIds?: number[] | null;
}

export interface Building3DApplicationDtosStudyManagerTaskCommitsBatchLockedInput {
  reportLocked?: boolean;
  taskCommitIds?: number[] | null;
}

export interface Building3DApplicationDtosStudyManagerBatchTaskCommitCheckInput {
  taskCommitIds?: number[] | null;
}

/**
 * 批改作业
 */
export interface Building3DApplicationDtosStudyManagerTaskCommitCheckInput {
  status?: Building3DDomainEntitiesEnumTaskCommitStatus;

  /** 意见 */
  remark?: string | null;

  /**
   * 分数
   * @format int32
   */
  mark?: number;
}

export interface Building3DApplicationDtosStudyManagerCheckRulesDto {
  /** @format int32 */
  delayDays?: number;
  name?: string | null;
  rulesContent?: string | null;
  typeContent?: string | null;

  /**
   * 来源作业Id
   * @format int64
   */
  taskId?: number;

  /** 是否系统预置规则 */
  isSystem?: boolean;

  /**
   * 创建时间
   * @format date-time
   */
  creationTime?: string;

  /**
   * 创建人
   * @format int64
   */
  createUserId?: number;
  type?: Building3DDomainEntitiesEnumTaskType;

  /** @format int64 */
  id?: number;
}

/**
 * 分数权重占比配置表
 */
export interface Building3DApplicationDtosStudyManagerCheckRuleConfigsDto {
  /**
   * 构件数量分数占比
   * @format double
   */
  propertiesPoint?: number;

  /**
   * 属性分数占比
   * @format double
   */
  attributesPoint?: number;

  /**
   * 构件数量标准值
   * @format int64
   */
  propertieStandard?: number;

  /**
   * 作业
   * @format int64
   */
  taskId?: number;
  taskType?: Building3DDomainEntitiesEnumTaskType;

  /** @format int64 */
  id?: number;
}

/**
 * 构件数量评分规则
 */
export interface Building3DApplicationDtosStudyManagerCheckRulePropertiesDto {
  /**
   * 浮动值
   * @format int64
   */
  difference?: number;

  /**
   * 得分
   * @format double
   */
  mark?: number;

  /**
   * 作业
   * @format int64
   */
  taskId?: number;

  /** @format int64 */
  id?: number;
}

/**
 * 审查规则详情
 */
export interface Building3DApplicationDtosStudyManagerCheckRuleDetailsDto {
  checkRule?: Building3DApplicationDtosStudyManagerCheckRulesDto;

  /** 分数权重占比配置表 */
  checkRuleConfig?: Building3DApplicationDtosStudyManagerCheckRuleConfigsDto;
  checkRuleProperties?:
  | Building3DApplicationDtosStudyManagerCheckRulePropertiesDto[]
  | null;
}

export interface Building3DApplicationDtosStudyManagerCheckRulePropertiesListInputDto {
  /** @format int64 */
  taskId?: number;
  list?: Building3DApplicationDtosStudyManagerCheckRulePropertiesDto[] | null;
  type?: Building3DDomainEntitiesEnumTaskType;
}

export interface Building3DWebAdminDtosAuditModelInput {
  param?: string | null;
}

export interface Building3DWebAdminDtosBatchOutputAuditModelPdfInput {
  listTaskCommitId?: number[] | null;
  listParam?: string[] | null;

  /** @format int32 */
  type?: number;
  template?: string | null;
}

export interface Building3DApplicationDtosStudyManagerTaskQuestionListDto {
  /** @format int64 */
  id?: number;
  fileName?: string | null;
  taskName?: string | null;

  /** @format int64 */
  fileSize?: number;

  /** @format date-time */
  creationTime?: string;
  configure?: Building3DDomainEntitiesEnumConfigure;
}

export interface Building3DApplicationDtosStudyManagerDesignFileTypesPageListInput {
  /** @format int64 */
  parentId?: number | null;
  name?: string | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface Building3DApplicationDtosStudyManagerDesignFileTypesDto {
  name?: string | null;

  /** @format int64 */
  parentId?: number;
  createNickName?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignFileTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDesignFileTypesDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerDesignFilesPageListInput {
  classType?: Building3DDomainEntitiesEnumDesignClassType;

  /** @format int64 */
  typeId?: number | null;
  isFinish?: boolean | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

/**
 * 保存定制资源文件
 */
export interface Building3DApplicationDtosStudyManagerDesignFileExtendsDto {
  designFile?: Building3DApplicationDtosStudyManagerDesignFilesDto;
  uploadFile?: Building3DApplicationDtosStudyManagerUploadFilesDto;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignFileExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDesignFileExtendsDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerDesignViewFileInput {
  viewFile?: Building3DApplicationDtosStudyManagerUploadFilesDto;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerDesignFilesFinishInput {
  isFinish?: boolean;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerDesignFileViewsPageListInput {
  /** @format int64 */
  designFileId?: number | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignFileViewsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDesignFileViewsDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerDocumentFileTypesPageListInput {
  /** @format int64 */
  parentId?: number | null;
  name?: string | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface Building3DApplicationDtosStudyManagerDocumentFileTypesDto {
  name?: string | null;

  /** @format int64 */
  parentId?: number;
  createNickName?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDocumentFileTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDocumentFileTypesDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerDocumentFilesPageListInput {
  /** @format int64 */
  typeId?: number | null;
  isLocked?: boolean | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface Building3DApplicationDtosStudyManagerDocumentFilesDto {
  /**
   * 资料文件
   * @format int64
   */
  uploadFileId?: number;

  /**
   * 资源目录Id
   * @format int64
   */
  typeId?: number;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface Building3DApplicationDtosStudyManagerDocumentFileExtendsDto {
  documentFile?: Building3DApplicationDtosStudyManagerDocumentFilesDto;
  uploadFile?: Building3DApplicationDtosStudyManagerUploadFilesDto;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDocumentFileExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDocumentFileExtendsDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerDesignTaskTypesPageListInput {
  /** @format int64 */
  parentId?: number | null;
  name?: string | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

/**
 * 定制仿真课程目录
 */
export interface Building3DApplicationDtosStudyManagerDesignTaskTypesDto {
  /** 目录名称 */
  name?: string | null;

  /**
   * 父级
   * @format int64
   */
  parentId?: number;
  createNickName?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignTaskTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDesignTaskTypesDto[] | null;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignTasksDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDesignTasksDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerDesignTaskViewsPageListInput {
  /** @format int64 */
  designTaskId?: number | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignTaskViewsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDesignTaskViewsDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerDesignTaskViewsBatchSortInput {
  ids?: number[] | null;
}

export interface Building3DApplicationDtosStudyManagerBatchDesignTaskUsersDto {
  /**
   * 作业Id
   * @format int64
   */
  designTaskId?: number;
  targetType?: Building3DDomainEntitiesEnumTaskTargetType;

  /** 成员Id */
  targetIds?: number[] | null;
}

export interface Building3DApplicationDtosStudyManagerEnumItemsDto {
  /** @format int64 */
  id?: number;
  val?: string | null;
  txt?: string | null;
  groupCode?: string | null;
}

export interface Building3DApplicationDtosStudyManagerDesignQuestionTypesPageListInput {
  /** @format int64 */
  parentId?: number | null;
  name?: string | null;
  filter?: string | null;
  sorting?: string | null;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface Building3DApplicationDtosStudyManagerDesignQuestionTypesDto {
  name?: string | null;

  /** @format int64 */
  parentId?: number;
  createNickName?: string | null;

  /** @format date-time */
  creationTime?: string;

  /** @format int64 */
  createUserId?: number;

  /** @format int64 */
  id?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignQuestionTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDesignQuestionTypesDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerDesignQuestionsPageListInput {
  /** @format int64 */
  typeId?: number | null;
  classType?: Building3DDomainEntitiesEnumQuestionClassType;
  ids?: number[] | null;
  filter?: string | null;
  sorting?: string | null;
  isSortByIds?: boolean;

  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;

  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignQuestionsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DApplicationDtosStudyManagerDesignQuestionsDto[] | null;
}

/**
 * @format int32
 */
export type Building3DDomainEntitiesToDoEventType = 0 | 1;

/**
 * 待办事件
 */
export interface Building3DWebVenderDtosOutToDoEventDto {
  /** 产品名称 */
  productName?: string | null;
  type?: Building3DDomainEntitiesToDoEventType;

  /**
   * 创建时间
   * @format date-time
   */
  creationTime?: string;

  /** 产品型号 */
  modelNumber?: string | null;

  /** 品牌名称 */
  brandName?: string | null;

  /** 描述 */
  description?: string | null;

  /** @format int64 */
  productId?: number;

  /** @format int64 */
  pvId?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosOutToDoEventDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull {
  /** @format int64 */
  totalCount?: number;
  items?: Building3DWebVenderDtosOutToDoEventDto[] | null;
}

export interface Building3DApplicationDtosStudyManagerUploadFilesBatchLockedInput {
  isLocked?: boolean;
  uploadFileIds?: number[] | null;
}

export interface Building3DApplicationDtosStudyManagerUploadFilesModelThumbnailDto {
  /**
   * Id
   * @format int64
   */
  id?: number;

  /** 模型缩略图 */
  modelThumbnail?: string | null;
}

/**
 * 导入班级成员
 */
export interface Building3DApplicationDtosImportExportDtosImportClassMemberExcelInput {
  /**
   * 班级Id
   * @format int64
   */
  classId?: number;

  /** 上传的excel文件名称 */
  fileName?: string | null;
}

export interface Building3DWebMainDtosOutUserSummaryDto {
  /**
   * 收藏数
   * @format int32
   */
  collectedCount?: number;

  /**
   * 待更新数
   * @format int32
   */
  toBeUpdatedCount?: number;

  /**
   * 下载数
   * @format int32
   */
  downloadedCount?: number;

  /**
   * 品牌数
   * @format int32
   */
  brandCount?: number;
}

export interface Building3DWebFoundationDtosOutUserDto {
  /**
   * 用户Id
   * @format int64
   */
  userId?: number;

  /** 用户名 */
  userName?: string | null;

  /** 昵称 */
  nickName?: string | null;

  /** 手机号 */
  phoneNumber?: string | null;

  /** 邮箱 */
  email?: string | null;

  /** 职业 */
  profession?: string | null;

  /** 组织 */
  organization?: string | null;

  /** 国家 */
  country?: string | null;

  /** 省份 */
  province?: string | null;

  /** 城市 */
  city?: string | null;

  /** 头像 */
  avatar?: string | null;

  /**
   * 到期时间
   * @format date-time
   */
  limitTime?: string | null;

  /**
   * 到期天数
   * @format int32
   */
  limitDays?: number;
}

export interface Building3DApplicationDtosInUserDto {
  /** 昵称 */
  nickName?: string | null;

  /** 职业 */
  profession?: string | null;

  /** 组织 */
  organization?: string | null;

  /** 国家 */
  country?: string | null;

  /** 省份 */
  province?: string | null;

  /** 城市 */
  city?: string | null;
}

export interface Building3DApplicationDtosInUserAvatarDto {
  /** 头像 */
  avatar?: string | null;
}

export interface Building3DApplicationDtosInUserPasswordDto {
  /** 当前密码 */
  current?: string | null;

  /** 新密码 */
  modified?: string | null;
}

export interface Building3DApplicationDtosInBindPhoneNumberDto {
  /** 验证码 */
  captcha?: string | null;

  /** 手机号 */
  phoneNumber?: string | null;
}

/**
 * 用户总览
 */
export interface Building3DWebAdminDtosOutUserSummaryDto {
  /**
   * 今日新增数量
   * @format int32
   */
  todayIncreaseCount?: number;

  /**
   * 昨日新增数量
   * @format int32
   */
  yesterdayIncreaseCount?: number;

  /**
   * 本月新增数量
   * @format int32
   */
  monthIncreaseCount?: number;

  /**
   * 会员总数
   * @format int32
   */
  userTotalCount?: number;
}

export interface Building3DApplicationDtosInUserAdminSaveDto {
  /**
   * 用户Id
   * @format int64
   */
  userId?: number | null;

  /** 用户名 */
  userName?: string | null;

  /** 邮箱 */
  email?: string | null;

  /** 角色 */
  roleIds?: number[] | null;

  /** 品牌 */
  brandIds?: number[] | null;

  /** 昵称 */
  nickName?: string | null;

  /** 密码 */
  password?: string | null;

  /** 备注 */
  description?: string | null;
}

export interface Building3DApplicationDtosInUserBulkRemoveDto {
  userIds?: number[] | null;
}

export interface Building3DWebAdminDtosOutUserPermissionNodeDto {
  /**
   * 权限Id
   * @format int64
   */
  id?: number;

  /**
   * 父级节点
   * @format int64
   */
  parentId?: number;

  /** 权限名称 */
  name?: string | null;
  type?: Building3DDomainEntitiesPermissionType;

  /** 前端约定显示的编码 */
  code?: string | null;

  /** 子节点 */
  children?: Building3DWebAdminDtosOutUserPermissionNodeDto[] | null;
}

export interface Building3DWebAdminDtosOutUserPermissionTree {
  roots?: Building3DWebAdminDtosOutUserPermissionNodeDto[] | null;
}

export interface Building3DApplicationDtosSetLimitTimeInput {
  userIds?: number[] | null;

  /** @format date-time */
  limitTime?: string | null;
}

export interface Building3DWebMainDtosOutConcernedBrandDto {
  /**
   * 品牌Id
   * @format int64
   */
  brandId?: number;
  logo?: string | null;

  /** 名称 */
  name?: string | null;

  /**
   * 产品数
   * @format int64
   */
  productCount?: number;

  /**
   * 粉丝数
   * @format int64
   */
  fansCount?: number;

  /** 已关注? */
  isConcerned?: boolean;
}

export interface Building3DApplicationDtosInBrandConcernDto {
  /**
   * 品牌Id
   * @format int64
   */
  brandId?: number;
}

export interface Building3DApplicationDtosInBrandCancelConcernDto {
  /**
   * 品牌Id
   * @format int64
   */
  brandId?: number;
}

export interface Building3DApplicationDtosInUserProductCollectDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;
}

export interface Building3DApplicationDtosInUserProductCancelCollectDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;
}

export interface Building3DWebMainDtosOutToBeUpdatedProductDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 产品版本Id
   * @format int64
   */
  pvId?: number;

  /** 缩略图 */
  thumbnail?: string | null;

  /** 名称 */
  name?: string | null;

  /** 类别 */
  categoryTxt?: string | null;

  /** 文件类型 */
  fileExtName?: string | null;

  /**
   * 品牌Id
   * @format int64
   */
  brandId?: number;

  /** 品牌 */
  brand?: string | null;

  /**
   * 产品更新时间
   * @format date-time
   */
  updatedAt?: string;
}

export interface Building3DWebMainDtosOutProductDownloadLogDto {
  /**
   * 产品Id
   * @format int64
   */
  productId?: number;

  /**
   * 版本Id
   * @format int64
   */
  pvId?: number;

  /** 缩略图 */
  thumbnail?: string | null;

  /** 名称 */
  name?: string | null;

  /** 类别 */
  categoryTxt?: string | null;

  /** 文件类型 */
  fileExtName?: string | null;

  /**
   * 品牌Id
   * @format int64
   */
  brandId?: number;

  /** 品牌 */
  brand?: string | null;

  /**
   * 下载时间
   * @format date-time
   */
  downloadedAt?: string;
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === "object"
    ) {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData
          ? { "Content-Type": type }
          : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Building3D API
 * @version v1
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Account
     * @name VFAccountRegisterUpdate
     * @summary 注册
     * @request PUT:/api/v{version}/f/Account/Register
     */
    vFAccountRegisterUpdate: (
      data: Building3DApplicationDtosInAccountRegisterDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/f/Account/Register`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name VFAccountSmsCaptchaDetail
     * @summary 发送短信验证码
     * @request GET:/api/v{version}/f/Account/SMSCaptcha
     */
    vFAccountSmsCaptchaDetail: (
      query?: { phoneNumber?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/f/Account/SMSCaptcha`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name VFAccountFindPasswordNextStepUpdate
     * @summary 找回密码下一步
     * @request PUT:/api/v{version}/f/Account/FindPassword/NextStep
     */
    vFAccountFindPasswordNextStepUpdate: (
      data: Building3DApplicationDtosInFindPasswordNextStepDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/f/Account/FindPassword/NextStep`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name VFAccountResetPasswordUpdate
     * @summary 找回密码重置密码
     * @request PUT:/api/v{version}/f/Account/ResetPassword
     */
    vFAccountResetPasswordUpdate: (
      data: Building3DApplicationDtosInResetPasswordDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/f/Account/ResetPassword`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name VMBrandDetail
     * @summary 获取品牌分页列表
     * @request GET:/api/v{version}/m/Brand
     */
    vMBrandDetail: (
      query?: {
        Name?: string | null;
        Sorting?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebMainDtosOutBrandListDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        any
      >({
        path: `/api/v1/m/Brand`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name VMBrandDetail2
     * @summary 获取品牌详情
     * @request GET:/api/v{version}/m/Brand/{id}
     * @originalName vMBrandDetail
     * @duplicate
     */
    vMBrandDetail2: (id: number, params: RequestParams = {}) =>
      this.request<Building3DWebMainDtosOutBrandDetailDto, any>({
        path: `/api/v1/m/Brand/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name VABrandCertificationsDetail
     * @summary 获取品牌认证
     * @request GET:/api/v{version}/a/Brand/Certifications
     * @secure
     */
    vABrandCertificationsDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebAdminDtosOutBrandCertificationDto[], void>({
        path: `/api/v1/a/Brand/Certifications`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name VABrandDetail
     * @summary 分页查询
     * @request GET:/api/v{version}/a/Brand
     * @secure
     */
    vABrandDetail: (
      query?: {
        Sorting?: string | null;
        Name?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutBrandPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Brand`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name VABrandUpdate
     * @summary 保存品牌
     * @request PUT:/api/v{version}/a/Brand
     * @secure
     */
    vABrandUpdate: (
      data: Building3DApplicationDtosInBrandDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Brand`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name VABrandDelete
     * @summary 删除
     * @request DELETE:/api/v{version}/a/Brand
     * @secure
     */
    vABrandDelete: (data: number[] | null, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/a/Brand`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name VABrandIsOrNotManufacturerUpdate
     * @summary 修改是否是制造商
     * @request PUT:/api/v{version}/a/Brand/IsOrNotManufacturer
     * @secure
     */
    vABrandIsOrNotManufacturerUpdate: (
      data: Building3DApplicationDtosInBrandIsOrNotManufacturerDto[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Brand/IsOrNotManufacturer`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brand
     * @name VABrandIsOrNotShowUpdate
     * @summary 修改是否是前台显示
     * @request PUT:/api/v{version}/a/Brand/IsOrNotShow
     * @secure
     */
    vABrandIsOrNotShowUpdate: (
      data: Building3DApplicationDtosInBrandIsOrNotShowDto[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Brand/IsOrNotShow`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Brands
     * @name VVBrandsOptionsDetail
     * @summary 获取品牌选项
     * @request GET:/api/v{version}/v/Brands/Options
     * @secure
     */
    vVBrandsOptionsDetail: (
      query?: { SkipCount?: number; MaxResultCount?: number },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosOutBrandOptionDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/v/Brands/Options`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BroadcastMessages
     * @name VABroadcastMessagesDetail
     * @request GET:/api/v{version}/a/BroadcastMessages
     * @secure
     */
    vABroadcastMessagesDetail: (
      query?: {
        Title?: string | null;
        ReceiverType?: Building3DDomainEntitiesReceiverType;
        StartPublishTime?: string | null;
        EndPublishTime?: string | null;
        Sorting?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutBroadcastMessagePageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/BroadcastMessages`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BroadcastMessages
     * @name VABroadcastMessagesCreate
     * @summary 保存消息
     * @request POST:/api/v{version}/a/BroadcastMessages
     * @secure
     */
    vABroadcastMessagesCreate: (
      data: Building3DApplicationDtosInBroadcastMessageDto,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutBroadcastMessageSaveDto, void>({
        path: `/api/v1/a/BroadcastMessages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BroadcastMessages
     * @name VABroadcastMessagesDetail2
     * @request GET:/api/v{version}/a/BroadcastMessages/{id}
     * @originalName vABroadcastMessagesDetail
     * @duplicate
     * @secure
     */
    vABroadcastMessagesDetail2: (id: number, params: RequestParams = {}) =>
      this.request<Building3DWebAdminDtosOutBroadcastMessageDto, void>({
        path: `/api/v1/a/BroadcastMessages/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BroadcastMessages
     * @name VABroadcastMessagesPublishUpdate
     * @summary 发布消息
     * @request PUT:/api/v{version}/a/BroadcastMessages/{id}/Publish
     * @secure
     */
    vABroadcastMessagesPublishUpdate: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/BroadcastMessages/${id}/Publish`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BroadcastMessages
     * @name VABroadcastMessagesWithdrawUpdate
     * @summary 撤回消息
     * @request PUT:/api/v{version}/a/BroadcastMessages/{id}/Withdraw
     * @secure
     */
    vABroadcastMessagesWithdrawUpdate: (
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/BroadcastMessages/${id}/Withdraw`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Certification
     * @name VACertificationUpdate
     * @summary 添加认证机构
     * @request PUT:/api/v{version}/a/Certification
     * @secure
     */
    vACertificationUpdate: (
      data: Building3DApplicationDtosInCertificationDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Certification`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comparison
     * @name VMComparisonPropertiesDetail
     * @summary 获取参数
     * @request GET:/api/v{version}/m/Comparison/Properties
     */
    vMComparisonPropertiesDetail: (
      query?: { productIds?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutComparisonProductDto[], any>({
        path: `/api/v1/m/Comparison/Properties`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comparison
     * @name VMComparisonModelsDetail
     * @summary 获取模型
     * @request GET:/api/v{version}/m/Comparison/Models
     */
    vMComparisonModelsDetail: (
      query?: { productIds?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutComparisonProductDto[], any>({
        path: `/api/v1/m/Comparison/Models`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comparison
     * @name VMComparisonDrawingsDetail
     * @summary 获取图纸
     * @request GET:/api/v{version}/m/Comparison/Drawings
     */
    vMComparisonDrawingsDetail: (
      query?: { productIds?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutComparisonProductDto[], any>({
        path: `/api/v1/m/Comparison/Drawings`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Enums
     * @name VFEnumsDetail
     * @summary 获取枚举项
     * @request GET:/api/v{version}/f/Enums
     */
    vFEnumsDetail: (
      query?: { group?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebFoundationDtosOutEnumDto[], any>({
        path: `/api/v1/f/Enums`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name VFLogsDetail
     * @request GET:/api/v{version}/f/Logs
     */
    vFLogsDetail: (
      query?: { SkipCount?: number; MaxResultCount?: number },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebFoundationDtosOutLogDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        any
      >({
        path: `/api/v1/f/Logs`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name VAMemberDetail
     * @summary 获取会员列表
     * @request GET:/api/v{version}/a/Member
     * @secure
     */
    vAMemberDetail: (
      query?: {
        UserName?: string | null;
        NickName?: string | null;
        RegisteredAtFrom?: string | null;
        RegisteredAtTo?: string | null;
        MemberLevelId?: number | null;
        Sorting?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutMemberPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Member`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name VAMemberDelete
     * @summary 删除会员
     * @request DELETE:/api/v{version}/a/Member
     * @secure
     */
    vAMemberDelete: (data: number[] | null, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/a/Member`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name VAMemberStatisticsDetail
     * @summary 获取会员统计信息
     * @request GET:/api/v{version}/a/Member/{id}/Statistics
     * @secure
     */
    vAMemberStatisticsDetail: (id: number, params: RequestParams = {}) =>
      this.request<Building3DWebAdminDtosOutMemberStatisticsDto, void>({
        path: `/api/v1/a/Member/${id}/Statistics`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name VAMemberUpdate
     * @summary 添加/修改会员
     * @request PUT:/api/v{version}/a/Member/{id}
     * @secure
     */
    vAMemberUpdate: (
      id: number,

      data: Building3DApplicationDtosInMemberModifyDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Member/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name VAMemberEnableUpdate
     * @summary 批量启用
     * @request PUT:/api/v{version}/a/Member/Enable
     * @secure
     */
    vAMemberEnableUpdate: (data: number[] | null, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/a/Member/Enable`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name VAMemberDisableUpdate
     * @summary 批量禁用
     * @request PUT:/api/v{version}/a/Member/Disable
     * @secure
     */
    vAMemberDisableUpdate: (
      data: number[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Member/Disable`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name VAMemberGrowthDetailsDetail
     * @summary 获取成长值明细
     * @request GET:/api/v{version}/a/Member/{id}/GrowthDetails
     * @secure
     */
    vAMemberGrowthDetailsDetail: (
      id: number,

      query?: {
        SourceType?: Building3DDomainEntitiesGrowthChangeSourceType;
        CreationTimeFrom?: string | null;
        CreationTimeTo?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutGrowthDetailsPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Member/${id}/GrowthDetails`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name VAMemberGrowthDetailsCreate
     * @summary 修改成长值
     * @request POST:/api/v{version}/a/Member/{id}/GrowthDetails
     * @secure
     */
    vAMemberGrowthDetailsCreate: (
      id: number,

      data: Building3DApplicationDtosInModifyGrowthDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Member/${id}/GrowthDetails`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MemberLevel
     * @name VAMemberLevelDetail
     * @summary 获取所有的会员等级
     * @request GET:/api/v{version}/a/MemberLevel
     * @secure
     */
    vAMemberLevelDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebAdminDtosOutMemberLevelDto[], void>({
        path: `/api/v1/a/MemberLevel`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MemberLevel
     * @name VAMemberLevelUpdate
     * @summary 保存
     * @request PUT:/api/v{version}/a/MemberLevel
     * @secure
     */
    vAMemberLevelUpdate: (
      data: Building3DApplicationDtosInSaveMemberLevelDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/MemberLevel`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MemberLevel
     * @name VAMemberLevelDelete
     * @summary 删除
     * @request DELETE:/api/v{version}/a/MemberLevel/{id}
     * @secure
     */
    vAMemberLevelDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/a/MemberLevel/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MemberStatistics
     * @name VAStatisticsMemberInfoDetail
     * @summary 会员统计信息
     * @request GET:/api/v{version}/a/Statistics/Member/Info
     * @secure
     */
    vAStatisticsMemberInfoDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebAdminDtosOutMemberStatisticsInfoDto, void>({
        path: `/api/v1/a/Statistics/Member/Info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MemberStatistics
     * @name VAStatisticsMemberAddTrendDetail
     * @summary 新增会员趋势
     * @request GET:/api/v{version}/a/Statistics/Member/AddTrend
     * @secure
     */
    vAStatisticsMemberAddTrendDetail: (
      query: {
        SelectedTime: string;
        TimeRange: Building3DDomainEntitiesTimeRange;
      },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebAdminDtosOutMemberStatisticsAddTrendDto, void>({
        path: `/api/v1/a/Statistics/Member/AddTrend`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingQueryMyClassesCreate
     * @summary 获取班级列表 - 分页
     * @request POST:/api/v{version}/m/MyStuding/QueryMyClasses
     * @secure
     */
    vMMyStudingQueryMyClassesCreate: (
      data: Building3DApplicationDtosStudyManagerInClassInfoPagedDto,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerClassesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/m/MyStuding/QueryMyClasses`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingQueryMyTasksCreate
     * @summary 作业 - 分页
     * @request POST:/api/v{version}/m/MyStuding/QueryMyTasks
     * @secure
     */
    vMMyStudingQueryMyTasksCreate: (
      data: Building3DApplicationDtosStudyManagerTasksPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/m/MyStuding/QueryMyTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingQueryMyTaskFilesCreate
     * @summary 作业资料 - 分页
     * @request POST:/api/v{version}/m/MyStuding/{taskId}/QueryMyTaskFiles
     * @secure
     */
    vMMyStudingQueryMyTaskFilesCreate: (
      taskId: number,

      data: Building3DApplicationDtosStudyManagerTaskFilesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskFileExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/m/MyStuding/${taskId}/QueryMyTaskFiles`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingTaskFilesSaveCreate
     * @summary 作业资料上传 - 扩展
     * @request POST:/api/v{version}/m/MyStuding/{taskId}/TaskFilesSave
     * @secure
     */
    vMMyStudingTaskFilesSaveCreate: (
      taskId: number,

      data: Building3DApplicationDtosStudyManagerTaskFileExtendsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/MyStuding/${taskId}/TaskFilesSave`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingTaskFilesRemoveCreate
     * @summary 作业资料 - 删除
     * @request POST:/api/v{version}/m/MyStuding/TaskFilesRemove
     * @secure
     */
    vMMyStudingTaskFilesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/MyStuding/TaskFilesRemove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingMyTaskUserCommitsPageCreate
     * @summary （某个学生）我的提交情况查询 - 分页
     * @request POST:/api/v{version}/m/MyStuding/{taskId}/MyTaskUserCommits_Page
     * @secure
     */
    vMMyStudingMyTaskUserCommitsPageCreate: (
      taskId: number,

      data: Building3DApplicationDtosStudyManagerTaskUserCommitsPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskUserCommitExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/m/MyStuding/${taskId}/MyTaskUserCommits_Page`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingMyTaskCommitSubmitCreate
     * @summary 提交我的作业
     * @request POST:/api/v{version}/m/MyStuding/{taskId}/MyTaskCommitSubmit
     * @secure
     */
    vMMyStudingMyTaskCommitSubmitCreate: (
      taskId: number,

      data: Building3DApplicationDtosStudyManagerMyTaskCommitSubmitInput,
      params: RequestParams = {}
    ) =>
      this.request<number, void>({
        path: `/api/v1/m/MyStuding/${taskId}/MyTaskCommitSubmit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingMyTaskCommitRemoveCreate
     * @summary 删除我提交的作业
     * @request POST:/api/v{version}/m/MyStuding/{taskId}/{commitId}/MyTaskCommitRemove
     * @secure
     */
    vMMyStudingMyTaskCommitRemoveCreate: (
      taskId: number,
      commitId: number,

      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/MyStuding/${taskId}/${commitId}/MyTaskCommitRemove`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingUserCheckReportCreate
     * @summary 用户主动获取报告
     * @request POST:/api/v{version}/m/MyStuding/UserCheckReport
     * @secure
     */
    vMMyStudingUserCheckReportCreate: (
      data: Building3DWebAdminDtosOutputAuditModelPdfInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/MyStuding/UserCheckReport`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingQueryMyUploadFilesCreate
     * @summary 资料文件：分页
     * @request POST:/api/v{version}/m/MyStuding/QueryMyUploadFiles
     * @secure
     */
    vMMyStudingQueryMyUploadFilesCreate: (
      data: Building3DApplicationDtosStudyManagerUploadFilesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerUploadFilesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/m/MyStuding/QueryMyUploadFiles`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingMyTaskNoticesPageCreate
     * @summary 作业通知 - 分页
     * @request POST:/api/v{version}/m/MyStuding/{taskId}/MyTaskNotices_Page
     * @secure
     */
    vMMyStudingMyTaskNoticesPageCreate: (
      taskId: number,

      data: Building3DApplicationDtosStudyManagerTaskNoticesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskNoticesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/m/MyStuding/${taskId}/MyTaskNotices_Page`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingMyTaskOpinionsGetListCreate
     * @summary 批改批注:列表
     * @request POST:/api/v{version}/m/MyStuding/{taskId}/{commitId}/MyTaskOpinions_GetList
     * @secure
     */
    vMMyStudingMyTaskOpinionsGetListCreate: (
      taskId: number,
      commitId: number,

      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerTaskOpinionsDto[],
        void
      >({
        path: `/api/v1/m/MyStuding/${taskId}/${commitId}/MyTaskOpinions_GetList`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingQueryMyDesignTasksCreate
     * @summary 我的课程 - 分页
     * @request POST:/api/v{version}/m/MyStuding/QueryMyDesignTasks
     * @secure
     */
    vMMyStudingQueryMyDesignTasksCreate: (
      data: Building3DApplicationDtosStudyManagerDesignTasksPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignTaskExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/m/MyStuding/QueryMyDesignTasks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingDesignTaskViewsCreate
     * @summary 课程详情 - 内容
     * @request POST:/api/v{version}/m/MyStuding/{designTaskId}/DesignTask_Views
     * @secure
     */
    vMMyStudingDesignTaskViewsCreate: (
      designTaskId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerDesignTaskViewsDto[],
        void
      >({
        path: `/api/v1/m/MyStuding/${designTaskId}/DesignTask_Views`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingDesignTaskDetailsCreate
     * @summary 课程详情
     * @request POST:/api/v{version}/m/MyStuding/{designTaskId}/DesignTask_Details
     * @secure
     */
    vMMyStudingDesignTaskDetailsCreate: (
      designTaskId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerDesignTaskDetailsDto,
        void
      >({
        path: `/api/v1/m/MyStuding/${designTaskId}/DesignTask_Details`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingDesignFileDetailsCreate
     * @summary 定制资源详情
     * @request POST:/api/v{version}/m/MyStuding/{designFileId}/DesignFile_Details
     * @secure
     */
    vMMyStudingDesignFileDetailsCreate: (
      designFileId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerDesignFileDetails,
        void
      >({
        path: `/api/v1/m/MyStuding/${designFileId}/DesignFile_Details`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingDesignTaskUpdateDesignFinishStatusCreate
     * @summary 更新课程完成状态
     * @request POST:/api/v{version}/m/MyStuding/{designTaskId}/DesignTask_UpdateDesignFinishStatus
     * @secure
     */
    vMMyStudingDesignTaskUpdateDesignFinishStatusCreate: (
      designTaskId: number,

      data: Building3DApplicationDtosStudyManagerUpdateDesignFinishStatusInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/MyStuding/${designTaskId}/DesignTask_UpdateDesignFinishStatus`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MyStuding
     * @name VMMyStudingQueryMyDesignQuestionsListByIdsCreate
     * @summary 定制习题：按照习题编号顺序查询
     * @request POST:/api/v{version}/m/MyStuding/QueryMyDesignQuestionsListByIds
     * @secure
     */
    vMMyStudingQueryMyDesignQuestionsListByIdsCreate: (
      data: Building3DApplicationDtosStudyManagerQueryDesignQuestionsListByIdsInput,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerDesignQuestionsDto[],
        void
      >({
        path: `/api/v1/m/MyStuding/QueryMyDesignQuestionsListByIds`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PageVisits
     * @name VFPageVisitsCreate
     * @summary 新增页面访问记录
     * @request POST:/api/v{version}/f/PageVisits
     */
    vFPageVisitsCreate: (
      data: Building3DApplicationDtosInPageVisitCreateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/f/PageVisits`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductStatusesDetail
     * @summary 获取状态信息
     * @request GET:/api/v{version}/v/Product/Statuses
     * @secure
     */
    vVProductStatusesDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosOutProductStatusInfo[], void>({
        path: `/api/v1/v/Product/Statuses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductDetail
     * @summary 分页查询
     * @request GET:/api/v{version}/v/Product
     * @secure
     */
    vVProductDetail: (
      query?: {
        Name?: string | null;
        Type?: number | null;
        BrandId?: number | null;
        IsOffline?: boolean | null;
        IsNew?: boolean | null;
        Status?: Building3DDomainEntitiesProductStatus;
        Sorting?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosInProductPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/v/Product`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductDelete
     * @summary 批量删除(放进回收站)
     * @request DELETE:/api/v{version}/v/Product
     * @secure
     */
    vVProductDelete: (data: number[] | null, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/v/Product`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductCreate
     * @summary 保存产品
     * @request POST:/api/v{version}/v/Product
     * @secure
     */
    vVProductCreate: (
      data: Building3DApplicationDtosInProductSaveDto,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutProductSaveDto, void>({
        path: `/api/v1/v/Product`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductVersionsDetail
     * @summary 产品版本
     * @request GET:/api/v{version}/v/Product/{productId}/Versions
     * @secure
     */
    vVProductVersionsDetail: (productId: number, params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosOutProductVersionDto[], void>({
        path: `/api/v1/v/Product/${productId}/Versions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductVersionsSetCurrentUpdate
     * @summary 设置为当前版本
     * @request PUT:/api/v{version}/v/Product/{productId}/Versions/{pvId}/SetCurrent
     * @secure
     */
    vVProductVersionsSetCurrentUpdate: (
      productId: number,
      pvId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/${productId}/Versions/${pvId}/SetCurrent`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductOnlineUpdate
     * @summary 产品上架
     * @request PUT:/api/v{version}/v/Product/Online
     * @secure
     */
    vVProductOnlineUpdate: (
      data: Building3DApplicationDtosInProductOnlineDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/Online`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductOfflineUpdate
     * @summary 产品下架
     * @request PUT:/api/v{version}/v/Product/Offline
     * @secure
     */
    vVProductOfflineUpdate: (
      data: Building3DApplicationDtosInProductOfflineDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/Offline`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductSummaryDetail
     * @summary 获取产品概览
     * @request GET:/api/v{version}/v/Product/Summary
     * @secure
     */
    vVProductSummaryDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosOutProductSummaryDto, void>({
        path: `/api/v1/v/Product/Summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductAuditCreate
     * @summary 提交产品审核
     * @request POST:/api/v{version}/v/Product/{pvId}/Audit
     * @secure
     */
    vVProductAuditCreate: (pvId: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/${pvId}/Audit`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VVProductAuditRecordsDetail
     * @summary 获取产品所选版本的审核记录
     * @request GET:/api/v{version}/v/Product/{pvId}/AuditRecords
     * @secure
     */
    vVProductAuditRecordsDetail: (pvId: number, params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosOutProductAuditRecordDto[], void>({
        path: `/api/v1/v/Product/${pvId}/AuditRecords`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VMProductUpdate
     * @summary 获取产品分页列表
     * @request PUT:/api/v{version}/m/Product
     */
    vMProductUpdate: (
      data: Building3DWebMainDtosInProductPagedDto,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebMainDtosOutProductDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        any
      >({
        path: `/api/v1/m/Product`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VMProductSearchCountUpdate
     * @summary 获取产品列表数量
     * @request PUT:/api/v{version}/m/Product/SearchCount
     */
    vMProductSearchCountUpdate: (
      data: Building3DWebMainDtosInProductSearchCountDto,
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutProductSearchCountDto, any>({
        path: `/api/v1/m/Product/SearchCount`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VMProductFilterPropertiesDetail
     * @summary 获取产品列表筛选属性
     * @request GET:/api/v{version}/m/Product/FilterProperties
     */
    vMProductFilterPropertiesDetail: (
      query?: {
        Keyword?: string | null;
        BrandIds?: number[] | null;
        FileTypeNames?: string[] | null;
        CategoryIds?: number[] | null;
        HighFilterProperties?:
        | Building3DWebMainDtosInHighFilterPropertyDto[]
        | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutFilterPropertyDto, any>({
        path: `/api/v1/m/Product/FilterProperties`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VMProductHighFilterPropertiesDetail
     * @summary 获取产品列表高级筛选属性
     * @request GET:/api/v{version}/m/Product/HighFilterProperties
     */
    vMProductHighFilterPropertiesDetail: (
      query?: {
        Keyword?: string | null;
        BrandIds?: number[] | null;
        FileTypeNames?: string[] | null;
        CategoryIds?: number[] | null;
        HighFilterProperties?:
        | Building3DWebMainDtosInHighFilterPropertyDto[]
        | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutHighFilterPropertyDto[], any>({
        path: `/api/v1/m/Product/HighFilterProperties`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VMProductCategoryFilterPropertiesDetail
     * @summary 获取产品列表筛选分类
     * @request GET:/api/v{version}/m/Product/CategoryFilterProperties
     */
    vMProductCategoryFilterPropertiesDetail: (
      query?: {
        ProductName?: string | null;
        BrandId?: number | null;
        FileTypeNames?: string[] | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutCategoryDto[], any>({
        path: `/api/v1/m/Product/CategoryFilterProperties`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VMProductQueryMyParentProductTypesCreate
     * @summary 获取我有权限查看的分类
     * @request POST:/api/v{version}/m/Product/QueryMyParentProductTypes
     */
    vMProductQueryMyParentProductTypesCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/m/Product/QueryMyParentProductTypes`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VAProductNewUpdate
     * @summary 设为新品
     * @request PUT:/api/v{version}/a/Product/New
     * @secure
     */
    vAProductNewUpdate: (
      data: Building3DApplicationDtosInProductSetAsNewDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Product/New`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name VAProductNewDelete
     * @summary 取消新品
     * @request DELETE:/api/v{version}/a/Product/New
     * @secure
     */
    vAProductNewDelete: (
      data: Building3DApplicationDtosInProductCancelNewDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Product/New`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductAudit
     * @name VAProductAuditsDetail
     * @summary 分页查询
     * @request GET:/api/v{version}/a/Product/Audits
     * @secure
     */
    vAProductAuditsDetail: (
      query?: {
        Name?: string | null;
        Type?: number | null;
        BrandId?: number | null;
        Sorting?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutProductAuditPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Product/Audits`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductAudit
     * @name VAProductAuditPassUpdate
     * @summary 产品审核通过
     * @request PUT:/api/v{version}/a/Product/AuditPass
     * @secure
     */
    vAProductAuditPassUpdate: (
      data: Building3DApplicationDtosInProductAuditPassDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Product/AuditPass`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductAudit
     * @name VAProductAuditNoPassUpdate
     * @summary 产品审核不通过
     * @request PUT:/api/v{version}/a/Product/AuditNoPass
     * @secure
     */
    vAProductAuditNoPassUpdate: (
      data: Building3DApplicationDtosInProductAuditNoPassDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Product/AuditNoPass`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductTypeTreeDetail
     * @summary 分类树
     * @request GET:/api/v{version}/v/Product/Type/Tree
     * @secure
     */
    vVProductTypeTreeDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosOutProductTypeTreeDto, void>({
        path: `/api/v1/v/Product/Type/Tree`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductTypeHistoriesDetail
     * @summary 分类历史
     * @request GET:/api/v{version}/v/Product/Type/Histories
     * @secure
     */
    vVProductTypeHistoriesDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosOutProductTypeHistoryDto[], void>({
        path: `/api/v1/v/Product/Type/Histories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductInfosDetail
     * @summary 信息
     * @request GET:/api/v{version}/v/Product/Infos
     * @secure
     */
    vVProductInfosDetail: (
      query?: {
        productId?: number | null;
        pvId?: number | null;
        step?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebVenderDtosOutProductSaveInfosDto, void>({
        path: `/api/v1/v/Product/Infos`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductCategoryOptionsDetail
     * @summary 获取类型选项
     * @request GET:/api/v{version}/v/Product/Category/Options
     * @secure
     */
    vVProductCategoryOptionsDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosOutCategoryOptionDto[], void>({
        path: `/api/v1/v/Product/Category/Options`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductPropertiesUpdate
     * @summary 保存产品属性
     * @request PUT:/api/v{version}/v/Product/{id}/Properties
     * @secure
     */
    vVProductPropertiesUpdate: (
      id: number,

      data: Building3DApplicationDtosInProductPropertyDto[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/${id}/Properties`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductOptionsDetail
     * @summary 获取产品项
     * @request GET:/api/v{version}/v/Product/Options
     * @secure
     */
    vVProductOptionsDetail: (
      query?: {
        Type?: number | null;
        BrandId?: number | null;
        IsShelve?: boolean | null;
        ExcludeProductIds?: number[] | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosOutProductOptionDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/v/Product/Options`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductCorrelationsUpdate
     * @summary 保存相关产品
     * @request PUT:/api/v{version}/v/Product/{id}/Correlations
     * @secure
     */
    vVProductCorrelationsUpdate: (
      id: number,

      data: Building3DApplicationDtosInProductSaveCorrelationsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/${id}/Correlations`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductStepTypesCreate
     * @summary 保存产品(选择分类)
     * @request POST:/api/v{version}/v/Product/Step/Types
     * @secure
     */
    vVProductStepTypesCreate: (
      data: Building3DApplicationDtosInProductSaveSelectTypesStepDto,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutProductSaveDto, void>({
        path: `/api/v1/v/Product/Step/Types`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBasicInfo
     * @name VVProductStep1Create
     * @summary 保存产品(填写信息)
     * @request POST:/api/v{version}/v/Product/Step/1
     * @secure
     */
    vVProductStep1Create: (
      data: Building3DApplicationDtosInProductSaveStepOneDto,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutProductSaveDto, void>({
        path: `/api/v1/v/Product/Step/1`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name VAProductCategoryDetail
     * @summary 获取类型
     * @request GET:/api/v{version}/a/ProductCategory
     * @secure
     */
    vAProductCategoryDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebAdminDtosOutProductCategoryDto[], void>({
        path: `/api/v1/a/ProductCategory`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name VAProductCategoryUpdate
     * @request PUT:/api/v{version}/a/ProductCategory
     * @secure
     */
    vAProductCategoryUpdate: (
      data: Building3DApplicationDtosInProductCategoryDefDto,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutProductCategoryDefDto, void>({
        path: `/api/v1/a/ProductCategory`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name VAProductCategoryPropertiesUpdate
     * @summary 添加属性
     * @request PUT:/api/v{version}/a/ProductCategory/Properties
     * @secure
     */
    vAProductCategoryPropertiesUpdate: (
      data: Building3DApplicationDtosInProductCategoryDefAddPropertyDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductCategory/Properties`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name VAProductCategoryPropertiesDelete
     * @summary 删除属性
     * @request DELETE:/api/v{version}/a/ProductCategory/Properties
     * @secure
     */
    vAProductCategoryPropertiesDelete: (
      data: Building3DApplicationDtosInProductCategoryDefRemovePropertiesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductCategory/Properties`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name VAProductCategoryPropertiesSortUpdate
     * @summary 属性排序
     * @request PUT:/api/v{version}/a/ProductCategory/Properties/Sort
     * @secure
     */
    vAProductCategoryPropertiesSortUpdate: (
      data: Building3DApplicationDtosInProductCategoryDefSortPropertyDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductCategory/Properties/Sort`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name VAProductCategoryCopyUpdate
     * @summary 复制类型
     * @request PUT:/api/v{version}/a/ProductCategory/Copy
     * @secure
     */
    vAProductCategoryCopyUpdate: (
      data: Building3DApplicationDtosInProductCategoryDefCopyDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductCategory/Copy`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name VAProductCategoryPropertiesCopyUpdate
     * @summary 复制属性
     * @request PUT:/api/v{version}/a/ProductCategory/Properties/Copy
     * @secure
     */
    vAProductCategoryPropertiesCopyUpdate: (
      data: Building3DApplicationDtosInProductCategoryDefCopyPropertiesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductCategory/Properties/Copy`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name VAProductCategoryDelete
     * @summary 删除类型
     * @request DELETE:/api/v{version}/a/ProductCategory/{id}
     * @secure
     */
    vAProductCategoryDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductCategory/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductContrastSelection
     * @name VMProductContrastSelectionDetail
     * @summary 获取已选产品
     * @request GET:/api/v{version}/m/ProductContrastSelection
     * @secure
     */
    vMProductContrastSelectionDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebMainDtosOutContrastProductDto[], void>({
        path: `/api/v1/m/ProductContrastSelection`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductContrastSelection
     * @name VMProductContrastSelectionUpdate
     * @summary 选择
     * @request PUT:/api/v{version}/m/ProductContrastSelection
     * @secure
     */
    vMProductContrastSelectionUpdate: (
      data: Building3DApplicationDtosInProductContrastSelectionDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/ProductContrastSelection`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductContrastSelection
     * @name VMProductContrastSelectionDelete
     * @summary 清空
     * @request DELETE:/api/v{version}/m/ProductContrastSelection
     * @secure
     */
    vMProductContrastSelectionDelete: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/m/ProductContrastSelection`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductContrastSelection
     * @name VMProductContrastSelectionDelete2
     * @summary 移除
     * @request DELETE:/api/v{version}/m/ProductContrastSelection/{productId}
     * @originalName vMProductContrastSelectionDelete
     * @duplicate
     * @secure
     */
    vMProductContrastSelectionDelete2: (
      productId: number,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/ProductContrastSelection/${productId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDetail
     * @name VMProductDetailDetail
     * @summary 详情
     * @request GET:/api/v{version}/m/ProductDetail/{id}
     */
    vMProductDetailDetail: (id: number, params: RequestParams = {}) =>
      this.request<Building3DWebMainDtosOutProductDetailDto, any>({
        path: `/api/v1/m/ProductDetail/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDetail
     * @name VMProductDetailCertAndRatingDetail
     * @summary 认证与评级
     * @request GET:/api/v{version}/m/ProductDetail/{id}/CertAndRating
     */
    vMProductDetailCertAndRatingDetail: (
      id: number,

      query: { pvId: number },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutProductQualityInfoDto, any>({
        path: `/api/v1/m/ProductDetail/${id}/CertAndRating`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDetail
     * @name VMProductDetailTechDocsDetail
     * @summary 技术文档
     * @request GET:/api/v{version}/m/ProductDetail/{id}/TechDocs
     */
    vMProductDetailTechDocsDetail: (
      id: number,
      query: { pvId: number },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutProductTechDocDto[], any>({
        path: `/api/v1/m/ProductDetail/${id}/TechDocs`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDetail
     * @name VMProductDetailCorrelationsDetail
     * @summary 相关产品
     * @request GET:/api/v{version}/m/ProductDetail/{id}/Correlations
     */
    vMProductDetailCorrelationsDetail: (
      id: number,

      query: { pvId: number },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutProductCorrelationDto[], any>({
        path: `/api/v1/m/ProductDetail/${id}/Correlations`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductDetail
     * @name VMProductDetailModelsDetail
     * @summary 族文件
     * @request GET:/api/v{version}/m/ProductDetail/{id}/Models
     */
    vMProductDetailModelsDetail: (
      id: number,
      query: { pvId: number },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutProductModelFileDto[], any>({
        path: `/api/v1/m/ProductDetail/${id}/Models`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VVProductFilesModelsCreate
     * @summary 上传模型
     * @request POST:/api/v{version}/v/Product/Files/Models
     * @secure
     */
    vVProductFilesModelsCreate: (
      data: Building3DApplicationDtosInProductModelFileDto[] | null,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutProductFileUploadDto[], void>({
        path: `/api/v1/v/Product/Files/Models`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VVProductFilesDrawingsCreate
     * @summary 上传图纸
     * @request POST:/api/v{version}/v/Product/Files/Drawings
     * @secure
     */
    vVProductFilesDrawingsCreate: (
      data: Building3DApplicationDtosInProductDrawingsFileDto[] | null,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutProductFileUploadDto[], void>({
        path: `/api/v1/v/Product/Files/Drawings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VVProductFilesImagesCreate
     * @summary 上传图片
     * @request POST:/api/v{version}/v/Product/Files/Images
     * @secure
     */
    vVProductFilesImagesCreate: (
      data: Building3DApplicationDtosInProductUploadFileDto1Building3DApplicationDtosInProductFileDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutProductFileUploadDto[], void>({
        path: `/api/v1/v/Product/Files/Images`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VVProductFilesPrimaryUpdate
     * @summary 设为主图
     * @request PUT:/api/v{version}/v/Product/Files/Primary
     * @secure
     */
    vVProductFilesPrimaryUpdate: (
      data: Building3DApplicationDtosInProductImageFileAsPrimaryDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/Files/Primary`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VVProductFilesTechDocsCreate
     * @summary 上传技术文档
     * @request POST:/api/v{version}/v/Product/Files/TechDocs
     * @secure
     */
    vVProductFilesTechDocsCreate: (
      data: Building3DApplicationDtosInProductUploadFileDto1Building3DApplicationDtosInProductTechDocDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutProductFileUploadDto[], void>({
        path: `/api/v1/v/Product/Files/TechDocs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VVProductFilesCoverUpdate
     * @summary 上传封面
     * @request PUT:/api/v{version}/v/Product/Files/Cover
     * @secure
     */
    vVProductFilesCoverUpdate: (
      data: Building3DApplicationDtosInUploadCoverDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/Files/Cover`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VVProductFilesDelete
     * @summary 删除文件
     * @request DELETE:/api/v{version}/v/Product/Files/{id}
     * @secure
     */
    vVProductFilesDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/Files/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VMProductFilesSignedUrlDetail
     * @summary 获取签名的URL
     * @request GET:/api/v{version}/m/ProductFiles/SignedUrl
     * @secure
     */
    vMProductFilesSignedUrlDetail: (
      query?: {
        productId?: number;
        pvId?: number;
        extensionName?: string | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosOutFileSignedUrlDto, void>({
        path: `/api/v1/m/ProductFiles/SignedUrl`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VMProductFilesModelFileDetail
     * @summary 下载模型文件
     * @request GET:/api/v{version}/m/ProductFiles/ModelFile
     */
    vMProductFilesModelFileDetail: (
      query?: { p?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/m/ProductFiles/ModelFile`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductFiles
     * @name VMProductFilesDownloadModelFileDetail
     * @summary 下载模型文件
     * @request GET:/api/v{version}/m/ProductFiles/DownloadModelFile
     * @secure
     */
    vMProductFilesDownloadModelFileDetail: (
      query?: {
        productId?: number;
        pvId?: number;
        extensionName?: string | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/ProductFiles/DownloadModelFile`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductRecycleBin
     * @name VVProductRecycleBinDetail
     * @summary 产品列表
     * @request GET:/api/v{version}/v/Product/RecycleBin
     * @secure
     */
    vVProductRecycleBinDetail: (
      query?: {
        Name?: string | null;
        Type?: number | null;
        BrandId?: number | null;
        Sorting?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosOutProductRecycleBinPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/v/Product/RecycleBin`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductRecycleBin
     * @name VVProductRecycleBinDelete
     * @summary 批量删除
     * @request DELETE:/api/v{version}/v/Product/RecycleBin
     * @secure
     */
    vVProductRecycleBinDelete: (
      data: number[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/RecycleBin`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductRecycleBin
     * @name VVProductRecycleBinRestoreUpdate
     * @summary 还原
     * @request PUT:/api/v{version}/v/Product/RecycleBin/Restore
     * @secure
     */
    vVProductRecycleBinRestoreUpdate: (
      data: number[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/Product/RecycleBin/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductStatistics
     * @name VAStatisticsProductProportionRootTypeDownloadDetail
     * @summary 一级分类下载量占比
     * @request GET:/api/v{version}/a/Statistics/Product/Proportion/RootType/Download
     * @secure
     */
    vAStatisticsProductProportionRootTypeDownloadDetail: (
      query?: { timeRange?: Building3DDomainEntitiesTimeRange },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebAdminDtosOutProportionItemDto[], void>({
        path: `/api/v1/a/Statistics/Product/Proportion/RootType/Download`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductStatistics
     * @name VAStatisticsProductDistributionRootTypeDetail
     * @summary 一级分类数据统计
     * @request GET:/api/v{version}/a/Statistics/Product/Distribution/RootType
     * @secure
     */
    vAStatisticsProductDistributionRootTypeDetail: (
      query?: { timeRange?: Building3DDomainEntitiesTimeRange },
      params: RequestParams = {}
    ) =>
      this.request<
        Record<string, Building3DWebAdminDtosOutDistributionDto>,
        void
      >({
        path: `/api/v1/a/Statistics/Product/Distribution/RootType`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductStatistics
     * @name VAStatisticsProductTops10Detail
     * @summary 排名前10的产品
     * @request GET:/api/v{version}/a/Statistics/Product/Tops/10
     * @secure
     */
    vAStatisticsProductTops10Detail: (
      query?: { timeRange?: Building3DDomainEntitiesTimeRange },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebAdminDtosOutStatisticsTopProductDto[], void>({
        path: `/api/v1/a/Statistics/Product/Tops/10`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VVProductTypeTreeDetail2
     * @summary 分类树
     * @request GET:/api/v{version}/v/ProductType/Tree
     * @originalName vVProductTypeTreeDetail
     * @duplicate
     * @secure
     */
    vVProductTypeTreeDetail2: (params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosOutProductTypeFullTreeDto, void>({
        path: `/api/v1/v/ProductType/Tree`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VVProductTypeCreate
     * @summary 创建分类
     * @request POST:/api/v{version}/v/ProductType
     * @secure
     */
    vVProductTypeCreate: (
      data: Building3DApplicationDtosInProductTypeCreateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/ProductType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VVProductTypeUpdate
     * @summary 修改分类
     * @request PUT:/api/v{version}/v/ProductType
     * @secure
     */
    vVProductTypeUpdate: (
      data: Building3DApplicationDtosInProductTypeModifyDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/ProductType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VVProductTypeSortUpdate
     * @summary 修改排序
     * @request PUT:/api/v{version}/v/ProductType/Sort
     * @secure
     */
    vVProductTypeSortUpdate: (
      data: Building3DApplicationDtosInProductTypeSortDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/ProductType/Sort`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VVProductTypeDelete
     * @summary 删除
     * @request DELETE:/api/v{version}/v/ProductType/{id}
     * @secure
     */
    vVProductTypeDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/v/ProductType/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VVProductTypeProductTransferTypeUpdate
     * @summary 产品分类转移
     * @request PUT:/api/v{version}/v/ProductType/ProductTransferType
     * @secure
     */
    vVProductTypeProductTransferTypeUpdate: (
      data: Building3DApplicationDtosInProductTransferTypeDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/v/ProductType/ProductTransferType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VVProductTypeViewProductDetail
     * @summary 查看产品
     * @request GET:/api/v{version}/v/ProductType/{id}/ViewProduct
     * @secure
     */
    vVProductTypeViewProductDetail: (id: number, params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosProductTypeViewProductDto, void>({
        path: `/api/v1/v/ProductType/${id}/ViewProduct`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VAProductTypeImportUpdate
     * @summary 导入类型
     * @request PUT:/api/v{version}/a/ProductType/Import
     * @secure
     */
    vAProductTypeImportUpdate: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductType/Import`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VAProductTypeProductTypeUserCreateCreate
     * @summary 增加产品类型授权
     * @request POST:/api/v{version}/a/ProductType/ProductTypeUser_Create
     * @secure
     */
    vAProductTypeProductTypeUserCreateCreate: (
      data: Building3DApplicationDtosProductTypeUsersDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductType/ProductTypeUser_Create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VAProductTypeProductTypeUserDeleteCreate
     * @summary 删除产品类型授权
     * @request POST:/api/v{version}/a/ProductType/ProductTypeUser_Delete
     * @secure
     */
    vAProductTypeProductTypeUserDeleteCreate: (
      data: Building3DApplicationDtosProductTypeUsersDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductType/ProductTypeUser_Delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VAProductTypeProductTypeUserBatchCreateCreate
     * @summary 增加产品类型授权
     * @request POST:/api/v{version}/a/ProductType/ProductTypeUser_BatchCreate
     * @secure
     */
    vAProductTypeProductTypeUserBatchCreateCreate: (
      data: Building3DApplicationDtosBatchProductTypeUserInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductType/ProductTypeUser_BatchCreate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductType
     * @name VAProductTypeProductTypeUserBatchDeleteCreate
     * @summary 删除产品类型授权
     * @request POST:/api/v{version}/a/ProductType/ProductTypeUser_BatchDelete
     * @secure
     */
    vAProductTypeProductTypeUserBatchDeleteCreate: (
      data: Building3DApplicationDtosBatchProductTypeUserInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/ProductType/ProductTypeUser_BatchDelete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyDefs
     * @name VAPropertyDefsUpdate
     * @summary 保存
     * @request PUT:/api/v{version}/a/PropertyDefs
     * @secure
     */
    vAPropertyDefsUpdate: (
      data: Building3DApplicationDtosInPropertyDefDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/PropertyDefs`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickEntry
     * @name VVQuickEntryOptionalTreeDetail
     * @summary 获取快捷入口设置树
     * @request GET:/api/v{version}/v/QuickEntry/OptionalTree
     * @secure
     */
    vVQuickEntryOptionalTreeDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebVenderDtosOutOptionalQuickEntryTreeDto, void>({
        path: `/api/v1/v/QuickEntry/OptionalTree`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuickEntry
     * @name VVQuickEntryUpdate
     * @summary 保存快捷入口
     * @request PUT:/api/v{version}/v/QuickEntry
     * @secure
     */
    vVQuickEntryUpdate: (data: number[] | null, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/v/QuickEntry`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Regions
     * @name VFRegionsDetail
     * @request GET:/api/v{version}/f/Regions
     */
    vFRegionsDetail: (
      query?: { parentCode?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebFoundationDtosOutRegionDto[], any>({
        path: `/api/v1/f/Regions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name VFRoleDetail
     * @summary 获取角色列表
     * @request GET:/api/v{version}/f/Role
     * @secure
     */
    vFRoleDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebFoundationDtosOutRoleDto[], void>({
        path: `/api/v1/f/Role`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name VFRoleCreate
     * @summary 新增角色
     * @request POST:/api/v{version}/f/Role
     * @secure
     */
    vFRoleCreate: (
      data: Building3DApplicationDtosInRoleCreateDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/f/Role`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name VFRoleUpdate
     * @summary 修改角色
     * @request PUT:/api/v{version}/f/Role
     * @secure
     */
    vFRoleUpdate: (
      data: Building3DApplicationDtosInRoleModifyDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/f/Role`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name VFRolePermissionTreeDetail
     * @summary 获取角色权限
     * @request GET:/api/v{version}/f/Role/PermissionTree
     * @secure
     */
    vFRolePermissionTreeDetail: (
      query?: { id?: number | null },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebFoundationDtosOutPermissionTreeDto, void>({
        path: `/api/v1/f/Role/PermissionTree`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name VFRoleDelete
     * @summary 删除角色
     * @request DELETE:/api/v{version}/f/Role/{id}
     * @secure
     */
    vFRoleDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/f/Role/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name VFRoleEnableUpdate
     * @summary 批量启用
     * @request PUT:/api/v{version}/f/Role/Enable
     * @secure
     */
    vFRoleEnableUpdate: (data: number[] | null, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/f/Role/Enable`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name VFRoleDisableUpdate
     * @summary 批量禁用
     * @request PUT:/api/v{version}/f/Role/Disable
     * @secure
     */
    vFRoleDisableUpdate: (data: number[] | null, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/f/Role/Disable`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SearchStatistics
     * @name VAStatisticsProductSearchTops10Detail
     * @summary 搜索统计前十
     * @request GET:/api/v{version}/a/Statistics/ProductSearch/Tops/10
     * @secure
     */
    vAStatisticsProductSearchTops10Detail: (
      query?: { timeRange?: Building3DDomainEntitiesTimeRange },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebAdminDtosOutSearchStatisticsTopDto[], void>({
        path: `/api/v1/a/Statistics/ProductSearch/Tops/10`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SiteMessages
     * @name VFSiteMessagesDetail
     * @request GET:/api/v{version}/f/SiteMessages
     * @secure
     */
    vFSiteMessagesDetail: (
      query?: {
        Category?: Building3DDomainEntitiesMessageCategory;
        Status?: Building3DDomainEntitiesMessageStatus;
        ReceiverType?: Building3DDomainEntitiesReceiverType;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebFoundationDtosOutSiteMessageRowDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/f/SiteMessages`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SiteMessages
     * @name VFSiteMessagesDelete
     * @summary 清空
     * @request DELETE:/api/v{version}/f/SiteMessages
     * @secure
     */
    vFSiteMessagesDelete: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/f/SiteMessages`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SiteMessages
     * @name VFSiteMessagesReadUpdate
     * @summary 已读
     * @request PUT:/api/v{version}/f/SiteMessages/{id}/Read
     * @secure
     */
    vFSiteMessagesReadUpdate: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/f/SiteMessages/${id}/Read`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SiteMessages
     * @name VFSiteMessagesReadAllUpdate
     * @summary 全部已读
     * @request PUT:/api/v{version}/f/SiteMessages/ReadAll
     * @secure
     */
    vFSiteMessagesReadAllUpdate: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/f/SiteMessages/ReadAll`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SiteMessages
     * @name VFSiteMessagesUnreadCountDetail
     * @summary 获取未读消息数
     * @request GET:/api/v{version}/f/SiteMessages/UnreadCount
     * @secure
     */
    vFSiteMessagesUnreadCountDetail: (
      query?: { receiverType?: Building3DDomainEntitiesReceiverType },
      params: RequestParams = {}
    ) =>
      this.request<number, void>({
        path: `/api/v1/f/SiteMessages/UnreadCount`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SiteMessages
     * @name VFSiteMessagesCategoriesDetail
     * @summary 获取类型信息
     * @request GET:/api/v{version}/f/SiteMessages/Categories
     * @secure
     */
    vFSiteMessagesCategoriesDetail: (
      query?: { receiverType?: Building3DDomainEntitiesReceiverType },
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DWebFoundationDtosOutSiteMessageCategoryInfoDto[],
        void
      >({
        path: `/api/v1/f/SiteMessages/Categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name VAStatisticsDistributionVisitDetail
     * @summary 页面流量分布
     * @request GET:/api/v{version}/a/Statistics/Distribution/Visit
     * @secure
     */
    vAStatisticsDistributionVisitDetail: (
      query?: {
        option?: string | null;
        timeRange?: Building3DDomainEntitiesTimeRange;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        Record<string, Building3DWebAdminDtosOutDistributionDto>,
        void
      >({
        path: `/api/v1/a/Statistics/Distribution/Visit`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Statistics
     * @name VAStatisticsProportionVisitClientTypeDetail
     * @summary 页面流量客户端占比
     * @request GET:/api/v{version}/a/Statistics/Proportion/Visit/ClientType
     * @secure
     */
    vAStatisticsProportionVisitClientTypeDetail: (
      query?: {
        option?: string | null;
        timeRange?: Building3DDomainEntitiesTimeRange;
      },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebAdminDtosOutProportionItemDto[], void>({
        path: `/api/v1/a/Statistics/Proportion/Visit/ClientType`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyQueryDesignQuestionsListByIdsCreate
     * @summary 定制习题：按照习题编号顺序查询
     * @request POST:/api/v{version}/a/Study/QueryDesignQuestionsListByIds
     * @secure
     */
    vAStudyQueryDesignQuestionsListByIdsCreate: (
      data: Building3DApplicationDtosStudyManagerQueryDesignQuestionsListByIdsInput,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerDesignQuestionsDto[],
        void
      >({
        path: `/api/v1/a/Study/QueryDesignQuestionsListByIds`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignQuestionsSaveCreate
     * @summary 定制题库 - 创建
     * @request POST:/api/v{version}/a/Study/DesignQuestions_Save
     * @secure
     */
    vAStudyDesignQuestionsSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDesignQuestionsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignQuestions_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignQuestionsRemoveCreate
     * @summary 定制题库 - 删除
     * @request POST:/api/v{version}/a/Study/DesignQuestions_Remove
     * @secure
     */
    vAStudyDesignQuestionsRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignQuestions_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyAdminUploadFilesPageListCreate
     * @summary 列表 - 分页
     * @request POST:/api/v{version}/a/Study/AdminUploadFiles_PageList
     * @secure
     */
    vAStudyAdminUploadFilesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerUploadFilesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerUploadFilesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/AdminUploadFiles_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyClassesSaveCreate
     * @summary 保存班级
     * @request POST:/api/v{version}/a/Study/Classes_Save
     * @secure
     */
    vAStudyClassesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerClassesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/Classes_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyClassesPageListCreate
     * @summary 获取班级列表 - 分页
     * @request POST:/api/v{version}/a/Study/Classes_PageList
     * @secure
     */
    vAStudyClassesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerInClassInfoPagedDto,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerClassesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/Classes_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyClassesRemoveCreate
     * @summary 删除班级
     * @request POST:/api/v{version}/a/Study/Classes_Remove
     * @secure
     */
    vAStudyClassesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/Classes_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudySetClassManagerCreate
     * @summary 设置班主任
     * @request POST:/api/v{version}/a/Study/SetClassManager
     * @secure
     */
    vAStudySetClassManagerCreate: (
      data: Building3DApplicationDtosStudyManagerSetClassManagerInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/SetClassManager`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyBatchAddClassMemberCreate
     * @summary 设置班级成员
     * @request POST:/api/v{version}/a/Study/BatchAddClassMember
     * @secure
     */
    vAStudyBatchAddClassMemberCreate: (
      data: Building3DApplicationDtosStudyManagerBatchClassMemberDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/BatchAddClassMember`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyBatchRemoveClassMemberCreate
     * @summary 批量移除班级成员
     * @request POST:/api/v{version}/a/Study/BatchRemoveClassMember
     * @secure
     */
    vAStudyBatchRemoveClassMemberCreate: (
      data: Building3DApplicationDtosStudyManagerBatchClassMemberDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/BatchRemoveClassMember`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyUsersPageByStudyCreate
     * @summary 获取用户
     * @request POST:/api/v{version}/a/Study/Users_PageByStudy
     * @secure
     */
    vAStudyUsersPageByStudyCreate: (
      data: Building3DWebAdminDtosInUserPageDto,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutUserPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/Users_PageByStudy`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyExportUserDtoFilePageByStudyCreate
     * @summary 导出用户账号
     * @request POST:/api/v{version}/a/Study/ExportUserDtoFile_PageByStudy
     * @secure
     */
    vAStudyExportUserDtoFilePageByStudyCreate: (
      data: Building3DWebAdminDtosInUserPageDto,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosStudyManagerFileDto, void>({
        path: `/api/v1/a/Study/ExportUserDtoFile_PageByStudy`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskTypesPageListCreate
     * @summary 作业分类 - 分页
     * @request POST:/api/v{version}/a/Study/TaskTypes_PageList
     * @secure
     */
    vAStudyTaskTypesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerTaskTypesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/TaskTypes_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskTypesSaveCreate
     * @summary 作业分类 - 创建
     * @request POST:/api/v{version}/a/Study/TaskTypes_Save
     * @secure
     */
    vAStudyTaskTypesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerTaskTypesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskTypes_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskTypesRemoveCreate
     * @summary 作业分类 - 删除
     * @request POST:/api/v{version}/a/Study/TaskTypes_Remove
     * @secure
     */
    vAStudyTaskTypesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskTypes_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTasksPageListCreate
     * @summary 作业 - 分页
     * @request POST:/api/v{version}/a/Study/Tasks_PageList
     * @secure
     */
    vAStudyTasksPageListCreate: (
      data: Building3DApplicationDtosStudyManagerTasksPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/Tasks_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTasksSaveCreate
     * @summary 作业 - 创建
     * @request POST:/api/v{version}/a/Study/Tasks_Save
     * @secure
     */
    vAStudyTasksSaveCreate: (
      data: Building3DApplicationDtosStudyManagerTasksDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/Tasks_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTasksRemoveCreate
     * @summary 作业 - 删除
     * @request POST:/api/v{version}/a/Study/Tasks_Remove
     * @secure
     */
    vAStudyTasksRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/Tasks_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTasksConfigureOverCreate
     * @summary 题库 - 配置完成
     * @request POST:/api/v{version}/a/Study/Tasks_ConfigureOver
     * @secure
     */
    vAStudyTasksConfigureOverCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/Tasks_ConfigureOver`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTasksChooseQuestionCreate
     * @summary 题库 - 选择题库到作业
     * @request POST:/api/v{version}/a/Study/Tasks_ChooseQuestion
     * @secure
     */
    vAStudyTasksChooseQuestionCreate: (
      data: Building3DApplicationDtosStudyManagerTaskQuestionDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/Tasks_ChooseQuestion`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyBatchAddTaskUsersCreate
     * @summary 批量添加作业成员
     * @request POST:/api/v{version}/a/Study/BatchAddTaskUsers
     * @secure
     */
    vAStudyBatchAddTaskUsersCreate: (
      data: Building3DApplicationDtosStudyManagerBatchTaskUsersDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/BatchAddTaskUsers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyBatchRemoveTaskUsersCreate
     * @summary 批量移除作业成员
     * @request POST:/api/v{version}/a/Study/BatchRemoveTaskUsers
     * @secure
     */
    vAStudyBatchRemoveTaskUsersCreate: (
      data: Building3DApplicationDtosStudyManagerBatchTaskUsersDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/BatchRemoveTaskUsers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyQueryTaskUserIdsCreate
     * @summary 获取作业成员Id
     * @request POST:/api/v{version}/a/Study/QueryTaskUserIds
     * @secure
     */
    vAStudyQueryTaskUserIdsCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<number[], void>({
        path: `/api/v1/a/Study/QueryTaskUserIds`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskFilesPageListCreate
     * @summary 作业资料 - 分页[fileConvertState（ UnStart = 0, Progress = 1, Success = 2, Failing = -1）]
     * @request POST:/api/v{version}/a/Study/TaskFiles_PageList
     * @secure
     */
    vAStudyTaskFilesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerTaskFilesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskFileExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/TaskFiles_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskFilesSaveCreate
     * @summary 作业资料 - 创建
     * @request POST:/api/v{version}/a/Study/TaskFiles_Save
     * @secure
     */
    vAStudyTaskFilesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerTaskFilesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskFiles_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskFileExtendsSaveCreate
     * @summary 作业资料上传 - 扩展
     * @request POST:/api/v{version}/a/Study/TaskFileExtends_Save
     * @secure
     */
    vAStudyTaskFileExtendsSaveCreate: (
      data: Building3DApplicationDtosStudyManagerTaskFileExtendsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskFileExtends_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskFileQuestionExtendsSaveCreate
     * @summary 题库资料上传 - 扩展
     * @request POST:/api/v{version}/a/Study/TaskFileQuestionExtends_Save
     * @secure
     */
    vAStudyTaskFileQuestionExtendsSaveCreate: (
      data: Building3DApplicationDtosStudyManagerTaskFileExtendsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskFileQuestionExtends_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskFilesRemoveCreate
     * @summary 作业资料 - 删除
     * @request POST:/api/v{version}/a/Study/TaskFiles_Remove
     * @secure
     */
    vAStudyTaskFilesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskFiles_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskFilesBatchRemoveCreate
     * @summary 作业资料 - 批量删除
     * @request POST:/api/v{version}/a/Study/TaskFiles_BatchRemove
     * @secure
     */
    vAStudyTaskFilesBatchRemoveCreate: (
      data: Building3DApplicationDtosStudyManagerTaskFilesBatchRemoveInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskFiles_BatchRemove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskUserCommitsPageByUserCreate
     * @summary （全部学生）提交情况查询 - 分页
     * @request POST:/api/v{version}/a/Study/{taskId}/TaskUserCommits_PageByUser
     * @secure
     */
    vAStudyTaskUserCommitsPageByUserCreate: (
      taskId: number,

      data: Building3DApplicationDtosStudyManagerTaskUserCommitsPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskUserCommitExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/${taskId}/TaskUserCommits_PageByUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyExportTaskUserCommitsPageByUserCreate
     * @summary 导出提交情况
     * @request POST:/api/v{version}/a/Study/{taskId}/ExportTaskUserCommits_PageByUser
     * @secure
     */
    vAStudyExportTaskUserCommitsPageByUserCreate: (
      taskId: number,

      data: Building3DApplicationDtosStudyManagerTaskUserCommitsPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosStudyManagerFileDto, void>({
        path: `/api/v1/a/Study/${taskId}/ExportTaskUserCommits_PageByUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskUserCommitsPageCreate
     * @summary （某个学生）提交情况查询 - 分页
     * @request POST:/api/v{version}/a/Study/{taskId}/{userId}/TaskUserCommits_Page
     * @secure
     */
    vAStudyTaskUserCommitsPageCreate: (
      taskId: number,
      userId: number,

      data: Building3DApplicationDtosStudyManagerTaskUserCommitsPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskUserCommitExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/${taskId}/${userId}/TaskUserCommits_Page`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskCommitsBatchLockedCreate
     * @summary 批量设置系统审查报告锁定状态
     * @request POST:/api/v{version}/a/Study/TaskCommits_BatchLocked
     * @secure
     */
    vAStudyTaskCommitsBatchLockedCreate: (
      data: Building3DApplicationDtosStudyManagerTaskCommitsBatchLockedInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskCommits_BatchLocked`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyBatchTaskCommitCheckWithSystemCreate
     * @summary 批量同步分数
     * @request POST:/api/v{version}/a/Study/{taskId}/BatchTaskCommitCheckWithSystem
     * @secure
     */
    vAStudyBatchTaskCommitCheckWithSystemCreate: (
      taskId: number,

      data: Building3DApplicationDtosStudyManagerBatchTaskCommitCheckInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/${taskId}/BatchTaskCommitCheckWithSystem`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskCommitCheckCreate
     * @summary 批改作业
     * @request POST:/api/v{version}/a/Study/{taskId}/{commitId}/TaskCommitCheck
     * @secure
     */
    vAStudyTaskCommitCheckCreate: (
      taskId: number,
      commitId: number,

      data: Building3DApplicationDtosStudyManagerTaskCommitCheckInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/${taskId}/${commitId}/TaskCommitCheck`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskOpinionsSaveCreate
     * @summary 批改批注:创建
     * @request POST:/api/v{version}/a/Study/{taskId}/{commitId}/TaskOpinions_Save
     * @secure
     */
    vAStudyTaskOpinionsSaveCreate: (
      taskId: number,
      commitId: number,

      data: Building3DApplicationDtosStudyManagerTaskOpinionsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/${taskId}/${commitId}/TaskOpinions_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskOpinionsRemoveCreate
     * @summary 批改批注:删除
     * @request POST:/api/v{version}/a/Study/TaskOpinions_Remove
     * @secure
     */
    vAStudyTaskOpinionsRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskOpinions_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskOpinionsGetListCreate
     * @summary 批改批注:列表
     * @request POST:/api/v{version}/a/Study/{taskId}/{commitId}/TaskOpinions_GetList
     * @secure
     */
    vAStudyTaskOpinionsGetListCreate: (
      taskId: number,
      commitId: number,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerTaskOpinionsDto[],
        void
      >({
        path: `/api/v1/a/Study/${taskId}/${commitId}/TaskOpinions_GetList`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyCheckRulesQueryDetailByTaskIdCreate
     * @summary 获取作业配置项
     * @request POST:/api/v{version}/a/Study/CheckRules_QueryDetailByTaskId
     * @secure
     */
    vAStudyCheckRulesQueryDetailByTaskIdCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerCheckRuleDetailsDto,
        void
      >({
        path: `/api/v1/a/Study/CheckRules_QueryDetailByTaskId`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyCheckRulesSaveCreate
     * @summary 保存作业配置项 - 属性审查规则
     * @request POST:/api/v{version}/a/Study/CheckRules_Save
     * @secure
     */
    vAStudyCheckRulesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerCheckRulesDto,
      params: RequestParams = {}
    ) =>
      this.request<number, void>({
        path: `/api/v1/a/Study/CheckRules_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyCheckRuleConfigsSaveCreate
     * @summary 保存作业配置项 - 权重占比
     * @request POST:/api/v{version}/a/Study/CheckRuleConfigs_Save
     * @secure
     */
    vAStudyCheckRuleConfigsSaveCreate: (
      data: Building3DApplicationDtosStudyManagerCheckRuleConfigsDto,
      params: RequestParams = {}
    ) =>
      this.request<number, void>({
        path: `/api/v1/a/Study/CheckRuleConfigs_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyCheckRulePropertiesSaveCreate
     * @summary 构件评分规则 - 保存
     * @request POST:/api/v{version}/a/Study/CheckRuleProperties_Save
     * @secure
     */
    vAStudyCheckRulePropertiesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerCheckRulePropertiesListInputDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/CheckRuleProperties_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyAuditModelCreate
     * @summary 系统审查模型
     * @request POST:/api/v{version}/a/Study/AuditModel
     * @secure
     */
    vAStudyAuditModelCreate: (
      data: Building3DWebAdminDtosAuditModelInput,
      params: RequestParams = {}
    ) =>
      this.request<string, void>({
        path: `/api/v1/a/Study/AuditModel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyOutputAuditModelPdfCreate
     * @summary 审查并创建报告pdf
     * @request POST:/api/v{version}/a/Study/OutputAuditModelPdf
     * @secure
     */
    vAStudyOutputAuditModelPdfCreate: (
      data: Building3DWebAdminDtosOutputAuditModelPdfInput,
      params: RequestParams = {}
    ) =>
      this.request<Building3DApplicationDtosStudyManagerCheckReportsDto, void>({
        path: `/api/v1/a/Study/OutputAuditModelPdf`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyBatchOutputAuditModelPdfCreate
     * @summary 批量审查并创建报告pdf
     * @request POST:/api/v{version}/a/Study/BatchOutputAuditModelPdf
     * @secure
     */
    vAStudyBatchOutputAuditModelPdfCreate: (
      data: Building3DWebAdminDtosBatchOutputAuditModelPdfInput,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerCheckReportsDto[],
        void
      >({
        path: `/api/v1/a/Study/BatchOutputAuditModelPdf`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskNoticesPageListCreate
     * @summary 作业通知 - 分页
     * @request POST:/api/v{version}/a/Study/TaskNotices_PageList
     * @secure
     */
    vAStudyTaskNoticesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerTaskNoticesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerTaskNoticesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/TaskNotices_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskNoticesSaveCreate
     * @summary 作业通知 - 创建
     * @request POST:/api/v{version}/a/Study/TaskNotices_Save
     * @secure
     */
    vAStudyTaskNoticesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerTaskNoticesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskNotices_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTaskNoticesRemoveCreate
     * @summary 作业通知 - 删除
     * @request POST:/api/v{version}/a/Study/TaskNotices_Remove
     * @secure
     */
    vAStudyTaskNoticesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TaskNotices_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTestReportCreate
     * @summary 测试
     * @request POST:/api/v{version}/a/Study/TestReport
     * @secure
     */
    vAStudyTestReportCreate: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/TestReport`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyTasksQuestionListCreate
     * @summary 题库列表
     * @request POST:/api/v{version}/a/Study/TasksQuestion_List
     * @secure
     */
    vAStudyTasksQuestionListCreate: (
      data: Building3DApplicationDtosStudyManagerTasksPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerTaskQuestionListDto[],
        void
      >({
        path: `/api/v1/a/Study/TasksQuestion_List`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFileTypesPageListCreate
     * @summary 仿真资源目录 - 分页
     * @request POST:/api/v{version}/a/Study/DesignFileTypes_PageList
     * @secure
     */
    vAStudyDesignFileTypesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDesignFileTypesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignFileTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DesignFileTypes_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFileTypesSaveCreate
     * @summary 仿真资源目录 - 创建
     * @request POST:/api/v{version}/a/Study/DesignFileTypes_Save
     * @secure
     */
    vAStudyDesignFileTypesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDesignFileTypesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignFileTypes_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFileTypesRemoveCreate
     * @summary 仿真资源目录 - 删除
     * @request POST:/api/v{version}/a/Study/DesignFileTypes_Remove
     * @secure
     */
    vAStudyDesignFileTypesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignFileTypes_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFilesPageListCreate
     * @summary 仿真资源 - 分页查询
     * @request POST:/api/v{version}/a/Study/DesignFiles_PageList
     * @secure
     */
    vAStudyDesignFilesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDesignFilesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignFileExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DesignFiles_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFileExtendsSaveCreate
     * @summary 仿真资源 - 保存
     * @request POST:/api/v{version}/a/Study/DesignFileExtends_Save
     * @secure
     */
    vAStudyDesignFileExtendsSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDesignFileExtendsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignFileExtends_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFilesSaveCreate
     * @summary 仿真资源 - 保存
     * @request POST:/api/v{version}/a/Study/DesignFiles_Save
     * @secure
     */
    vAStudyDesignFilesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDesignFilesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignFiles_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyQueryDesignFileDetailsCreate
     * @summary 仿真资源 - 获取详情
     * @request POST:/api/v{version}/a/Study/QueryDesignFileDetails
     * @secure
     */
    vAStudyQueryDesignFileDetailsCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<
        Building3DApplicationDtosStudyManagerDesignFileDetails,
        void
      >({
        path: `/api/v1/a/Study/QueryDesignFileDetails`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFileUploadViewFileCreate
     * @summary 仿真资源 - 上传二维图纸
     * @request POST:/api/v{version}/a/Study/DesignFile_UploadViewFile
     * @secure
     */
    vAStudyDesignFileUploadViewFileCreate: (
      data: Building3DApplicationDtosStudyManagerDesignViewFileInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignFile_UploadViewFile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFilesRemoveCreate
     * @summary 仿真资源 - 删除
     * @request POST:/api/v{version}/a/Study/DesignFiles_Remove
     * @secure
     */
    vAStudyDesignFilesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignFiles_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFilesFinishCreate
     * @summary 仿真资源 - 完成设置
     * @request POST:/api/v{version}/a/Study/DesignFiles_Finish
     * @secure
     */
    vAStudyDesignFilesFinishCreate: (
      data: Building3DApplicationDtosStudyManagerDesignFilesFinishInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignFiles_Finish`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFileViewsSaveCreate
     * @summary 仿真资源试图 - 保存
     * @request POST:/api/v{version}/a/Study/DesignFileViews_Save
     * @secure
     */
    vAStudyDesignFileViewsSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDesignFileViewsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignFileViews_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFileViewsRemoveCreate
     * @summary 仿真资源试图 - 删除
     * @request POST:/api/v{version}/a/Study/DesignFileViews_Remove
     * @secure
     */
    vAStudyDesignFileViewsRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignFileViews_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignFileViewsPageListCreate
     * @summary 仿真资源试图 - 分页查询
     * @request POST:/api/v{version}/a/Study/DesignFileViews_PageList
     * @secure
     */
    vAStudyDesignFileViewsPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDesignFileViewsPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignFileViewsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DesignFileViews_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDocumentFileTypesPageListCreate
     * @summary 公共资源目录 - 分页
     * @request POST:/api/v{version}/a/Study/DocumentFileTypes_PageList
     * @secure
     */
    vAStudyDocumentFileTypesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDocumentFileTypesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDocumentFileTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DocumentFileTypes_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDocumentFileTypesSaveCreate
     * @summary 公共资源目录 - 创建
     * @request POST:/api/v{version}/a/Study/DocumentFileTypes_Save
     * @secure
     */
    vAStudyDocumentFileTypesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDocumentFileTypesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DocumentFileTypes_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDocumentFileTypesRemoveCreate
     * @summary 公共资源目录 - 删除
     * @request POST:/api/v{version}/a/Study/DocumentFileTypes_Remove
     * @secure
     */
    vAStudyDocumentFileTypesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DocumentFileTypes_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDocumentFilesPageListCreate
     * @summary 公共资源 - 分页查询
     * @request POST:/api/v{version}/a/Study/DocumentFiles_PageList
     * @secure
     */
    vAStudyDocumentFilesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDocumentFilesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDocumentFileExtendsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DocumentFiles_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDocumentFileExtendsSaveCreate
     * @summary 公共资源 - 保存
     * @request POST:/api/v{version}/a/Study/DocumentFileExtends_Save
     * @secure
     */
    vAStudyDocumentFileExtendsSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDocumentFileExtendsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DocumentFileExtends_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDocumentFilesRemoveCreate
     * @summary 公共资源 - 删除
     * @request POST:/api/v{version}/a/Study/DocumentFiles_Remove
     * @secure
     */
    vAStudyDocumentFilesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DocumentFiles_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTaskTypesPageListCreate
     * @summary 定制仿真课程目录 - 分页
     * @request POST:/api/v{version}/a/Study/DesignTaskTypes_PageList
     * @secure
     */
    vAStudyDesignTaskTypesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDesignTaskTypesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignTaskTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DesignTaskTypes_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTaskTypesSaveCreate
     * @summary 定制仿真课程目录 - 创建
     * @request POST:/api/v{version}/a/Study/DesignTaskTypes_Save
     * @secure
     */
    vAStudyDesignTaskTypesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDesignTaskTypesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignTaskTypes_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTaskTypesRemoveCreate
     * @summary 定制仿真课程目录 - 删除
     * @request POST:/api/v{version}/a/Study/DesignTaskTypes_Remove
     * @secure
     */
    vAStudyDesignTaskTypesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignTaskTypes_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTasksPageListCreate
     * @summary 定制仿真课程 - 分页
     * @request POST:/api/v{version}/a/Study/DesignTasks_PageList
     * @secure
     */
    vAStudyDesignTasksPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDesignTasksPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignTasksDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DesignTasks_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTasksSaveCreate
     * @summary 定制仿真课程 - 创建
     * @request POST:/api/v{version}/a/Study/DesignTasks_Save
     * @secure
     */
    vAStudyDesignTasksSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDesignTasksDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignTasks_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTasksRemoveCreate
     * @summary 定制仿真课程 - 删除
     * @request POST:/api/v{version}/a/Study/DesignTasks_Remove
     * @secure
     */
    vAStudyDesignTasksRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignTasks_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTaskViewsPageListCreate
     * @summary 定制仿真课程试图 - 分页
     * @request POST:/api/v{version}/a/Study/DesignTaskViews_PageList
     * @secure
     */
    vAStudyDesignTaskViewsPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDesignTaskViewsPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignTaskViewsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DesignTaskViews_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTaskViewsSaveCreate
     * @summary 定制仿真课程试图 - 创建
     * @request POST:/api/v{version}/a/Study/DesignTaskViews_Save
     * @secure
     */
    vAStudyDesignTaskViewsSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDesignTaskViewsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignTaskViews_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTaskViewsBatchSortCreate
     * @summary 定制仿真课程试图 - 排序
     * @request POST:/api/v{version}/a/Study/DesignTaskViews_BatchSort
     * @secure
     */
    vAStudyDesignTaskViewsBatchSortCreate: (
      data: Building3DApplicationDtosStudyManagerDesignTaskViewsBatchSortInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignTaskViews_BatchSort`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignTaskViewsRemoveCreate
     * @summary 定制仿真课程试图 - 删除
     * @request POST:/api/v{version}/a/Study/DesignTaskViews_Remove
     * @secure
     */
    vAStudyDesignTaskViewsRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignTaskViews_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyBatchAddDesignTaskUsersCreate
     * @summary 批量添加课程成员
     * @request POST:/api/v{version}/a/Study/BatchAddDesignTaskUsers
     * @secure
     */
    vAStudyBatchAddDesignTaskUsersCreate: (
      data: Building3DApplicationDtosStudyManagerBatchDesignTaskUsersDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/BatchAddDesignTaskUsers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyBatchRemoveDesignTaskUsersCreate
     * @summary 批量移除课程成员
     * @request POST:/api/v{version}/a/Study/BatchRemoveDesignTaskUsers
     * @secure
     */
    vAStudyBatchRemoveDesignTaskUsersCreate: (
      data: Building3DApplicationDtosStudyManagerBatchDesignTaskUsersDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/BatchRemoveDesignTaskUsers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyQueryDesignTaskUserIdsCreate
     * @summary 获取课程成员Id
     * @request POST:/api/v{version}/a/Study/QueryDesignTaskUserIds
     * @secure
     */
    vAStudyQueryDesignTaskUserIdsCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<number[], void>({
        path: `/api/v1/a/Study/QueryDesignTaskUserIds`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyEnumItemsSaveCreate
     * @summary 枚举 - 编辑
     * @request POST:/api/v{version}/a/Study/EnumItems_Save
     * @secure
     */
    vAStudyEnumItemsSaveCreate: (
      data: Building3DApplicationDtosStudyManagerEnumItemsDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/EnumItems_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignQuestionTypesPageListCreate
     * @summary 定制题库目录 - 分页
     * @request POST:/api/v{version}/a/Study/DesignQuestionTypes_PageList
     * @secure
     */
    vAStudyDesignQuestionTypesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDesignQuestionTypesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignQuestionTypesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DesignQuestionTypes_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignQuestionTypesSaveCreate
     * @summary 定制题库目录 - 创建
     * @request POST:/api/v{version}/a/Study/DesignQuestionTypes_Save
     * @secure
     */
    vAStudyDesignQuestionTypesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerDesignQuestionTypesDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignQuestionTypes_Save`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignQuestionTypesRemoveCreate
     * @summary 定制题库目录 - 删除
     * @request POST:/api/v{version}/a/Study/DesignQuestionTypes_Remove
     * @secure
     */
    vAStudyDesignQuestionTypesRemoveCreate: (
      data: Building3DApplicationDtosInputDto1SystemInt64SystemPrivateCoreLibVersion4000CultureNeutralPublicKeyToken7Cec85D7Bea7798E,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/Study/DesignQuestionTypes_Remove`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Study
     * @name VAStudyDesignQuestionsPageListCreate
     * @summary 定制题库 - 分页
     * @request POST:/api/v{version}/a/Study/DesignQuestions_PageList
     * @secure
     */
    vAStudyDesignQuestionsPageListCreate: (
      data: Building3DApplicationDtosStudyManagerDesignQuestionsPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerDesignQuestionsDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/Study/DesignQuestions_PageList`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ToDoEvent
     * @name VVToDoEventDetail
     * @request GET:/api/v{version}/v/ToDoEvent
     * @secure
     */
    vVToDoEventDetail: (
      query?: {
        Sorting?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebVenderDtosOutToDoEventDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/v/ToDoEvent`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UploadFiles
     * @name VMUploadFilesUploadFilesSaveCreate
     * @summary 保存
     * @request POST:/api/v{version}/m/UploadFiles/UploadFiles_Save
     */
    vMUploadFilesUploadFilesSaveCreate: (
      data: Building3DApplicationDtosStudyManagerUploadFilesDto,
      params: RequestParams = {}
    ) =>
      this.request<number, any>({
        path: `/api/v1/m/UploadFiles/UploadFiles_Save`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UploadFiles
     * @name VMUploadFilesUploadFilesPageListCreate
     * @summary 列表 - 分页
     * @request POST:/api/v{version}/m/UploadFiles/UploadFiles_PageList
     */
    vMUploadFilesUploadFilesPageListCreate: (
      data: Building3DApplicationDtosStudyManagerUploadFilesPageListInput,
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DApplicationDtosStudyManagerUploadFilesDtoBuilding3DApplicationVersion1000CultureNeutralPublicKeyTokenNull,
        any
      >({
        path: `/api/v1/m/UploadFiles/UploadFiles_PageList`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UploadFiles
     * @name VMUploadFilesUploadFilesBatchLockedCreate
     * @summary 批量设置锁定状态
     * @request POST:/api/v{version}/m/UploadFiles/UploadFiles_BatchLocked
     */
    vMUploadFilesUploadFilesBatchLockedCreate: (
      data: Building3DApplicationDtosStudyManagerUploadFilesBatchLockedInput,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/m/UploadFiles/UploadFiles_BatchLocked`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UploadFiles
     * @name VMUploadFilesUploadFilesModelThumbnailCreate
     * @summary 修改缩略图
     * @request POST:/api/v{version}/m/UploadFiles/UploadFiles_ModelThumbnail
     */
    vMUploadFilesUploadFilesModelThumbnailCreate: (
      data: Building3DApplicationDtosStudyManagerUploadFilesModelThumbnailDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/m/UploadFiles/UploadFiles_ModelThumbnail`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UploadFiles
     * @name VMUploadFilesFileServerGetSessionIdCreate
     * @summary 获取文件上传sessionId
     * @request POST:/api/v{version}/m/UploadFiles/FileServer_GetSessionId
     */
    vMUploadFilesFileServerGetSessionIdCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/m/UploadFiles/FileServer_GetSessionId`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UploadFiles
     * @name VMUploadFilesGenerateImportMemberUserAccountsDtoTemplateAsyncCreate
     * @summary 生成导入模板 + 前台用户账号
     * @request POST:/api/v{version}/m/UploadFiles/GenerateImportMemberUserAccountsDtoTemplateAsync
     */
    vMUploadFilesGenerateImportMemberUserAccountsDtoTemplateAsyncCreate: (
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/m/UploadFiles/GenerateImportMemberUserAccountsDtoTemplateAsync`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UploadFiles
     * @name VMUploadFilesUploadExcelFileAsyncCreate
     * @summary 接收上传文件方法
     * @request POST:/api/v{version}/m/UploadFiles/UploadExcelFileAsync
     */
    vMUploadFilesUploadExcelFileAsyncCreate: (
      data: { file?: File | null },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/m/UploadFiles/UploadExcelFileAsync`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UploadFiles
     * @name VMUploadFilesImportUserFileCreate
     * @summary 开始导入用户账号
     * @request POST:/api/v{version}/m/UploadFiles/ImportUserFile
     */
    vMUploadFilesImportUserFileCreate: (
      data: Building3DApplicationDtosImportExportDtosImportClassMemberExcelInput,
      params: RequestParams = {}
    ) =>
      this.request<number, any>({
        path: `/api/v1/m/UploadFiles/ImportUserFile`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VMUserSummaryDetail
     * @summary 概览
     * @request GET:/api/v{version}/m/User/Summary
     * @secure
     */
    vMUserSummaryDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebMainDtosOutUserSummaryDto, void>({
        path: `/api/v1/m/User/Summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VMUserIsLoginedDetail
     * @summary 已登录?
     * @request GET:/api/v{version}/m/User/IsLogined
     * @secure
     */
    vMUserIsLoginedDetail: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/m/User/IsLogined`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VFUserDetail
     * @request GET:/api/v{version}/f/User
     * @secure
     */
    vFUserDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebFoundationDtosOutUserDto, void>({
        path: `/api/v1/f/User`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VFUserUpdate
     * @summary 修改信息
     * @request PUT:/api/v{version}/f/User
     * @secure
     */
    vFUserUpdate: (
      data: Building3DApplicationDtosInUserDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/f/User`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VFUserAvatarUpdate
     * @summary 修改头像
     * @request PUT:/api/v{version}/f/User/Avatar
     * @secure
     */
    vFUserAvatarUpdate: (
      data: Building3DApplicationDtosInUserAvatarDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/f/User/Avatar`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VFUserPasswordUpdate
     * @summary 修改密码
     * @request PUT:/api/v{version}/f/User/Password
     * @secure
     */
    vFUserPasswordUpdate: (
      data: Building3DApplicationDtosInUserPasswordDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/f/User/Password`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VFUserBindPhoneCreate
     * @summary 绑定手机号
     * @request POST:/api/v{version}/f/User/BindPhone
     * @secure
     */
    vFUserBindPhoneCreate: (
      data: Building3DApplicationDtosInBindPhoneNumberDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/f/User/BindPhone`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VAUserSummaryDetail
     * @summary 概览
     * @request GET:/api/v{version}/a/User/Summary
     * @secure
     */
    vAUserSummaryDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebAdminDtosOutUserSummaryDto, void>({
        path: `/api/v1/a/User/Summary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VAUserDetail
     * @summary 获取后台用户
     * @request GET:/api/v{version}/a/User
     * @secure
     */
    vAUserDetail: (
      query?: {
        Search?: string | null;
        UserName?: string | null;
        PhoneNumber?: string | null;
        NickName?: string | null;
        RoleId?: number | null;
        UserTypeArr?: Building3DDomainEntitiesEnumClassUserType[] | null;
        InClass?: boolean;
        ClassId?: number | null;
        TaskId?: number | null;
        InTask?: boolean | null;
        DesignTaskId?: number | null;
        InDesignTask?: boolean | null;
        IsEnabled?: boolean | null;
        Filter?: string | null;
        Sorting?: string | null;
        SkipCount?: number;
        MaxResultCount?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebAdminDtosOutUserPageItemDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/a/User`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VAUserUpdate
     * @summary 保存用户
     * @request PUT:/api/v{version}/a/User
     * @secure
     */
    vAUserUpdate: (
      data: Building3DApplicationDtosInUserAdminSaveDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/User`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VAUserDelete
     * @summary 删除用户
     * @request DELETE:/api/v{version}/a/User
     * @secure
     */
    vAUserDelete: (
      data: Building3DApplicationDtosInUserBulkRemoveDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/User`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VAUserEnableUpdate
     * @summary 批量启用
     * @request PUT:/api/v{version}/a/User/Enable
     * @secure
     */
    vAUserEnableUpdate: (data: number[] | null, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/a/User/Enable`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VAUserDisableUpdate
     * @summary 批量禁用
     * @request PUT:/api/v{version}/a/User/Disable
     * @secure
     */
    vAUserDisableUpdate: (data: number[] | null, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/a/User/Disable`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VAUserPermissionTreeDetail
     * @summary 获取用户权限
     * @request GET:/api/v{version}/a/User/PermissionTree
     * @secure
     */
    vAUserPermissionTreeDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebAdminDtosOutUserPermissionTree, void>({
        path: `/api/v1/a/User/PermissionTree`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name VAUserSetLimitTimeCreate
     * @summary 设置到期时间
     * @request POST:/api/v{version}/a/User/SetLimitTime
     * @secure
     */
    vAUserSetLimitTimeCreate: (
      data: Building3DApplicationDtosSetLimitTimeInput,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/a/User/SetLimitTime`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserBrands
     * @name VMUserBrandsConcernedDetail
     * @summary 已关注的品牌
     * @request GET:/api/v{version}/m/User/Brands/Concerned
     * @secure
     */
    vMUserBrandsConcernedDetail: (
      query?: { search?: string | null },
      params: RequestParams = {}
    ) =>
      this.request<Building3DWebMainDtosOutConcernedBrandDto[], void>({
        path: `/api/v1/m/User/Brands/Concerned`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserBrands
     * @name VMUserBrandsUpdate
     * @summary 关注
     * @request PUT:/api/v{version}/m/User/Brands
     * @secure
     */
    vMUserBrandsUpdate: (
      data: Building3DApplicationDtosInBrandConcernDto[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/User/Brands`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserBrands
     * @name VMUserBrandsDelete
     * @summary 取消关注
     * @request DELETE:/api/v{version}/m/User/Brands
     * @secure
     */
    vMUserBrandsDelete: (
      data: Building3DApplicationDtosInBrandCancelConcernDto[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/User/Brands`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProducts
     * @name VMUserProductsUpdate
     * @summary 收藏
     * @request PUT:/api/v{version}/m/User/Products
     * @secure
     */
    vMUserProductsUpdate: (
      data: Building3DApplicationDtosInUserProductCollectDto[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/User/Products`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProducts
     * @name VMUserProductsDelete
     * @summary 取消收藏
     * @request DELETE:/api/v{version}/m/User/Products
     * @secure
     */
    vMUserProductsDelete: (
      data: Building3DApplicationDtosInUserProductCancelCollectDto[] | null,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/m/User/Products`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProducts
     * @name VMUserProductsCollectedDetail
     * @summary 已收藏产品
     * @request GET:/api/v{version}/m/User/Products/Collected
     * @secure
     */
    vMUserProductsCollectedDetail: (
      query?: { SkipCount?: number; MaxResultCount?: number },
      params: RequestParams = {}
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1Building3DWebMainDtosOutProductDtoBuilding3DWebVersion1000CultureNeutralPublicKeyTokenNull,
        void
      >({
        path: `/api/v1/m/User/Products/Collected`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProducts
     * @name VMUserProductsToBeUpdatedDetail
     * @summary 待更新产品
     * @request GET:/api/v{version}/m/User/Products/ToBeUpdated
     * @secure
     */
    vMUserProductsToBeUpdatedDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebMainDtosOutToBeUpdatedProductDto[], void>({
        path: `/api/v1/m/User/Products/ToBeUpdated`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProducts
     * @name VMUserProductsDownloadedDetail
     * @summary 已下载产品
     * @request GET:/api/v{version}/m/User/Products/Downloaded
     * @secure
     */
    vMUserProductsDownloadedDetail: (params: RequestParams = {}) =>
      this.request<Building3DWebMainDtosOutProductDownloadLogDto[], void>({
        path: `/api/v1/m/User/Products/Downloaded`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
