import axios from 'axios'
import { version } from './config'
import {
  http_delete, http_get, http_post, http_put,
} from './helpers'
import type { ModelUpload, UploadImages } from './types'

const host = process.env.VUE_APP_FILEURL
// 获取spd token
export const getSPD = http_get<
  void,
  {
    token: string
  }
>(`/api/v${version}/f/Account/SPD`)

export const getSessionId = http_post<
  void,
  any
>(`/api/v${version}/m/UploadFiles/FileServer_GetSessionId`)

// 查询文件上传的状态
export async function getUploadFileStatus(opt: any, token: string) {
  try {
    const myInterceptor = axios.interceptors.request.use((config) => {
      config.headers['Content-type'] = 'application/json'
      config.headers.common.Authorization = token
      return config
    })
    const res = await axios.get(`${host}file/api/v1.0/FlowJs/UploadFileStatus`, { params: opt })
    axios.interceptors.request.eject(myInterceptor)
    return res
  }
  catch (err) {
    console.error(err)
    return undefined // 非业务错误
  }
}
// 大文件上传
export async function postUploadModels(opt: any, token: string) {
  try {
    const myInterceptor = axios.interceptors.request.use((config) => {
      config.headers['Content-type'] = 'multipart/form-data'
      config.headers.Authorization = token
      const formData = new FormData()
      Object.keys(opt).forEach((key) => {
        formData.append(key, opt[key])
      })
      config.data = formData
      return config
    })
    const res = await axios.post(`${host}file/api/v1.0/FlowJs/Upload`)
    axios.interceptors.request.eject(myInterceptor)
    return res
  }
  catch (err) {
    console.error(err)
    return undefined // 非业务错误
  }
}
// 图片文件上传
export async function postUploadPicture(opt: any, token: string) {
  try {
    const myInterceptor = axios.interceptors.request.use((config) => {
      config.headers['Content-type'] = 'multipart/form-data'
      config.headers.Authorization = token
      const formData = new FormData()
      Object.keys(opt).forEach((key) => {
        formData.append(key, opt[key])
      })
      config.data = formData
      return config
    })
    const res = await axios.post(`${host}api/v1.0/File`)
    axios.interceptors.request.eject(myInterceptor)
    axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    return res
  }
  catch (err) {
    console.error(err)
    return undefined // 非业务错误
  }
}
// 模型上传
export const postFilesModels = http_post<
    ModelUpload[],
    any
>(`/api/v${version}/v/Product/Files/Models`)

// 图纸上传
export const postFilesDrawings = http_post<
    ModelUpload[],
    any
>(`/api/v${version}/v/Product/Files/Drawings`)

// 产品相册图片上传
export const postImages = http_post<
  {
    pvId: string
    files: UploadImages[]
  },
  any
>(`/api/v${version}/v/Product/Files/Images`)

// 设为主图
export const putPrimaryImages = http_put<
    {
      productId: string
      pvId: string
      fileId: string
      isSnapshot: boolean
    },
    any
>(`/api/v${version}/v/Product/Files/Primary`)

// 删除文件
export const delFiles = http_delete<
    {
      id: string
    },
    any
>(`/api/v${version}/v/Product/Files/{id}`)
