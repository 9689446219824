
import { DesignFileView } from '@/api/customized-simulation-resources';
import { myConfirm } from '@/utils/confirm';
import { safeJsonParse } from '@/utils/safe-json-parse';
import Vue from 'vue';
import SvgIcon from '@/components/SvgIcon.vue';
import { Message } from 'element-ui';
import { convert_uuid_byte } from '@/utils/convert-uuid-to-byteid';
import { convert_instanceid_to_uuid } from '@/utils/convert-instanceid-to-uuid';
import AddModal from '../add-modal/index.vue';

export default Vue.extend({
  components: { AddModal, SvgIcon },
  data() {
    return {
      addItemModalVisible: false,
    };
  },
  computed: {
    list(): DesignFileView[] {
      return this.$store.state.customizedSimulationResourcesModule
        .detailedListOfSimulationResources;
    },
    selectedItem(): DesignFileView {
      return this.$store.state.customizedSimulationResourcesModule.selectedSimulationResourceDetail;
    },
    editItem(): DesignFileView {
      return this.$store.state.customizedSimulationResourcesModule.editSimulationResourceDetail;
    },
    spdApi3d() {
      return this.$store.getters['modelViewModule/api3d'];
    },
    twoDspdApi3d() {
      return this.$store.getters['modelViewModule/twoDApi3d'];
    },
    bindedList(): DesignFileView[] {
      return this.$store.getters['customizedSimulationResourcesModule/bindedList'];
    },
  },
  methods: {
    confirm2DPerspective(item) {
      this.$store.dispatch('customizedSimulationResourcesModule/bind2DPerspective', {
        perspective: this.twoDspdApi3d.getCurrentLook(),
      });
    },
    isPositioned(item: DesignFileView): boolean {
      return !!safeJsonParse(item?.viewSettings).twoDPerspective;
    },
    selectItem(item) {
      this.$store.commit(
        'customizedSimulationResourcesModule/setSelectedSimulationResourceDetail',
        item,
      );
      const { twoDPerspective } = safeJsonParse(item.viewSettings);
      if (twoDPerspective) {
        this.twoDspdApi3d.restoreCameraView({
          isAnimation: false,
        });
        this.twoDspdApi3d.setCurrentLook(twoDPerspective, true);
      }
      if (this.isbinded(item)) {
        const compoentIds = safeJsonParse(item.viewSettings).componentIds.map((v) => convert_uuid_byte(convert_instanceid_to_uuid(v, 1)));
        const api3d = this.$store.getters['modelViewModule/api3d'];
        api3d.showAll();
        api3d.update();
        api3d.restoreHighLightRenderElement();
        compoentIds.forEach((v) => {
          api3d.highLightRenderElement(v);
        });
        this.$store.commit('customizedSimulationResourcesModule/setSelectedComponentIds', [
          ...safeJsonParse(item.viewSettings).componentIds,
        ]);
      } else {
        const api3d = this.$store.getters['modelViewModule/api3d'];
        api3d.showAll();
        api3d.update();
        api3d.restoreHighLightRenderElement();
      }
    },
    backPage() {
      this.$router.back();
    },
    addItem() {
      if (this.list.length === 10) {
        Message.warning('最多只能绑定十条数据');
        return;
      }
      this.$store.commit('customizedSimulationResourcesModule/resetEditSimulationResourceDetail');
      this.addItemModalVisible = true;
    },
    handleConfirm(data) {
      this.$store.dispatch(
        'customizedSimulationResourcesModule/createEditSimulationResourceDetails',
        {
          title: data.title,
          viewSettings: JSON.stringify({
            ...safeJsonParse(this.editItem?.viewSettings),
            index: data.index,
          }),
          id: this.editItem.id ? this.editItem.id : undefined,
        },
      );
    },
    handleEditItem(item: DesignFileView) {
      this.$store.commit(
        'customizedSimulationResourcesModule/setEditSimulationResourceDetail',
        item,
      );
      this.addItemModalVisible = true;
    },
    handleDeleteItem(item: DesignFileView) {
      myConfirm(this, {
        text: '确认删除此项吗？',
        resolve: () => {
          this.$store.dispatch(
            'customizedSimulationResourcesModule/deleteSimulationResourceDetails',
            {
              id: item.id,
            },
          );
        },
      });
    },
    bindComponent() {
      this.$store.dispatch('customizedSimulationResourcesModule/bindComponent');
    },
    unbindComponent() {
      this.$store.dispatch('customizedSimulationResourcesModule/unbindComponent');
    },
    isbinded(item: DesignFileView): boolean {
      return !!safeJsonParse(item?.viewSettings).componentIds?.length;
    },
    checkAndComplete() {
      if (this.bindedList.length < this.list.length) {
        Message.warning('请完成关联');
      } else {
        Message.success('已保存修改');
        this.$router.back();
      }
    },
  },
});
