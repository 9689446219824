export function convert_instanceid_to_uuid(intanceid, newModelId ) {
  function add_zero(str, size) {
      if (str.length < size) {
          for (let i = 0; i < size - str.length; i++) {
              str = "0" + str;
          }
      }
      return str;
  }
  let high = (intanceid % 0x100).toString(16);
  let low = (parseInt(`${intanceid / 0x100}`)).toString(16);
  let tail_newId = newModelId.toString(16);
  let tail_originId = '00';//newModelId.toString(16);
  high = add_zero(high, 2);
  low = add_zero(low, 2);
  tail_newId = add_zero(tail_newId, 2);
  tail_originId = add_zero(tail_originId, 2);
  let string = high + low + "0000000000000000000000000000" + tail_newId + tail_originId;
  return string;
}