
import Ellipsis from "@/components/Ellipsis.vue";
import { difference, isEmpty } from "lodash";
import Vue, { PropType } from "vue";
import { TaskFile } from "../types";
import { convert_uuid_byte } from "@/utils/convert-uuid-to-byteid";
import { TreeItem } from "./task-model-view-left-menu.vue";
import { convert_instanceid_to_uuid } from "@/utils/convert-instanceid-to-uuid";
export default Vue.extend({
  components: { Ellipsis },
  props: {
    file: { type: Object as PropType<TaskFile["uploadFile"]> },
    tree: { type: Array as PropType<TreeItem[]> },
    api: { type: Object }
  },
  computed: {
    name(): String {
      return this.file.fileName;
    },
    defaultChecked(): number[] {
      if (isEmpty(this.tree)) return [];
      const lastchildren: any = [];
      const forxh = list => {
        for (var i = 0; i < list.length; i++) {
          var chlist = list[i];
          if (chlist.children?.length > 0) {
            forxh(chlist.children);
          } else {
            lastchildren.push(chlist);
          }
        }
      };
      forxh(this.tree);
      return new Array(lastchildren.pop().key).fill(1).map((v, i) => i);
    }
  },
  data() {
    return {
      checked: true,
      defaultProps: {
        children: "children",
        label: "name"
      }
    };
  },

  methods: {
    handleCheck(a, b) {
      const lastchildren: any = [];
      const forxh = list => {
        for (var i = 0; i < list.length; i++) {
          var chlist = list[i];
          if (chlist.children?.length > 0) {
            forxh(chlist.children);
          } else {
            lastchildren.push(chlist);
          }
        }
      };
      forxh(this.tree);
      const allInstanceIds = lastchildren.map(v => v.instanceIds).flat();
      const checkedInstanceIds = b.checkedNodes
        .filter(v => v.instanceIds)
        .map(v => v.instanceIds)
        .flat();
      const hiddenInstanceIds = difference(allInstanceIds, checkedInstanceIds);
      const byteids = hiddenInstanceIds.map(v =>
        convert_uuid_byte(convert_instanceid_to_uuid(v, 1))
      );
      this.api.showAll();
      byteids.forEach(v => this.api.hiddenRenderElement(v));
      this.api.updateView();
    }
  }
});
