import { http_get, http_post, http_put } from './helpers'
import { version } from './config'
import type {
  BrandOptions, CertAndRating, Correlations, ProductInfo, ProductInfoTypeTreeNode,
  ProductInfos, ProductTypeHistory, TechDocs,
} from './types'

export const getProductInfo = http_get<
  {
    productId: string
    step: number
  },
  ProductInfos
>(`/api/v${version}/v/Product/Infos`)
export const getBrandOptions = http_get< // 获取品牌选项
  {
    SkipCount?: number
    MaxResultCount?: number
  },
  {
    totalCount: number
    items: BrandOptions[]
  }
>(`/api/v${version}/v/Brands/Options`)

export const getProductInfoTypeTree = http_get< // 分类树
  void,
  {
    roots: ProductInfoTypeTreeNode[]
  }
>(`/api/v${version}/v/Product/Type/Tree`)

export const getProductOptions = http_get< // 获取关联产品列表
  {
    type?: number | null
    brandId?: number | null
    excludeProductIds: string
    skipCount?: number
    maxResultCount?: number
  },
  {
    totalCount: number
    items: {
      productId: string
      pvId: string
      name: string
    }[]
  }
>(`/api/v${version}/v/Product/Options`)

export const putProductCorrelations = http_put< // 保存关联产品
  Correlations,
  void
>(`/api/v${version}/v/Product/{id}/Correlations`)

/**
 * 获取分类历史
 */
export const getProductTypeHistory = http_get<
  void,
  ProductTypeHistory[]
>(`/api/v${version}/v/Product/Type/Histories`)

/**
 * 产品类型选项
 */
export const getCategoryOptions = http_get<
  void,
  {
    val: string
    name: string
  }[]
>(`/api/v${version}/v/Product/Category/Options`)

/**
 * 保存产品第一步
 */
export const saveProductBaseInfo = http_post<
  {
    product: ProductInfo
    certAndRating: CertAndRating
    techDocs: TechDocs[]
  },
  any
>(`/api/v${version}/v/Product/Step/1`)

/**
 * 获取品牌认证
 */
export const getProductCertifications = http_get<
  void,
  {
    id: string
    txt: string
  }[]
>(`/api/v${version}/a/Brand/Certifications`)

/**
 * 获取枚举
 */
export const getEnums = http_get<
  {
    group: string
  },
  {
    val: string
    txt: string
  }[]
>(`/api/v${version}/f/Enums`)
