
import Vue from 'vue';

export default Vue.extend({
  props: {
    color: {
      type: String,
      required: true,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes() {
      return [
        'z-sheet',
        { 'z-sheet__tile': this.tile },
        { 'z-sheet__rounded': this.rounded },
        `bg-${this.color}`,
      ];
    },
  },
});
