export function convert_uuid_byte(newid) {
  var string__ = "";
  var alllength = parseInt(`${newid.length / 4}`);
  for (let i = 0; i < alllength; i++) {
    var strnum = "";
    for (let n = 0; n < 4; n++) {
      strnum += newid[i * 4 + n];
    }
    var xxxx_str = String.fromCharCode(parseInt(strnum, 16));
    string__ += xxxx_str;
  }
  return string__;
}
