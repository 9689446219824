import { version } from './config'
import { http_post } from './helpers'

export interface DesignFile {
  name: string
  classType: number
  typeId: number
  uploadFileId?: number
  isFinish?: boolean
  viewFileId?: number
  creationTime?: string
  createUserId?: number
  id?: any
  viewSettings?: string
}
export interface UploadFile {
  fileId: string
  fileName: string
  fileExtention: string
  fileConvertState: number
  fileSize: number
  fileUrl: string
  creationTime?: string
  createUserId?: number
  id?: number
}
type ViewFile = UploadFile
export interface DesignFileView {
  viewSettings: string
  designFileId: number
  title?: string
  creationTime?: string
  createUserId?: number
  id?: number
}
interface CreateEditSimulationResourceDirectoryParams {
  name: string
  id?: number
}
interface CreateEditSimulationResourceDirectoryResponse {}
interface GetSimulationResourcesParams {
  classType?: number
  isFinish?: boolean
  sorting?: '0' | '1'
  typeId: number
  skipCount: number
  maxResultCount: number
}
export interface SimulationResource {
  designFile: DesignFile
  uploadFile: UploadFile
}
export interface GetSimulationResourcesResponse {
  totalCount: number
  items: SimulationResource[]
}
interface GetSimulationResourceDirectoryParams {
  skipCount: number
  maxResultCount: number
  sorting?: '0' | '1'
}
interface GetSimulationResourceDirectoryResponse {
  totalCount: number
  items: {
    name: string
    creationTime: string
    createUserId: number
    id: number
  }[]
}
interface DeleteSimulationResourceDirectoryParams {
  id: number
}
interface DeleteSimulationResourceDirectoryResponse {}
interface CreateOrEditSimulationResourceParams {
  designFile: DesignFile
  uploadFile: UploadFile
}
interface CreateOrEditSimulationResourceResponse {}
interface DeleteSimulationResourcesParams {
  id: number
}
interface DeleteSimulationResourcesResponse {}
interface GetSimulationResourceDetailsParams { id: number }
interface GetSimulationResourceDetailsResponse {
  designFile: DesignFile
  uploadFile: UploadFile
  viewFile: ViewFile
  designFileViewList: DesignFileView[]
}
type CreateEditSimulationResourceDetailsParams = DesignFileView
interface CreateEditSimulationResourceDetailsResponse {}
interface DeleteSimulationResourceDetailsParams { id: number }
interface DeleteSimulationResourceDetailsResponse {}
interface UploadSimulation2DDrawingParams {
  viewFile: UploadFile
  id: number
}
interface UploadSimulation2DDrawingResponse {}
/**
 * 创建编辑仿真资源目录
 */
export const createEditSimulationResourceDirectory = http_post<
  CreateEditSimulationResourceDirectoryParams,
  CreateEditSimulationResourceDirectoryResponse
>(`/api/v${version}/a/Study/DesignFileTypes_Save`)

/**
 * 获取仿真资源目录
 */
export const getSimulationResourceDirectory = http_post<
  GetSimulationResourceDirectoryParams,
  GetSimulationResourceDirectoryResponse
>(`/api/v${version}/a/Study/DesignFileTypes_PageList`)

/**
 * 删除仿真资源目录
 */
export const deleteSimulationResourceDirectory = http_post<
  DeleteSimulationResourceDirectoryParams,
  DeleteSimulationResourceDirectoryResponse
>(`/api/v${version}/a/Study/DesignFileTypes_Remove`)

/**
 * 新建仿真资源
 */
export const createOrEditSimulationResource = http_post<
  CreateOrEditSimulationResourceParams,
  CreateOrEditSimulationResourceResponse
>(`/api/v${version}/a/Study/DesignFileExtends_Save`)

/**
 * 获取仿真资源
 */
export const getSimulationResources = http_post<
  GetSimulationResourcesParams,
  GetSimulationResourcesResponse
>(`/api/v${version}/a/Study/DesignFiles_PageList`)

/**
 * 删除仿真资源
 */
export const deleteSimulationResources = http_post<
  DeleteSimulationResourcesParams,
  DeleteSimulationResourcesResponse
>(`/api/v${version}/a/Study/DesignFiles_Remove`)

/**
 * 获取仿真资源详情
 */
export const getSimulationResourceDetails = http_post<
  GetSimulationResourceDetailsParams,
  GetSimulationResourceDetailsResponse
>(`/api/v${version}/a/Study/QueryDesignFileDetails`)

/**
 * 创建编辑仿真资源详情
 */
export const createEditSimulationResourceDetails = http_post<
  CreateEditSimulationResourceDetailsParams,
  CreateEditSimulationResourceDetailsResponse
>(`/api/v${version}/a/Study/DesignFileViews_Save`)

/**
 * 删除仿真资源详情
 */
export const deleteSimulationResourceDetails = http_post<
  DeleteSimulationResourceDetailsParams,
  DeleteSimulationResourceDetailsResponse
>(`/api/v${version}/a/Study/DesignFileViews_Remove`)

/**
 * 上传仿真二维图纸
 */
export const uploadSimulation2DDrawing = http_post<
  UploadSimulation2DDrawingParams,
  UploadSimulation2DDrawingResponse
>(`/api/v${version}/a/Study/DesignFile_UploadViewFile`)
