
import Vue from 'vue';

const { spdengine } = window as any;
export default Vue.extend({
  props: {
    bench: {
      type: Number,
    },
    toolbarItem: {
      type: Object,
    },
    is2D: {
      type: Boolean,
    },
    setParams: {
      type: Object,
    },
    radian: {
      type: Number,
    },
    drawingPath: {
      type: Boolean,
    },
    canvasId: { type: String },
  },
  data() {
    return {
      forecolor: 1,
      unit: 0,
      explodeValue: 50,
      viewpoint: '',
      together: [
        'disintegrate',
        'line',
        'angle',
        'set',
        'fullScreen',
        'zoomModel',
        'rotate',
      ], // 需要联动的功能
      checked: ['zoomModel', 'fullScreen', 'angle', 'set'],
      angles: [
        {
          do: '正视图',
          icon: require('../assets/images/toolbar/btn_shitu_zheng.png'),
          angle: [0, -Math.PI / 2, Math.PI / 2],
        },
        {
          do: '后视图',
          icon: require('../assets/images/toolbar/btn_shitu_hou.png'),
          angle: [0, -Math.PI / 2, -Math.PI / 2],
        },
        {
          do: '左视图',
          icon: require('../assets/images/toolbar/btn_shitu_zuo.png'),
          angle: [0, -Math.PI / 2, -Math.PI],
        },
        {
          do: '右视图',
          icon: require('../assets/images/toolbar/btn_shitu_you.png'),
          angle: [0, -Math.PI / 2, 0],
        },
        {
          do: '顶视图',
          icon: require('../assets/images/toolbar/btn_shitu_ding.png'),
          angle: [0, 0, Math.PI / 2],
        },
        {
          do: '底视图',
          icon: require('../assets/images/toolbar/btn_shitu_di.png'),
          angle: [0, -Math.PI, Math.PI / 2],
        },
      ],
      items: [] as any,
      modelItems: [
        {
          cmd: 'home',
          do: 'Home',
          active: false,
          icon: require('../assets/images/toolbar/btn_home.png'),
          activeIcon: require('../assets/images/toolbar/btn_home_ov.png'),
        },
        {
          cmd: 'frameSelection',
          do: '框选',
          active: false,
          icon: require('../assets/images/toolbar/img_kuangxuan.png'),
          activeIcon: require('../assets/images/toolbar/img_kuangxuan_hover.png'),
        },
        {
          cmd: 'zoomModel',
          do: '缩放窗口',
          active: false,
          icon: require('../assets/images/toolbar/btn_kuangxuan.png'),
          activeIcon: require('../assets/images/toolbar/btn_kuangxuan_ov.png'),
        },
        {
          cmd: 'rotate',
          do: '旋转',
          active: false,
          icon: require('../assets/images/toolbar/btn_xuanzhuan.png'),
          activeIcon: require('../assets/images/toolbar/btn_xuanzhuan_ov.png'),
        },
        {
          cmd: 'sectioning',
          do: '剖切',
          active: false,
          icon: require('../assets/images/toolbar/btn_baoqie.png'),
          activeIcon: require('../assets/images/toolbar/btn_baoqie_ov.png'),
        },
        {
          cmd: 'disintegrate',
          do: '分解',
          active: false,
          icon: require('../assets/images/toolbar/btn_fenjie.png'),
          activeIcon: require('../assets/images/toolbar/btn_fenjie_ov.png'),
        },
        {
          cmd: 'line',
          do: '线框模式',
          active: true,
          icon: require('../assets/images/toolbar/btn_xiankuang.png'),
          activeIcon: require('../assets/images/toolbar/btn_xiankuang_ov.png'),
        },
        {
          cmd: 'angle',
          do: '视角',
          active: false,
          icon: require('../assets/images/toolbar/btn_shitu.png'),
          activeIcon: require('../assets/images/toolbar/btn_shitu_ov.png'),
        },
        {
          cmd: 'measure3D',
          do: '测量',
          active: false,
          icon: require('../assets/images/toolbar/measure.png'),
          activeIcon: require('../assets/images/toolbar/measure_ov.png'),
        },
        {
          cmd: 'wander',
          do: '漫游',
          active: false,
          icon: require('../assets/images/toolbar/img_manyou.png'),
          activeIcon: require('../assets/images/toolbar/img_manyou_hover.png'),
        },
        {
          cmd: 'fullScreen',
          do: '全屏',
          active: false,
          icon: require('../assets/images/toolbar/btn_quanpin.png'),
          activeIcon: require('../assets/images/toolbar/btn_quanpin_ov.png'),
        },
        {
          cmd: 'drawing',
          do: '2D',
          active: false,
          icon: require('../assets/images/toolbar/btn_2D.png'),
          activeIcon: require('../assets/images/toolbar/btn_2D_ov.png'),
        },
        // {
        //     cmd: 'animation', do: ' 安装模拟动画', active: false, icon: require('../assets/images/toolbar/btn_play.png'), activeIcon: require('../assets/images/toolbar/btn_play_ov.png'),
        // },
      ],
      drawingItems: [
        {
          cmd: 'home',
          do: 'Home',
          active: false,
          icon: require('../assets/images/toolbar/btn_home.png'),
          activeIcon: require('../assets/images/toolbar/btn_home_ov.png'),
        },
        {
          cmd: 'zoomModel',
          do: '缩放窗口',
          active: false,
          icon: require('../assets/images/toolbar/btn_kuangxuan.png'),
          activeIcon: require('../assets/images/toolbar/btn_kuangxuan_ov.png'),
        },
        {
          cmd: 'measureLength',
          do: '测量长度',
          active: false,
          icon: require('../assets/images/toolbar/length.png'),
          activeIcon: require('../assets/images/toolbar/length_ac.png'),
        },
        {
          cmd: 'measureAngle',
          do: '测量角度',
          active: false,
          icon: require('../assets/images/toolbar/angle.png'),
          activeIcon: require('../assets/images/toolbar/angle_ac.png'),
        },
        {
          cmd: 'measureArea',
          do: '测量面积',
          active: false,
          icon: require('../assets/images/toolbar/area.png'),
          activeIcon: require('../assets/images/toolbar/area_ac.png'),
        },
        {
          cmd: 'clear',
          do: '清除标记信息',
          active: false,
          icon: require('../assets/images/toolbar/clear.png'),
          activeIcon: require('../assets/images/toolbar/clear_ov.png'),
        },
        {
          cmd: 'clockwise',
          do: '顺时针',
          active: false,
          icon: require('../assets/images/toolbar/clockwise.png'),
          activeIcon: require('../assets/images/toolbar/clockwise_ac.png'),
        },
        {
          cmd: 'anticlockwise',
          do: '逆时针',
          active: false,
          icon: require('../assets/images/toolbar/anticlockwise.png'),
          activeIcon: require('../assets/images/toolbar/anticlockwise_ac.png'),
        },
        {
          cmd: 'set',
          do: '设置',
          active: false,
          icon: require('../assets/images/toolbar/set.png'),
          activeIcon: require('../assets/images/toolbar/set_ac.png'),
        },

        {
          cmd: 'fullScreen',
          do: '全屏',
          active: false,
          icon: require('../assets/images/toolbar/btn_quanpin.png'),
          activeIcon: require('../assets/images/toolbar/btn_quanpin_ov.png'),
        },
        {
          cmd: 'model',
          do: '3D',
          active: false,
          icon: require('../assets/images/toolbar/model.png'),
          activeIcon: require('../assets/images/toolbar/model_ac.png'),
        },
      ],
    };
  },
  created() {
    console.log(this.bench);
    console.log(this.toolbarItem);
    console.log(this.is2D);
    console.log(this.setParams);
    console.log(this.radian);
    console.log(this.drawingPath);
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  watch: {
    isMobile(val) {
      if (this.$route.name === 'Compare') {
        spdengine.Apis.getApi(this.bench).PARAMS.spd_logoEnable = !val; // 关闭logo
      }
      this.getItems();
    },
    toolbarItem: {
      handler(item) {
        if (item.cmd === 'fullScreen') {
          const fullScreen = this.items.find(
            (i) => i.cmd === item.cmd && i.do !== item.do,
          );
          fullScreen && this.zoom(fullScreen);
          return;
        }
        const idx = this.items.findIndex(
          (i) => i.cmd === item.cmd && i.active !== item.active,
        );
        if (idx > -1) {
          if (this.checked.includes(item.cmd)) {
            this.items[idx].active = !this.items[idx].active;
            return;
          }
          const api = spdengine.Apis.getApi(this.bench);
          this[item.cmd](api); // 操作联动
        }
      },
      deep: true,
    },
    setParams: {
      handler(item) {
        if (item.idx === 0 && this.forecolor !== item.val) {
          this.forecolor = item.val;
        } else if (item.idx === 1 && this.unit !== item.val) {
          this.unit = item.val;
        }
      },
      deep: true,
    },
    is2D() {
      this.drawingItems.forEach((e) => {
        e.active = false;
      });
      this.modelItems.forEach((e) => {
        e.active = false;
      });
      this.getItems();
    },
    forecolor(val) {
      // 图纸前景色
      spdengine.Apis.getApi(this.bench).PARAMS.material_state = val === 1 ? 'normal' : 'solidColor';
      this.$emit('set', { idx: 0, val });
    },
    unit(val) {
      // 图纸测量单位
      const resultUnit = val === 0 ? spdengine.GUMUNIT.UNIT_MM : spdengine.GUMUNIT.UNIT_M;
      spdengine.Apis.getApi(this.bench)['3D'].setMeasureParams({
        resultUnit,
        visiable: true,
      });
      this.$emit('set', { idx: 1, val });
    },
    explodeValue(val) {
      // 分解程度
      const explodeValue = val / 50;
      spdengine.Apis.getApi(this.bench)['3D'].setExplosionEnable(
        this.items[5].active,
        this.items[5].active ? explodeValue : 0,
      );
      spdengine.Apis.getApi(this.bench).setCurrentViewpoint(this.viewpoint);
    },
  },
  mounted() {
    this.getItems();
    window.addEventListener('resize', this.fullMeasure);
  },
  methods: {
    fullMeasure() {
      const fullscreenEle = document.fullscreenElement
        || (document as any).mozFullScreenElement
        || (document as any).webkitFullscreenElement; // 返回当前处于全屏模式的DOM元素
      if (!fullscreenEle) {
        // 是否退出全屏
        const item = this.items.find(
          (i) => i.cmd === 'fullScreen' && i.do === '退出全屏',
        );
        if (item) {
          this.zoom(item);
          this.$emit('together', item);
        }
      }
    },
    getItems() {
      const drawingIdx = this.modelItems.findIndex((e) => e.cmd === 'drawing');
      const modelIdx = this.drawingItems.findIndex((e) => e.cmd === 'model');
      if (!this.drawingPath && drawingIdx !== -1) { this.modelItems.splice(drawingIdx, 1); }
      if (this.$route.name === 'Compare' && modelIdx !== -1) { this.drawingItems.splice(modelIdx, 1); }
      if (this.isMobile) {
        if (!this.is2D) {
          const del = ['rotate', 'fullScreen', 'measure3D', 'angle'];
          this.items = this.modelItems.filter((e) => !del.includes(e.cmd));
        } else {
          const del = [
            'measureLength',
            'measureAngle',
            'measureArea',
            'fullScreen',
            'clear',
          ];
          this.items = this.drawingItems.filter((e) => !del.includes(e.cmd));
        }
      } else {
        this.items = this.is2D ? this.drawingItems : this.modelItems;
      }
    },
    switchCmd(item, idx: number) {
      if (!this.bench) return;
      const together = this.together.find((cmd) => cmd === item.cmd); // 是否需要联动
      const api = spdengine.Apis.getApi(this.bench);
      if (item.cmd !== 'fullScreen' && item.cmd !== 'disintegrate') {
        // 关闭前面打开的功能
        this.items
          .filter(
            (e) => e.active
              && e.cmd !== item.cmd
              && e.cmd !== 'fullScreen'
              && e.cmd !== 'disintegrate',
          )
          .forEach((e) => {
            if (e.cmd !== 'angle') this[e.cmd](api, e);
          });
      }
      if (item.cmd === 'angle' || item.cmd === 'set') {
        this.items[idx].active = !this.items[idx].active;
        if (this.items[5].active && !this.is2D) { this.disintegrate(api, this.items[5]); } // 关闭分解
      } else {
        if (this.items.length > 6 && this.items[7].active && !this.is2D) {
          this.items[7].active = false;
        } // 关闭视角
        this[item.cmd](api, item);
      }
      if (together) this.$emit('together', item);
    },
    home(api) {
      this.$emit('setRadian', 0);
      api['3D'].home3D();
    },
    zoomModel(api) {
      // 框选
      this.items[2].active = !this.items[2].active;
      api.do(new spdengine.ZoomWinCommand());
    },
    rotate(api, item) {
      // 旋转
      item.active = !item.active;
      if (item.active) {
        api.runLoopRotationAnimation(1, 0.03); // 旋转
      } else {
        api.stopLoopRotationAnimation();
      }
    },
    sectioning(api, item) {
      // 剖切
      api.do(new spdengine.ClippingCommand());
      item.active = !item.active;
    },
    disintegrate(api, item) {
      // 分解
      item.active = !item.active;
      if (item.active) this.viewpoint = api.getCurrentViewpoint();
      const explodeValue = this.explodeValue / 50;
      api['3D'].setExplosionEnable(item.active, item.active ? explodeValue : 0);
      api.setCurrentViewpoint(this.viewpoint);
    },
    line(api, item) {
      // 线框模式
      item.active = !item.active;
      if (item.active) {
        api.PARAMS.material_state = 'purecolor';
        api.PARAMS.eg_pureColor = 0x02d6dc45; // 构件颜色
        api.PARAMS.eg_wireframeColor = 0x02d6dcff; // 线框颜色
      } else {
        api.PARAMS.material_state = 'normal';
      }
    },
    fullScreen(api, item) {
      if (item.do === '全屏') {
        this.zoom(item);
        this.fullIn();
      } else {
        this.zoom(item);
        this.fullOut();
      }
    },
    zoom(item) {
      if (item.do === '全屏') {
        item.icon = require('../assets/images/toolbar/zoomout.png');
        item.activeIcon = require('../assets/images/toolbar/zoomout_ov.png');
        item.do = '退出全屏';
      } else {
        item.icon = require('../assets/images/toolbar/btn_quanpin.png');
        item.activeIcon = require('../assets/images/toolbar/btn_quanpin_ov.png');
        item.do = '全屏';
      }
    },
    fullIn() {
      const elem: any = document.getElementById(this.canvasId);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    //  退出全屏
    fullOut() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
    },
    changeAngle(angle) {
      // 视角
      const api = spdengine.Apis.getApi(this.bench)['3D'];
      api.getProjectRotationRef().set(angle[0], angle[1], angle[2]);
      api.controlUpdate();
    },
    drawing() {
      // 2D
      if (this.$route.name === 'Compare') {
        this.$router.push({
          path: '/compare/drawing',
        });
      } else {
        this.$emit('drawing', true);
      }
    },
    animation() {
      // 动画
      console.log(11);
    },
    handleMeasure3D(item) {
      if (item.cmd === 'clear') {
        this.clear();
        return;
      }
      item.active = !item.active;
      this[item.cmd]();
    },
    measure3D(api, item) {
      item.active = !item.active;
      if (!item.active) {
        const val = this.drawingItems.find((i) => i.active); // 关闭测量功能
        if (val) {
          val.active = false;
          this[val.cmd]();
        }
      }
    },
    frameSelection(api, item) {
      item.active = !item.active;
      api.do(new spdengine.RegionCommand());
    },
    clear() {
      // 清除全部标记信息
      const api = spdengine.Apis.getApi(this.bench);
      api.clearAllMeasureInfos();
      !this.is2D && api.removeAllMarkerDeviceInfo();
    },
    measureLength() {
      // 测量长度
      this.measure(2, 'DIS', [3, 4]);
    },
    measureAngle() {
      // 测量角度
      this.measure(3, 'ANH', [2, 4]);
    },
    measureArea() {
      // 测量面积
      const api = spdengine.Apis.getApi(this.bench);
      if (!this.drawingItems[4].active && !this.is2D) {
        api.UI.do(new spdengine.Measure3DCommand());
        api.PARAMS.mk_enable = false;
      }
      this.measure(4, 'PSENCLOSEAREA', [3, 2]);
      const state = this.drawingItems[4].active;
      if (this.is2D) return;
      if (state) {
        // 3d测量面积功能
        api.PARAMS.mk_enable = true;
        api['3D'].addMarkerDivEventListener('click', (ev) => {
          if (ev.uuid && this.drawingItems[4].active) {
            const area = api['3D'].getComponentArea(ev.uuid);
            // eslint-disable-next-line
            const info = {
              lineState: spdengine.MakerLineState.POLYLINE,
              state: 0,
              deviceId: spdengine._Math.generateUUID(),
              isOutterFlicker: true,
              subLineRadian: Math.PI / 2,
              subLineLength: 10,
              customColor: '#FFFFFF8F',
              pointOffset: new spdengine.Vector23D(40, -40),
              circleNumber: 2,
              intersectionRatio: 10 / 20,
              outterInsterval: 2,
            };
            api['3D'].markerDeviceInfoByComponentId(ev.uuid, info);
            const div = document.createElement('div');
            const node = document.createTextNode(
              `构件面积  ${area.toFixed(3)}`,
            );
            div.style.paddingLeft = '10px';
            div.style.color = 'white';
            div.appendChild(node);
            (info as any).div.appendChild(div);
          }
        });
      }
    },
    measure(idx, type, arr) {
      const meaCommand = this.is2D
        ? new spdengine.Measure2DCommand()
        : new spdengine.Measure3DCommand();
      this.drawingItems[arr[0]].active = false;
      this.drawingItems[arr[1]].active = false;
      if (this.is2D) this.items[idx].active = !this.items[idx].active;
      const api = spdengine.Apis.getApi(this.bench);
      if (api.PARAMS.mk_enable) api.PARAMS.mk_enable = false;
      if (api.PARAMS.ms_enable || !this.drawingItems[idx].active) {
        api.UI.do(meaCommand);
      }
      if (this.drawingItems[idx].active && !(idx === 4 && !this.is2D)) {
        api.PARAMS.ms_state = spdengine.MEASURESTATE[type];
        api.UI.do(meaCommand);
      }
    },
    wander(api, item) {
      item.active = !item.active;
      api.UI.do(new spdengine.WanderCommand());
      if (api.PARAMS.lg_enableGrid) api.PARAMS.lg_enableGrid = false;
      api.PARAMS.wd_enable = item.active;
    },
    clockwise(api) {
      // 顺时针
      let newRadian = this.radian;
      newRadian = newRadian === 0 || newRadian === 0.5 ? 2 : newRadian - 0.5;
      this.$emit('setRadian', newRadian);
      api.setCameraRotationAngle(0, Math.PI * newRadian);
    },
    anticlockwise(api) {
      // 逆时针
      let newRadian = this.radian;
      newRadian = newRadian === 0 ? 1 : newRadian + 0.5;
      this.$emit('setRadian', newRadian);
      api.setCameraRotationAngle(0, Math.PI * newRadian);
    },
    model() {
      // 3d
      if (this.$route.name === 'Compare') {
        this.$router.push({
          path: '/compare/model',
        });
      } else {
        this.$emit('drawing', false);
      }
    },
  },
  destroyed() {
    window.removeEventListener('resize', () => this.fullMeasure);
  },
});
