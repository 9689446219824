
import Vue from 'vue';
import ModelView from '@/components/model-view/index.vue';
import ModelViewRightMenu from '@/features/simulation-resource-model-view-right-menu/index.vue';
import { generateComponentDetail, strToInt32 } from '@/features/collage/get-component-detail';
import { getComponentInformation } from '@/api/model-view';

export default Vue.extend({
  components: { ModelView, ModelViewRightMenu },
  data() {
    return {
      modelId: '',
      benchId: 0,
      isDraw: false,
      showRightMenu: true,
    };
  },
  computed: {
    api3d(): any {
      return (window as any).spdengine.Apis.getApi(this.benchId)['3D'];
    },
  },
  methods: {
    async handleClickComponent(uuid) {
      // this.api3d.restoreHighLightRenderElement();
      // this.api3d.highLightRenderElement(uuid);
      const currentSelectedComponents = this.api3d.getSelectedElementGuids().map(strToInt32);
      if (currentSelectedComponents.length !== 1) {
        this.$store.commit('simulationRightMenuModule/setComponentInformations', {
          name: '',
          details: [],
        });
        (this.$refs.RightMenu as any).$refs.StructureTree.$refs.tree.setCheckedKeys([]);
        return;
      }
      const instanceId = currentSelectedComponents[0];
      const res = await getComponentInformation({
        modelId: this.modelId,
        instanceId,
      });
      if (res.status.error !== 0) {
        this.$message.error(res.status.errorDesc);
        return;
      }
      const componentDetails = generateComponentDetail(res.instanceProperty.propertySets);
      this.$store.commit('simulationRightMenuModule/setComponentInformations', {
        name: res.instanceProperty.name,
        details: componentDetails,
      });
      (this.$refs.RightMenu as any).$refs.StructureTree.$refs.tree.setCheckedKeys([]);
    },
    handleLoaded(benchId) {
      this.benchId = benchId;
      this.$store.commit('modelViewModule/setBenchId', benchId);
    },
    handleCheck(uuids) {
      this.api3d.restoreHighLightRenderElement();
      uuids.forEach((v) => this.api3d.highLightRenderElement(v));
    },
  },
  async mounted() {
    this.modelId = this.$route.query.id as string;
    this.isDraw = this.$route.query.draw === 'true';
    this.showRightMenu = this.$route.query.rightmenu === 'true';
  },
});
