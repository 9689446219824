import { http_delete, http_get, http_put } from './helpers'
import { version } from './config'
import type {
  Brand, BrandCertifications, BrandInfo, BrandIsManufacturer, BrandIsShow,
} from './types'

export const getBrandList = http_get<
  {
    Name?: string
    Sorting?: string
    SkipCount?: number
    MaxResultCount?: number
  },
  {
    totalCount: number
    items: Brand[]
  }
>(`/api/v${version}/a/Brand`)

export const putBrand = http_put<
  BrandInfo,
  void
>(`/api/v${version}/a/Brand`)

export const deleteBrand = http_delete<
  number[],
  void
>(`/api/v${version}/a/Brand`)

export const getCertifications = http_get< // 获取品牌认证
  void,
  BrandCertifications[]
>(`/api/v${version}/a/Brand/Certifications`)

export const putIsManufacturer = http_put< // 修改是否制造商
  BrandIsManufacturer[],
  void
>(`/api/v${version}/a/Brand/IsOrNotManufacturer`)

export const putIsShow = http_put< // 修改是否前台显示
  BrandIsShow[],
  void
>(`/api/v${version}/a/Brand/IsOrNotShow`)
