
import Vue from "vue";
import Ellipsis from "@/components/Ellipsis.vue";

export default Vue.extend({
  components: { Ellipsis },
  computed: {
    componentInformations() {
      return this.$store.state.simulationRightMenuModule.componentInformations;
    }
  }
});
