
import Vue from "vue";
import ElementUI from "element-ui";
import HeaderNews from "./HeaderNews.vue";
import SvgIcon from "../SvgIcon.vue";

export default Vue.extend({
  components: {
    HeaderNews,
    SvgIcon
  },
  data() {
    return {
      navConfig: [
        {
          id: 1,
          name: "首页",
          path: "/home",
          icon: "iconindex"
        },
        {
          id: 3,
          name: "产品",
          code: "ProductModule",
          path: "/product",
          icon: "iconchanpin"
        },
        {
          id: 2,
          name: "用户",
          code: "UserModule",
          path: "/user",
          icon: "iconusersetting"
        },
        {
          id: 4,
          name: "运营",
          code: "OperationModule",
          path: "/operation",
          icon: "iconyunying"
        },
        {
          id: 5,
          name: "内容",
          code: "ContentModule",
          path: "/content",
          icon: "iconneirong1"
        },
        {
          id: 6,
          name: "统计",
          code: "StatisticsModule",
          path: "/statistics",
          icon: "icontongji-l"
        },
        {
          id: 7,
          name: "教育",
          code: "StudyModule",
          path: "/collage",
          newIcon: "icon-zzj-home_icon_education"
        }
      ],
      searchKeyword: ""
    };
  },
  created() {
    this.$store.dispatch("productInfoModule/getProductTypeTreeSimple");
    this.$store.dispatch("getUnreadCount");
  },
  computed: {
    hasEvent(): any {
      return this.$store.state.hasEvent > 0;
    },
    unreadCount() {
      return this.$store.state.unreadCount;
    },
    userInfo(): any {
      console.log(this.$store.state.userInfo);
      return this.$store.state.userInfo;
    },
    nav(): any[] {
      return this.navConfig.filter(
        n => n.code === undefined || this.$store.state.permissions.includes(n.code)
      );
    }
  },
  methods: {
    login() {
      this.$emit("login");
    },
    logout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("permissions");
      this.$store.commit("jobManagementModule/resetAll");
      this.$router.replace({
        path: "login"
      });
    },
    search() {
      this.$router.push({
        path: "/search-results",
        query: {
          keyword: this.searchKeyword
        }
      });
      this.searchKeyword = "";
      (this.$refs.search as ElementUI.Input).blur();
    }
  }
});
