import { render, staticRenderFns } from "./task-model-viewer.vue?vue&type=template&id=02e9b189&scoped=true&"
import script from "./task-model-viewer.vue?vue&type=script&lang=ts&"
export * from "./task-model-viewer.vue?vue&type=script&lang=ts&"
import style0 from "./task-model-viewer.vue?vue&type=style&index=0&id=02e9b189&prod&lang=less&scoped=true&"
import style1 from "./task-model-viewer.vue?vue&type=style&index=1&id=02e9b189&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02e9b189",
  null
  
)

export default component.exports