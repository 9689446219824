
import { Message } from "element-ui";
import Vue from "vue";
enum SearchMethod {
  fuzzy,
  exact
}
export default Vue.extend({
  data() {
    return {
      attributeName: "",
      attributeValue: "",
      searchMethod: SearchMethod.fuzzy,
      loading: false
    };
  },
  computed: {
    searchComponentList(): number[] {
      return this.$store.state.simulationRightMenuModule.searchComponentList;
    }
  },
  methods: {
    async searchComponent() {
      if (!this.attributeName) {
        Message.error("属性名称必填");
        return;
      }
      this.loading = true;
      await this.$store.dispatch("simulationRightMenuModule/findComponentCollection", {
        attributeName: this.attributeName,
        attributeValue: this.attributeValue,
        searchMethod: this.searchMethod
      });
      this.$store.commit("customizedSimulationResourcesModule/setSelectedComponentIds", [
        ...this.searchComponentList
      ]);
      const api3d = this.$store.getters["modelViewModule/api3d"];
      api3d.showAll();
      api3d.update();
      api3d.restoreHighLightRenderElement();
      this.searchComponentList.forEach(v => {
        api3d.highLightRenderElement(v);
      });
      this.loading = false;
    }
  }
});
