import {
  http_delete, http_get, http_post, http_put,
} from './helpers'
import { version } from './config'
import type { Role, RolePermissionTree } from './types'

export const getRoleList = http_get<
  void,
  Role[]
>(`/api/v${version}/f/Role`)

export const createRole = http_post<
  {
    name: string
    description: string
    enabled: boolean
    selectedPermissionIds: string[]
  },
  void
>(`/api/v${version}/f/Role`)

export const editRole = http_put<
  {
    id: string
    name: string
    description: string
    enabled: boolean
    selectedPermissionIds: string[]
  },
  void
>(`/api/v${version}/f/Role`)

export const getRolePermissionTree = http_get<
  {
    id: string
  },
  RolePermissionTree
>(`/api/v${version}/f/Role/PermissionTree`)

export const removeRole = http_delete<
  {
    id: string
  },
  void
>(`/api/v${version}/f/Role/{id}`)

export const getPermissionTree = http_get<
  void,
  RolePermissionTree
>(`/api/v${version}/f/Role/PermissionTree`)

export const roleEnable = http_put<
  string[],
  void
>(`/api/v${version}/f/Role/Enable`)

export const roleDisable = http_put<
  string[],
  void
>(`/api/v${version}/f/Role/Disable`)
