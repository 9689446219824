import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router/index'

axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('token')}`

// axios 响应拦截器
axios.interceptors.response.use((data) => {
  if (data.status === 204)
    data.data = {} // todo

  console.log(data)

  return data.data
}, (err) => {
  console.log(err.response)
  const { status = 0 } = err.response
  switch (status) {
    case 403: return err.response.data || { error: { message: '无权限' } }
    case 400: return err.response.data
    case 401: Message({ type: 'error', message: '登录超时' }); router.replace({ path: '/login' }); break
    case 500: Message({ type: 'error', message: '操作失败,请稍后再试' }); break
    case 404: Message({ type: 'error', message: '404 Not Found' }); return err.response.data
    case 504: Message({ type: 'error', message: '网络超时' }); break
    default: {
      const message = err.response.data && err.response.data.error && err.response.data.error.message ? err.response.data.error.message : '操作失败,请稍后再试'
      Message({ type: 'error', message })
      return err
    }
    // default:{
    //   console.log(err);
    //   Message({
    //       message: err.message || err.msg,
    //       type: 'error',
    //       duration: 5 * 1000
    //     })

    //   // return Promise.reject('error')
    // }
  }
  return false
})

export function renderUrlTemplate(url: string, params: any) {
  // 需求：'building/{buildingId}' -> 'building/123'
  // https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/String/replace
  return url.replace(/\{([^}]+)\}/g, (m, p) => {
    const ret = params[p]
    delete params[p]
    return ret
  })
}

export function http_get<Opt, Res>(url: string) {
  return async (opt: Opt) => {
    const newUrl = renderUrlTemplate(url, opt)
    const res: unknown = await axios.get(newUrl, { params: opt })
    return res as { data: Res } | RespError
  }
}

export function http_post<Opt, Res>(url: string) {
  return async (opt: Opt) => {
    const newUrl = renderUrlTemplate(url, opt)
    const res: unknown = await axios.post(newUrl, opt)
    return res as { data: Res } | RespError
  }
}

export function http_put<Opt, Res>(url: string) {
  return async (opt: Opt) => {
    const newUrl = renderUrlTemplate(url, opt)
    const res: unknown = await axios.put(newUrl, opt)
    return res as { data: Res } | RespError
  }
}

export function http_delete<Opt, Res>(url: string) {
  return async (opt: Opt) => {
    const newUrl = renderUrlTemplate(url, opt)
    const res: unknown = await axios.delete(newUrl, { data: opt })
    return res as { data: Res } | RespError
  }
}

export interface RespError {
  error: {
    message: string
    code: number
    details: string
    validationErrors: null
  }
}

export function error(resp): resp is RespError {
  if ('error' in resp)
    return true

  return false
}
