import {
  http_delete, http_get, http_post, http_put,
} from './helpers'
import { version } from './config'
import type { ProductTypeTreeNode } from './types'

export const productTypeViewProduct = http_get<
  {
    id: string
  },
  {
    recycledCount: number
    productListCount: number
  }
>(`/api/v${version}/v/ProductType/{id}/ViewProduct`)

export const getProductTypeTree = http_get<
  void,
  {
    roots: ProductTypeTreeNode[]
  }
>(`/api/v${version}/v/ProductType/Tree`)

export const getProductTypeTreeSimple = http_get<
  void,
  {
    roots: any
  }
>(`/api/v${version}/v/Product/Type/Tree`)

export const createProductType = http_post<
  {
    parentId: string
    name: string
  },
  void
>(`/api/v${version}/v/ProductType`)

export const editProductType = http_put<
  {
    id: string
    name: string
    isShow: boolean
    description: string
  },
  void
>(`/api/v${version}/v/ProductType`)

export const deleteProductType = http_delete<
  {
    id: string
  },
  void
>(`/api/v${version}/v/ProductType/{id}`)

export const transferProductType = http_put<
  {
    sourceId: string
    targetTypeIds: string[]
  },
  void
>(`/api/v${version}/v/ProductType/ProductTransferType`)
