import axios from 'axios';
import CryptoJS from 'crypto-js';
import { Message } from 'element-ui';
import SparkMD5 from 'spark-md5';

interface Res {
  fileId: string;
  status: { error: number; errorDesc: string };
  filePath?: string;
  uploadStatus?: number;
}
export const uploadFile = (file: File, setFile, sessionId) => {
  let fileId;
  const reader = new FileReader();
  reader.onload = async (e) => {
    if (e.target?.readyState === FileReader.DONE) {
      const fileSumMd5 = await doIncrementalTest(file);
      let base = HexToBase64(fileSumMd5);
      base = base.replace(/\//g, '_');
      base = base.replace(/\+/g, '-');
      const data = {
        md5: base,
        size: file.size,
        fileName: file.name,
      };
      const res: Res = await axios.post(`${process.env.VUE_APP_BORON}/Boron/UploadFile`, data, {
        headers: {
          Authorization: `Bearer ${sessionId}`,
        },
      });
      setFile({ fileId: res.fileId });
      if (res.filePath) {
        setFile({ percentage: '100', filePath: res.filePath });
        return;
      }
      if (res.status.error === 0) {
        fileId = res.fileId;
        const result: any = await getUploadStatus(fileId, sessionId);
        if (result.chunkUploadEnd) {
          setFile({ percentage: '100', filePath: res.filePath });
          return;
        }
        postFile(file, result.unLoadChunkId.sort((a, b) => a - b)[0], fileId, setFile, sessionId);
      } else {
        Message.error(res?.status?.errorDesc);
      }
    }
  };
  reader.readAsBinaryString(file);
};
const doIncrementalTest = async (file) => {
  let running = false;
  return new Promise((resolve, reject) => {
    if (running) {
      return;
    }
    const blobSlice = File.prototype.slice
        || (File.prototype as any).mozSlice
        || (File.prototype as any).webkitSlice;
      const chunkSize = 2 * 1024 * 1024;
      const chunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;
      const spark = new SparkMD5();
      const fileReader = new FileReader();
    fileReader.onload = (e) => {
      spark.appendBinary(e.target?.result as string);
      currentChunk += 1;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        running = false;
        console.log('Finished loading!');
        resolve(spark.end());
      }
    };
    fileReader.onerror = () => {
      running = false;
      console.log('something went wrong');
    };
    const loadNext = () => {
      const start = currentChunk * chunkSize;
        const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsBinaryString(blobSlice.call(file, start, end));
    };
    running = true;
    loadNext();
  });
};

const postFile = async (file: File, i: number, fileId, setFile, sessionId) => {
  const { name } = file;
    const { size } = file;
    const { type } = file;
    let shardSize = 2 * 1024 * 1024; // 以2MB为一个分片,每个分片的大小
    const shardCount = Math.ceil(size / shardSize); // 总片数
  if (i >= shardCount) {
    let index = 0;
    let timer;
    timer = setInterval(async () => {
      const res: Res = await axios.get(
        `${process.env.VUE_APP_BORON}/Boron/IsFileUploadEnd?fileId=${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionId}`,
          },
        },
      );
      index++;
      // 检测五次如果都没有完成就上传失败
      if (index >= 5) {
        clearInterval(timer);
        setFile({ percentage: '99.9', filePath: '', error: true });
        return;
      }
      if (res.uploadStatus === 0) {
        clearInterval(timer);
        setFile({ percentage: '100', filePath: res.filePath });
      } else {
        Message.error(res?.status?.errorDesc);
      }
      console.log(res);
    }, 2000);
    return;
  }
  const start = i * shardSize;
  const end = start + shardSize;
  const packet = file.slice(start, end); // 将文件进行切片
  const files = new window.File([packet], name, { type });
  const reader = new FileReader();
  reader.onloadend = async (evt) => {
    if (evt.target?.readyState == FileReader.DONE) {
      let base = CryptoJS.enc.Base64.stringify(
        CryptoJS.MD5(CryptoJS.enc.Latin1.parse(evt.target?.result as string)),
      );
      base = base.replace(/\//g, '_');
      base = base.replace(/\+/g, '-');
      const form = new FormData();
      form.append('fileId', fileId);
      form.append('chunkId', `${i}`); // 当前是第几片
      form.append('chunkData', files, 'file');
      form.append('chunkMd5', base);
      if (shardCount === i + 1) {
        shardSize = size - i * shardSize;
      }
      const res: Res = await axios.post(
        `${process.env.VUE_APP_BORON}/Boron/UploadFileChunk`,
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${sessionId}`,
          },
        },
      );
      if (res.status.error === 0) {
        setFile({
          percentage: `${Math.min(((i + 1) * 100) / shardCount, 99.9).toFixed(1)}`,
          filePath: '',
        });
        postFile(file, i + 1, fileId, setFile, sessionId);
      } else {
        setFile({
          percentage: '0',
          filePath: '',
          error: true,
        });
        Message.error(res?.status?.errorDesc);
      }
    }
  };
  reader.readAsBinaryString(files);
};
const getUploadStatus = async (fileId, sessionId) => new Promise(async (resolve, reject) => {
    const data = {
      fileId,
    };
    const res: Res = await axios.get(
      `${process.env.VUE_APP_BORON}/Boron/GetUploadStatus?fileId=${fileId}`,
      {
        headers: {
          Authorization: `Bearer ${sessionId}`,
        },
      },
    );
    if (res.status.error === 0) {
      resolve(res);
    } else {
      Message.error(res?.status?.errorDesc);
      reject('error');
    }
  });

const HexToBase64 = (sha1) => {
  const digits = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  let base64_rep = '';
  let ascv;
  let bit_arr = 0;
  let bit_num = 0;
  for (var n = 0; n < sha1.length; ++n) {
    if (sha1[n] >= 'A' && sha1[n] <= 'Z') {
      ascv = sha1.charCodeAt(n) - 55;
    } else if (sha1[n] >= 'a' && sha1[n] <= 'z') {
      ascv = sha1.charCodeAt(n) - 87;
    } else {
      ascv = sha1.charCodeAt(n) - 48;
    }
    bit_arr = (bit_arr << 4) | ascv;
    bit_num += 4;
    if (bit_num >= 6) {
      bit_num -= 6;
      base64_rep += digits[bit_arr >>> bit_num];
      bit_arr &= ~(-1 << bit_num);
    }
  }
  if (bit_num > 0) {
    bit_arr <<= 6 - bit_num;
    base64_rep += digits[bit_arr];
  }
  const padding = base64_rep.length % 4;
  if (padding > 0) {
    for (var n = 0; n < 4 - padding; ++n) {
      base64_rep += '=';
    }
  }
  return base64_rep;
};
