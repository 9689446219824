import {
  http_delete, http_get, http_post, http_put,
} from './helpers'
import { version } from './config'
import type { GetProductListParams, Product, ProductVersion } from './types'

/**
 * 根据筛选条件，获取产品列表
 */
export const getProduct = http_get<
  GetProductListParams,
  {
    totalCount: number
    items: Product[]
  }
>(`/api/v${version}/v/Product`)

export const getProductVersions = http_get<
  {
    productId: string
  },
  ProductVersion[]
>(`/api/v${version}/v/Product/{productId}/Versions`)

/**
 * 产品移动到回收站
 */
export const deleteProduct = http_delete<
  string[],
  void
>(`/api/v${version}/v/Product`)

/**
 * 产品上架
 */
export const onlineProduct = http_put<
  {
    productIds: string[]
  },
  void
>(`/api/v${version}/v/Product/Online`)

/**
 * 产品下架
 */
export const offlineProduct = http_put<
  {
    productIds: string[]
  },
  void
>(`/api/v${version}/v/Product/Offline`)

/**
 * 提交产品审核
 */
export const postAudit = http_post<
  {
    pvId: string
  },
  void
>(`/api/v${version}/v/Product/{pvId}/Audit`)

/**
 * 设为新品
 */
export const setProductToNew = http_put<
  {
    productIds: string[]
  },
  void
>(`/api/v${version}/a/Product/New`)

/**
 * 取消新品
 */
export const setProductToOld = http_delete<
  {
    productIds: string[]
  },
  void
>(`/api/v${version}/a/Product/New`)

// /**
//  * todo: 这是啥接口啊
//  */
// export const getProductSummaryCount = http_get<
//   void,
//   {
//     onlineCount: number;
//     offlineCount: number;
//     allCount: number;
//   }
// >(`/api/v${version}/v/Product/Summary`);

/**
 * 获取产品总数
 */
export const getProductCount = http_get<
  void,
  {
    status: number
    count: number
  }[]
>(`/api/v${version}/v/Product/Statuses`)

/**
 * 设置为当前版本
 */
export const setCurrentVersion = http_put<
  {
    productId: string
    pvId: string
  },
  void
>(`/api/v${version}/v/Product/{productId}/Versions/{pvId}/SetCurrent`)
