
import Vue from "vue";
export default Vue.extend({
  name: "Ellipsis",
  props: {
    // 显示的文字内容
    content: {
      type: String,
      default: ""
    },
    refName: {
      type: String,
      default: ""
    },
    placement: {
      type: String,
      default: "top"
    },
    popperClass: {
      type: String
    }
  },
  data() {
    return {
      isShowTooltip: true
    };
  },
  methods: {
    onMouseOver(str) {
      const parentWidth = (this.$refs[str] as any).parentNode.offsetWidth;
      const contentWidth = (this.$refs[str] as any).offsetWidth;
      // 判断是否开启tooltip功能
      if (contentWidth > parentWidth) {
        this.isShowTooltip = false;
      } else {
        this.isShowTooltip = true;
      }
    }
  }
});
