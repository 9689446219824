import { version } from './config'
import { http_delete, http_get, http_post, http_put } from './helpers'
import type { RolePermissionTree, User } from './types'

export const getUserList = http_get<
  {
    search: string | null
    roleId: string | null
    skipCount: number | null
    maxResultCount: number | null
  },
  {
    totalCount: number
    items: User[]
  }
>(`/api/v${version}/a/User`)

export const getUserListByStudy = http_post<
  any,
  {
    totalCount: number
    items: []
  }
>(`/api/v${version}/a/Study/PageByStudy`)

export const editUser = http_put<
  {
    userId: string
    userName: string
    email: string
    roleIds: string[]
    brandIds: null | string[]
    nickName: string
    password: string
    description: string
  },
  void
>(`/api/v${version}/a/User`)

export const createUser = http_put<
  {
    userName: string
    email: string
    roleIds: string[]
    brandIds: null | string[]
    nickName: string
    password: string
    description: string
  },
  void
>(`/api/v${version}/a/User`)

export const removeUser = http_delete<
  {
    userIds: string[]
  },
  void
>(`/api/v${version}/a/User`)

export const enableUser = http_put<string[], void>(`/api/v${version}/a/User/Enable`)

export const disableUser = http_put<string[], void>(`/api/v${version}/a/User/Disable`)

export const getUserPermissionTree = http_get<void, RolePermissionTree>(
  `/api/v${version}/a/User/PermissionTree`,
)

export const setLimitTime = http_post<{ userIds: number[]; limitTime: any }, void>(
  `/api/v${version}/a/User/SetLimitTime`,
)
