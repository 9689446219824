import type { Module } from 'vuex'
import { Message } from 'element-ui'
import type { RootState } from '../types'
import { ibuidingAPI } from '@/api/ibuidingAPI'
import * as api from '@/api'

interface InitialState {
  catalogueList: any
  editCatalogue: { name?: string }
  selectedCatalogue: { name?: string; id?: number; createUserId?: number }
  questionList: any
  searchText: string
  selectQuestionType: number
}
const initialState: InitialState = {
  catalogueList: [],
  editCatalogue: {},
  selectedCatalogue: {},
  questionList: [],
  searchText: '',
  selectQuestionType: 0,
}
const questionBankModule: Module<InitialState, RootState> = {
  namespaced: true,
  state: { ...initialState },
  getters: {
    selectedCatalogueId(state) {
      return state.selectedCatalogue?.id
    },
    editCatalogueName(state) {
      return state.editCatalogue.name
    },

    filteredQuestionList(state) {
      return state.questionList.filter((v) => {
        if (!state.selectQuestionType)
          return v
        else
          return v.classType === state.selectQuestionType
      }).filter(v => v.name.includes(state.searchText))
    },
  },
  mutations: {
    setSearchText(state, payload) {
      state.searchText = payload
    },
    setSelectedCatalogue(state, payload) {
      state.selectedCatalogue = payload
    },
    setCatalogueList(state, payload) {
      state.catalogueList = payload
    },
    setEditCatalogue(state, payload) {
      state.editCatalogue = payload
    },
    setQuestionList(state, payload) {
      state.questionList = payload
    },
    setSelectQuestionType(state, payload) {
      state.selectQuestionType = payload
    },
  },
  actions: {
    async init({ state, commit, dispatch }) {
      await dispatch('getCatalogueList')
      commit('setSelectedCatalogue', state.catalogueList?.[0])
      await dispatch('getQuestionsByCatalogueId', { catalogeId: state.selectedCatalogue?.id })
    },
    async getCatalogueList({
      state, getters, commit, dispatch,
    }) {
      const res = await ibuidingAPI.vAStudyDesignQuestionTypesPageListCreate({
        maxResultCount: 999,
        skipCount: 0,
      })
      if (res && !api.error(res))
        commit('setCatalogueList', res.data.items)

      else
        Message.error(res.error.message)

      console.log(res)
    },
    async getQuestionsByCatalogueId({ state, commit, dispatch }, { catalogeId }) {
      const res = await ibuidingAPI.vAStudyDesignQuestionsPageListCreate({
        maxResultCount: 999,
        skipCount: 0,
        typeId: catalogeId,
      })
      if (res && !api.error(res)) {
        commit('setQuestionList', res.data.items)
        return true
      }
      Message.error(res.error.message)
    },
    async createEditCatalogue({ state, commit, dispatch }, { name, id }) {
      const res = await ibuidingAPI.vAStudyDesignQuestionTypesSaveCreate({ name, id })
      if (res && !api.error(res))
        dispatch('getCatalogueList')

      else
        Message.error(res.error.message)
    },
    async deleteCatalogue({ state, commit, dispatch }, { id }) {
      const res = await ibuidingAPI.vAStudyDesignQuestionTypesRemoveCreate({ id })
      if (res && !api.error(res))
        dispatch('getCatalogueList')

      else
        Message.error(res.error.message)
    },
    async createEditQuestion({
      state, commit, dispatch, getters,
    }, {
      classType, name, typeId, viewSettings, standAnswerArray, id, remark,
    }) {
      const res = await ibuidingAPI.vAStudyDesignQuestionsSaveCreate({
        classType,
        typeId: typeId ?? getters.selectedCatalogueId,
        name,
        viewSettings,
        standAnswerArray,
        id,
        remark,
      })
      if (res && !api.error(res))
        dispatch('getQuestionsByCatalogueId', { catalogeId: typeId ?? getters.selectedCatalogueId })

      else
        Message.error(res.error.message)
    },
    async deleteQuestion({
      state, commit, dispatch, getters,
    }, { id }) {
      const res = await ibuidingAPI.vAStudyDesignQuestionsRemoveCreate({
        id,
      })
      if (res && !api.error(res))
        dispatch('getQuestionsByCatalogueId', { catalogeId: getters.selectedCatalogueId })
      else
        Message.error(res.error.message)
    },
  },
}
export default questionBankModule
