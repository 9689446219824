
import { DesignFileView } from '@/api/customized-simulation-resources';
import { ImageExtension, VideoExtension, FileExtension } from '@/types/simulation';
import { safeJsonParse } from '@/utils/safe-json-parse';
import { uploadFile } from '@/utils/upload-file';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import Vue, { PropType } from 'vue';
import Ellipsis from '@/components/Ellipsis.vue';

const boronUrl = process.env.VUE_APP_BORON;
const uploadableExtensions = [...ImageExtension, ...VideoExtension, ...FileExtension];
enum UploadState {
  notFinish,
  finished
}
export default Vue.extend({
  props: {
    visible: { type: Boolean },
    item: { type: Object as PropType<DesignFileView> },
  },
  data() {
    return {
      form: {
        title: '',
        intro: '',
      },
      rules: {
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
      uploadFiles: [] as {
        id: string;
        name: string;
        path: string;
        state: UploadState;
      }[],
      boronUrl,
      uploadableExtensions: [...uploadableExtensions],
    };
  },
  computed: {
    hasEditItem() {
      return this.$store.getters[
        'customizedSimulationResourcesModule/hasEditSimulationResourceDetail'
      ];
    },
    fileList(): any[] {
      return this.uploadFiles.map((v) => ({ name: v.name, url: v.path }));
    },
    isAllFileUploaded(): boolean {
      return (
        this.uploadFiles.length === 0
        || this.uploadFiles.every((v) => v.state === UploadState.finished)
      );
    },
  },
  methods: {
    deleteFile(item: { id: string; name: string; path: string }) {
      this.uploadFiles = this.uploadFiles.filter((v) => v.id !== item.id);
    },
    closeModal() {
      this.$emit('update:visible', false);
    },
    handleConifrm() {
      (this.$refs.form as ElForm).validate((valid) => {
        if (valid && this.uploadFiles.length) {
          this.$emit('onConfirm', { title: this.form.title, uploadFiles: this.uploadFiles, intro: this.form.intro });
          this.closeModal();
        } else {
          if (!this.uploadFiles.length) {
            Message.error('请上传附件');
          }
          return false;
        }
      });
    },
    uploadFile(_file) {
      console.log(_file);
      const file = _file.raw;
      const index = file.name.lastIndexOf('.');
      const type: string = file.name.slice(index + 1);
      if (!this.uploadableExtensions.some((v) => v === type.toUpperCase())) {
        this.$message.warning(
          `暂时不支持该格式, 请上传${this.uploadableExtensions.join('、')}文件`,
        );
        return;
      }
      let fileId = '';
      const setFile = async (data) => {
        if (data.fileId) {
          if (!this.uploadFiles.some((v) => v.id === data.fileId)) {
            this.uploadFiles.push({
              id: data.fileId,
              name: file.name,
              path: '',
              state: UploadState.notFinish,
            });
            fileId = data.fileId;
          }
        }
        if (data.percentage === '100') {
          const index = this.uploadFiles.findIndex((v) => v.id === fileId);
          this.$set(this.uploadFiles, index, {
            ...this.uploadFiles[index],
            path: data.filePath,
            state: UploadState.finished,
          });
        }
      };
      uploadFile(file, setFile, this.$store.state.sessionId);
    },
  },
  watch: {
    visible: {
      handler(val: boolean) {
        if (val) {
          this.form.title = this.item.title || '';
          this.form.intro = safeJsonParse(this.item.viewSettings).intro || '';
          this.uploadFiles = (safeJsonParse(this.item.viewSettings).uploadFiles || []).map((v) => ({ ...v, state: UploadState.finished }));
        }
      },
      deep: true,
    },
  },
  components: { Ellipsis },
});
