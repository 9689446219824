
import Ellipsis from "@/components/Ellipsis.vue";
import Vue, { PropType } from "vue";
export default Vue.extend({
  components: { Ellipsis },
  props: {
    componentType: { type: String },
    componentDetails: { type: Array as PropType<{ key: string; value: string }[]> },
    isFold: { type: Boolean }
  }
});
