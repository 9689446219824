import { Module } from 'vuex';
import * as api from '@/api';
import { Message } from 'element-ui';
import { ibuidingAPI } from '@/api/ibuidingAPI';
import { RootState } from '../types';

export type DatabaseModuleState = {
  editCatalogue: { name?: string };
  selectedCatalogue: { name?: string; id?: number; createUserId?: number };
  catalogueList: { name: string; id: number; createUserId: number }[];
  searchText: string;
  documentList: { name: string; id: number; createUserId: number }[];
  selection: { id: string }[];
};
const initialState: DatabaseModuleState = {
  editCatalogue: {},
  selectedCatalogue: {},
  catalogueList: [],
  searchText: '',
  documentList: [],
  selection: [],
};
const DatabaseModule: Module<DatabaseModuleState, RootState> = {
  namespaced: true,
  state: { ...initialState },
  getters: {
    editCatalogueName(state) {
      return state.editCatalogue.name;
    },
    selectedCatalogueId(state) {
      return state.selectedCatalogue?.id;
    },
    selectedCatologueCreatorId(state) {
      return state.selectedCatalogue?.createUserId;
    },
  },
  mutations: {
    setEditCatalogue(state, payload) {
      state.editCatalogue = payload;
    },
    setSelectedCatalogue(state, payload) {
      state.selectedCatalogue = payload;
    },
    setCatalogueList(state, payload) {
      state.catalogueList = payload;
    },
    setSearchText(state, payload) {
      state.searchText = payload;
    },
    setDocumentList(state, payload) {
      state.documentList = payload;
    },
    setSelection(state, payload) {
      state.selection = payload;
    },

  },
  actions: {
    async init({ state, commit, dispatch }) {
      await dispatch('getCatalogueList');
      commit('setSelectedCatalogue', state.catalogueList?.[0]);
      await dispatch('getDocuments', { catalogeId: state.selectedCatalogue.id });
    },
    async getCatalogueList({ state, commit, dispatch }) {
      const res = await ibuidingAPI.vAStudyDocumentFileTypesPageListCreate({ maxResultCount: 999, skipCount: 0 });
      if (res && !api.error(res)) {
        commit('setCatalogueList', res.data.items);
      } else {
        Message.error(res.error.message);
      }
    },
    async createEditCatalogue({ state, commit, dispatch }, { name, id }) {
      const res = await ibuidingAPI.vAStudyDocumentFileTypesSaveCreate({ name, id });
      if (res && !api.error(res)) {
        dispatch('getCatalogueList');
      } else {
        Message.error(res.error.message);
      }
    },
    async deleteCatalogue({ state, commit, dispatch }, { id }) {
      const res = await ibuidingAPI.vAStudyDocumentFileTypesRemoveCreate({ id });
      if (res && !api.error(res)) {
        dispatch('getCatalogueList');
      } else {
        Message.error(res.error.message);
      }
    },
    async getDocuments({ state, commit, dispatch }, { catalogeId }) {
      const res = await ibuidingAPI.vAStudyDocumentFilesPageListCreate({
        maxResultCount: 999,
        skipCount: 0,
        typeId: catalogeId,
      });
      if (res && !api.error(res)) {
        commit('setDocumentList', res.data.items);
        return true;
      }
      Message.error(res.error.message);
    },
    async saveDocuments({ state, commit, dispatch }, {
      typeId, fileId, fileName, fileExtention, fileSize, fileUrl,
    }) {
      const res = await ibuidingAPI.vAStudyDocumentFileExtendsSaveCreate({
        documentFile: { typeId },
        uploadFile: {
          fileId, fileName, fileExtention, fileSize, fileUrl,
        },
      });
      if (res && !api.error(res)) {
        dispatch('getDocuments', { catalogeId: typeId });
      } else {
        Message.error(res.error.message);
      }
    },
    async deleteDocument({ state, commit, dispatch }, { id, typeId }) {
      const res = await ibuidingAPI.vAStudyDocumentFilesRemoveCreate({ id });
      if (res && !api.error(res)) {
        dispatch('getDocuments', { catalogeId: typeId });
      } else {
        Message.error(res.error.message);
      }
    },

  },
};
export default DatabaseModule;
