import { Base64 } from '@/utils/base64'
import store from '@/store'

export function filePreview({ fileUrl, fileName, fileExtention }) {
  const isImg = ['.jpeg', '.jpg', '.png'].includes(fileExtention)
  window.open(
    `https://fileview.spdspd.com/onlinePreview?url=${encodeURIComponent(
      Base64.encode(
        `${`${process.env.VUE_APP_FILEPREVIEW
         }/`}${fileUrl
        }?authorization=Bearer%20${store.state.sessionId
        }&fullfilename=${fileName}`,
      ),
    )}&watermarkTxt=${store.state.userInfo.nickName}`,
  )
}
