
import Vue, { PropType } from 'vue';
import { MenuType } from '@/store/modules/simulation-resource-model-view-right-menu';
import SvgIcon from '@/components/SvgIcon.vue';
import StructureTree from './structure-tree/index.vue';
import Atrribute from './atrribute/index.vue';
import ComponentLookup from './component-lookup/index.vue';

export default Vue.extend({
  components: {
    StructureTree, Atrribute, ComponentLookup, SvgIcon,
  },
  props: {
    modelId: { type: String as PropType<string> },
  },
  computed: {
    isTwoThree() {
      return this.$store.state.customizedSimulationResourcesModule.editSimulationResource.designFile.classType === 5;
    },
    activeName(): MenuType {
      return this.$store.state.simulationRightMenuModule.activeName;
    },
    title(): string {
      return this.$store.getters['simulationRightMenuModule/activeMenuText'];
    },
  },
  methods: {
    getComponentIds(ids) {
      this.$emit('onTreeCheck', ids);
    },
    setActiveName(name) {
      this.$store.commit('simulationRightMenuModule/setActiveName', name);
    },
  },
  watch: {
    activeName: {
      handler(val: string) {
        if (val) {
          if (!this.isTwoThree) {
            this.$store.getters['modelViewModule/api'].PARAMS.ng_right = 450;
          }
        } else {
          this.$store.getters['modelViewModule/api'].PARAMS.ng_right = 0;
        }
      },
    },
    modelId: {
      async handler(val) {
        if (val) {
          await this.$store.dispatch('getSessionId');
          setTimeout(() => {
            this.$store.dispatch('simulationRightMenuModule/init', { modelId: val });
          }, 200);
        }
      },
      immediate: true,
    },
  },
});
