import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import * as api from '@/api';
import { getEnums, RolePermissionTreeNode } from '@/api';
import typesModule from './modules/types';
import productInfoModule from './modules/productInfo';
import { RootState } from './types';
import bimOperationQuestionModule from './modules/bim-operation-question';
import jobManagementModule from './modules/job-management';
import simulationTrainingModule from './modules/simulation-training';
import customizedSimulationResourcesModule from './modules/customized-simulation-resources';
import simulationRightMenuModule from './modules/simulation-resource-model-view-right-menu';
import modelBrowsingModule from './modules/model-browsing';
import DatabaseModule from './modules/database';
import modelViewModule from './modules/model-view';
import questionBankModule from './modules/question-bank';

Vue.use(Vuex);
export default new Vuex.Store<RootState>({
  modules: {
    typesModule,
    productInfoModule,
    bimOperationQuestionModule,
    jobManagementModule,
    simulationTrainingModule,
    customizedSimulationResourcesModule,
    simulationRightMenuModule,
    modelBrowsingModule,
    modelViewModule,
    DatabaseModule,
    questionBankModule,
  },
  state: {
    isCollapse: localStorage.getItem('isCollapse')
      ? Boolean(Number(localStorage.getItem('isCollapse')))
      : false,
    hasEvent: sessionStorage.getItem('hasEvent') || 0,
    breadcrumbList: [],
    brandInfo: JSON.parse(localStorage.getItem('brandInfo') || '{}') as RootState['brandInfo'],
    fileToken: '',
    userInfo: (JSON.parse(sessionStorage.getItem('userInfo') as string) as api.UserAccount) || null,
    token: (sessionStorage.getItem('token') as '') || '',
    entranceList: [] as api.OptionalTree[],
    memberInfo: {},
    messageId: '',
    editUser: null,
    editRole: null,
    unreadCount: 0, // 未读消息数
    permissions: sessionStorage.getItem('permissions')
      ? JSON.parse(sessionStorage.getItem('permissions') as string)
      : ([] as string[]),
    sessionId: '',
  },
  getters: {
    isAdmin(state) {
      return state.userInfo.userId === 10000000;
    },
  },
  mutations: {
    isCollapse(state, isCollapse) {
      state.isCollapse = Boolean(isCollapse);
      localStorage.setItem('isCollapse', isCollapse);
    },
    hasEvent(state, hasEvent) {
      state.hasEvent = hasEvent;
      sessionStorage.setItem('hasEvent', hasEvent);
    },
    breadcrumbList(state, breadcrumbList) {
      state.breadcrumbList = breadcrumbList;
    },
    unreadCount(state, unreadCount) {
      state.unreadCount = unreadCount;
    },
    setUser(state, info) {
      state.userInfo = info;
      if (!state.userInfo.avatar) {
        state.userInfo.avatar = '/cdn/images/default/avatar.png';
      }
      sessionStorage.setItem('userInfo', JSON.stringify(info));
    },
    brandInfo(state, info) {
      state.brandInfo = info;
      sessionStorage.setItem('brandInfo', JSON.stringify(info));
    },
    fileToken(state, fileToken) {
      state.fileToken = fileToken;
    },
    token(state, access_token) {
      state.token = access_token;
      sessionStorage.setItem('token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    },
    entranceList(state, entranceList) {
      state.entranceList = entranceList;
    },
    memberInfo(state, memberInfo) {
      state.memberInfo = memberInfo;
    },
    messageId(state, messageId) {
      state.messageId = messageId;
    },
    editUser(state, user: api.User | null) {
      state.editUser = user;
    },
    editRole(state, role: api.Role | null) {
      state.editRole = role;
    },
    setSessionId(state, id) {
      state.sessionId = id;
    },
    setPermission(state, permissionTree: RolePermissionTreeNode[]) {
      state.permissions = [];
      const dfs = (node: RolePermissionTreeNode) => {
        state.permissions.push(node.code);
        node.children.forEach((c) => dfs(c));
      };
      permissionTree.forEach((node) => dfs(node));
      sessionStorage.setItem('permissions', JSON.stringify(state.permissions));
    },
  },
  actions: {
    async getSessionId({ commit }) {
      // 获取spd token
      const res = await api.getSessionId();
      if (!api.error(res)) {
        commit('setSessionId', res.data);
      }
    },
    async getUnreadCount({ commit }) {
      const res = await api.getUnreadCount({ receiverType: 1 });
      if (!api.error(res)) {
        commit('unreadCount', res.data);
      }
    },
    async getOptionalTree({ commit }) {
      const res = await api.getOptionalTree();
      if (!api.error(res)) {
        commit('entranceList', res.data.roots);
      }
    },
    async saveEntrance({ dispatch }, entryids) {
      const res = await api.postQuickEntry(entryids);
      if (!api.error(res)) {
        dispatch('getOptionalTree');
      }
    },
  },
});
