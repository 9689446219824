
import { DesignFileView } from '@/api/customized-simulation-resources';
import { myConfirm } from '@/utils/confirm';
import { safeJsonParse } from '@/utils/safe-json-parse';
import Vue from 'vue';
import Ellipsis from '@/components/Ellipsis.vue';
import { strToInt32 } from '@/features/collage/get-component-detail';
import { convert_uuid_byte } from '@/utils/convert-uuid-to-byteid';
import { convert_instanceid_to_uuid } from '@/utils/convert-instanceid-to-uuid';
import { isEmpty } from 'lodash-es';
import SvgIcon from '@/components/SvgIcon.vue';
import { Message } from 'element-ui';
import { filePreview } from '@/utils/filepreview';
import { FileExtension } from '@/types/simulation';
import PreviewModal from '../preview-modal/index.vue';
import AddModal from '../add-modal/index.vue';

export default Vue.extend({
  components: {
    AddModal, Ellipsis, PreviewModal, SvgIcon,
  },
  data() {
    return {
      addItemModalVisible: false,
      previewModalVisible: false,
      markingStatus: false,
      locatingItem: null as DesignFileView | null,
      previewItem: {},
    };
  },
  computed: {
    list(): DesignFileView[] {
      return this.$store.state.customizedSimulationResourcesModule
        .detailedListOfSimulationResources;
    },
    selectedItem(): DesignFileView {
      return this.$store.state.customizedSimulationResourcesModule.selectedSimulationResourceDetail;
    },
    editItem(): DesignFileView {
      return this.$store.state.customizedSimulationResourcesModule.editSimulationResourceDetail;
    },
    spdApi() {
      return this.$store.getters['modelViewModule/api'];
    },
    spdApi3D() {
      return this.$store.getters['modelViewModule/api3d'];
    },
    bindedList(): DesignFileView[] {
      return this.$store.getters['customizedSimulationResourcesModule/bindedList'];
    },
  },
  methods: {
    isPositioned(item: DesignFileView): boolean {
      return !!safeJsonParse(item?.viewSettings).perspective;
    },
    bindPerspective() {
      this.$store.dispatch('customizedSimulationResourcesModule/bindPerspective', {
        perspective: this.spdApi3D.getCurrentLook(),
      });
    },
    selectItem(item) {
      this.$store.commit(
        'customizedSimulationResourcesModule/setSelectedSimulationResourceDetail',
        item,
      );
      if (this.isbinded(item)) {
        const compoentIds = safeJsonParse(item.viewSettings).componentIds.map((v) => convert_uuid_byte(convert_instanceid_to_uuid(v, 1)));
        const { perspective } = safeJsonParse(item.viewSettings);
        const api3d = this.$store.getters['modelViewModule/api3d'];
        api3d.showAll();
        api3d.update();
        api3d.restoreHighLightRenderElement();
        compoentIds.forEach((v) => {
          api3d.highLightRenderElement(v);
        });
        api3d.backBenchCameraView();
        setTimeout(() => {
          perspective && api3d.setCurrentLook(perspective, true);
        }, 700);
        this.$store.commit('customizedSimulationResourcesModule/setSelectedComponentIds', [
          ...safeJsonParse(item.viewSettings).componentIds,
        ]);
      } else {
        const api3d = this.$store.getters['modelViewModule/api3d'];
        api3d.showAll();
        api3d.update();
        api3d.restoreHighLightRenderElement();
      }
      this.checkItem(item);
    },
    backPage() {
      this.$router.back();
    },
    addItem() {
      if (this.list.length === 10) {
        Message.warning('最多只能绑定十条数据');
        return;
      }
      this.$store.commit('customizedSimulationResourcesModule/resetEditSimulationResourceDetail');
      this.addItemModalVisible = true;
    },
    handleConfirm(data) {
      this.$store.dispatch(
        'customizedSimulationResourcesModule/createEditSimulationResourceDetails',
        {
          title: data.title,
          viewSettings: JSON.stringify({
            ...safeJsonParse(this.editItem.viewSettings),
            uploadFiles: data.uploadFiles,
            intro: data.intro,
          }),
          id: this.editItem.id ? this.editItem.id : undefined,
        },
      );
    },
    handleEditItem(item: DesignFileView) {
      this.$store.commit(
        'customizedSimulationResourcesModule/setEditSimulationResourceDetail',
        item,
      );
      this.addItemModalVisible = true;
    },
    handleDeleteItem(item: DesignFileView) {
      myConfirm(this, {
        text: '确认删除此项吗？',
        resolve: () => {
          this.$store.dispatch(
            'customizedSimulationResourcesModule/deleteSimulationResourceDetails',
            {
              id: item.id,
            },
          );
        },
      });
    },
    bindComponent() {
      this.$store.dispatch('customizedSimulationResourcesModule/bindComponent');
    },
    unbindComponent() {
      this.$store.dispatch('customizedSimulationResourcesModule/unbindComponent');
    },
    isbinded(item: DesignFileView): boolean {
      return !!safeJsonParse(item.viewSettings).componentIds?.length;
    },
    isLocated(item: DesignFileView): boolean {
      return !isEmpty(safeJsonParse(item.viewSettings).markerInfo);
    },
    cancelEdit() {
      this.spdApi3D.enableMarkerRefresh();
      this.spdApi.PARAMS.mk_enable = false;
      this.markingStatus = false;
    },
    locationItem(item: DesignFileView) {
      this.markingStatus = true;
      this.locatingItem = item;
      this.spdApi3D.enableMarkerRefresh();
      this.spdApi.PARAMS.mk_enable = true;
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === 27) {
          this.spdApi3D.enableMarkerRefresh();
          this.spdApi.PARAMS.mk_enable = false;
          this.markingStatus = false;
        }
      });
      this.spdApi3D.addMarkerDivEventListener('click', (ev) => {
        if (ev.uuid && this.markingStatus) {
          this.spdApi3D.removeAllMarkerDeviceInfo();
          const deviceId = (window as any).spdengine._Math.generateUUID();
          const info: any = {
            state: 0,
            deviceId,
            isFlicker: true,
            subLineRadian: Math.PI / 4,
            subLineLength: 200,
            customColor: '#DDDDDDDD',
          };
          this.spdApi3D.markerDeviceInfoByComponentId(ev.uuid, info);
          const content = this.$refs[this.locatingItem?.id!][0] as HTMLElement;
          content.style.height = 'unset';
          content.style.padding = '24px';
          content.style.overflow = 'visible';
          info.div.appendChild(content);
          const originViewSettings = safeJsonParse(this.locatingItem?.viewSettings!);
          this.$store.dispatch(
            'customizedSimulationResourcesModule/createEditSimulationResourceDetails',
            {
              title: this.locatingItem?.title,
              viewSettings: JSON.stringify({
                ...originViewSettings,
                markerInfo: { deviceId, componentId: strToInt32(ev.uuid) },
              }),
              id: this.locatingItem?.id,
            },
          );
          this.spdApi3D.enableMarkerRefresh();
          this.markingStatus = false;
        }
        this.spdApi.PARAMS.mk_enable = false;
      });
    },
    checkItem(item: DesignFileView) {
      const { markerInfo } = safeJsonParse(item.viewSettings);
      this.spdApi3D.removeAllMarkerDeviceInfo();
      if (isEmpty(markerInfo)) {
        return;
      }
      const { deviceId } = markerInfo;
      const { componentId } = markerInfo;
      const info: any = {
        state: 0,
        deviceId,
        isFlicker: true,
        subLineRadian: Math.PI / 4,
        subLineLength: 200,
        customColor: '#DDDDDDDD',
      };
      this.spdApi3D.markerDeviceInfoByComponentId(
        convert_uuid_byte(convert_instanceid_to_uuid(componentId, 1)),
        info,
      );
      const content = this.$refs[item.id!][0] as HTMLElement;
      info.div.appendChild(content);
      content.style.height = 'unset';
      content.style.padding = '24px';
      content.style.overflow = 'visible';
    },
    getFileList(data) {
      return safeJsonParse(data)?.uploadFiles || [];
    },
    getIntro(data) {
      return safeJsonParse(data)?.intro || '';
    },
    preview(fileItem: { path?: any; name?: any }) {
      const index = fileItem.path.lastIndexOf('%2E') + 3;
      const fileExtention = fileItem.path.slice(index);
      if (FileExtension.includes(fileExtention.toUpperCase())) {
        filePreview({
          fileUrl: fileItem.path,
          fileName: fileItem.name,
          fileExtention: fileItem.path.slice(index),
        });
      } else {
        this.previewModalVisible = true;
        this.previewItem = fileItem;
      }
    },
    checkAndComplete() {
      if (!this.list.every((v) => this.isLocated(v))) {
        Message.warning('请完成标记');
        return;
      }
      if (this.bindedList.length < this.list.length) {
        Message.warning('请完成关联');
      } else {
        Message.success('已保存修改');
        this.$router.back();
      }
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.list.forEach(v => {
    //     const markerInfo = safeJsonParse(v.viewSettings).markerInfo;
    //     if (isEmpty(markerInfo)) {
    //       return;
    //     }
    //     const deviceId = markerInfo.deviceId;
    //     const componentId = markerInfo.componentId;
    //     let info: any = {
    //       state: 0,
    //       deviceId: deviceId,
    //       isFlicker: true,
    //       subLineRadian: Math.PI / 4,
    //       subLineLength: 200,
    //       customColor: "#DDDDDDDD"
    //     };
    //     this.spdApi3D.markerDeviceInfoByComponentId(
    //       convert_uuid_byte(convert_instanceid_to_uuid(componentId, 1)),
    //       info
    //     );
    //     let content = this.$refs[v.id!][0] as HTMLElement;
    //     content.style.height = "unset";
    //     content.style.padding = "24px";
    //     content.style.overflow = "visible";
    //     info.div.appendChild(content);
    //   });
    //   this.spdApi3D.enableMarkerRefresh();
    // }, 2000);
  },
});
