
import Vue from 'vue';
import LeftMenu from '@/features/two-three-dimensional-comparison/left-menu/index.vue';
import ModelView from '@/components/model-view/index.vue';
import RightMenu from '@/features/simulation-resource-model-view-right-menu/index.vue';
import BrowsePanel from '@/features/two-three-dimensional-comparison/browse-panel/index.vue';
import { getComponentInformation } from '@/api/model-view';
import { generateComponentDetail, strToInt32 } from '@/features/collage/get-component-detail';
import { startConvertModel } from '@/utils/convert-model';
import { Message } from 'element-ui';
import { uploadFile } from '@/utils/upload-file';
import InitialPerspectiveBtn from '@/components/InitialPerspectiveBtn.vue';

const { spdengine } = window as any;
export default Vue.extend({
  components: {
    LeftMenu,
    ModelView,
    RightMenu,
    BrowsePanel,
    InitialPerspectiveBtn,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    modelId() {
      return this.$store.getters['customizedSimulationResourcesModule/editModelId'];
    },
    drawingId() {
      return this.$store.state.customizedSimulationResourcesModule.drawingInfo.fileId;
    },
    drawingName() {
      return this.$store.state.customizedSimulationResourcesModule.drawingInfo.fileName;
    },
    hasDrawing() {
      return this.$store.getters['customizedSimulationResourcesModule/hasDrawing'];
    },
  },
  methods: {
    async handleClickComponent(uuid) {
      const api3d = this.$store.getters['modelViewModule/api3d'];
      const currentSelectedComponents = api3d.getSelectedElementGuids().map(strToInt32);
      if (currentSelectedComponents.length === 1) {
        const instanceId = currentSelectedComponents[0];
        const res = await getComponentInformation({
          modelId: this.modelId,
          instanceId,
        });
        if (res.status.error !== 0) {
          this.$message.error(res.status.errorDesc);
          return;
        }
        const componentDetails = generateComponentDetail(res.instanceProperty.propertySets);
        this.$store.commit('simulationRightMenuModule/setComponentInformations', {
          name: res.instanceProperty.name,
          details: componentDetails,
        });
      } else {
        this.$store.commit('simulationRightMenuModule/setComponentInformations', {
          name: '',
          details: [],
        });
      }
      this.$store.commit('customizedSimulationResourcesModule/setSelectedComponentIds', [
        ...currentSelectedComponents,
      ]);
      (this.$refs.RightMenu as any).$refs.StructureTree.$refs.tree.setCheckedKeys([]);
    },
    handleCheck(uuids) {
      this.$store.commit(
        'customizedSimulationResourcesModule/setSelectedComponentIds',
        uuids.map((v) => strToInt32(v)),
      );
      const { Apis } = spdengine;
      const api = Apis.getApi(this.$store.state.modelViewModule.benchId);
      api['3D'].restoreHighLightRenderElement();
      uuids.forEach((v) => api['3D'].highLightRenderElement(v));
    },
    threeDimensionLoaded(benchId) {
      this.$store.commit('modelViewModule/setBenchId', benchId);
    },
    twoDimensionLoaded(benchId) {
      this.$store.commit('modelViewModule/set2DBenchId', benchId);
    },
    uploadModel(_file) {
      this.loading = true;
      const file = _file.raw;
      const { size } = file;
      const index = file.name.lastIndexOf('.');
      const type: string = file.name.slice(index + 1);
      const uploadableExtensions = ['RVT', 'DWG'];
      if (!uploadableExtensions.some((v) => v === type.toUpperCase())) {
        this.$message.warning(`暂时不支持该格式, 请上传${uploadableExtensions.join(' ')}文件`);
        return;
      }
      let drawingId = '';
      const drawingName = file.name;
      const setFile = async (data) => {
        if (data.fileId) {
          drawingId = data.fileId;
        }
        if (data.percentage === '100') {
          startConvertModel({
            modelId: drawingId,
            resolve: () => {
              this.loading = false;
              this.$store.dispatch(
                'customizedSimulationResourcesModule/uploadSimulation2DDrawing',
                {
                  fileId: drawingId,
                  fileName: drawingName,
                  fileExtention: type,
                  fileConvertState: 2,
                  fileSize: size,
                  fileUrl: data.filePath,
                },
              );
            },
            reject: (errorMessage: string) => {
              Message.error(errorMessage);
            },
          });
        }
      };
      uploadFile(file, setFile, this.$store.state.sessionId);
    },
  },
  mounted() {
    this.$store.dispatch('customizedSimulationResourcesModule/getSimulationResourceDetails', {
      id: this.$store.getters['customizedSimulationResourcesModule/editSimulationResourceId'],
    });
  },
});
