import * as bimOperationQuestionApi from "@/api/BimOperationQuestion";
import * as api from "@/api";
import { Task, TaskType } from "@/features/collage/types";
import { Module } from "vuex";
import { RootState } from "../types";
import { Message } from "element-ui";
import * as JobsApi from "@/api/Jobs";
type JobManagementState = {
  selectedTaskType: TaskType | null;
  questionCatalogueList: any[];
  selectableQuestionList: any[];
  selectedTask: Task | null;
  selectedTaskModelList: any[];
};
const initialState: JobManagementState = {
  selectedTaskType: null,
  questionCatalogueList: [],
  selectableQuestionList: [],
  selectedTask: null,
  // 作业绑定的题库模型
  selectedTaskModelList: []
};
const jobManagementModule: Module<JobManagementState, RootState> = {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    setSelectedTaskType(state, payload) {
      state.selectedTaskType = payload;
    },
    setQuestionCatalogueList(state, payload) {
      state.questionCatalogueList = payload;
    },
    setSelectableQuestionList(state, payload) {
      state.selectableQuestionList = payload;
    },
    setSelectedTask(state, payload) {
      state.selectedTask = payload;
    },
    setSelectedTaskModelList(state, payload) {
      state.selectedTaskModelList = payload;
    },
    resetAll(state) {
      state.questionCatalogueList = initialState.questionCatalogueList;
      state.selectableQuestionList = initialState.selectableQuestionList;
      state.selectedTask = initialState.selectedTask;
      state.selectedTaskModelList = initialState.selectedTaskModelList;
      state.selectedTaskType = initialState.selectedTaskType;
    }
  },
  actions: {
    async getQuestionCatalogueList({ commit, state, dispatch }) {
      const res = await bimOperationQuestionApi.getCatalogueList({
        type: bimOperationQuestionApi.JobType.questionBank,
        skipCount: 0,
        maxResultCount: 999,
        sorting: "1"
      });
      if (res && !api.error(res)) {
        commit("setQuestionCatalogueList", res.data.items);
        dispatch("getQuestionList", { id: undefined });
      } else {
        Message.error("获取目录出错");
        commit("setCatalogueList", []);
      }
    },
    async getQuestionList({ commit, state }, { id: catalogueId }) {
      const res = await bimOperationQuestionApi.getQuestions({
        typeId: catalogueId,
        skipCount: 0,
        maxResultCount: 999
      });
      if (res && !api.error(res)) {
        commit("setSelectableQuestionList", res.data);
      } else {
        Message.error("获取题目出错");
      }
    },
    async selectModelToTask({ state, dispatch }, { questionId, taskId }) {
      const res = await bimOperationQuestionApi.addModelToTask({ questionId, taskId });
      if (res && !api.error(res)) {
        return dispatch("getModelOfTask");
      } else {
        Message.error(res.error.message);
        return false;
      }
    },
    async getModelOfTask({ state, commit }) {
      const res = await JobsApi.getTaskFiles({
        taskId: state.selectedTask!.id,
        isTaskRule: true,
        maxResultCount: 999,
        skipCount: 0
      });
      if (res && !api.error(res)) {
        if (res.data.items.length) {
          commit("setSelectedTaskModelList", [res.data.items[0]]);
        } else {
          commit("setSelectedTaskModelList", []);
        }
        return true;
      } else {
        Message.error("获取作业的题库模型失败");
        return false;
      }
    },
    async downloadQuestionModel({ commit, dispatch, state, rootState }, { uploadFile }) {
      const res: any = await JobsApi.downloadFile(
        { fileId: uploadFile?.fileId! },
        rootState.sessionId
      );
      const blob = new Blob([res], { type: "application/octet-stream" });
      const reader = new FileReader();
      reader.onload = function(e) {
        const a = document.createElement("a");
        const fileName = uploadFile?.fileName + "." + uploadFile.fileExtention;
        a.download = decodeURIComponent(fileName!);
        a.href = e.target!.result as string;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      reader.readAsDataURL(blob);
    },
    async changeStatus({ state, dispatch }, { isLocked, uploadFileId }) {
      const res = await JobsApi.batchUploadFileLocked({ isLocked, uploadFileIds: [uploadFileId] });
      if (res && !api.error(res)) {
        dispatch("getModelOfTask");
      } else {
        Message.error("更改状态失败");
      }
    },
    async deleteQuestionModel({ state, commit, dispatch }, { id }) {
      const res = await JobsApi.removeTaskFile({ id });
      if (res && !api.error(res)) {
        dispatch("getModelOfTask");
      } else {
        Message.error("删除题库模型失败");
      }
    }
  }
};
export default jobManagementModule;
