import { version } from './config'
import { http_get } from './helpers'

// 页面流量分布
export const getVisit = http_get<
  {
    option?: string
    timeRange: string
  },
  any
>(`/api/v${version}/a/Statistics/Distribution/Visit`)

// 页面流量分布
export const getVisitClientType = http_get<
  {
    option?: string
    timeRange: string
  },
  any
>(`/api/v${version}/a/Statistics/Proportion/Visit/ClientType`)

// 会员统计信息
export const getVipInfo = http_get<
  void,
  {
    'memberTotalCount': number
    'thisMonthAdded': number
    'thisMonthYearOnYearGrowth': number
    'thisMonthAnnulusGrowth': number
    'lastMonthAdded': number
    'lastMonthYearOnYearGrowth': number
    'lastMonthAnnulusGrowth': number
    'firstRegisteredAt': string
  }
>(`/api/v${version}/a/Statistics/Member/Info`)

// 新增会员趋势
export const getAddTrend = http_get<
  {
    SelectedTime: string
    TimeRange: string
  },
  any
>(`/api/v${version}/a/Statistics/Member/AddTrend`)

// 排名前十的产品
export const getTopTenProducts = http_get<
  {
    TimeRange: string
  },
  any
>(`/api/v${version}/a/Statistics/Product/Tops/10`)

// 搜索统计前十
export const getTopTenSearch = http_get<
  {
    TimeRange: string
  },
  any
>(`/api/v${version}/a/Statistics/ProductSearch/Tops/10`)

// 一级分类下载量占比
export const getDownloadRate = http_get<
  {
    timeRange: string
  },
  any
>(`/api/v${version}/a/Statistics/Product/Proportion/RootType/Download`)

// 一级分类数据统计
export const getClassStatistic = http_get<
  {
    timeRange: number
  },
  any
>(`/api/v${version}/a/Statistics/Product/Distribution/RootType`)
