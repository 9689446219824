import ZBadge from './ZBadge.vue';
import ZTag from './ZTag.vue';
import ZTagGroup from './ZTagGroup.vue';
import ZDivider from './ZDivider.vue';
import ZStep from './ZStep.vue';
import ZStepHeader from './ZStepHeader.vue';
import ZSheet from './ZSheet.vue';

function install(vue: any) {
  vue.component('ZBadge', ZBadge);
  vue.component('ZTag', ZTag);
  vue.component('ZTagGroup', ZTagGroup);
  vue.component('ZDivider', ZDivider);
  vue.component('ZStep', ZStep);
  vue.component('ZStepHeader', ZStepHeader);
  vue.component('ZSheet', ZSheet);
}

export default {
  install,
  ZBadge,
  ZTag,
  ZTagGroup,
  ZDivider,
  ZStep,
  ZStepHeader,
  ZSheet,
};
