import { http_delete, http_get, http_put } from './helpers'
import { version } from './config'
import type { Product } from './types'

export const getProductRecycleList = http_get<
  {},
  {
    totalCount: number
    items: Product[]
  }
>(`/api/v${version}/v/Product/RecycleBin`)

export const removeProductFromRecycleBin = http_delete<
  string[],
  void
>(`/api/v${version}/v/Product/RecycleBin`)

export const restoreProductFromRecycleBin = http_put<
  string[],
  void
>(`/api/v${version}/v/Product/RecycleBin/Restore`)
