
import Vue, { PropType } from "vue";
import { Commit } from "../types";
import ControlImg from "@/assets/wander-operate.png";
import SetPositionImg from "@/assets/wander-set-position.png";
export default Vue.extend({
  props: {
    spdApi: { type: Object },
    spdApi3D: { type: Object },
    commit: { type: Object as PropType<Commit> },
    isWandering: { type: Boolean }
  },
  data() {
    return {
      wanderSpeedIdx: 2,
      wanderSpeedConfig: [0.25, 0.5, 1, 2, 4, 8],
      ControlImg,
      SetPositionImg,
      btnDisable: !this.isWandering
    };
  },
  watch: {
    isWandering: {
      handler(val) {
        this.btnDisable = !val;
      }
    }
  },
  methods: {
    wanderSpeedFormatter(val) {
      return `${this.wanderSpeedConfig[val]}X`;
    },
    sliderChange(e, a) {
      console.log(e, a);

      console.log(this.wanderSpeedConfig[this.wanderSpeedIdx]);
      console.log(this.spdApi3D.getWanderEnable());

      // 设置漫游速度
      try {
        // 之前的方法弃用了
        // this.spdApi3D.setWanderMoveSpeed(this.wanderSpeedConfig[this.wanderSpeedIdx]);
        this.spdApi.PARAMS.wd_moveSpeed = 10 * this.wanderSpeedConfig[this.wanderSpeedIdx];
        this.spdApi.PARAMS.wd_moveVerticalSpeed = 10 * this.wanderSpeedConfig[this.wanderSpeedIdx];
      } catch (error) {
        console.error("not found setWanderMoveSpeed");
      }
    },
    // 回到起始点
    returnInitPosition() {
      this.spdApi3D.setWanderPos(null, true);
    }
  }
});
