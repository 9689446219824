import * as bimOperationQuestionApi from '@/api/BimOperationQuestion';
import { Module } from 'vuex';
import * as api from '@/api';
import { safeParseNumber } from '@/utils/safe-parse-number';
import { safeJsonParse } from '@/utils/safe-json-parse';
import { Message } from 'element-ui';
import { RootState } from '../types';

export type BimOperationQuestionState = {
  getQuestionsLoading: boolean;
  catalogueList: bimOperationQuestionApi.CatalogueItem[];
  selectedCatalogue: bimOperationQuestionApi.CatalogueItem | undefined;
  modelList: any[];
  searchText: string;
  typeContent: string;
  rulesContent: string;
  configureVisible: boolean;
  uploadedFile: {
    fileSize: number;
    fileName: string;
    percentage: string;
    filePath: string;
    fileId: string;
    fileExtension: string;
    modelImage: string;
    error: boolean;
    precision: number;
    isUploaded: boolean;
    convertProgressText: string;
    convertProgress: number;
    convertError: boolean;
  };
  currentQuestionId: string;
  standardComponentsNumber: number;
  countErrorAndGrade: {
    errorValue: number;
    grade: number;
  }[];
  allocationCountValue: number;
  currentStep: number;
};
const bimOperationQuestionModule: Module<BimOperationQuestionState, RootState> = {
  namespaced: true,
  state: {
    getQuestionsLoading: true,
    catalogueList: [],
    selectedCatalogue: undefined,
    typeContent: '',
    rulesContent: '',
    configureVisible: false,
    modelList: [],
    searchText: '',
    currentQuestionId: '',
    uploadedFile: {
      fileName: '',
      percentage: '0',
      filePath: '',
      fileExtension: '',
      fileId: '',
      error: false,
      modelImage: '',
      fileSize: 0,
      precision: 4,
      isUploaded: false,
      convertProgressText: '',
      convertProgress: 0,
      convertError: false,
    },
    standardComponentsNumber: 600,
    countErrorAndGrade: [
      {
        errorValue: 200,
        grade: 20,
      },
      {
        errorValue: 150,
        grade: 40,
      },
      {
        errorValue: 100,
        grade: 60,
      },
      {
        errorValue: 50,
        grade: 80,
      },
      {
        errorValue: 10,
        grade: 100,
      },
    ],
    allocationCountValue: 50,
    currentStep: 1,
  },
  getters: {
    isSelectedCatalogueOwn(state, getters, rootState) {
      return rootState.userInfo.userId === state.selectedCatalogue?.createUserId;
    },
  },
  mutations: {
    setCatalogueList(state, payload) {
      state.catalogueList = payload;
    },
    setSelectedCatalogue(state, payload) {
      state.selectedCatalogue = payload;
    },
    setModelList(state, payload) {
      state.modelList = payload;
    },
    setSearchText(state, payload) {
      state.searchText = payload;
    },
    setUploadedFile(state, payload) {
      state.uploadedFile = { ...state.uploadedFile, ...payload };
    },
    resetUploadedFile(state) {
      state.uploadedFile = {
        fileName: '',
        percentage: '0',
        filePath: '',
        fileId: '',
        modelImage: '',
        error: false,
        fileExtension: '',
        fileSize: 0,
        precision: 4,
        isUploaded: false,
        convertProgressText: '',
        convertProgress: 0,
        convertError: false,
      };
    },
    setStandardComponentsNumber(state, payload) {
      state.standardComponentsNumber = payload;
    },
    setErrorValue(state, { value, index }) {
      state.countErrorAndGrade[index].errorValue = value;
    },
    setGrade(state, { value, index }) {
      state.countErrorAndGrade[index].grade = value;
    },
    setAllocationCountValue(state, payload) {
      state.allocationCountValue = payload;
    },
    setCurrentStep(state, payload) {
      state.currentStep = payload;
    },
    setCurrentQuestionId(state, payload) {
      state.currentQuestionId = payload;
    },
    resetAll(state) {
      state.uploadedFile = {
        fileName: '',
        percentage: '0',
        filePath: '',
        fileId: '',
        fileExtension: '',
        error: false,
        modelImage: '',
        fileSize: 0,
        precision: 4,
        isUploaded: false,
        convertProgressText: '',
        convertProgress: 0,
        convertError: false,
      };
      state.countErrorAndGrade = [
        {
          errorValue: 200,
          grade: 20,
        },
        {
          errorValue: 150,
          grade: 40,
        },
        {
          errorValue: 100,
          grade: 60,
        },
        {
          errorValue: 50,
          grade: 80,
        },
        {
          errorValue: 10,
          grade: 100,
        },
      ];
      state.allocationCountValue = 50;
      state.currentStep = 1;
      state.standardComponentsNumber = 600;
      state.currentQuestionId = '';
      state.typeContent = '';
      state.rulesContent = '';
    },
    setRulesContent(state, payload) {
      state.rulesContent = payload;
    },
    setTypeContent(state, payload) {
      state.typeContent = payload;
    },
    setConfigureVisible(state, payload) {
      state.configureVisible = payload;
    },
    setGetQuestionsLoading(state, payload) {
      state.getQuestionsLoading = payload;
    },
  },
  actions: {
    // 获取目录列表
    async getCatalogueList({ commit, state }) {
      const res = await bimOperationQuestionApi.getCatalogueList({
        type: bimOperationQuestionApi.JobType.questionBank,
        skipCount: 0,
        maxResultCount: 999,
        // sorting: "1"
      });
      if (res && !api.error(res)) {
        commit('setCatalogueList', res.data.items);
      } else {
        Message.error('获取目录出错');
        commit('setCatalogueList', []);
      }
    },
    // 删除某目录
    async deleteCatalogueItem({ commit, state, dispatch }, { id }) {
      const res = await bimOperationQuestionApi.deleteCatalogue({ id });
      if (res && !api.error(res)) {
        const remainingCatalogueList = state.catalogueList.filter((catalogue) => catalogue.id !== id);
        commit('setCatalogueList', remainingCatalogueList);
        commit('setSelectedCatalogue', remainingCatalogueList?.[0]);
        dispatch('getQuestionList', { id: remainingCatalogueList?.[0]?.id });
        return true;
      }
      Message.error(res.error?.message || '删除目录出错');
      return false;
    },
    // 获取对应目录的题目列表
    async getQuestionList({ commit, state }, { id: catalogueId }) {
      commit('setGetQuestionsLoading', true);
      const res = await bimOperationQuestionApi.getQuestionsByCatalogueId({
        typeId: catalogueId,
        skipCount: 0,
        maxResultCount: 999,
      });
      if (res && !api.error(res)) {
        const promises = res.data.items.map((v) => bimOperationQuestionApi.getModelByQuestionId({
          isTaskRule: true,
          maxResultCount: 999,
          skipCount: 0,
          taskId: v.id,
          type: bimOperationQuestionApi.JobType.questionBank,
        }));
        const reses = await Promise.all(promises);
        if (reses.every((v) => v && !api.error(v))) {
          const models = reses.map((v) => {
            if (v && !api.error(v)) {
              return v.data.items[0];
            }
          });
          res.data.items = res.data.items.map((v, i) => ({ ...v, model: models[i] }));
        } else {
          Message.error('根据题目获取模型出错');
          return false;
        }
        commit('setModelList', res.data.items);
        commit('setGetQuestionsLoading', false);
        return true;
      }
      Message.error('获取题目列表出错');
      return false;
    },
    // 创建对应id目录下的题目
    async createQuestion({ commit, state, dispatch }, { typeId, id }) {
      // 创建作业
      const res = await bimOperationQuestionApi.createQuestion({
        typeId,
        name: state.uploadedFile.fileName.split('.')[0],
        type: bimOperationQuestionApi.JobType.questionBank,
        id: state.currentQuestionId || undefined,
      });
      if (res && !api.error(res)) {
        const questionId = res.data;
        commit('setCurrentQuestionId', questionId);
        // 绑定模型到作业
        const res2 = await bimOperationQuestionApi.bindModelandQuestion({
          taskFile: { isTaskRule: true, taskId: questionId },
          uploadFile: {
            fileConvertState: 2,
            fileExtention: state.uploadedFile.fileExtension,
            fileId: state.uploadedFile.fileId,
            fileName: state.uploadedFile.fileName,
            fileTaskId: questionId,
            fileSize: state.uploadedFile.fileSize,
            fileUrl: state.uploadedFile.filePath,
            isLocked: true,
          },
        });
        if (res2 && !api.error(res2)) {
          const uploadFileId = res2.data;
          const res3 = await bimOperationQuestionApi.setUploadFileModelImage({
            id: uploadFileId,
            modelThumbnail: state.uploadedFile.modelImage,
          });
          if (res3 && !api.error(res3)) {
            return dispatch('getQuestionList', { id: typeId });
          }
          Message.error('上传模型缩略图出错');
          return false;
        }
      } else {
        Message.error(res?.error?.message || '创建题目出错');
        return false;
      }
    },
    // 删除对应模型（删除作业）
    async deleteModel({ commit, state, dispatch }, { id }) {
      const res = await bimOperationQuestionApi.deleteQuestion({ id });
      if (res && !api.error(res)) {
        return dispatch('getQuestionList', { id: state.selectedCatalogue?.id });
      }
      Message.error('删除作业失败');
      return false;
    },
    // 保存数量评分规则
    async saveQuantitativeRule({ state, commit, dispatch }) {
      const params: bimOperationQuestionApi.SaveQuantitativeRuleParams = {
        type: bimOperationQuestionApi.JobType.questionBank,
        taskId: state.currentQuestionId,
        list: state.countErrorAndGrade.map((v) => ({
          difference: v.errorValue,
          mark: v.grade / 100,
        })),
      };
      const res = await bimOperationQuestionApi.saveQuantitativeRule(params);
      if (res && !api.error(res)) {
        return dispatch('saveWeightAllocation');
      }
      Message.error('保存数量评分规则失败');
      return false;
    },
    // 保存权重配置
    async saveWeightAllocation({ state }) {
      const res = await bimOperationQuestionApi.saveWeightAllocation({
        taskId: state.currentQuestionId,
        propertieStandard: state.standardComponentsNumber,
        propertiesPoint: safeParseNumber(state.allocationCountValue / 100),
        attributesPoint: safeParseNumber(1 - state.allocationCountValue / 100),
      });
      if (res && !api.error(res)) {
        return true;
      }
      Message.error('保存权重配置失败');
      return false;
    },
    // 保存属性配置
    async saveAttributeRule({ state }, { typeContent, rulesContent }) {
      const res = await bimOperationQuestionApi.saveAttributeRule({
        taskId: state.currentQuestionId,
        typeContent,
        rulesContent,
      });
      if (res && !api.error(res)) {
        return true;
      }
      Message.error('保存属性配置失败');
      return false;
    },
    // 题库配置完成
    async configureFinish({ state, dispatch }) {
      const res = await bimOperationQuestionApi.configureFinish({ id: state.currentQuestionId });
      if (res && !api.error(res)) {
        dispatch('getQuestionList', { id: state.selectedCatalogue?.id });
        return true;
      }
      Message.error('题库配置完成失败');
      return false;
    },
    // 获取该题目的数量配置规则、属性配置规则、权重配置规则并放到当前状态中，用于编辑该题目
    async getAllRulesByQuestionId({ state, commit, dispatch }, { id }) {
      const res = await bimOperationQuestionApi.getAllRule({ id });
      if (res && !api.error(res)) {
        const quantitativeRule = res.data.checkRuleProperties;
        quantitativeRule.forEach((v, index) => {
          commit('setErrorValue', { value: v.difference, index });
          commit('setGrade', { value: safeParseNumber(v.mark * 100), index });
        });

        const attributeRule = res.data.checkRule;
        commit('setRulesContent', attributeRule?.rulesContent || '');
        commit('setTypeContent', attributeRule?.typeContent || '');

        const weightAllocation = res.data.checkRuleConfig;
        commit(
          'setAllocationCountValue',
          100 - safeParseNumber(weightAllocation.attributesPoint * 100),
        );
        commit('setStandardComponentsNumber', weightAllocation.propertieStandard);
        return true;
      }
      Message.error('获取配置失败');
      return false;
    },
  },
};
export default bimOperationQuestionModule;
