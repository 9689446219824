
import Vue from 'vue';
import { difference, isEmpty } from 'lodash';
import Ellipsis from '@/components/Ellipsis.vue';
import { convert_uuid_byte } from '@/utils/convert-uuid-to-byteid';
import { convert_instanceid_to_uuid } from '@/utils/convert-instanceid-to-uuid';
import { getModelTree } from '@/api/model-view';

export default Vue.extend({
  components: { Ellipsis },
  computed: {
    treeData(): any {
      return this.$store.state.simulationRightMenuModule.treeData;
    },
    modelId(): string {
      return this.$store.state.simulationRightMenuModule.modelId;
    },
    name(): string {
      return '';
    },
  },
  data() {
    return {
      checked: false,
      indeterminate: false,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      defaultChecked: [] as number[],
    };
  },
  methods: {
    handleAllChange(checked) {
      this.indeterminate = false;
      if (checked) {
        const lastchildren: any = [];
        const forxh = (list) => {
          for (let i = 0; i < list.length; i++) {
            const chlist = list[i];
            if (chlist.children?.length > 0) {
              forxh(chlist.children);
            } else {
              lastchildren.push(chlist);
            }
          }
        };
        forxh(this.treeData);
        const allInstanceIds = lastchildren.map((v) => v.instanceIds).flat();
        this.defaultChecked = new Array(lastchildren.pop().key).fill(1).map((v, i) => i);
        this.$emit(
          'onCheck',
          allInstanceIds.map((v) => convert_uuid_byte(convert_instanceid_to_uuid(v, 1))),
        );
      } else {
        (this.$refs.tree as any).setCheckedKeys([]);
        this.$emit('onCheck', []);
      }
    },
    handleCheck(a, b) {
      const lastchildren: any = [];
      const forxh = (list) => {
        for (let i = 0; i < list.length; i++) {
          const chlist = list[i];
          if (chlist.children?.length > 0) {
            forxh(chlist.children);
          } else {
            lastchildren.push(chlist);
          }
        }
      };
      forxh(this.treeData);
      const allInstanceIds = lastchildren.map((v) => v.instanceIds).flat();
      const checkedInstanceIds = b.checkedNodes
        .filter((v) => v.instanceIds)
        .map((v) => v.instanceIds)
        .flat();
      const hiddenInstanceIds = difference(allInstanceIds, checkedInstanceIds);
      const byteids = hiddenInstanceIds.map((v) => convert_uuid_byte(convert_instanceid_to_uuid(v, 1)));
      this.checked = hiddenInstanceIds.length === 0;
      this.indeterminate = hiddenInstanceIds.length > 0 && hiddenInstanceIds.length < allInstanceIds.length;
      this.$emit(
        'onCheck',
        checkedInstanceIds.map((v) => convert_uuid_byte(convert_instanceid_to_uuid(v, 1))),
      );
    },
  },
  watch: {},
});
