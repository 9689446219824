export enum SimulationResourceCategory {
  homePage,
  loadingPage,
  modelBrowsing,
  componentIntroduction,
  auxiliaryPositioning,
  twoDimensionalAndThreeDimensionalComparison,
  exercises,
}
export const SimulationResourceCategoryText = {
  [SimulationResourceCategory.homePage]: '主页',
  [SimulationResourceCategory.loadingPage]: '加载页',
  [SimulationResourceCategory.modelBrowsing]: '模型浏览',
  [SimulationResourceCategory.componentIntroduction]: '构件介绍',
  [SimulationResourceCategory.auxiliaryPositioning]: '辅助定位',
  [SimulationResourceCategory.twoDimensionalAndThreeDimensionalComparison]: '二维三维对照',
  [SimulationResourceCategory.exercises]: '练习题',
}

export const ImageExtension = ['JPG', 'PNG']
export const VideoExtension = [
  'MP4',
]
export const FileExtension = [
  'DOC', 'DOCX', 'PPT', 'XLS',
]

export enum CompletionStatus {
  notStart,
  studying,
  completed,
}

export const CompletionStatusText = {
  [CompletionStatus.notStart]: '未开始',
  [CompletionStatus.studying]: '学习中',
  [CompletionStatus.completed]: '已完成',
}
