import { version } from './config'
import { http_post } from './helpers'
import type { SimulationResourceCategory } from '@/types/simulation'

export interface SimulationTrainingDirectory {
  name: string
  parentId?: number
  id?: number
  creationTime?: string
  createUserId?: number
}
export interface SimulationTraining {
  title: string
  logo: string
  beginTime: string
  endTime: string
  typeId: number
  major: string
  grade: string
  description?: string
  status?: number
  creationTime?: string
  createUserId?: number
  id?: number
}
export interface SimulationTrainingCoursePage {
  viewSettings: string
  title?: string
  designTaskId: number
  sort: number
  designFileId?: number
  classType: number
  creationTime?: string
  createUserId?: number
  id?: number
}
export interface UserDetail {
  userId: number
  userName: string
  nickName: string
  phoneNumber: string
  designFinishStatus: number
}
interface GetSimulationTrainingDirectoryParams {
  parentId?: 0
  name?: string
  filter?: string
  sorting?: '0' | '1'
  skipCount: number
  maxResultCount: number
}
interface GetSimulationTrainingDirectoryResponse {
  totalCount: number
  items: SimulationTrainingDirectory[]
}

type CreateEditSimulationTrainingDirectoryParams = SimulationTrainingDirectory
interface CreateEditSimulationTrainingDirectoryResponse {}

interface DeleteSimulationTrainingDirectoryParams { id: number }
interface DeleteSimulationTrainingDirectoryResponse {}

interface GetSimulationTrainingListParams {
  status?: number
  filter?: string
  sorting?: '0' | '1'
  classType?: SimulationResourceCategory
  typeId: number
  skipCount: number
  maxResultCount: number
}
interface GetSimulationTrainingListResponse {
  totalCount: number
  items: SimulationTraining[]
}

type CreateEditSimulationTrainingParams = SimulationTraining
interface CreateEditSimulationTrainingResponse {}

interface DeleteSimulationTrainingParams { id: number }
interface DeleteSimulationTrainingResponse {}

interface GetSimulationTrainingCoursePageListParams {
  filter?: string
  sorting?: '0' | '1'
  designTaskId: number
  skipCount: number
  maxResultCount: number
}
interface GetSimulationTrainingCoursePageListResponse {
  totalCount: number
  items: SimulationTrainingCoursePage[]
}

type CreateEditSimulationTrainingCoursePageParams = SimulationTrainingCoursePage
interface CreateEditSimulationTrainingCoursePageResponse {}

interface DeleteSimulationTrainingCoursePageParams { id: number }
interface DeleteSimulationTrainingCoursePageResponse {}

interface AddMemberParams {
  designTaskId: number
  targetIds: number[]
}
interface AddMemberResponse {}

interface DeleteMemberParams {
  designTaskId: number
  targetIds: number[]
}
interface DeleteMemberResponse {}

interface GetMemberParams {
  id: number
}
interface GetMemberResponse {}

interface GetMemberDetailsParams {
  designTaskId: number
  inDesignTask: boolean
  skipCount: number
  maxResultCount: number
}
interface GetMemberDetailsResponse {
  totalCount: number
  items: UserDetail[]
}
interface ModifyEnumInformationParams {
  id?: number
  val?: string
  txt: string
  groupCode: string
}
interface ModifyEnumInformationResponse {}

/**
 * 获取仿真实训目录
 */
export const getSimulationTrainingDirectory = http_post<
  GetSimulationTrainingDirectoryParams,
  GetSimulationTrainingDirectoryResponse
>(`/api/v${version}/a/Study/DesignTaskTypes_PageList`)

/**
 * 创建编辑仿真实训目录
 */
export const createEditSimulationTrainingDirectory = http_post<
  CreateEditSimulationTrainingDirectoryParams,
  CreateEditSimulationTrainingDirectoryResponse
>(`/api/v${version}/a/Study/DesignTaskTypes_Save`)

/**
 * 删除仿真实训目录
 */
export const deleteSimulationTrainingDirectory = http_post<
  DeleteSimulationTrainingDirectoryParams,
  DeleteSimulationTrainingDirectoryResponse
>(`/api/v${version}/a/Study/DesignTaskTypes_Remove`)

/**
 * 获取仿真实训列表
 */
export const getSimulationTrainingList = http_post<
  GetSimulationTrainingListParams,
  GetSimulationTrainingListResponse
>(`/api/v${version}/a/Study/DesignTasks_PageList`)

/**
 * 创建仿真实训
 */
export const createEditSimulationTraining = http_post<
  CreateEditSimulationTrainingParams,
  CreateEditSimulationTrainingResponse
>(`/api/v${version}/a/Study/DesignTasks_Save`)

/**
 * 删除仿真实训
 */
export const deleteSimulationTraining = http_post<
  DeleteSimulationTrainingParams,
  DeleteSimulationTrainingResponse
>(`/api/v${version}/a/Study/DesignTasks_Remove`)

/**
 * 获取仿真实训课程页面列表
 */
export const getSimulationTrainingCoursePageList = http_post<
  GetSimulationTrainingCoursePageListParams,
  GetSimulationTrainingCoursePageListResponse
>(`/api/v${version}/a/Study/DesignTaskViews_PageList`)

/**
 * 创建仿真实训课程页面
 */
export const createEditSimulationTrainingCoursePage = http_post<
  CreateEditSimulationTrainingCoursePageParams,
  CreateEditSimulationTrainingCoursePageResponse
>(`/api/v${version}/a/Study/DesignTaskViews_Save`)

/**
 * 删除仿真实训课程页面
 */
export const deleteSimulationTrainingCoursePage = http_post<
  DeleteSimulationTrainingCoursePageParams,
  DeleteSimulationTrainingCoursePageResponse
>(`/api/v${version}/a/Study/DesignTaskViews_Remove`)

/**
 * 添加成员
 */
export const addMember = http_post<AddMemberParams, AddMemberResponse>(
  `/api/v${version}/a/Study/BatchAddDesignTaskUsers`,
)

/**
 * 删除成员
 */
export const deleteMember = http_post<DeleteMemberParams, DeleteMemberResponse>(
  `/api/v${version}/a/Study/BatchRemoveDesignTaskUsers`,
)

/**
 * 获取成员id
 */
export const getMember = http_post<GetMemberParams, GetMemberResponse>(
  `/api/v${version}/a/Study/QueryDesignTaskUserIds`,
)

/**
 * 获取仿真实训成员详细信息
 */
export const getMemberDetails = http_post<GetMemberDetailsParams, GetMemberDetailsResponse>(
  `/api/v${version}/a/Study/Users_PageByStudy`,
)

/**
 * 修改enum信息
 */
export const modifyEnumInformation = http_post<
  ModifyEnumInformationParams,
  ModifyEnumInformationResponse
>(`/api/v${version}/a/Study/EnumItems_Save`)
