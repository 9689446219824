import axios from 'axios'
import { version } from './config'
import { http_post } from './helpers'
import type {
  AllRules,
  Commit,
  Task,
  TaskFile,
  TaskFilesExtendsQuery,
  TaskFilesQuery,
  TaskType,
  TaskTypes_PageListQuery,
  TaskTypes_RemoveQuery,
  TaskTypes_SaveQuery,
  Tasks_PageListQuery,
  Tasks_SaveQuery,
} from '@/features/collage/types'

export const getTaskTypeList = http_post<
  TaskTypes_PageListQuery,
  {
    totalCount: number
    items: TaskType[]
  }
>(`/api/v${version}/a/Study/TaskTypes_PageList`)

export const addOrUpdateTaskType = http_post<TaskTypes_SaveQuery, void>(
  `/api/v${version}/a/Study/TaskTypes_Save`,
)

export const deleteTaskType = http_post<TaskTypes_RemoveQuery, void>(
  `/api/v${version}/a/Study/TaskTypes_Remove`,
)

export const getTasks = http_post<
  Tasks_PageListQuery,
  {
    totalCount: number
    items: Task[]
  }
>(`/api/v${version}/a/Study/Tasks_PageList`)

export const createOrUpdateTask = http_post<Tasks_SaveQuery, void>(
  `/api/v${version}/a/Study/Tasks_Save`,
)

export const deleteTask = http_post<{ id: string }, void>(`/api/v${version}/a/Study/Tasks_Remove`)
export const getTaskFiles = http_post<
  TaskFilesQuery,
  {
    totalCount: number
    items: TaskFile[]
  }
>(`/api/v${version}/a/Study/TaskFiles_PageList`)

export const uploadFileToTask = http_post<TaskFilesExtendsQuery, void>(
  `/api/v${version}/a/Study/TaskFileExtends_Save`,
)
export const removeTaskFile = http_post<{ id: string }, void>(
  `/api/v${version}/a/Study/TaskFiles_Remove`,
)

export const saveWeightAllocation = http_post<
  Partial<{
    propertiesPoint: number
    attributesPoint: number
    propertieStandard: number
    taskId: string
    id: string
  }>,
  void
>(`/api/v${version}/a/Study/CheckRuleConfigs_Save`)

export const savePropertiesRule = http_post<
  Partial<{
    taskId: string
    list: {
      difference: number
      mark: number
      taskId?: string
      id?: string
    }[]
  }>,
  void
>(`/api/v${version}/a/Study/CheckRuleProperties_Save`)

export const saveComponentRules = http_post<
  Partial<{
    rulesContent: string
    typeContent: string
    taskId: string
  }>,
  void
>(`/api/v${version}/a/Study/CheckRules_Save`)

export const getAllRules = http_post<{ id: string }, AllRules>(
  `/api/v${version}/a/Study/CheckRules_QueryDetailByTaskId`,
)

export const fetchCommits = http_post<
  { sorting?: string; skipCount: number; maxResultCount: number; taskId: string },
  { totalCount: number; items: Commit[] }
>(`/api/v${version}/a/Study/{taskId}/TaskUserCommits_PageByUser`)

export const fetchStudentCommits = http_post<
  { sorting?: string; skipCount: number; maxResultCount: number; taskId: string; userId: string },
  { totalCount: number; items: Commit[] }
>(`/api/v${version}/a/Study/{taskId}/{userId}/TaskUserCommits_Page`)

export const correctTask = http_post<
  {
    status: number
    remark: string
    mark?: number
    commitId: string
    taskId: string
  },
  void
>(`/api/v${version}/a/Study/{taskId}/{commitId}/TaskCommitCheck`)

// 批量同步分数
export const batchSynchronizationScore = http_post<
  {
    taskCommitIds: string[]
    taskId: string
  },
  void
>(`/api/v${version}/a/Study/{taskId}/BatchTaskCommitCheckWithSystem`)

export const fetchTaskMembers = http_post<
  {
    id: string
  },
  any
>(`/api/v${version}/a/Study/QueryTaskUserIds`)

export const addTaskMembers = http_post<
  {
    taskId: string
    targetType?: number
    targetIds: string[]
  },
  any
>(`/api/v${version}/a/Study/BatchAddTaskUsers`)

export const removeTaskMembers = http_post<
  {
    taskId: string
    targetType?: number
    targetIds: string[]
  },
  any
>(`/api/v${version}/a/Study/BatchRemoveTaskUsers`)
interface auditRes {
  taskId: string
  taskCommitId: string
  mark: number
  isQualified: boolean
  reportUrl: string
  path: string
  hash: string
  checkResult: string
  errorFileUrl: string
  errorFilePath: string
  errorFileHash: string
  errorMessage: string
  createUserId: string
  creationTime: string
  id: string
}
export const batchOutputAuditModelPdf = http_post<
  {
    listTaskCommitId: string[]
    listParam: string[]
    type?: number
    template: string
  },
  auditRes[]
>(`/api/v${version}/a/Study/BatchOutputAuditModelPdf`)
export const outputAuditModelPdf = http_post<
  {
    taskCommitId: string
    userId: string
    isRetry?: boolean
  },
  auditRes
>(`/api/v${version}/a/Study/OutputAuditModelPdf`)
export const taskOpinions_Save = http_post<
  {
    remark: string
    commitId: string
    taskId: string
    imageUrl: string
  },
  void
>(`/api/v${version}/a/Study/{taskId}/{commitId}/TaskOpinions_Save`)

export const ExportTaskUserCommits_PageByUser = http_post<
  {
    taskId: string
    isCommit: boolean
    filter?: string
    sorting?: string
    skipCount?: number
    maxResultCount?: number
  },
  {
    hash: string
    fileName: string
    fileType: string
  }
>(`/api/v${version}/a/Study/{taskId}/ExportTaskUserCommits_PageByUser`)

// export const fetchCommits = (
//   taskId: string,
//   params: { sorting?: string; skipCount: number; maxResultCount: number }
// ) => axios.post(`/api/v${version}/a/Study/${taskId}/TaskUserCommits_PageByUser`, params);

export const convertModel = (data: { fileId: string; tessLevel: number }, sessionId) =>
  axios.post(`${process.env.VUE_APP_BORON}/Boron/ConvertFile`, data, {
    headers: {
      Authorization: `${'Bearer' + ' '}${sessionId}`,
    },
  })

export const getConvertProgress = (data: { fileId: string }, sessionId) =>
  axios.get(`${process.env.VUE_APP_BORON}/Boron/GetConvertProgress?fileId=${data.fileId}`, {
    headers: {
      Authorization: `${'Bearer' + ' '}${sessionId}`,
    },
  })

export const downloadFile = (data: { fileId: string }, sessionId) =>
  axios.post(
    `${process.env.VUE_APP_BORON}/Boron/DownloadFile/Original/${data.fileId}`,
    {},
    {
      headers: {
        Authorization: `${'Bearer' + ' '}${sessionId}`,
      },
      responseType: 'arraybuffer',
    },
  )

export const getComponents = (data: { fileId: string }, sessionId) =>
  axios.get(
    `${process.env.VUE_APP_BORON}/Boron/GetInstances?fileId=${data.fileId}&includeProperty=true`,
    {
      headers: {
        Authorization: `${'Bearer' + ' '}${sessionId}`,
      },
    },
  )

export const getComponentsWithoutProperty = (data: { fileId: string }, sessionId) =>
  axios.get(
    `${process.env.VUE_APP_BORON}/Boron/GetInstances?fileId=${data.fileId}&includeProperty=false`,
    {
      headers: {
        Authorization: `${'Bearer' + ' '}${sessionId}`,
      },
    },
  )

export const getComponentDetail = (data: { fileId: string; instanceId: number }, sessionId) =>
  axios.get(
    `${process.env.VUE_APP_BORON}/Boron/GetInstanceProperty?fileId=${data.fileId}&instanceId=${data.instanceId}`,
    {
      headers: {
        Authorization: `${'Bearer' + ' '}${sessionId}`,
      },
    },
  )
export const getModelTree = (data: { fileId: string }, sessionId) =>
  axios.get(`${process.env.VUE_APP_BORON}/Boron/GetModelLevelTree?fileId=${data.fileId}`, {
    headers: {
      Authorization: `${'Bearer' + ' '}${sessionId}`,
    },
  })

/**
 * 作业通知 -分页列表
 */
export const TaskNotices_PageList = http_post<
  {
    taskId: number | string
    sorting?: string
    skipCount: number
    maxResultCount: number
  },
  {
    totalCount: number
    items: {
      taskId: number
      content: string
      creationTime: string
      createUserId: number
      id: number
    }[]
  }
>(`/api/v${version}/a/Study/TaskNotices_PageList`)

/**
 * 作业通知-创建
 */
export const TaskNotices_Save = http_post<
  {
    taskId: number | string
    content: string
    creationTime?: string
    createUserId?: number
    id?: number
  },
  void
>(`/api/v${version}/a/Study/TaskNotices_Save`)

/**
 * 作业通知-移除
 */
export const TaskNotices_Remove = http_post<
  {
    id: number
  },
  void
>(`/api/v${version}/a/Study/TaskNotices_Remove`)

export const TaskOpinions_GetList = http_post<
  {
    taskId: string
    commitId: string
  },
  void
>(`/api/v${version}/a/Study/{taskId}/{commitId}/TaskOpinions_GetList`)

export const TaskOpinions_Remove = http_post<
  {
    id: string
  },
  void
>(`/api/v${version}/a/Study/TaskOpinions_Remove`)

interface BatchUploadFileLockedParams {
  isLocked: boolean
  uploadFileIds: string[]
}
export const batchUploadFileLocked = http_post<BatchUploadFileLockedParams, void>(
  `/api/v${version}/m/UploadFiles/UploadFiles_BatchLocked`,
)
export const batchCommitLocked = http_post<
  { reportLocked: boolean; taskCommitIds: string[] },
  void
>(`/api/v${version}/a/Study/TaskCommits_BatchLocked`)
