
import { DesignFileView } from '@/api/customized-simulation-resources';
import { safeJsonParse } from '@/utils/safe-json-parse';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    visible: { type: Boolean },
    item: { type: Object as PropType<DesignFileView> },
  },
  data() {
    return {
      form: {
        title: '',
        index: 1,
      },
      rules: {
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        index: [{ required: true, message: '请选择序号', trigger: 'change' }],
      },
    };
  },
  computed: {
    hasEditItem() {
      return this.$store.getters[
        'customizedSimulationResourcesModule/hasEditSimulationResourceDetail'
      ];
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false);
    },
    handleConifrm() {
      const newIndex = this.form.index;
      const newTitle = this.form.title;
      const oldIndexs = this.$store.state.customizedSimulationResourcesModule.detailedListOfSimulationResources.map((v) => safeJsonParse(v.viewSettings).index);
      const oldTitles = this.$store.state.customizedSimulationResourcesModule.detailedListOfSimulationResources.map((v) => v.title);
      if (oldIndexs.some((v) => v === newIndex)) {
        Message.warning('该序号已被使用');
        return;
      }
      if (oldTitles.some((v) => v === newTitle)) {
        Message.warning('该名称已被使用');
        return;
      }
      (this.$refs.form as ElForm).validate((valid) => {
        if (valid) {
          this.$emit('onConfirm', this.form);
          this.closeModal();
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    visible: {
      handler(val: boolean) {
        if (val) {
          this.form.title = this.item.title!;
          this.form.index = safeJsonParse(this.item.viewSettings).index || 1;
        }
      },
      deep: true,
    },
  },
});
