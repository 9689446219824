import { Module } from 'vuex';
import { RootState } from '../types';

type ModelViewState = {
  benchId: number;
  twoDBenchId: number;
};
const modelViewModule: Module<ModelViewState, RootState> = {
  namespaced: true,
  state: {
    benchId: 0,
    twoDBenchId: 0,
  },
  getters: {
    api3d(state) {
      return (window as any).spdengine.Apis.getApi(state.benchId)['3D'];
    },
    api(state) {
      return (window as any).spdengine.Apis.getApi(state.benchId);
    },
    twoDApi3d(state) {
      return (window as any).spdengine.Apis.getApi(state.twoDBenchId)['3D'];
    },

  },
  mutations: {
    setBenchId(state, payload) {
      state.benchId = payload;
    },
    set2DBenchId(state, payload) {
      state.twoDBenchId = payload;
    },
  },
  actions: {},
};
export default modelViewModule;
