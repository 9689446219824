import { http_get, http_post, http_put } from './helpers'
import { version } from './config'
import type {
  BroadcastMessages, BroadcastMessagesForm, Messages, NewsItem,
} from './types'

// 获取未读消息数量
export const getUnreadCount = http_get<
  {
    receiverType: number
  },
  number
>(`/api/v${version}/f/SiteMessages/UnreadCount`)

// 获取全部消息
export const getSitMessages = http_get<
    Messages,
    {
      totalCount: number
      items: NewsItem[] | []
    }
>(`/api/v${version}/f/SiteMessages`)

// 获取类型消息
export const getCategories = http_get<
    {
      receiverType: number
    },
    {
      category: number
      count: number
    }[]
>(`/api/v${version}/f/SiteMessages/Categories`)

// 获取全部广播消息
export const getBroadcastMessages = http_get<
    {
      title: number | null
      receiverType: number | null
      startPublishTime: string | null
      endPublishTime: string | null
      sorting: string
      skipCount: number
      maxResultCount: number
    },
    {
      totalCount: number
      items: BroadcastMessages[] | []
    }
>(`/api/v${version}/a/BroadcastMessages`)

// 保存消息
export const postBroadcastMessages = http_post<
    BroadcastMessagesForm,
    {
      messageId: string | null
    }
>(`/api/v${version}/a/BroadcastMessages`)

// 阅读某条消息
export const readMessages = http_put<
    { id: string },
    any
>(`/api/v${version}/f/SiteMessages/{id}/Read`)

// 获取广播消息
export const getMessages = http_get<
    {
      id: string | null
    },
    BroadcastMessagesForm
>(`/api/v${version}/a/BroadcastMessages/{id}`)

// 发布消息
export const putMessagesPublish = http_put<
    {
      id: string | null
    },
    void
>(`/api/v${version}/a/BroadcastMessages/{id}/Publish`)

// 撤回消息
export const putMessagesWithdraw = http_put<
    {
      id: string | null
    },
    void
>(`/api/v${version}/a/BroadcastMessages/{id}/Withdraw`)
