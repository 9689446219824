import { Module } from 'vuex';
import * as api from '@/api';
import { findComponentCollection, getModelTree } from '@/api/model-view';
import { Message } from 'element-ui';
import { convert_instanceid_to_uuid } from '@/utils/convert-instanceid-to-uuid';
import { convert_uuid_byte } from '@/utils/convert-uuid-to-byteid';
import { RootState } from '../types';

export enum MenuType {
  structureTree = 'structureTree',
  attribute = 'attribute',
  componentLookup = 'componentLookup'
}
const activeMenuText = {
  [MenuType.structureTree]: '结构树',
  [MenuType.attribute]: '属性',
  [MenuType.componentLookup]: '构件查找',
};
type SimulationRightMenuState = {
  activeName: MenuType | '';
  modelId: string;
  treeData: any;
  componentInformations: { name: string; details: { key: string; value: string }[] };
  searchComponentList: number[];
};
const simulationRightMenuModule: Module<SimulationRightMenuState, RootState> = {
  namespaced: true,
  state: {
    modelId: '',
    activeName: '',
    treeData: [],
    componentInformations: { name: '', details: [] },
    searchComponentList: [],
  },
  getters: {
    activeMenuText(state, getters, rootState, rootGetters) {
      return activeMenuText[state.activeName];
    },
  },
  mutations: {
    setModelId(state, payload) {
      state.modelId = payload;
    },
    setActiveName(state, payload) {
      state.activeName = payload;
    },
    setTreeData(state, payload) {
      state.treeData = payload;
    },
    setComponentInformations(state, payload) {
      state.componentInformations = payload;
    },
    setSearchComponentList(state, payload) {
      state.searchComponentList = payload;
    },
    resetAll(state) {
      state.activeName = '';
      state.treeData = [];
      state.searchComponentList = [];
      state.componentInformations = { name: '', details: [] };
    },
  },
  actions: {
    async init({ state, commit, dispatch }, { modelId }) {
      commit('resetAll');
      commit('setModelId', modelId);
      await dispatch('getTreeData');
    },
    async getTreeData({ state, commit, dispatch }) {
      const res = await getModelTree(state.modelId);
      if (res.status.error !== 0) {
        Message.error(res.status.errorDesc);
        return;
      }
      let i = 0;
      const addKey = (arr) => arr.map((item) => ({
          ...item,
          key: i++,
          children: 'children' in item ? addKey(item.children) : undefined,
        }));
      commit('setTreeData', addKey(res.modelLevelTree.children));
    },
    async findComponentCollection({
 state, commit, dispatch, rootGetters,
}, data) {
      const res = await findComponentCollection({ ...data, modelId: state.modelId });
      if (res.status.error === 0) {
        const compoentIds = res.instances.map((v) => convert_uuid_byte(convert_instanceid_to_uuid(v, 1)));
        commit('setSearchComponentList', [...compoentIds]);
      } else {
        Message.error(res.status.errorDesc);
      }
    },
  },
};
export default simulationRightMenuModule;
