
import Vue from "vue";
import { fabric } from "fabric";
import { Tool } from "./add-notation-modal.vue";
export default Vue.extend({
  props: {
    base64PImg: { type: String },
    color: { type: String },
    tool: { type: String },
    currentIndex: { type: Number },
    visible: { type: Boolean }
  },
  data() {
    return {
      canvas: {} as fabric.Canvas,
      circleMode: false,
      rectangleMode: false,
      textMode: false,
      circleRef: {} as fabric.Ellipse,
      rectRef: {} as fabric.Rect,
      isMouseDown: false,
      startPointer: { x: 0, y: 0 },
      textInputVisible: false,
      notationText: "",
      history: [] as String[],
      historyObjects: [] as fabric.Object[]
    };
  },
  methods: {
    saveImage(): string {
      return this.canvas.toDataURL({ format: "png" });
    },

    resetImg(base64PImg: string) {
      this.canvas.clear();
      fabric.Image.fromURL(base64PImg, img => {
        {
          this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
            scaleX: 954 / img.width!,
            scaleY: 479 / img.height!
          });
        }
      });
    },
    setColor(color) {
      this.canvas.freeDrawingBrush.color = color;
    },
    resetCanvas() {
      this.canvas.off("mouse:down");
      this.canvas.off("mouse:move");
    },
    handleConfirm() {
      const text = new fabric.Text(this.notationText, {
        top: this.startPointer.y,
        left: this.startPointer.x,
        fontSize: 16,
        fill: this.color
      });
      this.canvas.add(text);
      this.textInputVisible = false;
      this.notationText = "";
    }
  },
  watch: {
    currentIndex: {
      handler(val, oldVal) {
        if (val < oldVal) {
          this.canvas.remove(this.historyObjects.pop()!);
        }
      }
    },
    base64PImg: {
      handler(val) {
        this.resetImg(val);
      }
    },
    color: {
      handler(val) {
        this.setColor(val);
      }
    },
    visible: {
      handler(val) {
        if (!val) {
          this.resetCanvas();
          this.circleMode = false;
          this.rectangleMode = false;
          this.textMode = false;
          this.canvas.isDrawingMode = false;
          this.textInputVisible = false;
          this.notationText = "";
        } else {
          this.resetImg(this.base64PImg);
        }
      }
    },
    tool: {
      handler(val: Tool) {
        switch (val) {
          case Tool.pen:
            this.resetCanvas();
            this.circleMode = false;
            this.rectangleMode = false;
            this.textMode = false;
            this.canvas.isDrawingMode = true;
            break;
          case Tool.rectangle:
            this.resetCanvas();
            this.canvas.isDrawingMode = false;
            this.textMode = false;
            this.circleMode = false;
            this.rectangleMode = true;
            this.canvas.on("mouse:down", e => {
              this.isMouseDown = true;
              this.startPointer = e.pointer!;
              const rect = new fabric.Rect({
                left: e.pointer!.x,
                top: e.pointer!.y,
                width: 1,
                height: 1,
                fill: "",
                stroke: this.color,
                strokeWidth: 2
              });
              this.canvas.add(rect);
              this.rectRef = rect;
            });
            this.canvas.on("mouse:move", e => {
              if (!this.isMouseDown) {
                return;
              }
              this.rectRef.set("left", Math.min(e.pointer!.x, this.startPointer.x));
              this.rectRef.set("top", Math.min(e.pointer!.y, this.startPointer.y));
              this.rectRef.set("width", Math.abs(e.pointer!.x - this.startPointer.x));
              this.rectRef.set("height", Math.abs(e.pointer!.y - this.startPointer.y));
              this.canvas.renderAll();
            });
            break;
          case Tool.circle:
            this.resetCanvas();
            this.canvas.isDrawingMode = false;
            this.textMode = false;
            this.rectangleMode = false;
            this.circleMode = true;
            this.canvas.on("mouse:down", e => {
              this.startPointer = e.pointer!;
              this.isMouseDown = true;
              let circle = new fabric.Ellipse({
                left: e.pointer!.x,
                top: e.pointer!.y,
                originX: "left",
                originY: "top",
                stroke: this.color,
                strokeWidth: 2,
                fill: ""
              });
              this.canvas.add(circle);
              this.circleRef = circle;
            });
            this.canvas.on("mouse:move", e => {
              if (!this.isMouseDown) {
                return;
              }
              this.circleRef.set("left", Math.min(e.pointer!.x, this.startPointer.x));
              this.circleRef.set("top", Math.min(e.pointer!.y, this.startPointer.y));
              this.circleRef.set("rx", Math.abs((this.startPointer.x - e.pointer!.x) / 2));
              this.circleRef.set("ry", Math.abs((this.startPointer.y - e.pointer!.y) / 2));
              this.canvas.renderAll();
            });
            break;
          case Tool.text:
            this.resetCanvas();
            this.canvas.isDrawingMode = false;
            this.circleMode = false;
            this.rectangleMode = false;
            this.textMode = true;
            this.canvas.on("mouse:down", e => {
              if (this.textInputVisible) return;
              this.startPointer = e.pointer!;
              this.textInputVisible = true;
            });
            break;
          default:
            this.canvas.isDrawingMode = false;
            this.circleMode = false;
            this.rectangleMode = false;
            this.textMode = false;
            break;
        }
      }
    }
  },
  mounted() {
    const canvas = new fabric.Canvas("notationCanvas", {
      isDrawingMode: false,
      selection: false,
      controlsAboveOverlay: false
    });
    fabric.Object.prototype.hasControls = false;
    fabric.Object.prototype.hasBorders = false;
    fabric.Object.prototype.hoverCursor = "default";
    fabric.Object.prototype.selectable = false;
    this.canvas = canvas;
    this.resetImg(this.base64PImg);
    this.setColor(this.color);
    this.canvas.freeDrawingBrush.width = 2;
    this.canvas.on("object:added", e => {
      this.historyObjects.push(e.target!);
      this.$emit("update:currentIndex", this.currentIndex + 1);
    });
    this.canvas.on("mouse:up", e => {
      this.isMouseDown = false;
    });
  }
});
