import qs from 'qs'
import axios from 'axios'
import { http_get, http_put } from './helpers'
import { version } from './config'
import type { UserAccount } from './types'

export async function login(opt: { username: string; password: string }) {
  try {
    // TODO 发正式环境需要更改正式环境的域名
    // const tokenUrl = `${
    //   process.env.NODE_ENV === "dev" ? process.env.VUE_APP_BASEURL : ""
    // }/id/connect/token`;
    const tokenUrl
      = `${process.env.NODE_ENV === 'dev' ? process.env.VUE_APP_BASEURL : ''}` + '/id/connect/token'
    const res: unknown = await axios.post(
      tokenUrl,
      qs.stringify({
        username: opt.username,
        password: opt.password,
        client_id: 'ibuilding3d',
        client_secret: 'secret',
        scope: 'iBuilding3D',
        grant_type: 'password',
      }),
    )
    return res as
      | {
        access_token: string
        expires_in: number
        token_type: 'Bearer'
        scope: 'iBuilding3D'
      }
      | any
  }
  catch (err) {
    console.error('token', err)
    return undefined // 非业务错误
  }
}

export const register = http_put<
  {
    phoneNumber: string
    verificationCode: string
    password: string
  },
  void
>(`/api/v${version}/f/Account/Register`)

// GET /api/v{version}/f/User
// 获取用户账号信息
export const getUser = http_get<void, UserAccount>(`/api/v${version}/f/User`)

// 修改密码
export const putAvatar = http_put<
  {
    avatar: string
  },
  void
>(`/api/v${version}/f/User/Avatar`)

// 修改密码
export const putPassword = http_put<
  {
    current: string
    modified: string
  },
  void
>(`/api/v${version}/f/User/Password`)
