
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      wrap: {
        type: Boolean,
        default: true,
      },
    },
  });
