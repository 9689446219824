
import Vue from 'vue'
import { getUser, login } from '@/api/Account'

import * as api from '@/api'

import { getUserPermissionTree } from '@/api'

export default Vue.extend({
  data() {
    return {
      loginFrom: {
        username: '',
        password: '',
      },
      checked: true,
      rules: {
        username: [{ required: true, message: '请输入账号或邮箱', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', tirgger: 'blur' }],
      },
      error_type: {
        1: '账号不存在',
        2: '密码错误',
        3: '账号已停用，如需使用请联系管理员',
      },
    }
  },
  methods: {
    login(ref) {
      (this.$refs[ref] as any).validate(async (valid) => {
        if (valid) {
          const res = await login(this.loginFrom)
          if (res && res.error) {
            (this as any).$message({
              type: 'error',
              message: (this as any).error_type[res.error_type],
            })
          }
          else {
            this.$store.commit('token', res.access_token)
            // 坑: 隐含语义，拿到 token 后的请求失败说明不是后台用户
            if (await this.fetchUser()) {
              this.$message({
                type: 'success',
                message: '登录成功',
                duration: 1000,
              })
              this.$router.replace({
                path: '/home',
              })
            }
            else {
              this.$message({
                type: 'error',
                message: '禁止访问',
              })
            }
          }
        }
      })
    },
    async fetchUser() {
      const userRes = await getUser()
      if (api.error(userRes))
        return false

      this.$store.commit('setUser', userRes.data)
      const permissionRes = await getUserPermissionTree()
      if (api.error(permissionRes))
        return false

      this.$store.commit('setPermission', permissionRes.data.roots)
      return true
    },
  },
})
