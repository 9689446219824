
import Vue from "vue";
import Ellipsis from "@/components/Ellipsis.vue";
import { DesignFileView } from "@/api/customized-simulation-resources";
import { safeJsonParse } from "@/utils/safe-json-parse";
import { convert_instanceid_to_uuid } from "@/utils/convert-instanceid-to-uuid";
import { convert_uuid_byte } from "@/utils/convert-uuid-to-byteid";

export default Vue.extend({
  components: { Ellipsis },
  data() {
    return {
      selectedItem: null as DesignFileView | null
    };
  },
  computed: {
    bindedList(): DesignFileView[] {
      return this.$store.getters["customizedSimulationResourcesModule/bindedList"];
    }
  },
  methods: {
    selectItem(item: DesignFileView) {
      this.selectedItem = item;
      const compoentIds = safeJsonParse(item.viewSettings).componentIds.map(v =>
        convert_uuid_byte(convert_instanceid_to_uuid(v, 1))
      );
      const perspective = safeJsonParse(item.viewSettings).perspective;
      const api3d = this.$store.getters["modelViewModule/api3d"];
      api3d.showAll();
      api3d.update();
      api3d.restoreHighLightRenderElement();
      compoentIds.forEach(v => {
        api3d.highLightRenderElement(v);
      });
      api3d.setCurrentLook(perspective, true);
    },
    isSelected(item: DesignFileView) {
      return this.selectedItem?.id === item.id;
    }
  }
});
