
import { getModelByQuestionId, JobType } from "../../api/BimOperationQuestion";
import Vue from "vue";
import * as api from "../../api";
import TaskModelViewer from "../../features/collage/task-detail/task-model-viewer.vue";
export default Vue.extend({
  data() {
    return {
      modelViewVisible: false,
      viewFile: {}
    };
  },
  async mounted() {
    this.$store.dispatch("getSessionId");
    const questionId = this.$route.query.id;
    const res = await getModelByQuestionId({
      taskId: `${questionId}`,
      isTaskRule: true,
      type: JobType.questionBank,
      skipCount: 0,
      maxResultCount: 999
    });
    if (res && !api.error(res)) {
      const file = res.data.items[0].uploadFile;
      this.viewFile = {
        fileExtention: "." + file.fileExtention,
        fileId: file.fileId,
        fileName: file.fileName,
        fileUrl: file.fileUrl
      };
    }
    this.modelViewVisible = true;
  },
  components: { TaskModelViewer }
});
