import { http_get, http_put } from './helpers'
import { version } from './config'
import type {
  OptionalTree, ProductSummary, TodoEvents, UserSummary,
} from './types'

// GET /api/v${version}/v/Product/Summary
// 产品概览
export const getProductSummary = http_get<
    void,
    ProductSummary
>(`/api/v${version}/v/Product/Summary`)

// GET /api/v{version}/a/User/Summary
// 用户概览
export const getUserSummary = http_get<
    void,
    UserSummary
>(`/api/v${version}/a/User/Summary`)

// GET /api/v{version}/v/ToDoEvent
// 代办事件
export const getTodoEvent = http_get<
    void,
    TodoEvents
>(`/api/v${version}/v/ToDoEvent`)

/**
 * 获取快捷入口设置树
 */
export const getOptionalTree = http_get<
    void,
    {
      roots: OptionalTree[]
    }
>(`/api/v${version}/v/QuickEntry/OptionalTree`)

/**
 * 保存快捷入口设置树
 */
export const postQuickEntry = http_put<
    string[],
    void
>(`/api/v${version}/v/QuickEntry`)
