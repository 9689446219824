import Vue from 'vue';
import VueRouter from 'vue-router';
import index from '../views/index.vue';
import Login from '../views/login/index.vue';
import ModelCheck from '../views/model-check/index.vue';
import ModelView from '../views/model-view/index.vue';
import ModelBrowsing from '../views/model-browsing/index.vue';
import ComponentIntroduction from '../views/component-introduction/index.vue';
import AuxiliaryPositioning from '../views/auxiliary-positioning/index.vue';
import TwoThreeDimensionalComparison from '../views/two-three-dimensional-comparison/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: index,
    redirect: '/home/system',
    children: [
      {
        path: 'home',
        name: 'Home',
        redirect: '/home/system',
        component: () => import('@/views/home/index.vue'),
        children: [
          {
            path: 'system',
            name: 'System',
            component: () => import('@/views/home/System.vue'),
          },
          {
            path: 'entrance',
            name: 'Entrance',
            component: () => import('@/views/home/SetEntrance.vue'),
            meta: {
              activeMenu: '/home/system',
              name: '快捷入口设置',
            },
          },
          {
            path: 'account',
            name: 'Account',
            component: () => import('@/views/home/Account.vue'),
            meta: {
              name: '账户设置',
            },
          },
          {
            path: 'message',
            name: 'Message',
            component: () => import('@/views/home/Message.vue'),
            meta: {
              name: '消息中心',
            },
          },
          {
            path: 'systemInfo',
            name: 'SystemInfo',
            component: () => import('@/views/home/SystemInfo.vue'),
            meta: {
              name: '基本信息',
            },
          },
        ],
      },
      {
        path: '/product',
        name: 'Product',
        redirect: '/product/list',
        component: () => import('@/views/product/index.vue'),
        children: [
          {
            path: 'list',
            name: 'ProductList',
            component: () => import('@/views/product/list/index.vue'),
            meta: {
              name: '产品列表',
            },
          },
          {
            path: 'add',
            name: 'AddProduct',
            component: () => import('@/views/product/addProduct/index.vue'),
            meta: {
              activeMenu: '/product/list',
              name: '添加产品',
            },
          },
          {
            path: 'edit',
            name: 'editProduct',
            component: () => import('@/views/product/addProduct/index.vue'),
            meta: {
              activeMenu: '/product/list',
              name: '编辑产品',
            },
          },
          {
            path: 'recycleBin',
            name: 'RecycleBin',
            meta: {
              name: '产品回收站',
            },
            component: () => import('@/views/product/recycleBin/index.vue'),
          },
          {
            path: 'audit',
            name: 'Audit',
            meta: {
              name: '产品审核',
            },
            component: () => import('@/views/product/audit/index.vue'),
          },
          {
            path: 'versionDetail/:id',
            name: 'VersionDetail',
            meta: {
              name: '版本详情',
              activeMenu: '/product/list',
            },
            component: () => import('@/views/product/versionDetail/index.vue'),
          },
          {
            path: 'brand',
            name: 'Brand',
            component: () => import('@/views/product/brand/index.vue'),
            meta: {
              name: '品牌管理',
            },
          },
          {
            path: '/product/brand/:type',
            name: 'BrandEdit',
            component: () => import('@/views/product/brand/BrandForm.vue'),
            meta: {
              activeMenu: '/product/brand',
            },
          },
          {
            path: 'classification',
            name: 'Classification',
            component: () => import('@/views/product/classification/index.vue'),
            meta: {
              name: '分类管理',
            },
          },
          {
            path: 'type',
            name: 'Type',
            component: () => import('@/views/product/type/index.vue'),
            meta: {
              name: '类型管理',
            },
          },
        ],
      },
      {
        path: '/user',
        name: 'user',
        redirect: '/user/memberList',
        component: () => import('@/views/user/index.vue'),
        children: [
          {
            path: 'memberList',
            name: 'MemberList',
            component: () => import('@/views/user/memberList/index.vue'),
            meta: {
              name: '会员列表',
            },
          },
          {
            path: 'memberEdit',
            name: 'MemberEdit',
            component: () => import('@/views/user/memberList/MemberEdit.vue'),
            meta: {
              activeMenu: '/user/memberList',
              name: '编辑会员',
            },
          },
          {
            path: 'level',
            name: 'Level',
            meta: {
              name: '等级权限',
            },
            component: () => import('@/views/user/level/index.vue'),
          },
          {
            path: 'task',
            name: 'Task',
            meta: {
              name: '任务设置',
            },
            component: () => import('@/views/user/task/index.vue'),
          },
          {
            path: 'list',
            name: 'UserList',
            component: () => import('@/views/user/list/index.vue'),
            meta: {
              name: '用户列表',
            },
          },
          {
            path: 'add',
            name: 'UserAdd',
            component: () => import('@/views/user/add/index.vue'),
            meta: {
              activeMenu: '/user/list',
            },
          },
          {
            path: 'edit',
            name: 'UserEdit',
            component: () => import('@/views/user/add/index.vue'),
            meta: {
              activeMenu: '/user/list',
            },
          },
          {
            path: 'role',
            name: 'Role',
            component: () => import('@/views/user/role/index.vue'),
            meta: {
              name: '角色权限',
            },
          },
          {
            path: 'role-create',
            name: 'RoleCreate',
            component: () => import('@/views/user/role/RoleCreate.vue'),
            meta: {
              activeMenu: '/user/role',
            },
          },
          {
            path: 'role-edit',
            name: 'RoleEdit',
            component: () => import('@/views/user/role/RoleCreate.vue'),
            meta: {
              activeMenu: '/user/role',
            },
          },
        ],
      },
      {
        path: '/operation',
        name: 'Operation',
        redirect: '/operation/messageSet',
        component: () => import('@/views/operation/index.vue'),
        children: [
          {
            path: 'messageSet',
            name: 'MessageSet',
            component: () => import('@/views/operation/messageSet/index.vue'),
            meta: {
              name: '消息设置',
            },
          },
          {
            path: 'create',
            name: 'MessageCreate',
            component: () => import('@/views/operation/messageSet/MessageForm.vue'),
            meta: {
              activeMenu: '/operation/messageSet',
              name: '创建消息',
            },
          },
          {
            path: 'edit',
            name: 'MessageEdit',
            component: () => import('@/views/operation/messageSet/MessageForm.vue'),
            meta: {
              activeMenu: '/operation/messageSet',
              name: '编辑消息',
            },
          },
        ],
      },
      {
        path: '/content',
        name: 'Content',
        component: () => import('@/views/content/index.vue'),
      },
      {
        path: '/statistics',
        name: 'Statistics',
        component: () => import('@/views/statistics/index.vue'),
        redirect: '/statistics/globalStatistics',
        children: [
          {
            path: 'globalStatistics',
            component: () => import('@/views/statistics/GlobalStatistics.vue'),
            name: 'GlobalStatistics',
          },
          {
            path: 'productStatistics',
            component: () => import('@/views/statistics/ProductStatistics.vue'),
            name: 'ProductStatistics',
          },
        ],
      },
      {
        path: '/collage',
        name: 'Collage',
        redirect: '/collage/jobs',
        component: () => import('@/views/collage/index.vue'),
        children: [
          {
            path: 'jobs',
            name: 'Jobs',
            component: () => import('@/views/collage/jobs/index.vue'),
            meta: {
              name: '智能评价',
            },
          },
          {
            path: 'classes',
            name: 'Classes',
            component: () => import('@/views/collage/classes/index.vue'),
            meta: {
              name: '班级管理',
            },
          },
          {
            path: 'simulationTraining',
            name: 'SimulationTraining',
            component: () => import('@/views/collage/simulation-training/index.vue'),
            meta: {
              name: '定制仿真',
            },
          },
          {
            path: '/collage/simulationTraining/practicalTrainingCourseConfigure',
            name: 'practicalTrainingCourseConfigure',
            component: () => import('@/views/collage/practical-training-course-configure/index.vue'),
            meta: {
              name: '实训课程',
              activeMenu: '/collage/SimulationTraining',
            },
          },
          {
            path: 'BIMPracticalOperationQuestion',
            name: 'BIMPracticalOperationQuestion',
            component: () => import('@/views/collage/bim-operation-question/index.vue'),
            meta: {
              name: 'BIM实操题',
            },
          },
          {
            path: 'customizedSimulationResources',
            name: 'CustomizedSimulationResources',
            component: () => import('@/views/collage/customized-simulation-resources/index.vue'),
            meta: {
              name: '定制仿真资源',
            },
          },
          {
            path: 'database',
            name: 'database',
            component: () => import('@/views/collage/database/index.vue'),
            meta: {
              name: '资源库',
            },
          },
          {
            path: 'questionBank',
            name: 'questionBank',
            component: () => import('@/views/collage/questionBank/index.vue'),
            meta: {
              name: '题库',
            },
          },
          {
            path: 'persons',
            name: 'Persons',
            component: () => import('@/views/collage/persons/index.vue'),
            meta: {
              name: '用户列表',
            },
          },
          {
            path: 'rules',
            name: 'Rules',
            component: () => import('@/views/collage/rules/index.vue'),
            meta: {
              name: '规则管理',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/model',
    name: 'Model',
    component: ModelCheck,
  },
  {
    path: '/model-view',
    name: 'Model',
    component: ModelView,
  },
  {
    path: '/modelBrowsing',
    name: 'ModelBrowsingPage',
    component: ModelBrowsing,
  },
  {
    path: '/componentIntroduction',
    name: 'ComponentIntroduction',
    component: ComponentIntroduction,
  },
  {
    path: '/auxiliaryPositioning',
    name: 'AuxiliaryPositioning',
    component: AuxiliaryPositioning,
  },
  {
    path: '/twoThreeDimensionalComparison',
    name: 'TwoThreeDimensionalComparison',
    component: TwoThreeDimensionalComparison,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
