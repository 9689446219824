
import { ibuidingAPI } from '@/api/ibuidingAPI';
import { Message } from 'element-ui';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    isTwoThree: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    api3d(): any {
      return this.$store.getters['modelViewModule/api3d'];
    },
    initialPerspective(): any {
      return this.$store.getters['customizedSimulationResourcesModule/editSimulationResourceById'].designFile.viewSettings;
    },
  },
  methods: {
    viewInitialPerspective() {
      if (!this.initialPerspective) {
        Message.warning('请先设置初始视角');
        return;
      }
      this.api3d.setCurrentLook(this.initialPerspective);
    },
    async setInitialPerspective() {
      const res = await ibuidingAPI.vAStudyDesignFilesSaveCreate({
        ...this.$store.getters['customizedSimulationResourcesModule/editSimulationResourceById'].designFile,
        viewSettings: this.api3d.getCurrentLook(),
      });
      if (!(res as any).error) {
        Message.success({
          message: '初始视角设置成功',
          duration: 1000,
          offset: 700,
          customClass: 'initialPerspectiveMessage',
        });
        await this.$store.dispatch('customizedSimulationResourcesModule/getSimulationResources', { catalogeId: this.$store.state.customizedSimulationResourcesModule.selectedCatalogue.id });
      }
    },
  },
});
