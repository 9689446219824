import { http_get, http_put } from './helpers'
import { version } from './config'
import type { Product, ProductAuditRecord } from './types'

export const getProductAuditList = http_get<
  {
    name?: string
    type?: number | null
    brandId?: string | null
    sorting?: string
    skipCount?: number
    maxResultCount?: number
  },
  {
    totalCount: number
    items: Product[]
  }
>(`/api/v${version}/a/Product/Audits`)

export const putProductAuditPass = http_put<
  {
    productIds: string[]
  },
  void
>(`/api/v${version}/a/Product/AuditPass`)

export const putProductAuditUnpass = http_put<
  {
    productIds: string[]
    reason: string
  },
  void
>(`/api/v${version}/a/Product/AuditNoPass`)

export const getProductAuditRecords = http_get<
  {
    pvId: string
  },
  ProductAuditRecord[]
>(`/api/v${version}/v/Product/{pvId}/AuditRecords`)
