import {
  createEditSimulationResourceDetails,
  createEditSimulationResourceDirectory,
  createOrEditSimulationResource,
  deleteSimulationResourceDetails,
  deleteSimulationResourceDirectory,
  deleteSimulationResources,
  DesignFileView,
  getSimulationResourceDetails,
  getSimulationResourceDirectory,
  getSimulationResources,
  GetSimulationResourcesResponse,
  SimulationResource,
  UploadFile,
  uploadSimulation2DDrawing,
} from '@/api/customized-simulation-resources';
import { Module } from 'vuex';
import * as api from '@/api';
import { Message } from 'element-ui';
import { safeJsonParse } from '@/utils/safe-json-parse';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { SimulationResourceCategory } from '@/types/simulation';
import { RootState } from '../types';

export type CustomizedSimulationResourcesState = {
  editCatalogue: { name?: string };
  selectedCatalogue: { name?: string; id?: number; createUserId?: number };
  catalogueList: { name: string; id: number; createUserId: number }[];
  searchText: string;
  simulationResourceList: SimulationResource[];
  editSimulationResource: SimulationResource;
  editSimulationResourceId: string;
  detailedListOfSimulationResources: DesignFileView[];
  selectedSimulationResourceDetail: DesignFileView;
  editSimulationResourceDetail: DesignFileView;
  selectedComponentIds: number[];
  drawingInfo: UploadFile;
  selectCategory: any;
};
const initialState: CustomizedSimulationResourcesState = {
  editCatalogue: {},
  selectedCatalogue: {},
  catalogueList: [],
  searchText: '',
  simulationResourceList: [],
  editSimulationResourceId: '',
  editSimulationResource: {
    designFile: {
      name: '',
      classType: 0,
      uploadFileId: 0,
      typeId: 0,
      isFinish: false,
      viewFileId: 0,
      creationTime: '',
      createUserId: 0,
      id: 0,
    },
    uploadFile: {
      fileId: '',
      fileName: '',
      fileExtention: '',
      fileConvertState: 0,
      fileSize: 0,
      fileUrl: '',
      creationTime: '',
      createUserId: 0,
      id: 0,
    },
  },
  detailedListOfSimulationResources: [],
  selectedSimulationResourceDetail: {
    designFileId: 0,
    title: '',
    viewSettings: '',
    createUserId: 0,
    creationTime: '',
    id: 0,
  },
  editSimulationResourceDetail: {
    designFileId: 0,
    title: '',
    viewSettings: '',
    createUserId: 0,
    creationTime: '',
    id: 0,
  },
  selectedComponentIds: [],
  drawingInfo: {
    fileUrl: '',
    fileConvertState: 0,
    fileExtention: '',
    fileId: '',
    fileName: '',
    fileSize: 0,
  },
  selectCategory: 'all',
};
const customizedSimulationResourcesModule: Module<CustomizedSimulationResourcesState, RootState> = {
  namespaced: true,
  state: { ...initialState },
  getters: {
    editSimulationResourceById(state) {
      return state.simulationResourceList.find((v) => v.designFile.id === state.editSimulationResourceId);
    },
    editCatalogueName(state) {
      return state.editCatalogue.name;
    },
    selectedCatalogueId(state) {
      return state.selectedCatalogue?.id;
    },
    hasEditItem(state) {
      return !!state.editSimulationResource.designFile.name;
    },
    editModelId(state) {
      return state.editSimulationResource.uploadFile.fileId;
    },
    editSimulationResourceId(state) {
      return state.editSimulationResource.designFile.id;
    },
    hasEditSimulationResourceDetail(state) {
      return !!state.editSimulationResourceDetail.title;
    },
    hasSelectedSimulationResourceDetail(state) {
      return !!state.selectedSimulationResourceDetail.title;
    },
    bindedList(state) {
      return state.detailedListOfSimulationResources?.filter(
        (v) => !!safeJsonParse(v.viewSettings).componentIds?.length
      );
    },
    hasDrawing(state) {
      return !!state.drawingInfo.fileName;
    },
  },
  mutations: {
    setEditCatalogue(state, payload) {
      state.editCatalogue = payload;
    },
    setSelectedCatalogue(state, payload) {
      state.selectedCatalogue = payload;
    },
    setCatalogueList(state, payload) {
      state.catalogueList = payload;
    },
    setSearchText(state, payload) {
      state.searchText = payload;
    },
    setSimulationResourceList(state, payload) {
      state.simulationResourceList = payload;
    },
    setEditSimulationResource(state, payload) {
      state.editSimulationResource = payload;
    },
    setEditSimulationResourceId(state, payload) {
      state.editSimulationResourceId = payload;
    },
    resetEditSimulationResource(state) {
      state.editSimulationResource = initialState.editSimulationResource;
    },
    setDetailedListOfSimulationResources(state, payload) {
      state.detailedListOfSimulationResources = payload;
    },
    setSelectedSimulationResourceDetail(state, payload) {
      state.selectedSimulationResourceDetail = payload;
    },
    setEditSimulationResourceDetail(state, payload) {
      state.editSimulationResourceDetail = payload;
    },
    resetEditSimulationResourceDetail(state) {
      state.editSimulationResourceDetail = initialState.editSimulationResourceDetail;
    },
    setSelectedComponentIds(state, payload) {
      state.selectedComponentIds = payload;
    },
    resetDetail(state) {
      state.detailedListOfSimulationResources = initialState.detailedListOfSimulationResources;
      state.selectedSimulationResourceDetail = initialState.selectedSimulationResourceDetail;
      state.editSimulationResourceDetail = initialState.editSimulationResourceDetail;
      state.selectedComponentIds = initialState.selectedComponentIds;
    },
    setDrawingInfo(state, payload) {
      state.drawingInfo = payload;
    },
    setSelectCategory(state, payload) {
      state.selectCategory = payload;
    },
  },
  actions: {
    async init({ state, commit, dispatch }) {
      await dispatch('getCatalogueList');
      commit('setSelectedCatalogue', state.catalogueList?.[0]);
      await dispatch('getSimulationResources', { catalogeId: state.selectedCatalogue.id });
    },
    async getCatalogueList({ state, commit, dispatch }) {
      const res = await getSimulationResourceDirectory({ maxResultCount: 999, skipCount: 0 });
      if (res && !api.error(res)) {
        commit('setCatalogueList', res.data.items);
      } else {
        Message.error(res.error.message);
      }
    },
    async createEditSimulationResourceDirectory({ state, commit, dispatch }, { name, id }) {
      const res = await createEditSimulationResourceDirectory({ name, id });
      if (res && !api.error(res)) {
        dispatch('getCatalogueList');
      } else {
        Message.error(res.error.message);
      }
    },
    async deleteSimulationResourceDirectory({ state, commit, dispatch }, { id }) {
      const res = await deleteSimulationResourceDirectory({ id });
      if (res && !api.error(res)) {
        dispatch('getCatalogueList');
      } else {
        Message.error(res.error.message);
      }
    },
    async createOrEditSimulationResource(
      { state, commit, dispatch },
      {
        category, name, modelId, convertState, fileName, fileSize, fileUrl,
      },
    ) {
      const res = await createOrEditSimulationResource({
        designFile: {
          classType: category,
          name,
          typeId: state.selectedCatalogue.id!,
          isFinish: false,
          id: state.editSimulationResource.designFile.id,
          viewFileId: state.editSimulationResource.designFile.viewFileId || undefined,
          viewSettings: state.editSimulationResource.designFile.viewSettings || undefined,
        },
        uploadFile: {
          fileConvertState: convertState,
          fileExtention: '.rvt',
          fileId: modelId,
          fileName,
          fileSize,
          fileUrl,
        },
      });
      if (res && !api.error(res)) {
        return await dispatch('getSimulationResources', { catalogeId: state.selectedCatalogue.id });
      }
      Message.error(res.error.message);
    },
    async getSimulationResources({ state, commit, dispatch }, { catalogeId }) {
      const res = await getSimulationResources({
        maxResultCount: 999,
        skipCount: 0,
        typeId: catalogeId,
      });
      if (res && !api.error(res)) {
        commit('setSimulationResourceList', res.data.items);
        return true;
      }
      Message.error(res.error.message);
    },
    async deleteSimulationResources({ state, commit, dispatch }, { id }) {
      const res = await deleteSimulationResources({
        id,
      });
      if (res && !api.error(res)) {
        dispatch('getSimulationResources', { catalogeId: state.selectedCatalogue.id });
      } else {
        Message.error(res.error.message);
      }
    },
    async getSimulationResourceDetails({ state, commit, dispatch }, { id }) {
      const res = await getSimulationResourceDetails({ id });
      if (res && !api.error(res)) {
        commit(
          'setDetailedListOfSimulationResources',
          // [
          //   ...state.detailedListOfSimulationResources.map(v =>
          //     res.data.designFileViewList?.find(d => d?.id === v?.id)
          //   ),
          //   ...res.data.designFileViewList?.filter(
          //     v => !state.detailedListOfSimulationResources.some(d => d?.id === v?.id)
          //   )
          // ].filter(Boolean)
          res.data.designFileViewList.sort((a, b) => (moment(a.creationTime!).isAfter(b.creationTime!) ? 1 : -1)),
        );
        if (!isEmpty(res.data.viewFile)) {
          commit('setDrawingInfo', res.data.viewFile);
        } else {
          commit('setDrawingInfo', initialState.drawingInfo);
        }
      } else {
        Message.error(res.error.message);
      }
    },
    async deleteSimulationResourceDetails({ state, commit, dispatch }, { id }) {
      const res = await deleteSimulationResourceDetails({ id });
      if (res && !api.error(res)) {
        dispatch('getSimulationResourceDetails', {
          id: state.editSimulationResource.designFile.id,
        });
      } else {
        Message.error(res.error.message);
      }
    },
    async createEditSimulationResourceDetails(
      { state, commit, dispatch },
      { title, viewSettings, id },
    ) {
      const res = await createEditSimulationResourceDetails({
        designFileId: state.editSimulationResource.designFile.id!,
        title,
        viewSettings,
        id,
      });
      if (res && !api.error(res)) {
        dispatch('getSimulationResourceDetails', {
          id: state.editSimulationResource.designFile.id,
        });
      } else {
        Message.error(res.error.message);
      }
    },
    async bindComponent({
      state, getters, commit, dispatch,
    }) {
      if (!getters.hasSelectedSimulationResourceDetail) {
        Message.warning('请先选择绑定的项');
        return;
      }
      if (!state.selectedComponentIds.length) {
        Message.warning('请在模型中或在结构树选择要关联的构件');
        return;
      }
      const res = await createEditSimulationResourceDetails({
        designFileId: state.editSimulationResource.designFile.id!,
        viewSettings: JSON.stringify({
          ...safeJsonParse(state.selectedSimulationResourceDetail.viewSettings),
          componentIds: state.selectedComponentIds,
        }),
        title: state.selectedSimulationResourceDetail.title,
        id: state.selectedSimulationResourceDetail.id,
      });
      if (res && !api.error(res)) {
        dispatch('getSimulationResourceDetails', {
          id: state.editSimulationResource.designFile.id,
        });
        commit('setSelectedSimulationResourceDetail', {
          ...state.selectedSimulationResourceDetail,
          viewSettings: JSON.stringify({
            ...safeJsonParse(state.selectedSimulationResourceDetail.viewSettings),
            componentIds: state.selectedComponentIds,
          }),
        });
        Message.success('关联构件成功');
      } else {
        Message.error(res.error.message);
      }
    },
    async bind2DPerspective({
      state, getters, commit, dispatch,
    }, { perspective }) {
      if (!getters.hasSelectedSimulationResourceDetail) {
        Message.warning('请先选择绑定的项');
        return;
      }
      const res = await createEditSimulationResourceDetails({
        designFileId: state.editSimulationResource.designFile.id!,
        viewSettings: JSON.stringify({
          ...safeJsonParse(state.selectedSimulationResourceDetail.viewSettings),
          twoDPerspective: perspective,
        }),
        title: state.selectedSimulationResourceDetail.title,
        id: state.selectedSimulationResourceDetail.id,
      });
      if (res && !api.error(res)) {
        dispatch('getSimulationResourceDetails', {
          id: state.editSimulationResource.designFile.id,
        });
        commit('setSelectedSimulationResourceDetail', {
          ...state.selectedSimulationResourceDetail,
          viewSettings: JSON.stringify({
            ...safeJsonParse(state.selectedSimulationResourceDetail.viewSettings),
            twoDPerspective: perspective,
          }),
        });
        Message.success('图纸视角设定成功');
      } else {
        Message.error(res.error.message);
      }
    },
    async bindPerspective({
      state, getters, commit, dispatch,
    }, { perspective }) {
      if (!getters.hasSelectedSimulationResourceDetail) {
        Message.warning('请先选择绑定的项');
        return;
      }
      const res = await createEditSimulationResourceDetails({
        designFileId: state.editSimulationResource.designFile.id!,
        viewSettings: JSON.stringify({
          ...safeJsonParse(state.selectedSimulationResourceDetail.viewSettings),
          perspective,
        }),
        title: state.selectedSimulationResourceDetail.title,
        id: state.selectedSimulationResourceDetail.id,
      });
      if (res && !api.error(res)) {
        dispatch('getSimulationResourceDetails', {
          id: state.editSimulationResource.designFile.id,
        });
        commit('setSelectedSimulationResourceDetail', {
          ...state.selectedSimulationResourceDetail,
          viewSettings: JSON.stringify({
            ...safeJsonParse(state.selectedSimulationResourceDetail.viewSettings),
            perspective,
          }),
        });
        Message.success('关联视角成功');
      } else {
        Message.error(res.error.message);
      }
    },
    async unbindComponent({
      state, getters, commit, dispatch,
    }) {
      if (!getters.hasSelectedSimulationResourceDetail) {
        Message.warning('请先选择解绑的项');
        return;
      }
      const res = await createEditSimulationResourceDetails({
        designFileId: state.editSimulationResource.designFile.id!,
        viewSettings: JSON.stringify({
          ...safeJsonParse(state.selectedSimulationResourceDetail.viewSettings),
          componentIds: [],
        }),
        title: state.selectedSimulationResourceDetail.title,
        id: state.selectedSimulationResourceDetail.id,
      });
      if (res && !api.error(res)) {
        dispatch('getSimulationResourceDetails', {
          id: state.editSimulationResource.designFile.id,
        });
        Message.success('解绑构件成功');
      } else {
        Message.error(res.error.message);
      }
    },
    async uploadSimulation2DDrawing({
      state, getters, commit, dispatch,
    }, viewFile) {
      const res = await uploadSimulation2DDrawing({
        viewFile,
        id: state.editSimulationResource.designFile.id!,
      });
      if (res && !api.error(res)) {
        dispatch('getSimulationResourceDetails', {
          id: state.editSimulationResource.designFile.id,
        });
      } else {
        Message.error(res.error.message);
      }
    },
  },
};
export default customizedSimulationResourcesModule;
