
import Vue from 'vue';
import LeftMenu from '@/features/component-introduction/left-menu/index.vue';
import ModelView from '@/components/model-view/index.vue';
import RightMenu from '@/features/simulation-resource-model-view-right-menu/index.vue';
import BrowsePanel from '@/features/component-introduction/browse-panel/index.vue';
import { getComponentInformation } from '@/api/model-view';
import { generateComponentDetail, strToInt32 } from '@/features/collage/get-component-detail';
import { DesignFileView } from '@/api/customized-simulation-resources';
import InitialPerspectiveBtn from '@/components/InitialPerspectiveBtn.vue';

const { spdengine } = window as any;
export default Vue.extend({
  components: {
    LeftMenu,
    ModelView,
    RightMenu,
    BrowsePanel,
    InitialPerspectiveBtn,
},
  data() {
    return {};
  },
  computed: {
    modelId() {
      return this.$store.getters['customizedSimulationResourcesModule/editModelId'];
    },
  },
  methods: {
    async handleClickComponent(uuid) {
      const api3d = this.$store.getters['modelViewModule/api3d'];
      const currentSelectedComponents = api3d.getSelectedElementGuids().map(strToInt32);
      if (currentSelectedComponents.length === 1) {
        const instanceId = currentSelectedComponents[0];
        const res = await getComponentInformation({
          modelId: this.modelId,
          instanceId,
        });
        if (res.status.error !== 0) {
          this.$message.error(res.status.errorDesc);
          return;
        }
        const componentDetails = generateComponentDetail(res.instanceProperty.propertySets);
        this.$store.commit('simulationRightMenuModule/setComponentInformations', {
          name: res.instanceProperty.name,
          details: componentDetails,
        });
      } else {
        this.$store.commit('simulationRightMenuModule/setComponentInformations', {
          name: '',
          details: [],
        });
      }
      this.$store.commit('customizedSimulationResourcesModule/setSelectedComponentIds', [
        ...currentSelectedComponents,
      ]);
      (this.$refs.RightMenu as any).$refs.StructureTree.$refs.tree.setCheckedKeys([]);
    },
    handleCheck(uuids) {
      this.$store.commit(
        'customizedSimulationResourcesModule/setSelectedComponentIds',
        uuids.map((v) => strToInt32(v)),
      );
      const { Apis } = spdengine;
      const api = Apis.getApi(this.$store.state.modelViewModule.benchId);
      api['3D'].restoreHighLightRenderElement();
      uuids.forEach((v) => api['3D'].highLightRenderElement(v));
    },
    handleLoaded(benchId) {
      this.$store.commit('modelViewModule/setBenchId', benchId);
    },
    handleSelect(item: DesignFileView) {
      (this.$refs.leftMenu as any).checkItem(item);
    },
  },
  mounted() {
    this.$store.dispatch('customizedSimulationResourcesModule/getSimulationResourceDetails', {
      id: this.$store.getters['customizedSimulationResourcesModule/editSimulationResourceId'],
    });
  },
});
