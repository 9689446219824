import moment from 'moment';
import { VueConstructor } from 'vue';

const vueMoment = {
  install(Vue: VueConstructor) {
    Vue.filter('moment', (timeString, format) => {
      if (!timeString || !format) {
        return '';
      }
     return moment(timeString).format(format);
    });
  },
};

export default vueMoment;
