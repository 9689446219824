import { version } from './config'
import { http_post } from './helpers'

export enum JobType {
  // 0:作业 1:题库
  task,
  questionBank,
}
export interface CatalogueItem {
  createUserId: string
  creationTime: string
  id: string
  name: string
  parentId: number
  type: JobType
}
interface GetCatalogueListParams {
  parentId?: string
  name?: string
  filter?: string
  sorting?: string
  type?: JobType
  skipCount: number
  maxResultCount: number
}
interface GetCatalogueListResponse {
  items: CatalogueItem[]
  totalCount: number
}
interface CreateCatalogueParams { name: string; type?: JobType }
interface EditCatalogueParams { id: string; name: string; type?: JobType }
interface DeleteCatalogueParams { id: string }
interface GetQuestionsByCatalogueIdParams {
  typeId: JobType
  sorting?: '0' | '1'
  skipCount: number
  maxResultCount: number
}
interface GetQuestionsParams {
  typeId: JobType
  sorting?: '0' | '1'
  skipCount: number
  maxResultCount: number
  name?: string
  filter?: string
}
export type GetQuestionsResponse = {
  id: string
  name: string
  fileSize: number
  creationTime: any
  configure: number
}[]

interface GetQuestionsByCatalogueIdResponse {
  items: any[]
  totalCount: number
}
interface GetModelByQuestionIdParams {
  taskId: string
  isTaskRule: true
  type: JobType.questionBank
  isLocked?: boolean
  skipCount: number
  maxResultCount: number
}
interface GetModelByQuestionIdResponse {
  items: {
    taskFile: {
      taskId: string
    }
    uploadFile: {
      fileId: string
      fileName: string
      fileExtention: string
      fileTaskId: string
      fileSize: number
      fileUrl: string
    }
  }[]
  totalCount: number
}
interface CreateQuestionParams {
  typeId: number
  name: string
  type: JobType.questionBank
  id?: string
}
interface BindModelandQuestionParams {
  taskFile: {
    taskId: string
    isTaskRule: true
  }
  uploadFile: {
    fileId: string
    fileName: string
    fileExtention: string
    fileTaskId: string
    fileConvertState: 2
    fileSize: number
    fileUrl: string
    isLocked: boolean
  }
}
interface DeleteQuestionParams {
  id: string
}
export interface SaveQuantitativeRuleParams {
  taskId: string
  list: {
    difference: number
    mark: number
  }[]
  type: JobType.questionBank
}
interface SaveWeightAllocationParams {
  attributesPoint: number
  propertieStandard: number
  propertiesPoint: number
  taskId: string
}
interface SaveAttributeRuleParams {
  rulesContent: string
  taskId: string
  typeContent: string
}
interface ConfigureFinishParams {
  id: string
}
interface GetAllRuleParams {
  id: string
}
interface GetAllRuleResponse {
  checkRule: {
    rulesContent: string
    typeContent: string
  }
  checkRuleConfig: {
    propertiesPoint: number
    attributesPoint: number
    propertieStandard: number
  }
  checkRuleProperties: {
    difference: number
    mark: number
  }[]
}
interface SetUploadFileModelImage {
  id: string
  modelThumbnail: string
}
interface AddModelToTaskParams {
  questionId: string
  taskId: string
}
/**
 * 获取目录列表
 */
export const getCatalogueList = http_post<GetCatalogueListParams, GetCatalogueListResponse>(
  `/api/v${version}/a/Study/TaskTypes_PageList`,
)
/**
 * 创建目录
 */
export const createCatalogue = http_post<CreateCatalogueParams, void>(
  `/api/v${version}/a/Study/TaskTypes_Save`,
)
/**
 * 编辑目录
 */
export const editCatalogue = http_post<EditCatalogueParams, void>(
  `/api/v${version}/a/Study/TaskTypes_Save`,
)
/**
 * 删除目录
 */
export const deleteCatalogue = http_post<DeleteCatalogueParams, void>(
  `/api/v${version}/a/Study/TaskTypes_Remove`,
)
/**
 * 获取此id目录下的题目
 */
export const getQuestionsByCatalogueId = http_post<
  GetQuestionsByCatalogueIdParams,
  GetQuestionsByCatalogueIdResponse
>(`/api/v${version}/a/Study/Tasks_PageList`)
/**
 * 通过目录id获取该目录的题目 新接口
 */
export const getQuestions = http_post<GetQuestionsParams, GetQuestionsResponse>(
  `/api/v${version}/a/Study/TasksQuestion_List`,
)

/**
 * 获取此id题目下的模型
 */
export const getModelByQuestionId = http_post<
  GetModelByQuestionIdParams,
  GetModelByQuestionIdResponse
>(`/api/v${version}/a/Study/TaskFiles_PageList`)
/**
 * 创建题目
 */
export const createQuestion = http_post<CreateQuestionParams, string>(
  `/api/v${version}/a/Study/Tasks_Save`,
)

/**
 * 将模型与题目绑定
 */
export const bindModelandQuestion = http_post<BindModelandQuestionParams, string>(
  `/api/v${version}/a/Study/TaskFileQuestionExtends_Save`,
)

/**
 * 删除题目（删除作业）
 */
export const deleteQuestion = http_post<DeleteQuestionParams, void>(
  `/api/v${version}/a/Study/Tasks_Remove`,
)
/**
 * 保存数量配置规则
 */
export const saveQuantitativeRule = http_post<SaveQuantitativeRuleParams, void>(
  `/api/v${version}/a/Study/CheckRuleProperties_Save`,
)

/**
 * 保存权重配置
 */
export const saveWeightAllocation = http_post<SaveWeightAllocationParams, void>(
  `/api/v${version}/a/Study/CheckRuleConfigs_Save`,
)
/**
 * 保存属性配置
 */
export const saveAttributeRule = http_post<SaveAttributeRuleParams, void>(
  `/api/v${version}/a/Study/CheckRules_Save`,
)
/**
 * 题目配置完成
 */
export const configureFinish = http_post<ConfigureFinishParams, void>(
  `/api/v${version}/a/Study/Tasks_ConfigureOver`,
)
/**
 * 获取所有配置规则
 */
export const getAllRule = http_post<GetAllRuleParams, GetAllRuleResponse>(
  `/api/v${version}/a/Study/CheckRules_QueryDetailByTaskId`,
)
/**
 * 给uploadFile添加模型缩略图
 */
export const setUploadFileModelImage = http_post<SetUploadFileModelImage, void>(
  `/api/v${version}/m/UploadFiles/UploadFiles_ModelThumbnail`,
)
/**
 * 将模型添加到task作业
 */
export const addModelToTask = http_post<AddModelToTaskParams, void>(
  `/api/v${version}/a/Study/Tasks_ChooseQuestion`,
)
