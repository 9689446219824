import { getConvertProgress, convertModel } from '@/api/model-view';

export async function startConvertModel({ modelId, resolve, reject }) {
  const getConvertProgressRes = await getConvertProgress(modelId);
  // 首次查询文件进度，模型还未开始转换
  if (getConvertProgressRes.status.error === 20012) {
    const convertModelRes = await convertModel(modelId);
    // 转换发起成功
    if (convertModelRes.status.error === 0) {
      pollingConversionProgress({ modelId, resolve, reject });
      return;
    }
      reject('转换发起失败');
      return;
  }
  //  首次查询文件进度，已经转换成功
  if (getConvertProgressRes.status.error === 0 && getConvertProgressRes.convertStatus === 0) {
    resolve();
    return;
  }
  //  首次查询文件进度，文件正在转换
  if (getConvertProgressRes.convertStatus === 1) {
    pollingConversionProgress({ modelId, resolve, reject });
    return;
  }
  //  首次查询文件进度，文件转换失败
  if (getConvertProgressRes.status.error !== 0) {
    reject('查询转换进度失败');
  }
}
export async function pollingConversionProgress({ modelId, resolve, reject }) {
  let timer;
  timer = setInterval(async () => {
    const getConvertProgressRes = await getConvertProgress(modelId);
    // 转换进度成功，清空定时器
    if (getConvertProgressRes.status.error === 0 && getConvertProgressRes.convertStatus === 0) {
      clearInterval(timer);
      resolve();
      return;
    }
    // 转换进度失败，清空定时器，将file状态改为fail
    if (getConvertProgressRes.status.error !== 0) {
      clearInterval(timer);
      reject('模型转换失败');
    }
  }, 4000);
}
