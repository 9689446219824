import type { Module } from 'vuex'
import { Message } from 'element-ui'
import { isEmpty } from 'lodash-es'
import Vue from 'vue'
import type { RootState } from '../types'
import { CompletionStatus, SimulationResourceCategory } from '@/types/simulation'
import * as api from '@/api'
import {
  addMember,
  createEditSimulationTraining,
  createEditSimulationTrainingCoursePage,
  createEditSimulationTrainingDirectory,
  deleteMember,
  deleteSimulationTraining,
  deleteSimulationTrainingCoursePage,
  deleteSimulationTrainingDirectory,
  getMemberDetails,
  getSimulationTrainingCoursePageList,
  getSimulationTrainingDirectory,
  getSimulationTrainingList,
} from '@/api/simulation-training'
import type {
  SimulationTraining,
  SimulationTrainingCoursePage,
  SimulationTrainingDirectory,
  UserDetail,
} from '@/api/simulation-training'

export interface SimulationTrainingState {
  editCatalogue: SimulationTrainingDirectory
  selectedCatalogue: SimulationTrainingDirectory
  catalogueList: SimulationTrainingDirectory[]
  searchText: string
  simulationTrainingList: SimulationTraining[]
  editSimulationTraining: SimulationTraining
  selectedSimulationTraining: SimulationTraining
  simulationTrainingCoursePageList: SimulationTrainingCoursePage[]
  activeSimulationTrainingCoursePage: SimulationTrainingCoursePage
  memberList: UserDetail[]
  completeStatus: CompletionStatus

}
const initialState: SimulationTrainingState = {
  editCatalogue: { name: '' },
  selectedCatalogue: { name: '' },
  catalogueList: [],
  searchText: '',
  simulationTrainingList: [],
  editSimulationTraining: {
    typeId: 0,
    beginTime: '',
    endTime: '',
    logo: '',
    title: '',
    major: '',
    grade: '',
  },
  selectedSimulationTraining: {
    typeId: 0,
    beginTime: '',
    endTime: '',
    logo: '',
    title: '',
    major: '',
    grade: '',
  },
  simulationTrainingCoursePageList: [],
  activeSimulationTrainingCoursePage: {
    classType: 0,
    designTaskId: 0,
    sort: 0,
    viewSettings: '',
  },
  memberList: [],
  completeStatus: CompletionStatus.notStart,

}
const simulationTrainingModule: Module<SimulationTrainingState, RootState> = {
  namespaced: true,
  state: { ...initialState },
  getters: {
    editCatalogueName(state) {
      return state.editCatalogue.name
    },
    selectedCatalogueId(state) {
      return state.selectedCatalogue.id
    },
    filteredSimulationTrainingList(state) {
      return state.simulationTrainingList.filter(v => v.title?.includes(state.searchText))
    },
    hasEditCatalogue(state) {
      return !!state.editCatalogue.name
    },
    hasEditSimulationTraining(state) {
      return !!state.editSimulationTraining.title
    },
    maxSort(state) {
      return state.simulationTrainingCoursePageList.reduce((a, b) => Math.max(a, b.sort), 0)
    },
    notStartList(state): UserDetail[] {
      return (
        (state.memberList as UserDetail[]).filter(
          v => v.designFinishStatus === CompletionStatus.notStart,
        ) || []
      )
    },
    studyingList(state): UserDetail[] {
      return (
        (state.memberList as UserDetail[]).filter(
          v => v.designFinishStatus === CompletionStatus.studying,
        ) || []
      )
    },
    completedList(state): UserDetail[] {
      return (
        (state.memberList as UserDetail[]).filter(
          v => v.designFinishStatus === CompletionStatus.completed,
        ) || []
      )
    },
  },
  mutations: {
    setEditCatalogue(state, payload) {
      state.editCatalogue = payload
    },
    setSelectedCatalogue(state, payload) {
      state.selectedCatalogue = payload
    },
    setCatalogueList(state, payload) {
      state.catalogueList = payload
    },
    setSearchText(state, payload) {
      state.searchText = payload
    },
    setSimulationTrainingList(state, payload) {
      state.simulationTrainingList = payload
    },
    setEditSimulationTraining(state, payload) {
      state.editSimulationTraining = payload
    },
    resetEditSimulationTraining(state, payload) {
      state.editSimulationTraining = initialState.editSimulationTraining
    },
    setSelectedSimulationTraining(state, payload) {
      state.selectedSimulationTraining = payload
    },
    setSimulationTrainingCoursePageList(state, payload) {
      Vue.set(state, 'simulationTrainingCoursePageList', payload)
    },
    setActiveSimulationTrainingCoursePage(state, payload) {
      state.activeSimulationTrainingCoursePage = payload
    },
    setMemberList(state, payload) {
      state.memberList = payload
    },
    setCompleteStatus(state, payload) {
      state.completeStatus = payload
    },

  },
  actions: {
    async init({ state, commit, dispatch }) {
      await dispatch('getCatalogueList')
      commit('setSelectedCatalogue', state.catalogueList?.[0])
      dispatch('getSimulationTrainingList', { catalogueId: state.catalogueList?.[0]?.id })
    },
    async getCatalogueList({ state, commit, dispatch }) {
      const res = await getSimulationTrainingDirectory({
        skipCount: 0,
        maxResultCount: 999,
      })
      if (res && !api.error(res))
        commit('setCatalogueList', res.data.items)

      else
        Message.error(res?.error?.message)
    },
    async createEditSimulationTrainingDirectory(
      {
        state, getters, commit, dispatch,
      },
      { inputValue },
    ) {
      const res = await createEditSimulationTrainingDirectory({
        name: inputValue,
        id: getters.hasEditCatalogue ? state.editCatalogue.id : undefined,
      })
      if (res && !api.error(res))
        await dispatch('getCatalogueList')

      else
        Message.error(res?.error?.message)
    },
    async deleteCatalogue({ state, commit, dispatch }, item) {
      const res = await deleteSimulationTrainingDirectory({
        id: item.id,
      })
      if (res && !api.error(res))
        dispatch('getCatalogueList')

      else
        Message.error(res?.error?.message)
    },
    async getSimulationTrainingList({ state, commit, dispatch }, { catalogueId }) {
      const res = await getSimulationTrainingList({
        skipCount: 0,
        maxResultCount: 999,
        typeId: catalogueId,
      })
      if (res && !api.error(res))
        commit('setSimulationTrainingList', res.data.items)

      else
        Message.error(res?.error?.message)
    },
    async createEditSimulationTraining(
      {
        state, commit, getters, dispatch,
      },
      {
        beginTime, endTime, logo, title, id, status, major, grade,
      },
    ) {
      const res = await createEditSimulationTraining({
        typeId: state.selectedCatalogue.id!,
        beginTime,
        endTime,
        logo,
        major,
        grade,
        title,
        status,
        id: getters.hasEditSimulationTraining ? state.editSimulationTraining.id : id,
      })
      if (res && !api.error(res))
        await dispatch('getSimulationTrainingList', { catalogueId: state.selectedCatalogue.id })

      else
        Message.error(res?.error?.message)
    },
    async deleteSimulationTraining({ state, commit, dispatch }, { id }) {
      const res = await deleteSimulationTraining({ id })
      if (res && !api.error(res))
        dispatch('getSimulationTrainingList', { catalogueId: state.selectedCatalogue.id })

      else
        Message.error(res?.error?.message)
    },
    async getSimulationTrainingCoursePageList({ state, commit, dispatch }) {
      const res = await getSimulationTrainingCoursePageList({
        designTaskId: state.selectedSimulationTraining.id!,
        maxResultCount: 999,
        skipCount: 0,
      })
      if (res && !api.error(res))
        commit('setSimulationTrainingCoursePageList', res.data.items)

      else
        Message.error(res?.error?.message)
    },
    async updateSimulationTrainingCoursePageSort({ state, commit, dispatch },
      {
        classType, designTaskId, sort, viewSettings, designFileId, id,
      }) {
      const item = {
        classType,
        designTaskId,
        designFileId,
        sort,
        viewSettings,
        id,
      }
      const res = await createEditSimulationTrainingCoursePage(item)
      if (res && !api.error(res))
        return true

      Message.error(res?.error?.message)
    },
    async createEditSimulationTrainingCoursePage(
      { state, commit, dispatch },
      {
        classType, designTaskId, sort, viewSettings, designFileId, id,
      },
    ) {
      const item = {
        classType,
        designTaskId,
        designFileId,
        sort,
        viewSettings,
        id,
      }
      const res = await createEditSimulationTrainingCoursePage(item)
      if (res && !api.error(res)) {
        await dispatch('getSimulationTrainingCoursePageList')
        commit(
          'setActiveSimulationTrainingCoursePage',
          id
            ? item
            : state.simulationTrainingCoursePageList.length > 0
              ? state.simulationTrainingCoursePageList[
                state.simulationTrainingCoursePageList.length - 1
              ]
              : initialState.activeSimulationTrainingCoursePage,
        )
      }
      else {
        Message.error(res?.error?.message)
      }
    },
    async deleteSimulationTrainingCoursePage({ state, commit, dispatch }, { id }) {
      const deleteSort = state.simulationTrainingCoursePageList.find(v => v.id === id)!.sort
      const needChangeSortList = state.simulationTrainingCoursePageList.filter(
        v => v.sort > deleteSort,
      )
      const promises = [
        ...needChangeSortList.map(v => createEditSimulationTrainingCoursePage({ ...v, sort: v.sort - 1 })),
        deleteSimulationTrainingCoursePage({ id }),
      ]
      const res = await Promise.all(promises)
      if (res.every(res => res && !api.error(res))) {
        await dispatch('getSimulationTrainingCoursePageList')
        if (id === state.activeSimulationTrainingCoursePage.id) {
          commit(
            'setActiveSimulationTrainingCoursePage',
            state.simulationTrainingCoursePageList[
              state.simulationTrainingCoursePageList.length - 1
            ],
          )
        }
      }
      else {
        Message.error('删除出错，请重试')
      }
    },
    async addMember({ state, commit, dispatch }, { designTaskId, targetIds }) {
      const res = await addMember({
        designTaskId,
        targetIds,
      })
      if (res && !api.error(res))
        dispatch('getMemberDetails', { designTaskId: state.selectedSimulationTraining.id })

      else
        Message.error(res?.error?.message)
    },
    async deleteMember({ state, commit, dispatch }, { designTaskId, targetIds }) {
      const res = await deleteMember({
        designTaskId,
        targetIds,
      })
      if (res && !api.error(res))
        dispatch('getMemberDetails', { designTaskId: state.selectedSimulationTraining.id })

      else
        Message.error(res?.error?.message)
    },
    async getMemberDetails({ state, commit, dispatch }, { designTaskId }) {
      const res = await getMemberDetails({
        designTaskId,
        inDesignTask: true,
        maxResultCount: 999,
        skipCount: 0,
      })
      if (res && !api.error(res))
        commit('setMemberList', res.data.items)

      else
        Message.error(res?.error?.message)
    },
  },
}
export default simulationTrainingModule
