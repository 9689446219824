import { http_delete, http_get, http_put } from './helpers'
import { version } from './config'
import type { Member, MemberLevel } from './types'

// 获取会员列表
export const getMember = http_get<
  {
    userName: string | null
    nickName: string | null
    registeredAtFrom: string | null
    registeredAtTo: string | null
    memberLevelId: number | null
    sorting: string | null
    skipCount?: number
    maxResultCount?: number
  },
  {
    totalCount: number
    items: Member[] | []
  }
>(`/api/v${version}/a/Member`)

// 编辑会员
export const putMember = http_put<Partial<{
  id: string
  userName: string
  nickName: string
  levelId: number | null
  country: string | null
  province: string | null
  city: string | null
  isEnabled: boolean
  newPassword: string | null
  phoneNumber?: string
}>
  ,
  void
>(`/api/v${version}/a/Member/{id}`)

// 获取会员等级
export const getMemberLevel = http_get<void, MemberLevel[]>(`/api/v${version}/a/MemberLevel`)

// 批量启用
export const putMemberEnable = http_put<string[], void>(`/api/v${version}/a/Member/Enable`)

// 批量禁用
export const putMemberDisable = http_put<string[], void>(`/api/v${version}/a/Member/Disable`)

// 删除会员
export const delMember = http_delete<string[], void>(`/api/v${version}/a/Member`)

// 删除会员
export const del = http_delete<string[], void>(`/api/v${version}/a/Member`)

// 获取会员统计信息
export const getMemberStatistics = http_get<
  {
    id: string
  },
  {
    loginedNumberOfDays: number
    collectedProductCount: number
    concernedBrandCount: number
    downloadedCount: number
    growthValue: number
  }
>(`/api/v${version}/a/Member/{id}/Statistics`)

// 获取地区
export const getRegions = http_get<
  {
    parentCode: string | null
  },
  {
    code: string
    name: string
  }[]
>(`/api/v${version}/f/Regions`)

export const getMemberList = http_get<
  any,
  {
    totalCount: number
    items: []
  }
>(`/api/v${version}/a/Members`)
