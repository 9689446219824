
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      borderColor: {
        type: String,
        default: 'gray',
      },
    },
  });
