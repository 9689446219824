
import Vue, { PropType } from "vue";
import StructureTree from "./structure-tree.vue";
import ModelNotion from "./model-notation.vue";
import { isEmpty } from "lodash-es";
import { Commit, TaskFile } from "../types";
import TaskModelViewWander from "./task-model-view-wander.vue";
export interface TreeItem {
  name: string;
  height?: number;
  instanceIds?: number[];
  children?: TreeItem[];
}
export default Vue.extend({
  props: {
    commit: { type: Object as PropType<Commit> },
    file: { type: Object as PropType<TaskFile["uploadFile"]> },
    spdApi: { type: Object },
    spdApi3D: { type: Object },
    isFold: { type: Boolean },
    isWandering: { type: Boolean },
    tree: { type: Array as PropType<TreeItem[]> }
  },
  components: { StructureTree, ModelNotion, TaskModelViewWander },
  data(): {
    activeName: "structureTree" | "modelNotation" | "measurement" | "roam";
  } {
    return {
      activeName: "structureTree"
    };
  },
  computed: {
    isCommit(): Boolean {
      return !isEmpty(this.commit);
    },
    fileId(): String {
      return this.file.fileId;
    }
  },
  methods: {
    handleFoldClick() {
      this.$emit("update:isFold", true);
    }
  }
});
