
    import Vue from 'vue';
    import * as api from '@/api';

    export default Vue.extend({
        data() {
            return {
                news: [] as api.NewsItem[],
            };
        },
        computed: {
            unreadCount() {
                return this.$store.state.unreadCount;
            },
        },
        watch: {
            unreadCount() {
                this.getSitMessages();
            },
        },
        mounted() {
            this.getSitMessages();
        },
        methods: {
            toUserNews() {
                if (this.$route.name !== 'Message') this.$router.replace('/home/message');
            },
            toNews(id) {
                this.toUserNews();
                this.$store.commit('lookNewsId', id);
            },
            async getSitMessages() {
                const res = await api.getSitMessages({
                    receiverType: 1,
                    skipCount: 0,
                    maxResultCount: 5,
                });
                if (!api.error(res)) {
                    res.data.items.forEach((e) => {
                        if (e.content) {
                            e.content = e.content.replace(/<img[^>]+>/g, '*[图片]*').replace(/<[^>]+>/g, '');
                            if (e.content.length > 50) {
                                e.content = `${e.content.slice(0, 50)}...`;
                            }
                        }
                    });
                    this.news = res.data.items;
                }
            },
        },
    });

