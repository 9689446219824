
import { TaskOpinions_GetList, TaskOpinions_Remove } from "@/api/Jobs";
import { isEmpty } from "lodash";
import Vue, { PropType } from "vue";
import { Commit } from "../types";
import AddNotationModal from "./add-notation-modal.vue";
import * as api from "@/api";
import { myConfirm } from "@/utils/confirm";
import moment from "moment";
export interface Notation {
  mark: number;
  status: number;
  remark: string;
  taskCommitId: string;
  taskId: string;
  imageUrl: string;
  creationTime: string;
  createUserId: string;
  id: string;
}
export default Vue.extend({
  components: { AddNotationModal },
  props: {
    fileId: { type: String },
    commit: { type: Object as PropType<Commit> },
    spdApi: { type: Object },
    spdApi3D: { type: Object }
  },
  data() {
    return {
      remark: "",
      imageUrl: "",
      visible: false,
      notationName: "",
      base64PImg: "",
      notations: [] as Notation[],
      onlyShow: false,
      selectNotation: {} as Notation
    };
  },
  methods: {
    async addNotation() {
      this.onlyShow = false;
      const base64PImg: string = this.spdApi3D.enginePrintScreen();
      this.base64PImg = base64PImg;
      this.visible = true;
    },
    async onSave() {
      this.notationName = "";
      this.getNotations();
    },
    async getNotations() {
      const res: any = await TaskOpinions_GetList({
        taskId: this.commit.commitInfo.taskId,
        commitId: this.commit.commitInfo.id
      });
      if (res.success) {
        this.notations = [...res.data];
      }
    },
    async handleDelete(id) {
      myConfirm(this, {
        text: "确认删除此批注？",
        resolve: async () => {
          const res = await TaskOpinions_Remove({ id });
          if (res && !api.error(res)) {
            this.notations = this.notations.filter(({ id: originId }) => originId !== id);
          } else {
            this.$message.error(res.error?.message);
          }
        }
      });
    },
    checkNotation(notation: Notation) {
      this.onlyShow = true;
      this.base64PImg = notation.imageUrl;
      this.selectNotation = notation;
      this.visible = true;
    }
  },
  watch: {
    commit: {
      handler(val) {
        if (isEmpty(val)) {
          this.notations = [];
        }
        this.getNotations();
      },
      immediate: true
    }
  },
  filters: {
    formatTime(val) {
      if (!val) return "";
      return moment(val).format("YYYY-MM-DD");
    }
  }
});
