
import { getModelPath } from '@/api/model-view';
import Vue from 'vue';
import ModelToolbar from '../model-toolbar.vue';

const { spdengine } = window as any;
export default Vue.extend({
  props: {
    modelId: { type: String },
    darkMode: { type: Boolean },
    wireMode: { type: Boolean, default: true },
    isThreeDimension: { type: Boolean, default: true },
    toolbarEnable: { type: Boolean, default: true },

  },
  data() {
    return {
      benchId: 0,
      spdApi3D: null,
      spdApi: null,
      loading: true,
    };
  },
  computed: {
    containerId(): string {
      return this.modelId + (!this.isThreeDimension ? '2d' : '');
    },
  },
  methods: {
    setDarkBg(api3d: any) {
      const cfgLinearGradient = [
        { stop: 0, color: '#23313E' },
        { stop: 1, color: '#0F1014' },
      ];
      const width = 1920;
      const height = 1080;
      const canvas = document.createElement('canvas');
      canvas.setAttribute('width', width.toString());
      canvas.setAttribute('height', height.toString());
      const ctx: any = canvas.getContext('2d');
      const grad = ctx.createRadialGradient(width / 2, height / 2, 5, width / 2, height / 2, 800);
      for (const item of cfgLinearGradient) {
        grad.addColorStop(item.stop, item.color);
      }
      ctx.fillStyle = grad;
      ctx.fillRect(0, 0, width, height);
      const data = ctx.getImageData(0, 0, width, height);
      api3d.setBackgroundImageData(data.data, data.width, data.height);
    },
    async initModel() {
      const loading = this.$loading({
        lock: true,
        text: '模型加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const res = await getModelPath(this.modelId);
      if (res.status.error !== 0) {
        this.$message.error(res.status.errorDesc);
        return;
      }
      const benchId = spdengine.Scott.create({
        container: document.getElementById(this.containerId),
        projectId: this.modelId,
        dock: 4,
        system: this.isThreeDimension
          ? spdengine.SystemType.ST_3D_WSM
          : spdengine.SystemType.ST_2D_WSM,
        gisOptions: {},
        useThreads: false,
        tooBarState: false,
        useWasmMemory: true,
        engineSrcPath: '/static/',
        envInitSuccessCB: () => {
          this.benchId = benchId;
          spdengine.Scott.run(this.benchId);
          const api = spdengine.Apis.getApi(this.benchId);
          const api3d = api['3D'];
          this.spdApi3D = api3d;
          this.spdApi = api;
          const pipeMatrix = new spdengine.Matrix43D().compose(
            new spdengine.Vector33D(-100, -994, -1415),
            new spdengine.Quaternion3D().setFromEuler(new spdengine.Euler3D(0, 0, 0)),
            new spdengine.Vector33D(1, 1, 1),
          );
          spdengine.Scott.load(this.benchId, {
            url: `${process.env.VUE_APP_BORON}/${res.filePath}/`,
            sessionId: this.$store.state.sessionId,
            workerUrl: '',
            orgModelId: 1,
            newModelId: 1,
            modelMatrix: pipeMatrix, // 模型在该视图中的变化矩阵
          });
          api.PARAMS.eg_enableOutline = true;
          api.PARAMS.sr_enable = true;
          api.PARAMS.rd_continue = true;
          api.PARAMS.eg_mutipleSamples = 8;
          api.PARAMS.eg_maxLevel = 3;
          api.PARAMS.hl_highlightoutlineColor = 4294902015;
          api.PARAMS.hl_enableClick = true; // 开启左键点击
          api.PARAMS.bc_rightMuneEnable = true; // 开启右键菜单
          api.PARAMS.wd_gravityEnable = false;
          api.PARAMS.wd_collisionEnable = false;
          api.PARAMS.wd_moveSpeed = 10;
          api.PARAMS.wd_moveVerticalSpeed = 10;
          api.PARAMS.sr_enable = false;
          if (this.darkMode) {
            this.setDarkBg(api3d);
          }
          if (this.wireMode) {
            api.PARAMS.material_state = 'purecolor';
            api.PARAMS.eg_pureColor = 47635525; // 构件颜色
            api.PARAMS.eg_wireframeColor = 47635711; // 线框颜色
          }
          api3d.addSelectEventListener('click', async (e) => {
            if (!e.uuids[0]) return;
            this.$emit('onClickComponent', e.uuids[0]);
          });
          api3d.addSelectEventListener('region', async (e) => {
            if (!e.uuids[0]) return;
            this.$emit('onClickComponent', e.uuids[0]);
          });
          api3d.addEventListener('fileloader_geomallloadend', () => { // 整个模型都加载完成之后会触发的事件
            api3d.addEventListener('renderfinish', () => {
              this.loading = false;
              loading.close();
              this.$emit('onLoaded', benchId);
            });
          });
        },
      });
    },
  },
  async mounted() {
    await this.$store.dispatch('getSessionId');
    this.initModel();
  },
  components: { ModelToolbar },
});
