import Axios from 'axios'
import store from '@/store'

interface GetModelPathParams {
  fileId: string
}
interface GetModelPathResponse {
  status: Status
  filePath: string
  files: string[]
}
interface GetComponentInformationResponse {
  status: Status
  instanceProperty: {
    id: number
    name: string
    instanceType: string
    propertySets: {
      name: string
      innerName: string
      properties: {
        name: string
        innerName: string
        value: string
        displayValue: string
        valueType: number
      }[]
    }[]
  }
}
interface GetModelTreeResponseChildren {
  name: string
  height?: number
  instanceIds?: number[]
  children?: ModelLevelTree[]
}
interface ModelLevelTree {
  name: string
  children: GetModelTreeResponseChildren[]
}

interface Status {
  error: number
  errorDesc: string
}
interface GetModelTreeResponse {
  status: Status
  modelLevelTree: ModelLevelTree
}
/**
 * 获取模型路径
 */
export const getModelPath = async (modelId: string) => {
  const res = await Axios.get<any, GetModelPathResponse>(
    `${process.env.VUE_APP_BORON}/Boron/GetRadonPath?fileId=${modelId}`,
    {
      headers: {
        Authorization: `Bearer ${store.state.sessionId}`,
      },
    },
  )
  return res
}

/**
 * 获取构件信息
 */
export const getComponentInformation = async (data: { modelId: string; instanceId: number }) => {
  const res = await Axios.get<void, GetComponentInformationResponse>(
    `${process.env.VUE_APP_BORON}/Boron/GetInstanceProperty?fileId=${data.modelId}&instanceId=${data.instanceId}`,
    {
      headers: {
        Authorization: `Bearer ${store.state.sessionId}`,
      },
    },
  )
  return res
}
/**
 * 获取构件信息
 */
export const getModelTree = async (modelId: string) => {
  const res = await Axios.get<void, GetModelTreeResponse>(
    `${process.env.VUE_APP_BORON}/Boron/GetModelLevelTree?fileId=${modelId}`,
    {
      headers: {
        Authorization: `Bearer ${store.state.sessionId}`,
      },
    },
  )
  return res
}

/**
 * 发起转换模型
 */
export const convertModel = async (modelId) => {
  const res = await Axios.post<
    {
      fileId: string
      tessLevel?: number
    },
    { status: { error: number }; convertStatus: number }
  >(
    `${process.env.VUE_APP_BORON}/Boron/ConvertFile`,
    {
      fileId: modelId,
      tessLevel: 4,
    },
    {
      headers: {
        Authorization: `Bearer ${store.state.sessionId}`,
      },
    },
  )
  return res
}

/**
 * 查询模型转换状态
 */
export const getConvertProgress = async (modelId) => {
  const res = await Axios.get<
    void,
    { status: { error: number }; convertStatus: number; progressDesc: string }
  >(`${process.env.VUE_APP_BORON}/Boron/GetConvertProgress?fileId=${modelId}`, {
    headers: {
      Authorization: `Bearer ${store.state.sessionId}`,
    },
  })
  return res
}

/**
 * 查找构件集合
 */
export const findComponentCollection = async ({
  modelId,
  attributeName,
  attributeValue,
  searchMethod,
}) => {
  const res = await Axios.get<
    void,
    { instances: number[]; status: { error: number; errorDesc: string } }
  >(
    `${
      process.env.VUE_APP_BORON
    }/Boron/GetFilteredInstances?fileId=${modelId}&propertyName=${attributeName}&propertyValue=${attributeValue}&fuzzy=${!searchMethod}`,
    {
      headers: {
        Authorization: `Bearer ${store.state.sessionId}`,
      },
    },
  )
  return res
}
