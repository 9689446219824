
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      content: {
        type: String,
        required: true,
      },
      closable: {
        type: Boolean,
        default: false,
      },
      checkable: {
        type: Boolean,
        default: false,
      },
      checked: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'label', // round label outlined
      },
    },
    computed: {
      classes(): any[] {
        return [
          'z-tag',
          `z-tag__${this.type}`,
          { 'z-tag__closable': this.closable },
          { 'z-tag__checkable': this.checkable },
          { checked: this.checkable && this.checked },
        ];
      },
    },
    methods: {
      onClose() {
        this.$emit('closed');
      },
      onClick() {
        if (this.checkable && typeof this.checked === 'boolean') {
          this.$emit('update:checked', !this.checked);
        }
      },
    },
  });
