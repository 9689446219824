import 'uno.css'
import Vue from 'vue'
import ElementUI, { Form } from 'element-ui'
import permissionDirective from '@/directives/permission'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/element-ui.scss'
import '@/assets/style/base.css'
import ZUI from './zui'
import VueMoment from '@/packages/vue-moment'
import '@/zui/zui.scss'
import '@/icons'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(ZUI)
Vue.use(VueMoment)
Vue.directive('permission', permissionDirective)

router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem('token')) {
    next()
  }
  else if (to.path !== '/login') {
    next({
      path: '/login',
    })
  }
  else {
    next()
  }
  if (to.path === '/product/edit') { // 跳转详情
    if (from.path === '/product/audit' || (from.path === '/home/system' && (store.state as any).productInfoModule.currentProductInfo.status === 10))
      to.meta!.activeMenu = '/product/audit'
    else
      to.meta!.activeMenu = '/product/list'

    if (Object.keys((store.state as any).productInfoModule.currentProductInfo).length) {
      next()
    }
    else {
      next({
        path: '/product/list',
      })
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
