import {
  http_delete,
  http_get,
  http_post,
  http_put,
} from './helpers'
import { version } from './config'
import type { ProductCategoryItem, Propertie } from './types'

// 类型列表
export const getProductCategory = http_get<
  void,
  ProductCategoryItem[]
>(`/api/v${version}/a/ProductCategory`)

// 添加类型
export const putProductCategory = http_put<
  {
    name: string
  },
  {
    categoryId: string
  }
>(`/api/v${version}/a/ProductCategory`)

// 修改类型名称
export const modifyProductCategoryName = http_put<
  {
    id: number
    name: string
  },
  void
>(`/api/v${version}/a/ProductCategory`)

// 删除类型
export const deleteProductCategory = http_delete<
  {
    id: string
  },
  void
>(`/api/v${version}/a/ProductCategory/{id}`)

// 添加属性
export const putProductProperties = http_put<
  {
    categoryId: string
    propertyName: string
  },
  void
>(`/api/v${version}/a/ProductCategory/Properties`)

// 删除属性
export const deleteProductProperties = http_delete<
  {
    id: string
    properties: string[]
  },
  void
>(`/api/v${version}/a/ProductCategory/Properties`)

// 复制属性
export const copyProductProperties = http_put <
  {
    id: string
    properties: string[]
  },
  void
>(`/api/v${version}/a/ProductCategory/Properties/Copy`)

// 复制类型
export const copyProductCategory = http_put<
  {
    sourceId: string
    name: string
  },
  void
>(`/api/v${version}/a/ProductCategory/Copy`)

// 修改属性定义
export const putPropertyDefs = http_put<
  Propertie,
  void
>(`/api/v${version}/a/PropertyDefs`)

// 保存选择分类
export const postTypes = http_post<
  {
    productId: string
    pvId: string
    types: number[]
  },
  {
    productId: string
    pvId: string
    isNewVersion: boolean
  }
>(`/api/v${version}/v/Product/Step/Types`)
