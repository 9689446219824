
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ZBadge',
    props: {
      content: {
        type: [String, Number],
        required: true,
      },
      visible: {
        type: Boolean,
        default: true,
      },
      dot: {
        type: Boolean,
        default: false,
      },
      max: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {};
    },
    computed: {
      displayContent(): string | number {
        if (this.dot) return '';
        if (typeof this.max === 'number') {
          if (typeof this.content === 'number') {
            return this.content > this.max ? `${this.max}+` : this.content;
          }
          return parseInt(this.content, 10) > this.max ? `${this.max}+` : this.content;
        }
        return this.content;
      },
    },
    methods: {},
  });
