// 类型管理相关
import {
  getProductCategory,
  putProductCategory,
  deleteProductCategory,
  putProductProperties,
  modifyProductCategoryName, // 修改属性名称
  copyProductCategory, // 复制类型
  deleteProductProperties, // 删除属性
  putPropertyDefs, // 修改属性定义
  copyProductProperties,
} from '@/api/ProductCategory';
import * as api from '@/api';
import { Module } from 'vuex';
import { RootState, TypesState } from '../types';

const typesModule: Module<TypesState, RootState> = {
  namespaced: true,
  state: {
    types: [],
  },
  mutations: {
    types(state, types) {
      state.types = types;
    },
  },
  getters: {},
  actions: {
    // 类型列表
    async getProductCategory({ commit }, filterWord?) {
      const res = await getProductCategory();
      if (!api.error(res)) {
        if (filterWord) {
          commit('types', res.data.filter((item) => item.name.indexOf(filterWord) >= 0));
        } else {
          commit('types', res.data);
        }
      }
    },
    // 新增类型
    async putProductCategory({ dispatch }, { attrName, typeName }) {
      const res1 = await putProductCategory({ name: typeName });
      if (!api.error(res1)) {
        const res2 = await putProductProperties({
          categoryId: res1.data.categoryId,
          propertyName: attrName,
        });
        dispatch('getProductCategory');
        return res2;
      }
      return res1;
    },
    // 删除类型
    async deleteProductCategory({ dispatch }, id) {
      await deleteProductCategory({ id });
      dispatch('getProductCategory');
    },

    // 添加属性
    async putProductProperties({ dispatch }, { categoryId, propertyName }) {
      const res = await putProductProperties({
        categoryId,
        propertyName,
      });
      dispatch('getProductCategory');
      return res;
    },

    // 修改属性名称
    async modifyProductCategoryName({ dispatch }, { id, name }) {
      const res = await modifyProductCategoryName({
        id, name,
      });
      dispatch('getProductCategory');
      return res;
    },
    // 复制类型
    async copyProductCategory({ dispatch }, { sourceId, name }) {
      const res = await copyProductCategory({
        sourceId,
        name,
      });
      dispatch('getProductCategory');
      return res;
    },
    // 删除属性
    async deleteProductProperties({ dispatch }, attr) {
      const res = await deleteProductProperties(attr);
      dispatch('getProductCategory');
      return res;
    },
    // 更改属性定义
    async putPropertyDefs({ dispatch }, attr) {
      const res = await putPropertyDefs(attr);
      console.log(res);
      dispatch('getProductCategory');
    },
    // 复制属性
    async copyProductProperties({ dispatch }, option) {
      const res = await copyProductProperties(option);
      console.log(res);
      dispatch('getProductCategory');
    },
  },
};

export default typesModule;
