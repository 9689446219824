
import axios from "axios";
import Vue, { PropType } from "vue";
import { Commit, TaskFile } from "../types";
import LeftMenu from "./task-model-view-left-menu.vue";
import * as api from "@/api";
import ComponentDetail from "./task-model-view-component-detail.vue";
import { getComponentDetail, getModelTree } from "@/api/Jobs";
import { generateComponentDetail, strToInt32 } from "../get-component-detail";
import ModelToolbar from "@/components/model-toolbar.vue";
const { spdengine } = window as any;
const apiUrl = process.env.VUE_APP_BORON;

export default Vue.extend({
  components: { LeftMenu, ComponentDetail, ModelToolbar },
  props: {
    visible: Boolean,
    file: {
      type: Object as PropType<TaskFile["uploadFile"]>
    },
    commit: { type: Object as PropType<Commit> },
    newTab: { type: Boolean }
  },

  data() {
    return {
      ref: {} as HTMLElement,
      apiUrl,
      benchId: 1,
      spdApi3D: null,
      spdApi: null,
      loading: true,
      componentDetails: [] as { key: string; value: string }[],
      componentType: "",
      leftFold: false,
      rightFold: false,
      isWandering: false,
      modelTree: [],
      isDrawing: false,
      radian: 0,
      setParams: {},
      toolbarItem: {}
    };
  },

  methods: {
    together(item) {
      this.toolbarItem = item;
    },
    set(val) {
      this.setParams = val;
    },
    setRadian(val) {
      this.radian = val;
    },
    handleLeftFoldClick() {
      this.leftFold = !this.leftFold;
    },
    handleRightFoldClick() {
      this.rightFold = !this.rightFold;
    },
    handleReturn() {
      this.componentDetails = [];
      this.componentType = "";
      this.isWandering = false;
      this.$emit("update:visible", false);
    },
    setbg(api3d: any) {
      const cfgLinearGradient = [
        { stop: 0, color: "#23313E" },
        { stop: 1, color: "#0F1014" }
      ];
      const width = 1920;
      const height = 1080;
      const canvas = document.createElement("canvas");
      canvas.setAttribute("width", width.toString());
      canvas.setAttribute("height", height.toString());
      const ctx: any = canvas.getContext("2d");
      const grad = ctx.createRadialGradient(width / 2, height / 2, 5, width / 2, height / 2, 800);
      // eslint-disable-next-line no-restricted-syntax
      for (const item of cfgLinearGradient) {
        grad.addColorStop(item.stop, item.color);
      }
      ctx.fillStyle = grad;
      ctx.fillRect(0, 0, width, height);
      const data = ctx.getImageData(0, 0, width, height);
      // debugger;
      console.log(data);

      api3d.setBackgroundImageData(data.data, data.width, data.height);
      // return data;
    }
    // handleClick() {
    //   console.log(this.benchId);
    //   spdengine.Apis.getApi(this.benchId)["3D"].hiddenComponent("6");
    //   console.log(spdengine.Apis.getApi(this.benchId)["3D"]);
    // }
  },
  computed: {
    fileId(): String {
      return this.file.fileId;
    }
  },
  watch: {
    file: {
      async handler(val) {
        this.loading = true;
        const res: any = await axios.get(
          `${process.env.VUE_APP_BORON}/Boron/GetRadonPath?fileId=${this.file.fileId}`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.sessionId
            }
          }
        );
        if (res.status.error !== 0) {
          this.$message.error(res.status.errorDesc);
          return;
        }
        const benchId = spdengine.Scott.create({
          container: document.getElementById("taskModelViewerRef"),
          projectId: "sid",
          dock: 4,
          system: spdengine.SystemType.ST_3D_WSM,
          gisOptions: {},
          useThreads: false,
          tooBarState: false,
          useWasmMemory: true,
          engineSrcPath: "/static/",
          envInitSuccessCB: () => {
            this.benchId = benchId;
            spdengine.Scott.run(this.benchId);
            let api = spdengine.Apis.getApi(this.benchId);
            let api3d = api["3D"];
            this.spdApi3D = api3d;
            this.spdApi = api;
            let pipeMatrix = new spdengine.Matrix43D().compose(
              new spdengine.Vector33D(-100, -994, -1415),
              new spdengine.Quaternion3D().setFromEuler(new spdengine.Euler3D(0, 0, 0)),
              new spdengine.Vector33D(1, 1, 1)
            );
            spdengine.Scott.load(this.benchId, {
              url: this.apiUrl + "/" + res.filePath + "/", //.jdx模型所在后台路径地址
              sessionId: this.$store.state.sessionId,
              workerUrl: "", //加载模型worker代码所在地址，为了提升模型加载速率，spdengine拉取数据时启用多个worker（pc10个，移动端5个），多线程加载数据，有效加快加载速度
              orgModelId: 1, //原始model ID，原始model id 考虑到模型复用问题
              newModelId: 1, //该模型在该项目中的ID号，orgModelId对应的是模型的固有id号，而该id是在该视图中的唯一标识符
              modelMatrix: pipeMatrix //模型在该视图中的变化矩阵
            });
            api.PARAMS.eg_enableOutline = true;
            api.PARAMS.sr_enable = true;
            api.PARAMS.rd_continue = true;
            api.PARAMS.eg_mutipleSamples = 8;
            api.PARAMS.eg_maxLevel = 3;
            api.PARAMS.hl_highlightoutlineColor = 0xffff00ff;
            api.PARAMS.hl_enableClick = true; //开启左键点击
            api.PARAMS.bc_rightMuneEnable = true; //开启右键菜单
            api.PARAMS.wd_gravityEnable = false;
            api.PARAMS.wd_collisionEnable = false;
            api.PARAMS.wd_moveSpeed = 10;
            api.PARAMS.wd_moveVerticalSpeed = 10;
            this.setbg(api3d);
            api3d.addSelectEventListener("click", async e => {
              if (!e.uuids[0]) return;
              let uuid = strToInt32(e.uuids?.[0]);
              const res: any = await getComponentDetail(
                { fileId: this.file.fileId, instanceId: uuid },
                this.$store.state.sessionId
              );
              if (res.status.error === 0) {
                const componentDetails = generateComponentDetail(res.instanceProperty.propertySets);
                this.componentDetails = componentDetails;
                this.componentType = res.instanceProperty.name;
              }
            });
            api3d.addWanderEventListener("enable", e => {
              this.isWandering = true;
            });
            api3d.addWanderEventListener("disable", e => {
              this.isWandering = false;
            });
            this.loading = false;
          }
        });
        const treeRes: any = await getModelTree(
          { fileId: this.file.fileId },
          this.$store.state.sessionId
        );
        if (treeRes.status.error === 0) {
          let i = 0;
          const addKey = arr =>
            arr.map(item => ({
              ...item,
              key: i++,
              children: "children" in item ? addKey(item.children) : undefined
            }));
          this.modelTree = addKey(treeRes.modelLevelTree.children);
        } else {
          this.$message.error("获取模型树出错");
        }
      },
      deep: true
    },
    visible: {
      handler(val) {
        if (!val) {
          this.modelTree.splice(0);
        }
      }
    }
  }
});
