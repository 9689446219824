
import { DesignFileView } from '@/api/customized-simulation-resources';
import { safeJsonParse } from '@/utils/safe-json-parse';
import { ElForm } from 'element-ui/types/form';
import Vue from 'vue';

export default Vue.extend({
  props: {
    visible: { type: Boolean },
    item: { type: Object },
  },
  data() {
    return {
      form: {
        title: '',
        style: 'highlight' as 'highlight' | 'quarantine',
      },
      rules: {
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        style: [{ required: true, message: '请选择样式', trigger: 'change' }],
      },
    };
  },
  computed: {
    hasEditItem() {
      return this.$store.getters[
        'customizedSimulationResourcesModule/hasEditSimulationResourceDetail'
      ];
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:visible', false);
    },
    handleConifrm() {
      (this.$refs.form as ElForm).validate((valid) => {
        if (valid) {
          this.$emit('onConfirm', this.form);
          this.closeModal();
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    visible: {
      handler(val: boolean) {
        if (val) {
          this.form.title = this.item.title;
          this.form.style = safeJsonParse(this.item.viewSettings)?.style || 'highlight';
        }
      },
      deep: true,
    },
  },
});
