import { Api } from './ibuilding3d-api'
import { safeJsonParse } from '@/utils/safe-json-parse'

export const { api: ibuidingAPI } = new Api({
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  },
  transformRequest: [
    (data, headers) => {
      headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`
      return JSON.stringify(data)
    },
  ],
  transformResponse: [
    data => (safeJsonParse(data).data ? safeJsonParse(data).data : data),
  ],
})
