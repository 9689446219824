import { Module } from "vuex";
import { RootState } from "../types";
import * as api from "@/api";
import { Message } from "element-ui";
import { getSimulationResourceDetails } from "@/api/customized-simulation-resources";

export type ModelBrowsingState = {
  list: { name: string; id: string; components: any[] }[];
  selectedItem: { name: string; id: string; components: any[] };
};
const modelBrowsingModule: Module<ModelBrowsingState, RootState> = {
  namespaced: true,
  state: { list: [], selectedItem: { name: "", id: "", components: [] } },
  getters: {
    bindedList(state) {
      return state.list.filter(v => !v.components.length);
    }
  },
  mutations: {
    setList(state, payload) {
      state.list = payload;
    },
    setSelectedItem(state, payload) {
      state.selectedItem = payload;
    },
    resetAll(state) {
      state.list = [];
      state.selectedItem = { name: "", id: "", components: [] };
    }
  },
  actions: {
    async init({ state, commit, dispatch }) {
      commit("resetAll");
      dispatch("getSimulationResourceDetails",);
    },
    async getSimulationResourceDetails({ state, commit, dispatch }, { id }) {
      const res = await getSimulationResourceDetails({ id });
      if (res && !api.error(res)) {
        console.log(res);
      } else {
        Message.error(res.error.message);
      }
    },
    async createItem({ state, commit, dispatch }, { name }) {
      dispatch("getSimulationResourceDetails");
    },
    async bindingComponent({ state, commit, dispatch }, { components }) {
      dispatch("getSimulationResourceDetails");
    }
  }
};
export default modelBrowsingModule;
