
import { taskOpinions_Save } from "@/api/Jobs";
import Vue, { PropType } from "vue";
import { Commit } from "../types";
import FabricCanvas from "./fabric-canvas.vue";
import * as api from "@/api";
import { isNumber, startsWith } from "lodash";
import { Notation } from "./model-notation.vue";
export enum Tool {
  rectangle = "icon-zzj-juxing",
  circle = "icon-zzj-yuanxingweixuanzhong",
  pen = "icon-zzj-huabi",
  text = "icon-zzj-wenzi1",
  undo = "icon-zzj-chexiao1"
}
export default Vue.extend({
  components: { FabricCanvas },
  props: {
    visible: { type: Boolean },
    name: { type: String },
    base64PImg: { type: String },
    commit: { type: Object as PropType<Commit> },
    onlyShow: { type: Boolean },
    selectNotation: { type: Object as PropType<Notation> }
  },
  data() {
    return {
      tools: Object.keys(Tool)
        .filter(v => !startsWith(v, "icon"))
        .map(key => Tool[key]),
      selectedTool: "",
      colors: [
        "rgb(239, 67, 67)",
        "rgb(239, 124, 67)",
        "rgb(238, 233, 83)",
        "rgb(67, 238, 106)",
        "rgb(65, 239, 210)",
        "rgb(67, 160, 240)",
        "rgb(165, 67, 240)"
      ],
      selectedColor: "rgb(239, 67, 67)",
      currentIndex: -1
    };
  },
  methods: {
    handleIconClick(icon) {
      if (icon === Tool.undo) {
        if (this.currentIndex === -1) return;
        this.currentIndex -= 1;
        return;
      }
      this.selectedTool = icon === this.selectedTool ? "" : icon;
    },
    renderTip(icon: Tool) {
      switch (icon) {
        case Tool.rectangle:
          return "矩形";
        case Tool.circle:
          return "圆形";
        case Tool.pen:
          return "画笔";
        case Tool.text:
          return "文字";
        case Tool.undo:
          return "撤回";
        default:
          break;
      }
    },
    async handleConfirm() {
      this.$emit("update:visible", false);
      const res = await taskOpinions_Save({
        remark: this.name,
        commitId: this.commit?.commitInfo?.id,
        taskId: this.commit?.commitFile?.taskId,
        imageUrl: (this.$refs["child"] as any).saveImage()
      });
      if (res && !api.error(res)) {
        this.$message.success("添加批注成功！");
        this.$emit("onSave");
      } else {
        this.$message.error(res.error?.message);
      }
    }
  }
});
