export const generateComponentDetail: (
  params: {
    name: string
    innerName: string
    properties: {
      name: string
      innerName: string
      value: string
      displayValue: string
      valueType: number
    }[]
  }[]
) => { key: string; value: string }[] = (components) => {
  const allProperties = components.map(v => v.properties).flat()
  const details = allProperties
    .map(v => ({
      key: v.innerName,
      value: v.displayValue,
    }))
    .filter(v => v.value)
  return details
}

export function strToInt32(byteGuid) {
  const newId = convert_uuid_can_read(byteGuid)
  const strnum = newId.substr(0, 4)
  const strnumnew = strnum[2] + strnum[3] + strnum[0] + strnum[1]
  const numchar1 = parseInt(strnumnew, 0x10)
  return numchar1
}

function convert_uuid_can_read(str) {
  const string__ = '0123456789abcdef'
  const __id = str
  let newid = ''
  for (let i = 0; i < __id.length; i++) {
    const numchar = __id.charCodeAt(i).toString(16)
    const s = 4 - numchar.length
    let ch = ''
    for (let n = 0; n < s; n++)
      ch += '0'

    newid += ch + numchar
  }
  return newid
}
