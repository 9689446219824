
import ibHeader from '@/components/header/index.vue';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return { showHint: this.$store.state.userInfo.limitDays < 6 };
  },
  components: {
    ibHeader,
  },
});
